import { ReactNode } from 'react';

import { StationContainer } from '../../../../components/warehouse/StationContainer';
import { Text } from '../../../../components/warehouse/Text';

export type PackingContainerProps = {
  step: number;
  children: ReactNode;
  stickyFooter?: ReactNode[];
  title: string | ReactNode;
  orderNumber: string;
  recipient: string;
  merchantName?: string;
};

export const PackingContainer = ({
  children,
  step,
  stickyFooter,
  title,
  orderNumber,
  recipient,
  merchantName,
}: PackingContainerProps): JSX.Element => {
  return (
    <StationContainer
      stickyFooter={stickyFooter}
      stepper={{
        steps: ['Check items', 'Select parcel', 'Pack and print'],
        currentStep: step,
      }}
      title={title}
      subTitle={`Order #${orderNumber}`}
      sideText={
        <>
          {merchantName && (
            <Text variant="body2" weight="medium" tag="span">
              {merchantName}
            </Text>
          )}
          <Text variant="body2" weight="regular" tag="span">
            <Text variant="body2" weight="medium" tag="span">
              Recipient:
            </Text>{' '}
            {recipient}
          </Text>
        </>
      }
    >
      {children}
    </StationContainer>
  );
};
