"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrintTroubleshootLabelOutcomes = exports.PrintFulfilmentOrderOutcomes = exports.FulfillmentOrderLabelNotPrinted = exports.InsertOptionIsNotSelected = void 0;
const z = __importStar(require("zod"));
const generic_1 = require("./generic");
const packing_1 = require("./packing");
const UnknownOrder = z.object({
    outcome: z.literal('UNKNOWN_ORDER'),
});
const UnknownWarehouse = z.object({
    outcome: z.literal('UNKNOWN_WAREHOUSE'),
});
const MissingWarehouseId = z.object({
    outcome: z.literal('MISSING_WAREHOUSE_ID'),
});
const UnknownStation = z.object({
    outcome: z.literal('UNKNOWN_STATION'),
});
const InvalidOrderStatus = z.object({
    outcome: z.literal('INVALID_ORDER_STATUS'),
    expectedStatus: z.string().min(1).array(),
    actualStatus: z.string(),
});
const NoZplLabelAvailable = z.object({
    outcome: z.literal('NO_ZPL_LABEL_AVAILABLE'),
});
const UnknownPrinter = z.object({
    outcome: z.literal('UNKNOWN_PRINTER'),
    printerName: z.string(),
});
const StationAlreadyPrintingLabel = z.object({
    outcome: z.literal('STATION_ALREADY_PRINTING_LABEL'),
    stationId: z.number(),
    fulfilmentOrderId: z.string(),
});
exports.InsertOptionIsNotSelected = z.object({
    outcome: z.literal('INSERT_OPTION_NOT_SELECTED'),
});
exports.FulfillmentOrderLabelNotPrinted = z.object({
    outcome: z.literal('FULFILMENT_ORDER_LABEL_NOT_PRINTED'),
});
exports.PrintFulfilmentOrderOutcomes = z.union([
    generic_1.Success,
    generic_1.GenericError,
    UnknownOrder,
    InvalidOrderStatus,
    NoZplLabelAvailable,
    UnknownPrinter,
    UnknownWarehouse,
    UnknownStation,
    MissingWarehouseId,
    StationAlreadyPrintingLabel,
    exports.InsertOptionIsNotSelected,
]);
exports.PrintTroubleshootLabelOutcomes = z.union([
    generic_1.Success,
    generic_1.GenericError,
    UnknownOrder,
    NoZplLabelAvailable,
    UnknownPrinter,
    UnknownWarehouse,
    UnknownStation,
    MissingWarehouseId,
    packing_1.PicklistNotFound,
]);
