import { ReactElement } from 'react';
import { AlertMessage } from '../../../../components/warehouse/AlertMessage';
import { AlertMessageTypes } from '../../../../components/warehouse/AlertMessage/AlertMessage';
import { Button } from '../../../../components/warehouse/Button';
import { Container } from '../../../../components/warehouse/Container';
import { Layout } from '../../../../components/warehouse/Layout';
import { ErrorMessageWrapper, PortLabel } from './StartPicking.elements';

interface StartPickingProps {
  portId: number;
  openPort: () => Promise<void>;
  error: string | null;
}

export function StartPicking({
  portId,
  openPort,
  error,
}: StartPickingProps): ReactElement {
  return (
    <Layout>
      <Container alignCenter>
        <ErrorMessageWrapper>
          {error && (
            <AlertMessage type={AlertMessageTypes.Error}>{error}</AlertMessage>
          )}
        </ErrorMessageWrapper>
        <PortLabel data-testid="port-label">Picking Port {portId}</PortLabel>
        <Button
          variant="primary"
          size="large"
          onClick={openPort}
          testId="start-picking-button"
        >
          Start picking
        </Button>
      </Container>
    </Layout>
  );
}
