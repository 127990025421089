import { Warehouse } from 'api-schema';
import { FormikErrors, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { isNotErrorResponse } from '../../apiClient';
import { SnackbarDefaults } from '../../constants/snackbarDefaults';
import { useApiClient } from '../../hooks/useApiClient';
import { DeviceConfig, useDeviceConfig } from '../../hooks/useDeviceConfig';
import { transformZodErrorsToFormik } from '../../utils/forms/transformZodErrorsToFormik';
import {
  DeviceConfigSchema,
  DeviceConfigSchemaType,
} from './DeviceConfigForm.model';
import { DeviceConfigFormView } from './DeviceConfigForm.view';

export const DeviceConfigForm = () => {
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const apiClient = useApiClient();

  useEffect(() => {
    (async () => {
      try {
        const { data, status } = await apiClient.get('/warehouses');
        if (isNotErrorResponse(data, status)) {
          setWarehouses(data.warehouses ?? []);
        }
      } catch (error) {
        // error displayed by apiClient
      }
    })();
  }, [apiClient]);

  const [deviceConfig, setDeviceConfig] = useDeviceConfig();
  const formik = useFormik<DeviceConfigSchemaType>({
    initialValues: {
      kind: 'HANDHELD',
      warehouseId: '',
    },
    validate: (values) => {
      const errors: FormikErrors<DeviceConfigSchemaType> = {};
      if (!values.warehouseId) {
        errors.warehouseId = 'A warehouse is required.';
      }

      if (
        values.kind === 'PICK_STATION' ||
        values.kind === 'REPLENISHMENT_PORT'
      ) {
        if (!values.port) {
          errors.port =
            'Port number is required for a pick station or replenishment port';
        }
      } else if (values.kind === 'HANDHELD') {
        if (!values.deviceId) {
          errors.deviceId = 'Device id is required for handheld devices.';
        }
      } else if (
        values.kind === 'PACK_STATION' ||
        values.kind === 'SHORT_PICK_TROUBLESHOOT' ||
        values.kind === 'LABEL_TROUBLESHOOT'
      ) {
        if (!values.stationId) {
          errors.stationId = 'Station id is required.';
        }
      }
      const zodErrors =
        transformZodErrorsToFormik<DeviceConfigSchemaType>(DeviceConfigSchema)(
          values
        );
      return { ...errors, ...zodErrors };
    },
    onSubmit: (values) => {
      setDeviceConfig(values as DeviceConfig);
      enqueueSnackbar('Device configuration saved', SnackbarDefaults);
    },
  });

  switch (deviceConfig.kind) {
    case 'REPLENISHMENT_PORT':
      return (
        <Redirect
          to={`/warehouse/${deviceConfig.warehouseId}/replenishment-port/${deviceConfig.port}`}
        />
      );
    case 'PICK_STATION':
      return (
        <Redirect
          to={`/warehouse/${deviceConfig.warehouseId}/pick-station/${deviceConfig.port}`}
        />
      );
    case 'PACK_STATION':
      return (
        <Redirect
          to={`/warehouse/${deviceConfig.warehouseId}/pack-and-dispatch/${deviceConfig.stationId}`}
        />
      );
    case 'SHORT_PICK_TROUBLESHOOT':
      return (
        <Redirect
          to={`warehouse/${deviceConfig.warehouseId}/short-pick-troubleshoot/${deviceConfig.stationId}`}
        />
      );
    case 'LABEL_TROUBLESHOOT':
      return (
        <Redirect
          to={`warehouse/${deviceConfig.warehouseId}/label-troubleshoot/${deviceConfig.stationId}`}
        />
      );
    default:
      return <DeviceConfigFormView {...formik} warehouses={warehouses} />;
  }
};
