import { ShortPickTroubleshootStationType } from 'api-schema/lib/model';
import { ReactElement } from 'react';
import { AlertMessageTypes } from '../../components/warehouse/AlertMessage';
import { Layout } from '../../components/warehouse/Layout';
import { StartStation } from '../../components/warehouse/StartStation';
import { StationHeader } from '../../components/warehouse/StationHeader';
import { ToteScan } from '../../components/warehouse/ToteScan';
import { ResolutionToteAlertMessage } from './components/ResolutionToteAlertMessage';
import { ShortPickTroubleshootToteCheck } from './components/ShortPickTroubleshootToteCheck';
import { ScanToteErrorType, SuccessMessageType } from './ShortPickTroubleshoot';

type ShortPickTroubleshootStationProps = {
  clientState: ShortPickTroubleshootStationType;
  scanToteError?: ScanToteErrorType;
  dispatchOpenShortPickTroubleshoot: () => Promise<void>;
  dispatchCloseShortPickTroubleshoot: () => Promise<void>;
  dispatchScanShortPickTroubleshootTote: (tote: string) => Promise<void>;
  dispatchResolveLater: () => Promise<void>;
  markReadyForPacking: () => void;
  cancelOrder: () => void;
  modal?: ReactElement;
  successMessage?: SuccessMessageType;
};

export function ShortPickTroubleshootStationView({
  clientState,
  scanToteError,
  dispatchOpenShortPickTroubleshoot,
  dispatchCloseShortPickTroubleshoot,
  dispatchScanShortPickTroubleshootTote,
  dispatchResolveLater,
  markReadyForPacking,
  cancelOrder,
  modal,
  successMessage,
}: ShortPickTroubleshootStationProps): ReactElement {
  const getScreenFromStatus = (
    status?: ShortPickTroubleshootStationType['status']
  ) => {
    switch (status) {
      case 'CLOSED':
        return (
          <StartStation
            stationHeaderText="Short Pick Troubleshooting Station"
            startStationButtonText="Start troubleshooting"
            handleOpenStationClick={dispatchOpenShortPickTroubleshoot}
          />
        );
      case 'AWAITING_SCAN':
        return (
          <ToteScan
            headerText="Scan a tote with red paper"
            placeholderText="Scan or enter the ID on a tote containing red paper"
            alert={
              scanToteError?.type === AlertMessageTypes.Info
                ? {
                    message: (
                      <ResolutionToteAlertMessage
                        message={scanToteError.message}
                      />
                    ),
                    type: AlertMessageTypes.Info,
                  }
                : scanToteError
            }
            successMessage={successMessage}
            scanToteHandler={dispatchScanShortPickTroubleshootTote}
          />
        );
      case 'CHECKING_ITEMS': {
        if (
          !clientState.fulfilmentOrderId ||
          !clientState.currentToteId ||
          !clientState.items
        ) {
          // TODO(WMS-735): address orphaned todo
          // eslint-disable-next-line todo-plz/ticket-ref
          // TODO some sort of error state?
          return <>Uh oh, something went wrong!</>;
        }
        return (
          <ShortPickTroubleshootToteCheck
            markReadyForPacking={markReadyForPacking}
            resolveLater={dispatchResolveLater}
            cancelOrder={cancelOrder}
            orderId={clientState.fulfilmentOrderId}
            fulfilledItems={clientState.items.picked}
            toBeFulfilledItems={clientState.items.inResolutionTote}
            unfulfillableItems={clientState.items.missing}
            recipientName={clientState.customerName ?? ''}
            merchantName={clientState.merchantName ?? ''}
            fulfilledToteId={clientState.currentToteId}
          />
        );
      }
      default:
        return (
          <StartStation
            stationHeaderText="Short Pick Troubleshooting Station"
            startStationButtonText="Start troubleshooting"
            handleOpenStationClick={dispatchOpenShortPickTroubleshoot}
          />
        );
    }
  };
  return (
    <Layout
      type="STATION"
      header={
        clientState &&
        clientState.status &&
        clientState.status !== 'CLOSED' && (
          <StationHeader
            stationHeaderTitle={`Short Pick Troubleshooting Station`}
            closeStation={
              clientState?.status === 'AWAITING_SCAN'
                ? dispatchCloseShortPickTroubleshoot
                : undefined
            }
          />
        )
      }
    >
      {getScreenFromStatus(clientState?.status)}
      {modal}
    </Layout>
  );
}
