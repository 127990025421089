import { ReactElement } from 'react';
import { ReactComponent as TroubleshootIcon } from '../../../../assets/img/icons/troubleshoot-icon.svg';

import { Button } from '../../../../components/warehouse/Button';
import { Container } from '../../../../components/warehouse/Container';
import { Text } from '../../../../components/warehouse/Text';
import { pluralise } from '../../../../utils/pluralise';
import { Loading } from '../Loading';

export type PushBackTotesProps = {
  completedToteCount: number;
  goBack: () => void;
  pushTotes: () => void;
  isUsingConveyor: boolean;
  totesBeingReplacedCount: number;
};

export const PushBackTotes = ({
  completedToteCount,
  goBack,
  pushTotes,
  isUsingConveyor,
  totesBeingReplacedCount,
}: PushBackTotesProps): ReactElement => {
  if (isUsingConveyor) {
    const message = `Waiting for ${pluralise(
      'tote',
      totesBeingReplacedCount,
      false
    )} to arrive`;

    return <Loading message={message} />;
  }

  return (
    <PushBackTotesManually
      completedToteCount={completedToteCount}
      goBack={goBack}
      pushTotes={pushTotes}
    />
  );
};

type PushBackManuallyProps = {
  completedToteCount: number;
  goBack: () => void;
  pushTotes: () => void;
};

const PushBackTotesManually = ({
  completedToteCount,
  goBack,
  pushTotes,
}: PushBackManuallyProps): ReactElement => {
  return (
    <Container
      buttons={[
        <Button variant="secondary" fullWidth onClick={goBack}>
          Go back
        </Button>,
        <Button variant="warning" fullWidth icon={<TroubleshootIcon />}>
          Troubleshoot
        </Button>,
      ]}
      padding="none"
      alignCenter
    >
      <Text variant="body1" weight="medium">
        Push back the {pluralise('completed tote', completedToteCount)}
      </Text>
      <Button
        variant="primary"
        onClick={pushTotes}
        testId="totes-pushed-button"
      >
        Totes pushed
      </Button>
    </Container>
  );
};
