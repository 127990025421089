import styled from 'styled-components';

const transitionDuration = '200ms';

export const OuterContainer = styled.label`
  display: inline-block;
  cursor: pointer;
`;

export const DisplayContainer = styled.div``;

export const HiddenInput = styled.input.attrs(() => ({
  type: 'checkbox',
}))`
  display: none;

  & + ${DisplayContainer} {
    background-color: ${(props) => props.theme.palette.grey[4]};
    width: 3.8rem;
    height: 2rem;
    border-radius: 25%/50%;
    align-items: center;
    padding: 0.29rem;
    display: flex;
    transition: background linear ${transitionDuration};
  }

  /* Circle */
  & + ${DisplayContainer}::before {
    content: '';
    display: block;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    z-index: 1;
    background-color: ${(props) => props.theme.palette.common.white};
    transition: transform linear ${transitionDuration};
  }

  &:checked + ${DisplayContainer} {
    background-color: ${(props) => props.theme.palette.primary.main};
    transition: background linear ${transitionDuration};
  }

  &:checked + ${DisplayContainer}::before {
    transform: translate(1.72rem);
    transition: transform linear ${transitionDuration};
  }
`;
