import { useRouteMatch } from 'react-router-dom';
import { SocketProvider } from '../components/common/SocketProvider';
import { baseUrl } from '../constants/config';
import { useWarehouse } from '../hooks/useWarehouse';
import { PackAndDispatchStation } from '../screens/packAndDispatch';
import { WarehouseThemeProvider } from '../themes/WarehouseTheme';

type RouteParams = { warehouseId: string; stationId: string };

export const PackAndDispatchStationApp = () => {
  const {
    params: { warehouseId, stationId },
  } = useRouteMatch<RouteParams>();

  useWarehouse(warehouseId);

  return (
    <WarehouseThemeProvider useLargeFontSet={false}>
      <SocketProvider
        baseUrl={baseUrl}
        warehouseId={warehouseId}
        stationId={stationId}
        providerType="packAndDispatch"
      >
        <main>
          <PackAndDispatchStation />
        </main>
      </SocketProvider>
    </WarehouseThemeProvider>
  );
};
