import { ReactComponent as CheckCircleIcon } from '../../../assets/img/icons/check-circle-inverse.svg';
import { warehouseColours } from '../../../constants/colours';
import { AlertMessage, AlertMessageTypes } from '../AlertMessage';
import {
  AlertWrapper,
  ContentWrapper,
  Step,
  StepCount,
  Wrapper,
} from './Steps.elements';

export type StepContent = {
  isComplete?: boolean;
  withCount?: boolean;
  content?: React.ReactNode;
  errorContent?: React.ReactNode;
};

export interface StepsProps {
  stepContents: StepContent[];
}

export function Steps({ stepContents }: Readonly<StepsProps>) {
  return (
    <div>
      {stepContents.map(
        ({ withCount = true, content, errorContent, isComplete }, index) => (
          <Step key={`step-${index}`}>
            {content && (
              <Wrapper className="content-wrapper">
                {withCount && (
                  <StepCount className="step-count" $isComplete={isComplete}>
                    {isComplete ? (
                      <CheckCircleIcon fill={warehouseColours.success.light} />
                    ) : (
                      index + 1
                    )}
                  </StepCount>
                )}
                <ContentWrapper>{content}</ContentWrapper>
              </Wrapper>
            )}
            {errorContent && (
              <AlertWrapper>
                <AlertMessage type={AlertMessageTypes.Error}>
                  {errorContent}
                </AlertMessage>
              </AlertWrapper>
            )}
          </Step>
        )
      )}
    </div>
  );
}
