import { ReactElement, ReactNode } from 'react';
import { ReactComponent as CheckMarkIcon } from '../../../assets/img/icons/check-mark.svg';
import { warehouseColours } from '../../../constants/colours';
import * as Elements from './ListItem.elements';

export type ListItemOptionProps = {
  value: string;
  label: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  selected?: boolean;
};

export function ListItem({
  value,
  label,
  selected,
  onClick,
}: ListItemOptionProps): ReactElement {
  return (
    <Elements.Container
      data-testid={value}
      $selected={selected}
      onClick={onClick}
    >
      {label}
      {selected && (
        <Elements.IconWrapper>
          <CheckMarkIcon fill={warehouseColours.primary.main} />
        </Elements.IconWrapper>
      )}
    </Elements.Container>
  );
}
