"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inventoryApiSchema = void 0;
const zod_http_schemas_1 = require("zod-http-schemas");
const http_1 = require("../http");
exports.inventoryApiSchema = (0, zod_http_schemas_1.createHttpSchema)({
    'GET /warehouses/:warehouseId/inventory/:merchantId': {
        responseBody: http_1.inventoryResponseBodySchema,
    },
    'GET /inventory': {
        responseBody: http_1.allInventoryBodySchema,
    },
});
