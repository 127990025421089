import {
  PartitionsPerBin,
  PartitionWithRetailUnit,
  PutawayPort,
  RetailUnit,
} from 'api-schema';
import { PutawayPortWithCurrentBin } from 'api-schema/lib/model';
import { InterruptState } from '../../../../store/reducer';
import { isNever } from '../../../../utils/isNever';
import { BinCheck } from './BinCheck';
import { BinConfigSelector } from './BinConfigSelector';
import { BinGuide } from './BinGuide';
import { ClosedPort } from './ClosedPort';
import { LoadingSpinnerPanel } from './LoadingSpinnerPanel';

type Props = {
  status: PutawayPort['status'];
  currentBin: PutawayPortWithCurrentBin['currentBin'];
  interruptState: InterruptState;
  nextBinPartitions: PartitionsPerBin;
  setNextBinPartitions: (partitions: PartitionsPerBin) => void;
  onSetBinConfiguration: () => Promise<void>;
  onCheckBinHasArrived: () => void;
  isManualBinArrivedEnabled: boolean;
  onCheckBinEmptiness: (isEmpty: boolean) => void;
  onDoSwapBin: () => void;
  onCancelSwapBin: () => void;
  targetPartition: number;
  onSwapBin: () => void;
  onClearBarcode: () => void;
  setScanInputEl: (ref: HTMLInputElement | undefined) => void;
  onChangeItem: (type: 'ADD_PUTAWAY_ITEM' | 'REMOVE_PUTAWAY_ITEM') => void;
  onBarcodeChange: (value: string) => void;
  retailUnitBarcode: string;
  activeBinPartitions: PartitionsPerBin;
  onPartitionClick: (partition: number) => void;
  activeProduct?: RetailUnit;
  partitions?: PartitionWithRetailUnit[];
  binWeight: number;
  hasActiveTransfer: boolean;
  onSetItemQuantity: (quantity: number) => void;
  onBarcodeFullyScanned: () => void;
  useNumberInput: boolean;
};

export const BinSectionController = ({
  status,
  interruptState,
  onSetBinConfiguration,
  onCheckBinHasArrived,
  isManualBinArrivedEnabled,
  setNextBinPartitions,
  nextBinPartitions,
  onDoSwapBin,
  onCancelSwapBin,
  onCheckBinEmptiness,
  currentBin,
  retailUnitBarcode,
  onBarcodeChange,
  onChangeItem,
  onClearBarcode,
  setScanInputEl,
  onSwapBin,
  targetPartition,
  activeBinPartitions,
  onPartitionClick,
  activeProduct,
  partitions,
  binWeight,
  hasActiveTransfer,
  onSetItemQuantity,
  onBarcodeFullyScanned,
  useNumberInput,
}: Props) => {
  switch (status) {
    case 'AWAITING_BIN': {
      return (
        <LoadingSpinnerPanel
          message="GETTING BIN..."
          onCheckBinHasArrived={onCheckBinHasArrived}
          isManualBinArrivedEnabled={isManualBinArrivedEnabled}
        />
      );
    }
    case 'READY': {
      // TODO(WMS-735): address orphaned todo
      // eslint-disable-next-line todo-plz/ticket-ref
      return <LoadingSpinnerPanel />; // TODO: what is this case used for?
    }
    case 'OPEN': {
      switch (interruptState) {
        case 'PARTITION_CHECK': {
          return (
            <BinConfigSelector
              nextBinPartitions={nextBinPartitions}
              onSetBinConfiguration={onSetBinConfiguration}
              setNextBinPartitions={setNextBinPartitions}
            />
          );
        }
        case 'EMPTY_CHECK': {
          return (
            <BinCheck
              checkText="Is this bin empty?"
              leftButtonText="EMPTY"
              rightButtonText="NOT EMPTY"
              onLeftButtonClick={() => onCheckBinEmptiness(true)}
              onRightButtonClick={() => onCheckBinEmptiness(false)}
            />
          );
        }
        case 'SWAP_CHECK': {
          return (
            <BinCheck
              checkText="Are you sure you want to get a new bin?"
              leftButtonText="YES"
              rightButtonText="NO"
              onLeftButtonClick={onDoSwapBin}
              onRightButtonClick={onCancelSwapBin}
            />
          );
        }
        default: {
          // TODO(WMS-735): address orphaned todo
          // eslint-disable-next-line todo-plz/ticket-ref
          // TODO: temp code. flow not working properly and bin guide is being displayed if activeBinPartitions is 0. which it shouldnt as it means they arent set
          // theres a point where after get bin, this is showing for a few frames before setInterrupt is called
          // this also gets called if partition isnt set up and you refresh the page
          // @ts-ignore
          if (activeBinPartitions === 0) {
            return (
              <BinConfigSelector
                nextBinPartitions={nextBinPartitions}
                onSetBinConfiguration={onSetBinConfiguration}
                setNextBinPartitions={setNextBinPartitions}
              />
            );
          }
          return (
            <BinGuide
              bin={currentBin}
              onAddItem={() => onChangeItem('ADD_PUTAWAY_ITEM')}
              onRemoveItem={() => onChangeItem('REMOVE_PUTAWAY_ITEM')}
              onChangeBarcode={onBarcodeChange}
              activeBinPartitions={activeBinPartitions}
              onSwapBin={onSwapBin}
              setScanInputEl={setScanInputEl}
              targetPartition={targetPartition}
              retailUnitBarcode={retailUnitBarcode}
              onPartitionClick={onPartitionClick}
              onClearBarcode={onClearBarcode}
              activeProduct={activeProduct}
              partitions={partitions}
              binWeight={binWeight}
              onSetItemQuantity={onSetItemQuantity}
              onBarcodeFullyScanned={onBarcodeFullyScanned}
              useNumberInput={useNumberInput}
            />
          );
        }
      }
    }
    case 'CLOSED': {
      return <ClosedPort hasActiveTransfer={hasActiveTransfer} />;
    }
    default:
      return isNever(status);
  }
};
