import { useRouteMatch } from 'react-router-dom';
import { useIntermediaryStationSocket } from '../../../components/common/SocketProvider/useSocket';
import { IntermediaryStationClosedView } from './IntermediaryStationClosed.view';

type RouteParams = { stationId: string };

export const IntermediaryStationClosed = () => {
  const {
    params: { stationId },
  } = useRouteMatch<RouteParams>();
  const { dispatchCommand } = useIntermediaryStationSocket();

  const onOpenStation = async () => {
    await dispatchCommand({
      type: 'OPEN_INTERMEDIARY_STATION',
    });
    return;
  };

  return (
    <IntermediaryStationClosedView
      stationId={stationId}
      onOpenStation={onOpenStation}
    />
  );
};
