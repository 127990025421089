import { ReactElement } from 'react';
import { ReactComponent as ExclamationIcon } from '../../../../assets/img/icons/error-circle.svg';
import { Button } from '../../../../components/warehouse/Button';
import { Modal } from '../../../../components/warehouse/Modal';
import { Text } from '../../../../components/warehouse/Text';

export type MissingItemModalProps = {
  closeModal: () => void;
  confirmMissingItem: () => void;
  barcode: string;
};

export const MissingItemModal = ({
  closeModal,
  confirmMissingItem,
  barcode,
}: MissingItemModalProps): ReactElement => {
  return (
    <Modal
      isVisible
      header={{ icon: <ExclamationIcon />, text: 'No items found?' }}
      variant="danger"
      actions={[
        <Button variant="secondary" fullWidth onClick={closeModal}>
          Cancel
        </Button>,
        <Button variant="danger" fullWidth onClick={confirmMissingItem}>
          Confirm
        </Button>,
      ]}
    >
      <Text margin={0} variant="body1" weight="regular" align="center">
        Please confirm that there are no items with the barcode{' '}
        <b font-weight="bold">{barcode}</b> in the bin partition.
      </Text>
    </Modal>
  );
};
