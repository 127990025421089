import { GridColDef, GridRowData } from '@material-ui/data-grid';
import { Transfer } from 'api-schema';

export const transferColumns: GridColDef[] = [
  { field: 'id', headerName: 'Transfer Id', flex: 1 },
  { field: 'numVariants', headerName: '# Variants', flex: 1 },
  { field: 'numUnits', headerName: '# Units', flex: 1 },
  { field: 'state', headerName: 'Status', flex: 1 },
];

export const mapTransferToRow = (tx: Transfer): GridRowData => ({
  ...tx,
  numVariants: tx.items.length,
  numUnits: tx.items.reduce((acc, item) => item.quantity + acc, 0),
});
