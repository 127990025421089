import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 1cm;
  margin-right: 2cm;
  width: 3.5cm;
  display: inline-block;
  break-inside: avoid;
  font-size: 10px;
  font-family: 'Andale Mono';
`;

export const DetailRow = styled.div`
  margin-bottom: 5px;
`;

export const DetailRowData = styled.div`
  float: right;
  display: inline-block;
  font-weight: bold;
`;

export const BarcodeCanvas = styled.canvas`
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  display: block;
`;
