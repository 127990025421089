import { Box } from '@material-ui/core';
import { Add, Check, PriorityHigh } from '@material-ui/icons';
import {
  Barcode,
  Container,
  Name,
  Status,
  TransferDetailsItemStatusType,
} from './TransferDetailsItem.elements';

type Props = {
  name?: string;
  barcode: string;
  quantity: number;
  quantityPutaway: number;
  isSelected: boolean;
};

const deriveItemPutawayStatus = (
  quantity: number,
  quantityPutaway: number
): TransferDetailsItemStatusType => {
  if (quantityPutaway === 0) {
    return 'EMPTY';
  }
  // can put away more than required
  if (quantityPutaway >= quantity) {
    return 'COMPLETE';
  }
  return 'PENDING';
};

const StatusIcon = ({ status }: { status: TransferDetailsItemStatusType }) => {
  switch (status) {
    case 'SELECTED':
      return <Add />;
    case 'COMPLETE':
      return <Check />;
    case 'PENDING':
      return <PriorityHigh />;
    default:
      return null;
  }
};

export const TransferDetailsItem = ({
  name,
  barcode,
  quantity,
  quantityPutaway,
  isSelected,
}: Props) => {
  const status: TransferDetailsItemStatusType = isSelected
    ? 'SELECTED'
    : deriveItemPutawayStatus(quantity, quantityPutaway);

  return (
    <Container $status={status} className={status.toLowerCase()}>
      <Box display="flex">
        <Status $status={status}>
          <StatusIcon status={status} />
        </Status>
        <Name>
          {name}
          <Barcode>({barcode})</Barcode>
        </Name>
      </Box>
      <Box>{`${quantityPutaway}/${quantity}`}</Box>
    </Container>
  );
};
