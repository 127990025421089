import styled, { css } from 'styled-components';

export type ContainerPaddingType = 'all' | 'vertical' | 'horizontal' | 'none';

interface WrapperProps {
  $padding?: ContainerPaddingType;
  $contentGap?: boolean;
  $bottomBoxShadow?: boolean;
}

interface TitleProps {
  $padding?: ContainerPaddingType;
}

interface ContentProps {
  $alignCenter: boolean;
  $flexFlow?: string;
}

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  height: fill-available;
  display: flex;
  flex-direction: column;

  ${({ theme, $contentGap }) => {
    if ($contentGap) {
      return css`
        gap: ${theme.spacing(2)};
      `;
    }
  }};

  ${({ $bottomBoxShadow }) => {
    if ($bottomBoxShadow) {
      return css`
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
      `;
    }
  }};

  border-radius: 10px;

  background-color: ${({ theme }) => theme.palette.common.white};

  border: 1px solid ${({ theme }) => theme.palette.grey[4]};

  ${({ $padding }) => {
    switch ($padding) {
      case 'all':
        return css`
          padding: ${({ theme }) => theme.spacing(2)};
        `;
      case 'vertical':
        return css`
          padding: ${({ theme }) => theme.spacing(2)} 0;
        `;
      case 'horizontal':
        return css`
          padding: 0 ${({ theme }) => theme.spacing(2)};
        `;
      case 'none':
        return css`
          padding: 0;
        `;
      default:
        return css`
          padding: 0;
        `;
    }
  }};
`;

export const Title = styled.div<TitleProps>`
  margin-bottom: 10px;
  ${({ $padding }) => {
    if ($padding === 'vertical') {
      return css`
        margin-left: 20px;
      `;
    }
    if ($padding === 'none') {
      return css`
        margin-left: 20px;
        margin-top: 20px;
      `;
    }
  }};
`;

export const Content = styled.div<ContentProps>`
  display: flex;
  flex: 1;
  flex-direction: column;

  ${({ $alignCenter }) => {
    if ($alignCenter) {
      return css`
        justify-content: center;
        align-items: center;
      `;
    }
  }}

  gap: ${({ theme }) => theme.spacing(2)};
  width: 100%;
  height: fill-available;

  ${(p) =>
    p.$flexFlow &&
    css`
      flex-flow: ${p.$flexFlow};
    `}
`;

export const ButtonBar = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid ${({ theme }) => theme.palette.grey[5]};
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(2)};
`;
