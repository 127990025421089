import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`;

interface HeaderProps {
  danger?: boolean;
}

export const Header = styled.div<HeaderProps>`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing(1)};
  height: ${({ theme }) => theme.spacing(6)};

  background-color: ${({ theme }) => theme.palette.grey[4]};
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  line-height: ${({ theme }) => theme.typography.body2.lineHeight};
  font-weight: ${({ theme }) => theme.typography.body2.fontWeight};

  ${({ danger, theme }) => {
    if (danger) {
      return css`
        border: 1px solid ${theme.palette.error.main};
        background-color: ${theme.palette.error.light};
      `;
    }
  }}

  button {
    line-height: ${({ theme }) => theme.typography.body2.lineHeight};
    font-size: ${({ theme }) => theme.typography.body2.fontSize};
    font-weight: ${({ theme }) => theme.typography.body2.fontWeight};
    padding: 2px 16px;
  }
`;

export const PortBody = styled.div`
  height: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto minmax(640px, 55%);

  background-color: ${({ theme }) => theme.palette.grey[2]};
  padding: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const StationBody = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow-x: hidden;

  background-color: ${({ theme }) => theme.palette.grey[2]};
`;
