import styled from 'styled-components';

export const TipsContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(2)};
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(1)};
  background: ${({ theme }) => theme.palette.info.light};
  border: 1px solid ${({ theme }) => theme.palette.info.dark};
  border-radius: 5px;
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
`;
