import * as Sentry from '@sentry/react';
import { PackAndDispatchClientState, Warehouse } from 'api-schema';
import {
  LabelTroubleshootStationType,
  ShortPickTroubleshootStationType,
} from 'api-schema/lib/model';
import { FeatureFlags } from 'api-schema/lib/model/featureFlags';
import { IntermediaryStationSocketState } from 'api-schema/lib/model/intermediaryStation';
import { WebsocketPort } from 'api-schema/lib/websocket';
import { ActionType } from 'typesafe-actions';
import { isWeakNever } from '../utils/isNever';
import * as app from './actions';

export type InterruptState =
  | 'EMPTY_CHECK'
  | 'SWAP_CHECK'
  | 'PARTITION_CHECK'
  | undefined;

export interface AppState {
  isAuthenticated: boolean;
  currentWarehouse?: Warehouse;
  warehouses: Warehouse[];
  portState?: WebsocketPort;
  interruptState: InterruptState;
  targetPartition: number;
  packingState?: PackAndDispatchClientState;
  sequenceNumber?: number;
  shortPickTroubleshootState?: ShortPickTroubleshootStationType;
  labelTroubleshootState?: LabelTroubleshootStationType;
  intermediaryStationState?: IntermediaryStationSocketState;
  featureFlags?: FeatureFlags;
}

export const AppInitialState: AppState = {
  isAuthenticated: false,
  currentWarehouse: undefined,
  warehouses: [],
  interruptState: undefined,
  targetPartition: 1,
};

export type AppActions = ActionType<typeof app>;

export function AppReducer(state: AppState, action: AppActions): AppState {
  switch (action.type) {
    case 'app/SET_IS_AUTHENTICATED': {
      return { ...state, isAuthenticated: action.payload };
    }
    case 'app/SET_CURRENT_WAREHOUSE': {
      const id = action.payload;
      const warehouse = state.warehouses.find((w) => w.id === id);
      return { ...state, currentWarehouse: warehouse };
    }
    case 'app/SET_WAREHOUSES': {
      return { ...state, warehouses: action.payload };
    }
    case 'app/UPDATE_CURRENT_WAREHOUSE': {
      return {
        ...state,
        currentWarehouse: {
          ...state.currentWarehouse,
          ...action.payload,
        },
      };
    }
    case 'app/SET_PORT_STATE': {
      return { ...state, portState: action.payload };
    }
    case 'app/SET_INTERRUPT_STATE': {
      return { ...state, interruptState: action.payload };
    }
    case 'app/SET_TARGET_PARTITION': {
      return { ...state, targetPartition: action.payload };
    }
    case 'app/SET_PACK_DISPATCH_STATE': {
      return { ...state, packingState: action.payload };
    }
    case 'app/SET_SHORT_PICK_TROUBLESHOOT_STATE': {
      return { ...state, shortPickTroubleshootState: action.payload };
    }
    case 'app/SET_LABEL_TROUBLESHOOT_STATE': {
      return { ...state, labelTroubleshootState: action.payload };
    }
    case 'app/SET_FEATURE_FLAGS': {
      return { ...state, featureFlags: action.payload };
    }
    case 'app/SET_INTERMEDIARY_STATION_STATE': {
      return {
        ...state,
        intermediaryStationState: action.payload.intermediaryStation,
      };
    }
    default: {
      Sentry.captureException(
        `Unhandled app state action ${JSON.stringify(action)}`
      );
      isWeakNever(action, false);
      return state;
    }
  }
}
