"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.warehouseSchema = exports.cmcGenesysPackagingSchema = exports.WarehouseInput = void 0;
const z = __importStar(require("zod"));
const autostore_1 = require("./autostore");
const featureFlags_1 = require("./featureFlags");
exports.WarehouseInput = z.object({
    name: z.string().min(1),
    address: z.string().min(1),
    isTest: z.boolean(),
});
exports.cmcGenesysPackagingSchema = z.object({
    machineId: z.string(),
    eligibleServiceCodes: z.array(z.string().min(1)),
    routingPercentage: z.number().gte(0).lte(100),
});
exports.warehouseSchema = exports.WarehouseInput.extend({
    id: z.string(),
    createdAt: z.union([z.string(), z.date()]),
    autostore: z.optional(autostore_1.autostore),
    isTest: z.boolean().optional(),
    releaseChannel: featureFlags_1.releaseChannel,
    cmcGenesysRoutingPercentage: z.number(),
    cmcGenesysPackaging: z.record(exports.cmcGenesysPackagingSchema),
});
