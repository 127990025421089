"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PubSubEvents = exports.ShippingLabelRequested = exports.FulfilmentOrderRejected = exports.PubSubEventBase = void 0;
const zod_1 = require("zod");
const model_1 = require("../../model");
const base_1 = require("../base");
exports.PubSubEventBase = base_1.eventBaseSchema
    .omit({
    id: true,
    created_at: true,
})
    .extend({
    warehouse: base_1.eventBaseSchema.shape.warehouseId.optional(),
});
exports.FulfilmentOrderRejected = exports.PubSubEventBase.extend({
    type: zod_1.z.literal('FULFILMENT_ORDER_REJECTED'),
    payload: zod_1.z.object({
        externalFulfilmentId: zod_1.z.string().min(1),
        merchantId: zod_1.z.string().min(1),
        reason: model_1.FulfilmentOrderRejectionReason,
    }),
});
exports.ShippingLabelRequested = exports.PubSubEventBase.extend({
    type: zod_1.z.literal('SHIPPING_LABEL_REQUESTED'),
    payload: zod_1.z.object({
        fulfilmentOrder: zod_1.z.object({
            externalFulfilmentId: zod_1.z.string().min(1),
        }),
    }),
});
exports.PubSubEvents = zod_1.z.union([
    exports.FulfilmentOrderRejected,
    exports.ShippingLabelRequested,
]);
