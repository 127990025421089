import { ReactElement } from 'react';
import styled from 'styled-components';
import { BaseTextComponent, Text } from '../../../components/warehouse/Text';

type InputUnitProps = {
  children: ReactElement | ReactElement[];
  unit?: string;
  fullWidth?: boolean;
};

const Wrapper = styled.span<{ $fullWidth?: boolean }>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4375rem;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
  ${BaseTextComponent} {
    margin: 0;
  }
`;

export const InputUnit = ({
  unit,
  fullWidth,
  children,
}: InputUnitProps): ReactElement => {
  if (!unit) {
    return <>{children}</>;
  }
  return (
    <Wrapper $fullWidth={fullWidth}>
      {children}
      <Text tag="span">{unit}</Text>
    </Wrapper>
  );
};
