"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.spectrumWebhookApiSchema = exports.zoneExitRequest = exports.zoneEntryRequest = exports.pickCompleteRequest = exports.bayExitRequest = exports.bayEntryRequest = void 0;
const zod_http_schemas_1 = require("zod-http-schemas");
const successResponse = zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('SUCCESS') });
const noopResponse = zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('NOOP') });
exports.bayEntryRequest = zod_http_schemas_1.z.object({
    bayId: zod_http_schemas_1.z.string().min(1),
    toteId: zod_http_schemas_1.z.string().min(1),
    warehouseId: zod_http_schemas_1.z.string().min(1),
    eventTimestamp: zod_http_schemas_1.z.string().datetime(),
});
exports.bayExitRequest = zod_http_schemas_1.z.object({
    bayId: zod_http_schemas_1.z.string().min(1),
    toteId: zod_http_schemas_1.z.string().min(1),
    warehouseId: zod_http_schemas_1.z.string().min(1),
    eventTimestamp: zod_http_schemas_1.z.string().datetime(),
});
exports.pickCompleteRequest = zod_http_schemas_1.z.object({
    bayId: zod_http_schemas_1.z.string().min(1),
    toteId: zod_http_schemas_1.z.string().min(1),
    warehouseId: zod_http_schemas_1.z.string().min(1),
    eventTimestamp: zod_http_schemas_1.z.string().datetime(),
});
exports.zoneEntryRequest = zod_http_schemas_1.z.object({
    zoneId: zod_http_schemas_1.z.string().min(1),
    toteId: zod_http_schemas_1.z.string().min(1),
    warehouseId: zod_http_schemas_1.z.string().min(1),
    eventTimestamp: zod_http_schemas_1.z.string().datetime(),
});
exports.zoneExitRequest = zod_http_schemas_1.z.object({
    zoneId: zod_http_schemas_1.z.string().min(1),
    toteId: zod_http_schemas_1.z.string().min(1),
    warehouseId: zod_http_schemas_1.z.string().min(1),
    eventTimestamp: zod_http_schemas_1.z.string().datetime(),
});
exports.spectrumWebhookApiSchema = (0, zod_http_schemas_1.createHttpSchema)({
    'POST /webhook/spectrum/bay/entry': {
        requestBody: exports.bayEntryRequest,
        responseBody: zod_http_schemas_1.z.union([
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('UNABLE_TO_QUEUE_TOTE_LINK_JOB') }),
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('CONVEYOR_NOT_CONFIGURED_FOR_WAREHOUSE') }),
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('UNABLE_TO_FETCH_BAY_STATE') }),
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('PORT_NOT_FOUND') }),
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('INVALID_PORT_TYPE') }),
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('TOTE_LOCATION_NOT_FOUND') }),
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('UNABLE_TO_QUEUE_TOTE_LINK_JOB') }),
            noopResponse,
            successResponse,
        ]),
    },
    'POST /webhook/spectrum/bay/exit': {
        requestBody: exports.bayExitRequest,
        responseBody: zod_http_schemas_1.z.union([
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('CONVEYOR_NOT_CONFIGURED_FOR_WAREHOUSE') }),
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('UNABLE_TO_FETCH_BAY_STATE') }),
            noopResponse,
            successResponse,
        ]),
    },
    'POST /webhook/spectrum/pick/complete': {
        requestBody: exports.pickCompleteRequest,
        responseBody: zod_http_schemas_1.z.union([
            noopResponse,
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('SUCCESS'),
                portId: zod_http_schemas_1.z.number(),
                bayId: zod_http_schemas_1.z.string(),
                toteId: zod_http_schemas_1.z.string(),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('BAY_IS_NOT_A_TOTE_LOCATION'),
                bayId: zod_http_schemas_1.z.string(),
                toteId: zod_http_schemas_1.z.string(),
                portId: zod_http_schemas_1.z.number(),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('PICK_ITEM_TO_TOTE_FAILED'),
                reason: zod_http_schemas_1.z.string(),
                bayId: zod_http_schemas_1.z.string(),
                toteId: zod_http_schemas_1.z.string(),
                portId: zod_http_schemas_1.z.number(),
            }),
        ]),
    },
    'POST /webhook/spectrum/zone/entry': {
        requestBody: exports.zoneEntryRequest,
        responseBody: noopResponse,
    },
    'POST /webhook/spectrum/zone/exit': {
        requestBody: exports.zoneExitRequest,
        responseBody: noopResponse,
    },
});
