import SearchIcon from '@material-ui/icons/Search';
import React, { ReactElement } from 'react';
import { IconWrapper, Input, Wrapper } from './Search.elements';

export interface SearchProps {
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  value: string;
}

export function Search({ onChange, value }: SearchProps): ReactElement {
  return (
    <Wrapper>
      <IconWrapper>
        <SearchIcon color="inherit" />
      </IconWrapper>
      <Input
        value={value}
        onChange={onChange}
        placeholder="Search"
        inputProps={{ 'aria-label': 'search' }}
      />
    </Wrapper>
  );
}
