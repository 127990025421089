"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transferEventSchema = exports.transferReceivedSchema = exports.transferArrivedSchema = exports.stockTransferCreatedAcceptedSchema = exports.stockTransferAcceptedSchema = exports.transferCancelledSchema = exports.transferShippedSchema = exports.transferCreatedSchema = exports.transferScannedSchema = void 0;
const zod_1 = require("zod");
const model_1 = require("../model");
const base_1 = require("./base");
exports.transferScannedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TRANSFER_SCANNED'),
    payload: zod_1.z.object({
        transferId: base_1.StringId,
        location: zod_1.z.object({
            portId: zod_1.z.number().int().positive(),
        }),
        putawayMode: model_1.putawayModeSchema.optional(),
    }),
});
exports.transferCreatedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TRANSFER_CREATED'),
    payload: zod_1.z.object({
        transferId: base_1.StringId,
        items: model_1.transferSchema.shape.items,
        externalId: zod_1.z.union([zod_1.z.string(), zod_1.z.number()]).optional(),
        shipment: model_1.transferSchema.shape.shipment,
        notes: model_1.transferSchema.shape.notes,
        isTest: model_1.transferSchema.shape.isTest,
        labelPath: base_1.LabelPaths.optional(),
        merchantId: model_1.transferSchema.shape.merchantId,
        integrations: zod_1.z
            .object({
            ssp: zod_1.z
                .object({
                id: zod_1.z.union([zod_1.z.string(), zod_1.z.number()]),
                transferNumberRef: zod_1.z.string(),
            })
                .optional(),
        })
            .optional(),
    }),
});
exports.transferShippedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TRANSFER_SHIPPED'),
    payload: zod_1.z.object({
        transferId: base_1.StringId,
    }),
});
exports.transferCancelledSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TRANSFER_CANCELLED'),
    payload: zod_1.z.object({
        transfer: zod_1.z.object({
            id: base_1.StringId,
        }),
    }),
});
exports.stockTransferAcceptedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('STOCK_TRANSFER_ACCEPTED'),
    payload: zod_1.z.object({
        transfer: model_1.transferSchema,
    }),
});
exports.stockTransferCreatedAcceptedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('STOCK_TRANSFER_CREATED_ACCEPTED'),
    payload: zod_1.z.object({
        transfer: model_1.transferSchema,
    }),
});
exports.transferArrivedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TRANSFER_ARRIVED'),
    payload: zod_1.z.object({
        transferId: base_1.StringId,
    }),
});
exports.transferReceivedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TRANSFER_RECEIVED'),
    payload: zod_1.z.object({
        transferId: base_1.StringId,
        receiveUser: zod_1.z.string().email().optional(),
        // change this to union type later if there are more types of problem
        receiveProblem: zod_1.z
            .object({
            type: zod_1.z.literal('ITEM_DISCREPANCIES'),
        })
            .optional(),
    }),
});
exports.transferEventSchema = zod_1.z.union([
    exports.transferCreatedSchema,
    exports.transferArrivedSchema,
    exports.transferScannedSchema,
    exports.transferShippedSchema,
    exports.transferCancelledSchema,
    exports.stockTransferCreatedAcceptedSchema,
    exports.stockTransferAcceptedSchema,
    exports.transferReceivedSchema,
]);
