import { CancelPackingOrderFailureOutcome } from 'api-schema/lib/commands/outcomes/packAndDispatch';

export const mapPackingErrorToHumanMessage = (
  error: CancelPackingOrderFailureOutcome
) => {
  switch (error) {
    case 'WAREHOUSE_NOT_FOUND':
      return 'Warehouse could not be found';
    case 'LABEL_GENERATION_FAILED':
      return 'Label generation failed, please try again';
    case 'PICKLIST_ID_NOT_FOUND':
      return 'Invalid Picklist ID';
    case 'FULFILMENT_ORDER_NOT_FOUND':
      return 'Fulfilment order could not be found';
    case 'ORDER_PACKING_CANCELLATION_REASONS_NOT_PROVIDED':
      return 'Cancellation reasons are missing, please try again';
    case 'PRINTER_NOT_FOUND':
      return 'Printer is not found';
    case 'PACKING_STATION_NOT_FOUND':
      return 'Invalid packing station';
    case 'INVALID_FULFILMENT_ORDER_STATUS':
      return 'Invalid fulfilment order status';
    case 'INVALID_PACKING_STATION_STATUS':
      return 'Invalid packing status';
    case 'SAVE_LABEL_FAILED':
      return 'Failed to save label, please try again';
    case 'TOTE_ID_NOT_FOUND':
      return 'Invalid tote ID';
  }
};
