import React from 'react';

interface FormHandlerMethods {
  setFieldTouched?: (name: string) => void;
  setFieldValue?: (name: string, value: string) => void;
}

/**
 * Returns a function that can directly be used for onChange Input props
 * @param setFieldTouched Formik's setFieldTouched
 * @param setFieldValue Formik's setFieldValue
 */
export function getFormHandlers({
  setFieldTouched,
  setFieldValue,
}: FormHandlerMethods): {
  handleInputTouch: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
} {
  const handleInputTouch = ({
    target: { name },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setFieldTouched ? setFieldTouched(name) : null;

  const handleInputChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setFieldValue ? setFieldValue(name, value) : null;

  return {
    handleInputTouch,
    handleInputChange,
  };
}
