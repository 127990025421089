import { PaperVariantType } from 'api-schema/lib/model/shortPickTroubleshoot';
import { ReactElement } from 'react';
import { ReactComponent as TroubleshootIcon } from '../../../../assets/img/icons/troubleshoot-icon.svg';
import { Button } from '../../../../components/warehouse/Button';
import { Modal } from '../../../../components/warehouse/Modal';
import { Text } from '../../../../components/warehouse/Text';

export type NoPaperAvailableModalProps = {
  paperVariant: PaperVariantType;
  closeModal: () => void;
};

export const NoPaperAvailableModal = ({
  paperVariant,
  closeModal,
}: NoPaperAvailableModalProps): ReactElement => (
  <Modal
    isVisible
    variant="warning"
    header={{
      text: `No ${paperVariant} paper available`,
      icon: <TroubleshootIcon />,
    }}
    actions={[
      <Button fullWidth variant="tertiary" onClick={closeModal}>
        Close
      </Button>,
    ]}
  >
    <Text variant="body1" weight="regular">
      Please alert the Conveyer Operator.
    </Text>
  </Modal>
);
