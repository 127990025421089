import { PutawayMode } from 'api-schema/lib/model';
import { ChipVariant, Wrapper } from './PortPutawayModeChip.elements';

export type PortPutawayModeChipProps = {
  variant?: PutawayMode;
};

const VARIANT_CHIP_MAP: Record<PutawayMode, ChipVariant> = {
  NUMBER_INPUT: 'numberInput',
  SCAN: 'scan',
};

const VARIANT_CHIP_CHILD_MAP: Record<PutawayMode, string> = {
  NUMBER_INPUT: 'Default Mode',
  SCAN: 'Scan each Item',
};

export function PortPutawayModeChip({
  variant = 'NUMBER_INPUT',
}: PortPutawayModeChipProps) {
  return (
    <Wrapper $variant={VARIANT_CHIP_MAP[variant]}>
      {VARIANT_CHIP_CHILD_MAP[variant]}
    </Wrapper>
  );
}
