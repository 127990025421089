import React, {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  KeyboardEvent,
  ReactElement,
} from 'react';
import { ReactComponent as SuccessIcon } from '../../../assets/img/icons/check-circle.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/img/icons/error-circle.svg';
import { warehouseColours } from '../../../constants/colours';
import { InputHeader } from '../../../elements/warehouse/InputHeader';
import { InputUnit } from '../../../elements/warehouse/InputUnit';
import {
  ButtonWrapper,
  Field,
  IconWrapper,
  Wrapper,
} from './InputField.elements';

export type InputFieldProps = {
  placeholderText?: string;
  hasError?: boolean;
  isValid?: boolean;
  embeddedButton?: React.ReactNode;
  fullWidth?: boolean;
  autoFocus?: boolean;
  onBlur?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
  id?: string;
  name?: string;
  value?: string;
  showIcon?: boolean;
  label?: string;
  unit?: string;
  disabled?: boolean;
};

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      placeholderText,
      hasError,
      isValid,
      embeddedButton: Button,
      fullWidth,
      autoFocus,
      onBlur,
      onChange,
      onKeyPress,
      id,
      name,
      value,
      showIcon = true,
      label,
      unit,
      disabled,
    }: InputFieldProps,
    ref: ForwardedRef<HTMLInputElement>
  ): ReactElement => {
    return (
      <InputHeader label={label} fullWidth={fullWidth}>
        <InputUnit unit={unit} fullWidth={fullWidth}>
          <Wrapper hasError={hasError} isValid={isValid} fullWidth={fullWidth}>
            <Field
              type="text"
              placeholder={placeholderText}
              autoFocus={autoFocus}
              onBlur={onBlur}
              onChange={onChange}
              onKeyPress={onKeyPress}
              id={id}
              name={name}
              value={value}
              ref={ref}
              disabled={disabled}
            />
            {showIcon && isValid && (
              <IconWrapper>
                <SuccessIcon fill={warehouseColours.success.main} />
              </IconWrapper>
            )}
            {showIcon && hasError && (
              <IconWrapper>
                <ErrorIcon fill={warehouseColours.danger.main} />
              </IconWrapper>
            )}
            {Button && <ButtonWrapper>{Button}</ButtonWrapper>}
          </Wrapper>
        </InputUnit>
      </InputHeader>
    );
  }
);
