import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Button } from '../../../components/admin/Button';
import { Container } from '../../../components/admin/Container';
import { Input } from '../../../components/admin/Input';
import { SectionHeader } from '../../../components/admin/SectionHeader';
import { SnackbarDefaults } from '../../../constants/snackbarDefaults';
import { FormRow } from '../../../elements/admin/FormRow';
import { useLocalStorage } from '../../../hooks/useLocalStorage';

export const Auth = () => {
  const saveAuthToken = useLocalStorage<string>('AUTH_TOKEN')[1];
  // use this to display, so we don't show the current value in local storage
  const [newAuthToken, setNewAuthToken] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const setToken = (token: string) => {
    setNewAuthToken(token);
  };

  const handleSubmit = () => {
    saveAuthToken(newAuthToken);
    setNewAuthToken('');
    enqueueSnackbar('Authentication token saved', SnackbarDefaults);
  };

  return (
    <Container>
      <SectionHeader>Set authentication token</SectionHeader>
      <FormRow spacingBottom={1}>
        <Input
          label="Authentication token"
          name="authtoken"
          type="password"
          onChange={(event) => setToken(event.target.value)}
          value={newAuthToken}
        />
      </FormRow>
      <FormRow>
        <Button type="submit" onClick={handleSubmit}>
          Save
        </Button>
      </FormRow>
    </Container>
  );
};
