import { ParcelSize } from 'api-schema/lib';
import { InsertStatus } from 'api-schema/lib/model';
import { ReactComponent as TroubleshootIcon } from '../../../../assets/img/icons/troubleshoot-icon.svg';
import { Box } from '../../../../components/warehouse/Box';
import { Button } from '../../../../components/warehouse/Button';
import { ParcelType } from '../../../../constants/parcel';
import {
  CorePackAndDispatchProps,
  StatusType,
} from '../../PackAndDispatch.model';
import { NetworkPrintingModal } from '../NetworkPrintingModal/NetworkPrintingModal';
import { PackingContainer } from '../PackingContainer';
import { PackingInstruction } from '../PackingInstruction';
import { PrintInstruction } from '../PrintInstruction';
import { ULDModal } from '../ULDModal';
import {
  PackAndPrintError,
  PackAndPrintErrorType,
} from './PackAndPrint.elements';

export type PackAndPrintProps = CorePackAndDispatchProps & {
  parcelSize: ParcelSize;
  parcelCount: number;
  parcelType: ParcelType;
  labelStatus: StatusType;
  printStatus: StatusType;
  showULDModal: boolean;
  cancelULDModal: () => void;
  handleTransferParcelToTroubleshoot: () => Promise<void>;
  isTroubleshootPrintingViaNetwork: boolean;
  setIsTroubleshootPrintingViaNetwork: (value: boolean) => void;
  sortParcelHandler: () => Promise<void>;
  changeParcelTypeHandler: () => Promise<void>;
  addInsertHandler: () => Promise<void>;
  skipInsertHandler: () => Promise<void>;
  printLabelHandler: () => Promise<void>;
  printLabelViaNetworkHandler: () => Promise<void>;
  printTroubleshootLabelHandler: () => Promise<void>;
  printTroubleshootLabelViaNetworkHandler: () => Promise<void>;
  merchantName?: string;
  isNetworkPrinting: boolean;
  isInsertRequired?: boolean;
  packingNote?: string;
  insertStatus?: InsertStatus;
  packAndPrintError: PackAndPrintErrorType;
};

export function PackAndPrint({
  items,
  orderNumber,
  recipient,
  parcelSize,
  parcelCount,
  parcelType,
  labelStatus,
  printStatus,
  showULDModal,
  onCannotPackOrderBtnClick,
  onComplete,
  changeParcelTypeHandler,
  printLabelHandler,
  printLabelViaNetworkHandler,
  printTroubleshootLabelHandler,
  printTroubleshootLabelViaNetworkHandler,
  cancelULDModal,
  sortParcelHandler,
  merchantName,
  handleTransferParcelToTroubleshoot,
  isTroubleshootPrintingViaNetwork,
  setIsTroubleshootPrintingViaNetwork,
  isNetworkPrinting,
  packingNote,
  addInsertHandler,
  skipInsertHandler,
  isInsertRequired,
  insertStatus,
  packAndPrintError,
}: PackAndPrintProps) {
  return (
    <PackingContainer
      step={3}
      stickyFooter={[
        <Button
          variant="warning"
          icon={<TroubleshootIcon />}
          onClick={onCannotPackOrderBtnClick}
          disabled={labelStatus === 'ERROR'}
        >
          Cannot pack order
        </Button>,
        <Button
          onClick={onComplete}
          disabled={labelStatus === 'ERROR'}
          testId="order-packed-button"
        >
          Order packed
        </Button>,
      ]}
      title={`Pack and print`}
      orderNumber={orderNumber}
      recipient={recipient}
      merchantName={merchantName}
    >
      <PackAndPrintError variant={packAndPrintError} />

      <PackingInstruction
        changeParcelTypeHandler={changeParcelTypeHandler}
        items={items}
        parcelSize={parcelSize}
        parcelCount={parcelCount}
        parcelType={parcelType}
        packingNote={packingNote}
        isInsertRequired={isInsertRequired}
        insertStatus={insertStatus}
        addInsertHandler={addInsertHandler}
        skipInsertHandler={skipInsertHandler}
      />
      <Box marginTop="20px">
        <PrintInstruction
          labelStatus={labelStatus}
          handleTransferParcelToTroubleshoot={
            handleTransferParcelToTroubleshoot
          }
          isTroubleshootPrintingViaNetwork={isTroubleshootPrintingViaNetwork}
          setIsTroubleshootPrintingViaNetwork={
            setIsTroubleshootPrintingViaNetwork
          }
          printStatus={printStatus}
          printLabelHandler={printLabelHandler}
          printLabelViaNetworkHandler={printLabelViaNetworkHandler}
          printTroubleshootLabelHandler={printTroubleshootLabelHandler}
          printTroubleshootLabelViaNetworkHandler={
            printTroubleshootLabelViaNetworkHandler
          }
        />
      </Box>
      <ULDModal
        isVisible={showULDModal}
        onCancel={cancelULDModal}
        onSorted={sortParcelHandler}
      />
      <NetworkPrintingModal open={isNetworkPrinting} />
    </PackingContainer>
  );
}
