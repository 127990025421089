import { ReactNode } from 'react';
import { BaseTextComponent } from './Text.elements';

export type TextVariant = 'h1' | 'h2' | 'h3' | 'body1' | 'body2';

export type TextWeight = 'regular' | 'medium' | 'bold';

export type TextProps = {
  className?: string;
  variant?: TextVariant;
  weight?: TextWeight;
  children?: ReactNode;
  tag?: keyof JSX.IntrinsicElements;
  testId?: string;
  margin?: 0 | 10 | 20;
  align?: 'center' | 'left' | 'right';
};

const variantsMapping: Record<TextVariant, keyof JSX.IntrinsicElements> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  body1: 'p',
  body2: 'p',
};

export function Text({
  variant = 'body2',
  weight = 'regular',
  tag,
  children,
  className,
  testId,
  margin,
  align,
}: TextProps) {
  const substituteTag = tag || variantsMapping[variant];
  return (
    <BaseTextComponent
      as={substituteTag}
      $weight={weight}
      $variant={variant}
      $margin={margin}
      className={className}
      data-testid={testId}
      $align={align}
    >
      {children}
    </BaseTextComponent>
  );
}
