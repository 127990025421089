import { Box, Divider, Grid, Paper, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { StorageType, StorageUnit, Warehouse } from 'api-schema';
import { Button } from '../../../components/admin/Button';
import { DataTable } from '../../../components/admin/DataTable';
import { Tabs } from '../../../components/admin/Tabs';
import { AdminThemeInterface } from '../../../themes/AdminTheme';
import { generatePathWithWarehouseId } from '../../../utils/generatePathWithWarehouseId';
import {
  getTabItems,
  mapBinsToRow,
  storageColumns,
} from './WarehouseOverview.methods';

type Props = {
  warehouse: Warehouse;
  storageUnits: StorageUnit[];
  selectedIds: Array<string | number>;
  setSelectedIds: (ids: Array<string | number>) => void;
  onPrintLabelClick: () => void;
  onStorageTypeClick: (storageType?: StorageType) => void;
  onDisableStorageUnitsClick: () => void;
  onEditWarehouseClick: () => void;
};

export const WarehouseOverviewView = ({
  warehouse,
  setSelectedIds,
  onPrintLabelClick,
  onStorageTypeClick,
  onDisableStorageUnitsClick,
  storageUnits,
  onEditWarehouseClick,
}: Props) => {
  const { spacing } = useTheme<AdminThemeInterface>();
  const tabItems = getTabItems(onStorageTypeClick);
  return (
    <Paper style={{ padding: spacing(2) }}>
      <Typography
        variant="h1"
        gutterBottom
        style={{ paddingBottom: spacing(2) }}
      >
        {warehouse.name}
      </Typography>
      <Grid container>
        <Grid item xs={3}>
          <strong>Address</strong>
        </Grid>
        <Grid item xs={9}>
          <Typography>{`${warehouse.address}`}</Typography>
        </Grid>
        <Grid item xs={3}>
          <strong>Other facet</strong>
        </Grid>
        <Grid item xs={9}>
          <Typography>Other details</Typography>
        </Grid>
        <Grid item>
          <Button onClick={onEditWarehouseClick}>Edit details</Button>
        </Grid>
      </Grid>

      <Divider />
      <Typography variant="h2" gutterBottom style={{ marginTop: spacing(2) }}>
        Storage
      </Typography>
      <Tabs items={tabItems} />
      <DataTable
        withSearch
        searchInternally
        onSelectionModelChange={(newSelection) => {
          setSelectedIds(newSelection.selectionModel);
        }}
        columns={storageColumns}
        rows={storageUnits.map(mapBinsToRow) || []}
        actions={
          <Box display="grid" gridAutoFlow="column" gridGap={spacing(1)}>
            <Button
              href={generatePathWithWarehouseId(
                '/admin/warehouse/:warehouseId?/storage-units/setup',
                { warehouseId: warehouse.id }
              )}
            >
              Add Storage Units
            </Button>
            <Button onClick={onPrintLabelClick}>Print Labels</Button>
            <Button onClick={onDisableStorageUnitsClick}>Disable Units</Button>
          </Box>
        }
      />
    </Paper>
  );
};
