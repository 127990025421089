import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { SocketProvider } from '../components/common/SocketProvider';
import { baseUrl } from '../constants/config';
import { Loadable } from '../elements/admin/Loadable';
import { useWarehouse } from '../hooks/useWarehouse';
import { PutawayTransfer } from '../screens/putawayPort/PutawayTransfer';
import { AdminThemeProvider } from '../themes/AdminTheme';
import { useNavStyles } from './styles';

type RouteParams = { warehouseId: string; portId: string };

export const PutawayPortApp = () => {
  const {
    params: { portId, warehouseId },
  } = useRouteMatch<RouteParams>();
  const [status, warehouse] = useWarehouse(warehouseId);
  const classes = useNavStyles();
  const [hasAutoStoreError, setHasAutoStoreError] = useState(false);

  return (
    <AdminThemeProvider>
      <SocketProvider
        baseUrl={baseUrl}
        portId={portId}
        warehouseId={warehouseId}
        providerType="putaway"
      >
        <div className={classes.root}>
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
              <Typography variant="h6" noWrap>
                Putaway Port {portId}
              </Typography>
              <div style={{ marginLeft: 'auto' }}>
                <Loadable isLoading={status === 'LOADING'}>
                  <Typography>{warehouse?.name}</Typography>
                </Loadable>
              </div>
            </Toolbar>
          </AppBar>
          {hasAutoStoreError && (
            <PutawayTransfer
              hasAutoStoreError={hasAutoStoreError}
              setHasAutoStoreError={setHasAutoStoreError}
            />
          )}
          {!hasAutoStoreError && (
            <main className={classes.content}>
              <div className={classes.toolbar} />
              <PutawayTransfer
                hasAutoStoreError={hasAutoStoreError}
                setHasAutoStoreError={setHasAutoStoreError}
              />
            </main>
          )}
        </div>
      </SocketProvider>
    </AdminThemeProvider>
  );
};
