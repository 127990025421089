import { action } from 'typesafe-actions';

import { PackAndDispatchClientState, Warehouse } from 'api-schema';
import {
  LabelTroubleshootStationType,
  ShortPickTroubleshootStationType,
} from 'api-schema/lib/model';
import { FeatureFlags } from 'api-schema/lib/model/featureFlags';
import { IntermediaryStationSocketState } from 'api-schema/lib/model/intermediaryStation';

import { WebsocketPort } from 'api-schema/lib/websocket';
import { InterruptState } from './reducer';

export const setIsAuthenticated = (isAuthenticated: boolean) =>
  action('app/SET_IS_AUTHENTICATED', isAuthenticated);

export const setCurrentWarehouse = (warehouseId: string) =>
  action('app/SET_CURRENT_WAREHOUSE', warehouseId);

export const setWarehouses = (warehouses: Warehouse[]) =>
  action('app/SET_WAREHOUSES', warehouses);

export const setPortState = (portState: WebsocketPort) =>
  action('app/SET_PORT_STATE', portState);

export const setInterruptState = (state: InterruptState) =>
  action('app/SET_INTERRUPT_STATE', state);

export const setTargetPartition = (index: number) =>
  action('app/SET_TARGET_PARTITION', index);

export const setPackAndDispatchState = (
  packAndDispatchState: PackAndDispatchClientState
) => action('app/SET_PACK_DISPATCH_STATE', packAndDispatchState);

export const setShortPickTroubleshootState = (
  shortPickTroubleshootState: ShortPickTroubleshootStationType
) =>
  action('app/SET_SHORT_PICK_TROUBLESHOOT_STATE', shortPickTroubleshootState);

export const setLabelTroubleshootState = (
  labelTroubleshootState: LabelTroubleshootStationType
) => action('app/SET_LABEL_TROUBLESHOOT_STATE', labelTroubleshootState);

export const updateCurrentWarehouse = (warehouse: Warehouse) =>
  action('app/UPDATE_CURRENT_WAREHOUSE', warehouse);

export const setFeatureFlags = (featureFlags: FeatureFlags) =>
  action('app/SET_FEATURE_FLAGS', featureFlags);

export const setIntermediaryStationState = (
  intermediaryStation: IntermediaryStationSocketState
) =>
  action('app/SET_INTERMEDIARY_STATION_STATE', {
    intermediaryStation,
  });
