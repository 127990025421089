"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckBinEmptinessOutcome = exports.PutawayCommandsOutcome = exports.checkBinHasArrivedOutcomeSchema = exports.ConfigurePartitionsOutcome = exports.PlaceItemsInPartitionOutcome = exports.CompletePutawayOutcome = exports.SwapBinsOutcome = exports.removePutawayItemOutcomeSchema = exports.addPutawayItemOutcomeSchema = exports.TargetPartitionEmpty = exports.InvalidTargetPartition = exports.NoBarcodeMatch = exports.NoCurrentTransfer = exports.BinNotEmpty = exports.RequestPutawayBinOutcome = exports.OpenPutawayPortOutcome = void 0;
const z = __importStar(require("zod"));
const generic_1 = require("./generic");
const port_1 = require("./port");
exports.OpenPutawayPortOutcome = z.union([
    generic_1.Success,
    port_1.InvalidPortStatus,
    generic_1.GenericError,
    generic_1.NoAutostore,
    port_1.NoMatchingBins,
    port_1.NoReadyBins,
    port_1.PortNotFound,
]);
exports.RequestPutawayBinOutcome = z.union([
    generic_1.Success,
    generic_1.GenericError,
    generic_1.NoAutostore,
    port_1.NoMatchingBins,
    port_1.NoReadyBins,
]);
exports.BinNotEmpty = z.object({
    outcome: z.literal('BIN_NOT_EMPTY'),
});
exports.NoCurrentTransfer = z.object({
    outcome: z.literal('NO_CURRENT_TRANSFER'),
});
exports.NoBarcodeMatch = z.object({
    outcome: z.literal('NO_BARCODE_RETAIL_UNIT_MATCH'),
});
exports.InvalidTargetPartition = z.object({
    outcome: z.literal('INVALID_TARGET_PARTITION'),
});
exports.TargetPartitionEmpty = z.object({
    outcome: z.literal('TARGET_PARTITION_EMPTY'),
});
exports.addPutawayItemOutcomeSchema = z.union([
    generic_1.Success,
    port_1.NoCurrentBin,
    exports.NoCurrentTransfer,
    exports.NoBarcodeMatch,
    exports.InvalidTargetPartition,
    port_1.InvalidPortType,
    port_1.PortNotFound,
]);
exports.removePutawayItemOutcomeSchema = z.union([
    generic_1.Success,
    port_1.NoCurrentBin,
    exports.NoCurrentTransfer,
    exports.NoBarcodeMatch,
    exports.InvalidTargetPartition,
    exports.TargetPartitionEmpty,
    port_1.InvalidPortType,
    port_1.PortNotFound,
]);
exports.SwapBinsOutcome = z.union([
    generic_1.Success,
    port_1.InvalidPortType,
    port_1.PortNotFound,
    exports.NoCurrentTransfer,
    port_1.NoCurrentBin,
    generic_1.NoAutostore,
    port_1.NoReadyBins,
    port_1.NoMatchingBins,
    port_1.InvalidPortStatus,
    generic_1.GenericError,
]);
exports.CompletePutawayOutcome = z.union([
    generic_1.Success,
    port_1.InvalidPortStatus,
    generic_1.GenericError,
    generic_1.NoAutostore,
    port_1.NoCurrentBin,
    exports.NoCurrentTransfer,
    port_1.InvalidPortType,
    port_1.PortNotFound,
]);
const BarcodeDoesNotMatchAnyRetailUnit = z.object({
    outcome: z.literal('BARCODE_DOES_NOT_MATCH_ANY_RETAIL_UNIT'),
    barcode: z.string(),
    transferId: z.string(),
});
const BarcodeIsDifferentFromBinItem = z.object({
    outcome: z.literal('BARCODE_IS_DIFFERENT_FROM_BIN_ITEM'),
    expectedBarcode: z.string(),
    actualBarcode: z.string(),
});
const TransferIdMismatch = z.object({
    outcome: z.literal('TRANSFER_ID_MISMATCH'),
    portTransferId: z.string(),
    commandTransferId: z.string(),
});
const PartitionNotFound = z.object({
    outcome: z.literal('PARTITION_NOT_FOUND'),
    partitionNumber: z.number(),
    binId: z.string(),
});
const PutawayUnchanged = z.object({
    outcome: z.literal('PUTAWAY_UNCHANGED'),
});
exports.PlaceItemsInPartitionOutcome = z.union([
    generic_1.Success,
    port_1.PortNotFound,
    port_1.NoCurrentBin,
    exports.NoCurrentTransfer,
    BarcodeDoesNotMatchAnyRetailUnit,
    BarcodeIsDifferentFromBinItem,
    port_1.InvalidPortType,
    TransferIdMismatch,
    PartitionNotFound,
    PutawayUnchanged,
]);
exports.ConfigurePartitionsOutcome = z.union([
    generic_1.Success,
    port_1.NoCurrentBin,
    exports.NoCurrentTransfer,
    exports.BinNotEmpty,
    port_1.InvalidPortType,
    port_1.PortNotFound,
]);
const NoAutostorePortStatus = z.object({
    outcome: z.literal('COULD_NOT_GET_AUTOSTORE_PORT_STATUS'),
    portId: z.number(),
});
const BinMismatchAtPort = z.object({
    outcome: z.literal('AUTOSTORE_BIN_AT_PORT_MISMATCH'),
    autostorePortBinId: z.number().optional(),
    wmsPortBinId: z.number(),
});
exports.checkBinHasArrivedOutcomeSchema = z.union([
    generic_1.Success,
    port_1.InvalidPortStatus,
    generic_1.GenericError,
    generic_1.NoAutostore,
    port_1.NoMatchingBins,
    port_1.NoReadyBins,
    port_1.PortNotFound,
    port_1.NoCurrentBin,
    exports.NoCurrentTransfer,
    port_1.InvalidPortType,
    NoAutostorePortStatus,
    BinMismatchAtPort,
]);
exports.PutawayCommandsOutcome = z.union([
    exports.OpenPutawayPortOutcome,
    exports.CompletePutawayOutcome,
    exports.RequestPutawayBinOutcome,
    exports.addPutawayItemOutcomeSchema,
    exports.removePutawayItemOutcomeSchema,
    exports.SwapBinsOutcome,
    exports.BinNotEmpty,
    port_1.InvalidPortType,
    exports.PlaceItemsInPartitionOutcome,
    exports.ConfigurePartitionsOutcome,
    exports.checkBinHasArrivedOutcomeSchema,
]);
exports.CheckBinEmptinessOutcome = z.union([
    generic_1.Success,
    port_1.NoCurrentBin,
    port_1.InvalidPortType,
    port_1.PortNotFound,
]);
