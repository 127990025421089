import styled from 'styled-components';
import { Container } from '../Container';

type WrapperProps = {
  $margin: number;
};

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 ${({ $margin }) => `${$margin}px`};
  justify-content: center;
`;

export const Content = styled(Container)`
  padding: ${({ theme }) => theme.spacing(4)};
  padding-bottom: ${({ theme }) => theme.spacing(6)};
  align-items: inherit;
  border-radius: 5px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
  border-radius: 5px;
`;
