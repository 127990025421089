import { CommandResultMessageType } from 'api-schema/lib/websocket';
import { ProviderContext } from 'notistack';
import { SnackbarDefaults } from '../../constants/snackbarDefaults';
import { mapCommandErrorToUserMessage } from './mapCommandErrorToUserMessage';

export const handleCommandRejectedResultWithSnackbar = (
  enqueueSnackbar: ProviderContext['enqueueSnackbar'],
  commandResultMessage: CommandResultMessageType
): void => {
  if (typeof commandResultMessage === 'string') {
    console.error(commandResultMessage);
    return;
  }

  const { result } = commandResultMessage;
  if (result?.outcome && result.outcome !== 'SUCCESS') {
    enqueueSnackbar(mapCommandErrorToUserMessage(result), {
      ...SnackbarDefaults,
      variant: 'error',
    });
  }
};
