import styled from 'styled-components';

export const PortLabel = styled.span`
  font-size: ${({ theme }) => theme.typography.h1.fontSize};
  font-weight: ${({ theme }) => theme.typography.h1.fontWeight};
  line-height: ${({ theme }) => theme.typography.h1.lineHeight};
`;

export const ErrorMessageWrapper = styled.div`
  position: absolute;
  width: 60%;
  top: 10vh;
`;
