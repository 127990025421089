import { ReactElement, ReactNode } from 'react';

import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Heading, Wrapper } from './SectionHeader.elements';

export interface SectionHeaderProps {
  children: string | ReactNode;
  collapse?: boolean;
}

export function SectionHeader({
  children,
  collapse = true,
}: SectionHeaderProps): ReactElement {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('xs'));

  const spacing = isMobile ? 2 : 3;

  return (
    <Wrapper collapse={collapse} spacing={spacing}>
      <Heading variant="h2">{children}</Heading>
    </Wrapper>
  );
}
