import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { EditWarehouseRequestBodyType, Warehouse } from 'api-schema/lib';
import { FormikProps } from 'formik';
import { Button } from '../../../components/admin/Button';
import { Container } from '../../../components/admin/Container';
import { Input } from '../../../components/admin/Input';
import { SectionHeader } from '../../../components/admin/SectionHeader';
import { FormRow } from '../../../elements/admin/FormRow';
import { Loadable } from '../../../elements/admin/Loadable';

type Props = {
  warehouse?: Warehouse;
};

export const EditWarehouseView = ({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  handleSubmit,
  isSubmitting,
  warehouse,
}: FormikProps<EditWarehouseRequestBodyType> & Props) => {
  if (!warehouse) {
    return (
      <>
        <SectionHeader>Edit warehouse</SectionHeader>
        <Container>
          <Typography>No warehouse found</Typography>
        </Container>
      </>
    );
  }

  return (
    <>
      <SectionHeader>Edit warehouse</SectionHeader>
      <Container>
        <form onSubmit={handleSubmit}>
          <Typography>Warehouse</Typography>
          <FormRow spacingBottom={2}>
            <Input
              label="Name"
              name="name"
              type="text"
              onChange={handleChange}
              value={values.name}
              error={errors.name}
              touched={touched.name}
              onBlur={handleBlur}
              fullWidth
            />
            <Input
              label="Address"
              name="address"
              type="text"
              onChange={handleChange}
              value={values.address}
              error={errors.address}
              touched={touched.address}
              onBlur={handleBlur}
              fullWidth
            />
            <FormRow>
              <FormControlLabel
                control={
                  <Checkbox
                    name="isTest"
                    checked={values.isTest}
                    onClick={handleChange}
                  />
                }
                label="Is test"
              />
            </FormRow>
          </FormRow>
          <Typography>Autostore</Typography>
          <FormRow spacingBottom={4}>
            <Input
              label="Network Address"
              name="autostore.networkAddress"
              type="text"
              onChange={handleChange}
              value={values.autostore?.networkAddress}
              error={errors.autostore?.networkAddress}
              touched={touched.autostore?.networkAddress}
              onBlur={handleBlur}
              fullWidth
            />
          </FormRow>
          <FormRow>
            <Button type="submit" disabled={isSubmitting}>
              <Loadable isLoading={isSubmitting}>Update warehouse</Loadable>
            </Button>
          </FormRow>
        </form>
      </Container>
    </>
  );
};
