import { ReactComponent as TroubleshootIcon } from '../../../../assets/img/icons/troubleshoot-icon.svg';
import {
  AlertMessage,
  AlertMessageTypes,
} from '../../../../components/warehouse/AlertMessage';
import { Button } from '../../../../components/warehouse/Button';
import { Modal } from '../../../../components/warehouse/Modal';
import { ButtonWrapper, TextWrapper } from './TroubleshootLabelModal.elements';

export type TroubleshootLabelModalProps = {
  onCancel: () => void;
  onTransferred: () => void;
  isVisible: boolean;
  header?: {
    text?: string;
  };
  children?: React.ReactNode;
};

export const TroubleshootLabelModal = ({
  onCancel,
  onTransferred,
  isVisible,
  header,
  children,
}: TroubleshootLabelModalProps): JSX.Element => {
  return (
    <Modal
      isVisible={isVisible}
      variant="warning"
      header={{
        text: header?.text || 'Transfer parcel to Troubleshoot',
        icon: <TroubleshootIcon />,
      }}
      actions={[
        <ButtonWrapper>
          <Button variant="secondary" fullWidth onClick={onCancel}>
            Cancel
          </Button>
        </ButtonWrapper>,
        <ButtonWrapper>
          <Button
            fullWidth
            onClick={onTransferred}
            testId="transfer-parcel-to-troubleshoot"
          >
            Parcel transferred
          </Button>
        </ButtonWrapper>,
      ]}
    >
      <TextWrapper>
        Please attach the troubleshoot label to the parcel. Transfer the parcel
        to the troubleshoot location.
      </TextWrapper>
      {children && (
        <AlertMessage type={AlertMessageTypes.Default}>{children}</AlertMessage>
      )}
    </Modal>
  );
};
