import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => {
    if (theme.currentFontSizeSet === 'large') {
      return css`
        width: 100px;
        height: 100px;
      `;
    } else {
      return css`
        width: 40px;
        height: 40px;
      `;
    }
  }}
`;

export const Animation = styled.div`
  ${({ theme }) => {
    if (theme.currentFontSizeSet === 'large') {
      return css`
        border: 16px solid ${theme.palette.grey[4]};
        border-top: 16px solid ${theme.palette.primary.main};
      `;
    } else {
      return css`
        border: 6px solid ${theme.palette.grey[4]};
        border-top: 6px solid ${theme.palette.primary.main};
      `;
    }
  }}
  box-sizing: border-box;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  width: 100%;
  height: 100%;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
