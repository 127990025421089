"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.spectrumWebhookApiSchema = exports.webhookApiSchema = void 0;
const zod_http_schemas_1 = require("zod-http-schemas");
const events_1 = require("../events");
const http_1 = require("../http");
const pubSubPushMessage = zod_http_schemas_1.z.object({
    message: zod_http_schemas_1.z.object({
        attributes: zod_http_schemas_1.z
            .record(zod_http_schemas_1.z.union([zod_http_schemas_1.z.string(), zod_http_schemas_1.z.number(), zod_http_schemas_1.z.boolean()]))
            .optional(),
        data: zod_http_schemas_1.z.string(),
        messageId: zod_http_schemas_1.z.string(),
        message_id: zod_http_schemas_1.z.string().optional(),
        publishTime: zod_http_schemas_1.z.string().optional(),
        publish_time: zod_http_schemas_1.z.string().optional(),
    }),
    subscription: zod_http_schemas_1.z.string(),
});
exports.webhookApiSchema = (0, zod_http_schemas_1.createHttpSchema)({
    'POST /webhook/skutopia': {
        requestBody: pubSubPushMessage,
        responseBody: zod_http_schemas_1.z.union([
            http_1.ErrorBody,
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('SUCCESS') }),
        ]),
    },
    'POST /webhook/wcs': {
        requestBody: pubSubPushMessage,
        responseBody: zod_http_schemas_1.z.union([
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('FAILED_TO_HANDLE_EVENT'),
                error: zod_http_schemas_1.z.unknown(),
                event: zod_http_schemas_1.z.union([events_1.wcsEvent, zod_http_schemas_1.z.unknown()]),
            }),
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('SUCCESS') }),
        ]),
    },
});
var webhook_1 = require("./spectrum/webhook");
Object.defineProperty(exports, "spectrumWebhookApiSchema", { enumerable: true, get: function () { return webhook_1.spectrumWebhookApiSchema; } });
