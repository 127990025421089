import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { Transfer } from 'api-schema';
import { DataTable } from '../../../components/admin/DataTable';
import { AdminThemeInterface } from '../../../themes/AdminTheme';
import { mapTransferToRow, transferColumns } from './TransferOverview.methods';

type Props = {
  transfers: Transfer[];
};

export const TransferOverviewView = ({ transfers }: Props) => {
  const { spacing } = useTheme<AdminThemeInterface>();
  return (
    <Box>
      <Typography variant="h2" gutterBottom style={{ marginTop: spacing(2) }}>
        Transfers
      </Typography>
      <DataTable
        withSearch
        searchInternally
        columns={transferColumns}
        rows={transfers.map(mapTransferToRow) || []}
      />
    </Box>
  );
};
