import styled from 'styled-components';
import { colours } from '../../../constants/colours';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 10000;
  padding: 10px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  // can't use theme at this level
  background: ${colours.error.main};
  color: ${colours.primary.light};
`;

export const TextWrapper = styled.span`
  a {
    // can't use theme at this level
    color: ${colours.primary.light};
  }
`;
