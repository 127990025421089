import { ReactElement } from 'react';
import { Button } from '../../../../components/warehouse/Button';
import { Modal } from '../../../../components/warehouse/Modal';
import { Text } from '../../../../components/warehouse/Text';

export type ConfirmReadyForPackingModalProps = {
  closeModal: () => void;
  readyForPacking: () => void;
};

export const ConfirmReadyForPackingModal = ({
  closeModal,
  readyForPacking,
}: ConfirmReadyForPackingModalProps): ReactElement => {
  return (
    <Modal
      isVisible
      actions={[
        <Button variant="secondary" fullWidth onClick={closeModal}>
          Cancel
        </Button>,
        <Button variant="primary" fullWidth onClick={readyForPacking}>
          Ready for packing
        </Button>,
      ]}
    >
      <Text variant="h1" weight="medium">
        Are all items fulfilled and ready for packing?
      </Text>
    </Modal>
  );
};
