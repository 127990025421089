import { ReactComponent as CheckCircleIcon } from '../../../assets/img/icons/check-circle-inverse.svg';
import { warehouseColours } from '../../../constants/colours';
import { AlertMessage, AlertMessageTypes } from '../AlertMessage';
import {
  PickingAlertWrapper,
  PickingContentWrapper,
  PickingStep,
  PickingStepCount,
  PickingStepsWrapper,
  PickingWrapper,
} from './PickingSteps.elements';
export type PickingStepContent = {
  completed?: boolean;
  withCount?: boolean;
  stepTitle?: React.ReactNode;
  errorContent?: React.ReactNode;
  content?: React.ReactNode;
};

export interface PickingStepsProps {
  stepContents: PickingStepContent[];
}

export function PickingSteps({ stepContents }: PickingStepsProps) {
  return (
    <PickingStepsWrapper>
      {stepContents.map(
        (
          { withCount = true, content, stepTitle, errorContent, completed },
          index
        ) => {
          return (
            <PickingStep key={`step-${index}`}>
              {stepTitle && (
                <PickingWrapper>
                  {withCount && (
                    <PickingStepCount $completed={completed}>
                      {completed ? (
                        <CheckCircleIcon
                          fill={warehouseColours.success.light}
                        />
                      ) : (
                        index + 1
                      )}
                    </PickingStepCount>
                  )}
                  <PickingContentWrapper>{stepTitle}</PickingContentWrapper>
                </PickingWrapper>
              )}
              {content}
              {errorContent && (
                <PickingAlertWrapper>
                  <AlertMessage type={AlertMessageTypes.Error}>
                    {errorContent}
                  </AlertMessage>
                </PickingAlertWrapper>
              )}
            </PickingStep>
          );
        }
      )}
    </PickingStepsWrapper>
  );
}
