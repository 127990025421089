import { PickingMode } from 'api-schema/lib/model/pickingMode';
import { PortCategoryChip } from '../../../../components/warehouse/PortCategoryChip';
import {
  ChipVariant,
  chipVariantPickingModeMap,
} from '../../../../components/warehouse/PortCategoryChip/PortCategoryChip.elements';
import { Text } from '../../../../components/warehouse/Text';
import {
  PortCategoryEnumValueType,
  PORT_CATEGORY,
  PORT_CATEGORY_LABEL,
} from '../../../../constants/port';
import { Wrapper } from './CategoryPanel.elements';

export type CategoryPanelProps = {
  categories: Array<PortCategoryEnumValueType>;
  queuedSelectedCategories: Array<PortCategoryEnumValueType> | null;
  pickingModes: Array<PickingMode>;
  queuedPickingModes: Array<PickingMode['name']> | undefined;
};

const VARIANT_CHIP_MAP = {
  [PORT_CATEGORY.SAME_DAY]: 'sameday',
  [PORT_CATEGORY.STANDARD]: 'standard',
  [PORT_CATEGORY.PRIORITY]: 'priority',
  [PORT_CATEGORY.B2B]: 'b2b',
  [PORT_CATEGORY.MANUAL_PACK]: 'manualPack',
} as const;

const PICKING_MODE_NAME_LABEL: Record<PickingMode['name'], string> = {
  B2B: 'B2B',
  STANDARD: 'Standard',
  PRIORITY: 'Priority',
  SAMEDAY: 'Same Day',
  MANUAL_PACK: 'Manual Pack',
};

type CategoryChipDetails = {
  friendlyName: string;
  chip: ChipVariant;
};

const TransitioningChip = ({
  categories,
  pickingModes,
}: {
  categories: Array<PortCategoryEnumValueType>;
  pickingModes: Array<PickingMode['name']> | undefined;
}) => {
  const transitioningPickingModeLabels = pickingModes
    ? pickingModes.map(
        (pickingModeName) => PICKING_MODE_NAME_LABEL[pickingModeName]
      )
    : categories.map((category) => PORT_CATEGORY_LABEL[category] as string);

  if (transitioningPickingModeLabels.length === 2) {
    return (
      <PortCategoryChip variant="transitioning">
        <Text tag="span">
          Transitioning to{' '}
          <Text weight="medium" tag="span">
            {transitioningPickingModeLabels[0]}
          </Text>{' '}
          and{' '}
          <Text weight="medium" tag="span">
            {transitioningPickingModeLabels[1]}
          </Text>{' '}
          modes
        </Text>
      </PortCategoryChip>
    );
  }

  return (
    <PortCategoryChip variant="transitioning">
      <Text tag="span">
        Transitioning to{' '}
        <Text weight="medium" tag="span">
          {transitioningPickingModeLabels[0]}
        </Text>{' '}
        mode
      </Text>
    </PortCategoryChip>
  );
};

export function CategoryPanel({
  categories,
  queuedSelectedCategories,
  pickingModes,
  queuedPickingModes,
}: CategoryPanelProps) {
  if (queuedSelectedCategories?.length) {
    return (
      <TransitioningChip
        categories={queuedSelectedCategories}
        pickingModes={queuedPickingModes}
      />
    );
  }

  const friendlyPickingModeNames: Array<CategoryChipDetails> =
    pickingModes.length > 0
      ? pickingModes.map((pickingMode) => ({
          chip: chipVariantPickingModeMap[pickingMode.name],
          friendlyName: PICKING_MODE_NAME_LABEL[pickingMode.name],
        }))
      : categories.map((category) => ({
          chip: VARIANT_CHIP_MAP[category],
          friendlyName: PORT_CATEGORY_LABEL[category],
        }));

  return (
    <Wrapper>
      {friendlyPickingModeNames.map((pickingMode) => (
        <PortCategoryChip variant={pickingMode.chip}>
          <Text tag="span">{pickingMode.friendlyName} mode</Text>
        </PortCategoryChip>
      ))}
    </Wrapper>
  );
}
