import { ReactComponent as PrintIcon } from '../../../../assets/img/icons/print.svg';
import { Button } from '../../../../components/warehouse/Button';
import { Container } from '../../../../components/warehouse/Container';
import { Spinner } from '../../../../components/warehouse/Spinner';
import { Steps } from '../../../../components/warehouse/Steps';
import { StepContent } from '../../../../components/warehouse/Steps/Steps';
import { Text } from '../../../../components/warehouse/Text';
import { StatusType } from '../../PackAndDispatch.model';
import { TroubleshootLabelModal } from '../TroubleshootLabelModal';
import {
  ErrorContent,
  GeneratingLabelWrapper,
  PrintLabelButtonsWrapper,
  TroubleshootButtonsWrapper,
} from './PrintInstruction.elements';

export type PrintInstructionProps = {
  labelStatus: StatusType;
  handleTransferParcelToTroubleshoot: () => Promise<void>;
  isTroubleshootPrintingViaNetwork: boolean;
  setIsTroubleshootPrintingViaNetwork: (value: boolean) => void;
  printStatus: StatusType;
  printLabelHandler: () => Promise<void>;
  printLabelViaNetworkHandler: () => Promise<void>;
  printTroubleshootLabelHandler: () => Promise<void>;
  printTroubleshootLabelViaNetworkHandler: () => Promise<void>;
};

const getContent = ({
  labelStatus,
  handleTransferParcelToTroubleshoot,
  isTroubleshootPrintingViaNetwork,
  setIsTroubleshootPrintingViaNetwork,
  printStatus,
  printLabelHandler,
  printTroubleshootLabelHandler,
  printLabelViaNetworkHandler,
  printTroubleshootLabelViaNetworkHandler,
}: PrintInstructionProps): StepContent[] => {
  switch (labelStatus) {
    case 'INITIAL':
      return [
        {
          withCount: false,
          content: (
            <GeneratingLabelWrapper>
              <PrintLabelButtonsWrapper>
                <Spinner />
              </PrintLabelButtonsWrapper>
              <Text variant="body2" tag="span" testId="generating-label-text">
                Generating label
              </Text>
            </GeneratingLabelWrapper>
          ),
        },
      ];
    case 'SUCCESS':
      return [
        {
          isComplete: printStatus === 'SUCCESS',
          content: (
            <>
              <Text variant="body1">Print shipping label</Text>
              <PrintLabelButtonsWrapper>
                <Button
                  variant="ghost"
                  onClick={printLabelHandler}
                  testId="print-label-button"
                >
                  {printStatus === 'SUCCESS'
                    ? `Reprint label via PDF`
                    : `Print label via PDF`}
                </Button>
                <Button
                  variant="tertiary"
                  onClick={(e) => {
                    e.preventDefault();
                    printLabelViaNetworkHandler();
                    e.currentTarget.blur();
                  }}
                  icon={<PrintIcon />}
                  testId="network-print-label-button"
                >
                  {printStatus === 'SUCCESS' ? 'Reprint label' : 'Print label'}
                </Button>
              </PrintLabelButtonsWrapper>
            </>
          ),
          errorContent: printStatus === 'ERROR' && (
            <Text variant="body2" tag="span">
              Printing failed. Please retry printing the label. If the problem
              persists, please print the label via the PDF viewer.
            </Text>
          ),
        },
        {
          content: (
            <Text variant="body1">Attach shipping label to the parcel</Text>
          ),
        },
      ];
    case 'ERROR':
      return [
        {
          errorContent: (
            <ErrorContent>
              <Text variant="body2" tag="span">
                <Text variant="body2" weight="medium" tag="span">
                  Unable to generate label.
                </Text>{' '}
                Please print a Troubleshoot Label to attach to the parcel.
              </Text>
              <TroubleshootButtonsWrapper>
                <Button
                  variant="criticalMessagePrimary"
                  onClick={printTroubleshootLabelViaNetworkHandler}
                  icon={<PrintIcon />}
                  testId="print-troubleshoot-label-button"
                >
                  Print Troubleshoot Label
                </Button>
                <Button
                  variant="criticalMessageGhost"
                  onClick={printTroubleshootLabelHandler}
                  testId="print-troubleshoot-label-button"
                >
                  Print via PDF viewer
                </Button>
                <TroubleshootLabelModal
                  isVisible={isTroubleshootPrintingViaNetwork}
                  header={{ text: 'Attach label and transfer parcel' }}
                  onCancel={() => {
                    setIsTroubleshootPrintingViaNetwork(false);
                  }}
                  onTransferred={() => {
                    setIsTroubleshootPrintingViaNetwork(false);
                    handleTransferParcelToTroubleshoot();
                  }}
                >
                  If the troubleshoot label failed to print, please cancel this
                  action and retry printing. If the problem persists, please
                  print the label via the PDF viewer.
                </TroubleshootLabelModal>
              </TroubleshootButtonsWrapper>
            </ErrorContent>
          ),
        },
      ];
  }
};

export function PrintInstruction(props: PrintInstructionProps) {
  return (
    <Container title="Print" contentGap={false} padding="none">
      <Steps stepContents={getContent(props)} />
    </Container>
  );
}
