import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { GridOverlay } from '@material-ui/data-grid';
import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import { Search } from '../Search';
import * as Table from './Table.elements';

export type Props = {
  searchTerm?: string;
  handleSearchChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  withSearch?: boolean;
  actions?: React.ReactElement;
};

export function Header({
  handleSearchChange,
  withSearch,
  actions,
}: Props): ReactElement {
  const [searchTerm, setSearchTerm] = useState('');

  // Don't return an empty <Table.Header>
  if (!withSearch && !actions) {
    return <></>;
  }
  return (
    <Table.Header>
      {withSearch && (
        <Search
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value);
            if (handleSearchChange) {
              handleSearchChange(event);
            }
          }}
        />
      )}
      {actions && actions}
    </Table.Header>
  );
}

const ProgressContainer = styled(Box)`
  position: absolute;
  top: 0;
  width: 100%;
  overflow: hidden;
  height: 2px;
`;

export function CustomTableLoadingOverlay(): ReactElement {
  return (
    <GridOverlay>
      <ProgressContainer>
        <LinearProgress />
      </ProgressContainer>
    </GridOverlay>
  );
}
