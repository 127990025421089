import { lighten, mix } from 'polished';
import styled, { css } from 'styled-components';

interface WrapperProps {
  hasError?: boolean;
  isValid?: boolean;
  fullWidth?: boolean;
}

export const Wrapper = styled.label<WrapperProps>`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  cursor: text;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

  border: 1px solid ${(props) => props.theme.palette.grey[5]};
  border-radius: ${(props) => props.theme.shape.borderRadius}px;

  ${({ theme }) => {
    return css`
      padding: ${theme.currentFontSizeSet === 'large' ? '6.5px' : '15.5px'} 16px;
    `;
  }};
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.15);
  background-color: ${(props) => props.theme.palette.grey[1]};

  &:focus-within {
    outline: none;
    border-color: ${(props) => props.theme.palette.primary.main};
    box-shadow:
      0 0 0 4px rgba(0, 95, 198, 0.4),
      inset 0 2px 2px rgba(0, 0, 0, 0.15);
  }

  input {
    padding: unset;
  }

  ${({ hasError, theme }) => {
    if (hasError) {
      return css`
        border-color: ${(props) => props.theme.palette.error.base};
        background: ${mix(
          0.4,
          theme.palette.error.light,
          theme.palette.common.white
        )};

        &:focus-within {
          outline: none;
          border: 1px solid ${(props) => props.theme.palette.error.base};
          box-shadow:
            0 0 0 4px rgba(205, 9, 9, 0.4),
            inset 0 2px 2px rgba(0, 0, 0, 0.15);
        }

        & ${Field} {
          color: ${(props) => props.theme.palette.error.dark};
          background: ${lighten(0.6, theme.palette.error.light)};
          background: ${mix(
            0.4,
            theme.palette.error.light,
            theme.palette.common.white
          )};
        }
      `;
    }
  }};
`;

export const ButtonWrapper = styled.div`
  margin-right: -12px;

  button {
    padding: 0 15px;
  }
`;

export const Field = styled.input`
  font-weight: 400;
  font-size: ${(props) =>
    props.theme.typography[
      props.theme.currentFontSizeSet === 'large' ? 'body1' : 'body2'
    ].fontSize};
  line-height: ${(props) => props.theme.typography.body1.lineHeight};
  border: none;
  background-color: ${(props) => props.theme.palette.grey[1]};
  width: 100%;
  margin-right: 8px;

  &::placeholder {
    color: ${(props) => props.theme.palette.text.placeholder};
  }

  &:focus {
    outline: none;
  }
`;

export const IconWrapper = styled.div`
  width: 30px;
  height: 30px;

  svg {
    width: inherit;
    height: inherit;
  }
`;
