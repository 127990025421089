import { ParcelSize } from 'api-schema/lib';

export type ParcelOptionType = {
  text: string;
  customLabel?: string;
  value: ParcelSize;
};

const THE_ICONIC_TAG = 'The Iconic';
const THE_ICONIC_LABEL_TEXT = 'THE ICONIC';

const PARCEL_SIZE: Array<ParcelOptionType> = [
  {
    text: 'Extra small',
    value: 'EXTRA_SMALL',
  },
  {
    text: 'Small',
    value: 'SMALL',
  },
  {
    text: 'Medium',
    value: 'MEDIUM',
  },
  {
    text: 'Large',
    value: 'LARGE',
  },
];

const ICONIC_PARCEL_SIZE: Array<ParcelOptionType> = [
  {
    text: 'Small',
    value: 'SMALL',
    customLabel: THE_ICONIC_LABEL_TEXT,
  },
  {
    text: 'Large',
    value: 'LARGE',
    customLabel: THE_ICONIC_LABEL_TEXT,
  },
];

export function getParcelOptionSetFromOrderTags(tags?: string[]) {
  if (tags?.includes(THE_ICONIC_TAG)) {
    return ICONIC_PARCEL_SIZE;
  }

  return PARCEL_SIZE;
}

export function getCustomLabelFromOrderTags(
  tags?: string[]
): string | undefined {
  if (tags?.includes(THE_ICONIC_TAG)) {
    return THE_ICONIC_LABEL_TEXT;
  }

  return undefined;
}

export function getParcelTypeFromOrderTags(tags?: string[]): ParcelType {
  if (tags?.includes(THE_ICONIC_TAG)) {
    return 'Satchel';
  }

  return 'Carton';
}

export type ParcelType = 'Satchel' | 'Carton';
