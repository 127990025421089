import {
  GridColDef,
  GridPageChangeParams,
  GridRowId,
  GridRowParams,
  GridRowsProp,
  GridSelectionModelChangeParams,
  GridSortModelParams,
} from '@material-ui/data-grid';
import React, { ReactElement, useEffect, useState } from 'react';
import { usePerformDebounced } from '../../../hooks/usePerformDebounced';
import { CustomTableLoadingOverlay, Header } from './Table.components';
import * as Table from './Table.elements';
import { filterRowsOptions, searchRows } from './Table.methods';

interface ComponentContextProps {
  handleSearchChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  withSearch?: boolean;
}

export const TableContext = React.createContext<ComponentContextProps>({});

export interface TableProps {
  actions?: ReactElement;
  checkboxSelection?: boolean;
  columns: GridColDef[];
  loading?: boolean;
  onPageChange?: (param: GridPageChangeParams) => void;
  onRowClick?: (param: GridRowParams) => void;
  onSearchChange?: (val: string) => void;
  onSelectionModelChange?: (param: GridSelectionModelChangeParams) => unknown;
  onSortModelChange?: (params: GridSortModelParams) => void;
  onPageSizeChange?: (params: GridPageChangeParams) => void;
  page?: number;
  paginationMode?: 'client' | 'server';
  rowCount?: number;
  rows: GridRowsProp;
  rowsPerPage?: number;
  searchInternally?: boolean;
  selectionModel?: Array<GridRowId>;
  withSearch?: boolean;
}
const DEFAULT_ROWS_PER_PAGE = [25, 50, 100];

export function DataTable({
  actions,
  checkboxSelection = true,
  columns,
  loading = false,
  onPageChange,
  onRowClick,
  onSearchChange,
  onSelectionModelChange,
  onSortModelChange,
  page,
  paginationMode = 'client',
  rowCount,
  rows,
  rowsPerPage = 25,
  onPageSizeChange,
  searchInternally = false,
  selectionModel,
  withSearch = false,
}: TableProps): ReactElement {
  const [filteredRows, setFilteredRows] = useState(rows);

  useEffect(() => {
    setFilteredRows(rows);
  }, [rows]);

  const handleDebounced = usePerformDebounced((value: string) => {
    if (withSearch && searchInternally) {
      console.log('filtering rows');
      setFilteredRows(searchRows(rows, value));
    }
    if (onSearchChange) {
      onSearchChange(value);
    }
  }, 300);

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (onSearchChange || (withSearch && searchInternally)) {
      handleDebounced(event.target.value);
    }
  };
  return (
    <Table.Wrapper>
      <Table.Data
        rows={filteredRows}
        columns={columns}
        autoHeight
        checkboxSelection={checkboxSelection}
        onSelectionModelChange={onSelectionModelChange}
        selectionModel={selectionModel}
        onSortModelChange={(params) => {
          if (onSortModelChange) {
            onSortModelChange(params);
          }
        }}
        components={{
          Header,
          LoadingOverlay: CustomTableLoadingOverlay,
          ColumnSortedAscendingIcon: Table.AscendingSortIcon,
          ColumnSortedDescendingIcon: Table.DescendingSortIcon,
        }}
        componentsProps={{
          header: {
            handleSearchChange,
            withSearch,
            actions,
          },
        }}
        pageSize={rowsPerPage}
        loading={loading}
        onRowClick={onRowClick}
        paginationMode={paginationMode}
        onPageChange={onPageChange}
        rowCount={rowCount}
        page={page}
        onPageSizeChange={onPageSizeChange}
        rowsPerPageOptions={
          rowCount
            ? filterRowsOptions(DEFAULT_ROWS_PER_PAGE, rowCount)
            : DEFAULT_ROWS_PER_PAGE
        }
        columnBuffer={columns.length}
      />
    </Table.Wrapper>
  );
}
