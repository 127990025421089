import styled, { css, keyframes } from 'styled-components';

export const Workstation = styled.div`
  .bin {
    opacity: 0;

    &.active {
      opacity: 1;
    }
  }

  .highlight {
    opacity: 0;
    transition: 0.3s ease opacity;

    &.active {
      opacity: 1;
    }
  }
`;

type BounceDirection = 'left' | 'right' | 'vertical';
const bounce = (direction: BounceDirection = 'left') => {
  if (direction === 'vertical') {
    return keyframes`
      from {
        transform: translateY(0);
      }

      to {
        transform: translateY(10px);
      }
    `;
  }

  const amount = direction === 'left' ? 10 : -10;
  return keyframes`
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(${amount}px);
    }
  `;
};

const toteColors = [
  { tote: 1, main: '#43A800', overlay: '#038200' }, // Green
  { tote: 2, main: '#F06D0F', overlay: '#FF5C00' }, // Orange
  { tote: 3, main: '#007AFF', overlay: '#007AFF' }, // Blue
  { tote: 4, main: '#D89F00', overlay: '#B47701' }, // Gold
  { tote: 5, main: '#9529E2', overlay: '#9314ED' }, // Purle
  { tote: 6, main: '#CD0909', overlay: '#CD0909' }, // Red
];

export const Totes = styled.div`
  ${toteColors.map(
    (toteColor) => css`
      .tote-${toteColor.tote} {
        .color {
          fill: ${toteColor.main};

          &.tote-outline {
            fill: #fff;
            stroke: ${toteColor.overlay};
          }
        }

        .color.tote-overlay {
          fill: ${toteColor.overlay};
        }
      }
    `
  )}
  .tote-1 .push-arrow,
  .tote-2 .push-arrow,
  .tote-3 .push-arrow {
    animation: ${bounce('left')} 0.5s ease-in-out infinite alternate;
  }

  .tote-4 .push-arrow,
  .tote-5 .push-arrow,
  .tote-6 .push-arrow {
    animation: ${bounce('right')} 0.5s ease-in-out infinite alternate;
  }

  .tote-1 .place-arrow,
  .tote-2 .place-arrow,
  .tote-3 .place-arrow,
  .tote-4 .place-arrow,
  .tote-5 .place-arrow,
  .tote-6 .place-arrow {
    animation: ${bounce('vertical')} 0.5s ease-in-out infinite alternate;
  }

  // Hide all states
  .tote > g {
    opacity: 0;
    transition: 0.3s ease opacity;
  }

  .tote .active {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;

  ${Workstation} {
    width: 85%;
    text-align: center;

    svg {
      width: 100%;
    }
  }

  ${Totes} {
    width: 100%;
    text-align: center;
    margin-top: -5px;

    svg {
      width: 100%;
    }
  }
`;

type PickIndicatorProps = {
  x: number;
  y: number;
  isVisible: boolean;
  isShortPick?: boolean;
};

const INDICATOR_ARROW_HEIGHT = 30;

export const PickIndicator = styled.span<PickIndicatorProps>`
  position: absolute;
  border-radius: 10px;
  background-color: ${({ isShortPick, theme }) =>
    isShortPick ? theme.palette.danger.main : theme.palette.secondary.main};
  font-size: ${({ theme }) => theme.typography.h2.fontSize};
  font-weight: ${({ theme }) => theme.typography.h2.fontWeight};
  color: ${({ theme }) => theme.palette.common.white};
  padding: 10px 20px;
  // Prefer a default x value of 50% so there's less jump the first time the indicator becomes visible
  left: ${({ x }) => x}px;
  top: ${({ y }) => y}px;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition:
    0.3s ease left,
    0.3s ease top;

  &::before {
    position: absolute;
    content: '';
    width: 0px;
    height: 0px;
    border-top: ${INDICATOR_ARROW_HEIGHT}px solid;
    border-top-color: ${({ isShortPick, theme }) =>
      isShortPick ? theme.palette.danger.main : theme.palette.secondary.main};
    border-left: ${INDICATOR_ARROW_HEIGHT - 10}px solid transparent;
    border-right: ${INDICATOR_ARROW_HEIGHT - 10}px solid transparent;
    left: 50%;
    transform: translateX(-50%);
    top: 100%;
  }
`;

export const TotesListSplitContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
