export const largeFontSizeSet = {
  h1: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '3.5rem',
    fontWeight: 500,
    lineHeight: 1.5,
  },
  h2: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '2.875rem',
    lineHeight: 1.5,
    fontWeight: 500,
  },
  h3: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '2.25rem',
    lineHeight: 1.5,
    fontWeight: 500,
  },
  body1: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '1.875rem',
    lineHeight: 1.5,
    fontWeight: 500,
  },
  body2: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '1.5rem',
    lineHeight: 1.5,
    fontWeight: 500,
  },
  body3: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '1.5rem',
    lineHeight: 1.5,
    fontWeight: 400,
  },
};

export const smallFontSizeSet = {
  h1: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '1.5rem',
    fontWeight: 500,
    lineHeight: 1.5,
  },
  h2: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '1.25rem',
    lineHeight: 1.5,
    fontWeight: 500,
  },
  h3: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '1.125rem',
    lineHeight: 1.5,
    fontWeight: 500,
  },
  body1: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '1.25rem',
    lineHeight: 1.5,
    fontWeight: 400,
  },
  body2: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '1.125rem',
    lineHeight: 1.5,
    fontWeight: 400,
  },
};
