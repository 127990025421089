"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cmcGenesysApiSchema = void 0;
const zod_1 = require("zod");
const zod_http_schemas_1 = require("zod-http-schemas");
const cmc_genesys_1 = require("../commands/outcomes/cmc-genesys");
const http_1 = require("../http");
exports.cmcGenesysApiSchema = (0, zod_http_schemas_1.createHttpSchema)({
    'POST /warehouses/:warehouseId/cmc-genesys/:machineId/tote/:referenceId/measure': {
        responseBody: cmc_genesys_1.measureCmcGenesysParcelOutcomeSchema,
        requestBody: zod_1.z.intersection(http_1.MeasureCmcGenesysParcelRequestBody, cmc_genesys_1.isTestSchema),
    },
    'POST /warehouses/:warehouseId/cmc-genesys/:machineId/tote/:toteId/scan': {
        responseBody: cmc_genesys_1.scanCmcGenesysToteOutcomeSchema,
        requestBody: cmc_genesys_1.isTestSchema,
    },
    'POST /warehouses/:warehouseId/cmc-genesys/:machineId/tote/:referenceId/exit': {
        responseBody: cmc_genesys_1.exitCmcGenesysToteOutcomeSchema,
        requestBody: zod_1.z.intersection(http_1.ExitCmcGenesysToteRequestBody, cmc_genesys_1.isTestSchema),
    },
    'POST /warehouses/:warehouseId/cmc-genesys/:machineId/parcel/:referenceId/label': {
        responseBody: cmc_genesys_1.labelCmcGenesysParcelOutcomeSchema,
        requestBody: zod_1.z.intersection(http_1.LabelCmcGenesysParcelRequestBody, cmc_genesys_1.isTestSchema),
    },
    'POST /warehouses/:warehouseId/cmc-genesys/:machineId/parcel/:referenceId/remove': {
        responseBody: cmc_genesys_1.removeCmcGenesysParcelOutcomeSchema,
        requestBody: cmc_genesys_1.isTestSchema,
    },
    'POST /warehouses/:warehouseId/cmc-genesys/:machineId/parcel/:referenceId/finish': {
        requestBody: zod_1.z.intersection(http_1.FinishCmcGenesysParcelRequestBody, cmc_genesys_1.isTestSchema),
        responseBody: cmc_genesys_1.finishCmcGenesysParcelOutcomeSchema,
    },
    'POST /warehouses/:warehouseId/cmc-genesys/:referenceId/troubleshoot': {
        requestBody: zod_1.z.object({
            troubleshootReason: zod_1.z.union([
                zod_1.z.literal('CMC_GENESYS_FAILED_TO_CREATE_BOX'),
                zod_1.z.literal('CMC_GENESYS_FAILED_TO_PRINT_LABEL'),
                zod_1.z.literal('CMC_GENESYS_ITEM_NOT_SUITABLE'),
                zod_1.z.literal('CMC_GENESYS_WRONG_BARCODE'),
                zod_1.z.literal('CMC_GENESYS_OTHER'),
            ]),
        }),
        responseBody: zod_1.z.union([
            zod_1.z.object({
                outcome: zod_1.z.literal('INVALID_CMC_GENESYS_REFERENCE_ID'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('FULFILMENT_ORDER_NOT_FOUND'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('INVALID_ORDER_STATUS'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('SUCCESS'),
            }),
        ]),
    },
    'GET /cmc-genesys-config': {
        responseBody: http_1.GetCmcGenesysConfigResponseBody,
    },
});
