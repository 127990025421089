// TODO(FCG-458): rename it to snackbarDefaultConfig
export const SnackbarDefaults = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  variant: 'success',
} as const;

export const snackbarErrorConfig = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  variant: 'error',
} as const;
