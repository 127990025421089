import { GridColDef, GridRowsProp } from '@material-ui/data-grid';
import { currencyFormatter } from '../../../utils/tables/currencyFormatter';
import * as Table from './Table.elements';

export const CLASSNAME_SORTED_CELL = 'HeaderCell--is-sorting';

export const withSortColumnHeaderIcon = (columns: GridColDef[]): GridColDef[] =>
  columns.map((col) => ({
    ...col,
    headerAlign: 'left',
    renderHeader: () => (
      <Table.HeaderCell>
        <span>{col.headerName}</span>
        <Table.HeaderSortIcon fontSize="small" />
      </Table.HeaderCell>
    ),
  }));

const currencyColumnNames = ['fee', 'price', 'cost', 'net'];

export const searchRows = (
  rowsToFilter: GridRowsProp,
  searchTerm: string
): GridRowsProp =>
  rowsToFilter.filter((row) =>
    Object.entries(row).some(([columnName, value]) => {
      if (!value) {
        return false;
      }
      if (typeof value === 'string') {
        return value
          .toLocaleLowerCase()
          .includes(searchTerm.toLocaleLowerCase());
      }
      if (typeof value === 'number') {
        if (
          currencyColumnNames.some((currencyColumn) =>
            columnName.toLocaleLowerCase().includes(currencyColumn)
          )
        ) {
          return currencyFormatter
            .format(Number(value) / 100)
            .toString()
            .includes(searchTerm.toLocaleLowerCase());
        }
        return value.toString().includes(searchTerm);
      }
      if (Array.isArray(value)) {
        return value.some((innerVal) =>
          innerVal
            .toString()
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase())
        );
      }
      return false;
    })
  );

export const filterRowsOptions = (
  rowsPerPageOptions: Array<number>,
  rowCount: number
): Array<number> =>
  rowsPerPageOptions.filter((rowLimit) => !rowCount || rowLimit <= rowCount);
