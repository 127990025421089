import styled, { css, CSSProperties } from 'styled-components';

export type BoxProps = {
  margin?: CSSProperties['margin'];
  marginLeft?: CSSProperties['marginLeft'];
  marginTop?: CSSProperties['marginTop'];
  marginBottom?: CSSProperties['marginBottom'];
  marginRight?: CSSProperties['marginRight'];
  padding?: CSSProperties['padding'];
  paddingLeft?: CSSProperties['paddingLeft'];
  paddingTop?: CSSProperties['paddingTop'];
  paddingBottom?: CSSProperties['paddingBottom'];
  paddingRight?: CSSProperties['paddingRight'];
  position?: CSSProperties['position'];
  height?: string;
  width?: string;
  children?: React.ReactNode;
};

export const Box = styled.div<BoxProps>`
  ${({
    margin,
    marginLeft,
    marginTop,
    marginBottom,
    marginRight,
    padding,
    paddingTop,
    paddingLeft,
    paddingRight,
    paddingBottom,
    position,
    height,
    width,
  }) => {
    return css`
      margin: ${typeof margin === 'number' ? `${margin}px` : margin};
      margin-left: ${typeof marginLeft === 'number'
        ? `${marginLeft}px`
        : marginLeft};
      margin-top: ${typeof marginTop === 'number'
        ? `${marginTop}px`
        : marginTop};
      margin-bottom: ${typeof marginBottom === 'number'
        ? `${marginBottom}px`
        : marginBottom};
      margin-right: ${typeof marginRight === 'number'
        ? `${marginRight}px`
        : marginRight};
      padding: ${typeof padding === 'number' ? `${padding}px` : padding};
      padding-left: ${typeof paddingLeft === 'number'
        ? `${paddingLeft}px`
        : paddingLeft};
      padding-top: ${typeof paddingTop === 'number'
        ? `${paddingTop}px`
        : paddingTop};
      padding-bottom: ${typeof paddingBottom === 'number'
        ? `${paddingBottom}px`
        : paddingBottom};
      padding-right: ${typeof paddingRight === 'number'
        ? `${paddingRight}px`
        : paddingRight};
      position: ${position};
      height: ${height};
      width: ${width};
    `;
  }}
`;
