import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const ContainerItem = styled(Box)`
  color: ${({ theme }) => theme.palette.grey['800']};
  padding: 2px 0px;
  span {
    color: ${({ theme }) => theme.palette.grey['600']};
  }
`;
