import { ReactElement } from 'react';
import { ReactComponent as TroubleshootIcon } from '../../../../assets/img/icons/troubleshoot-icon.svg';
import { Button } from '../../../../components/warehouse/Button';
import { Container } from '../../../../components/warehouse/Container';
import { Text } from '../../../../components/warehouse/Text';
import { pluralise } from '../../../../utils/pluralise';

//TODO go back flow

export type ReplaceTotesProps = {
  missingToteCount: number;
  replaceTotes: () => void;
  goBack: () => void;
};

export const ReplaceTotes = ({
  missingToteCount,
  replaceTotes,
  goBack,
}: ReplaceTotesProps): ReactElement => {
  return (
    <Container
      buttons={[
        <Button variant="secondary" fullWidth onClick={goBack}>
          Go back
        </Button>,
        <Button variant="warning" fullWidth icon={<TroubleshootIcon />}>
          Troubleshoot
        </Button>,
      ]}
      padding="none"
      alignCenter
    >
      <Text variant="body1" weight="medium">
        Replenish the {pluralise('missing tote', missingToteCount)}
      </Text>
      <Button variant="primary" onClick={replaceTotes}>
        Totes replenished
      </Button>
    </Container>
  );
};
