"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.autostoreStateReceivedEvent = exports.autostoreCompletePortState = exports.autostorePortState = exports.autostoreBinStateSchema = exports.autostorePortDefinition = exports.autostorePortType = exports.autostorePortMode = exports.autostoreBinMode = exports.autostorePortDefinitionChangedEvent = exports.autostoreLogDownloadCompletedEvent = exports.autostoreLogDownloadStartedEvent = exports.autostoreBinLocationChangedEvent = exports.autostoreBinClosedEvent = exports.autostoreBinOpenedEvent = exports.autostorePortModeChangedEvent = exports.autostoreEvent = exports.wcsEvent = exports.systemApiSchema = exports.merchantApiSchema = exports.featureFlagApiSchema = exports.picklistApiSchema = exports.webhookApiSchema = exports.createFulfilmentOrderRequestSchema = exports.AddTotesRequest = exports.PickPortStateModel = exports.putawayPortSchema = exports.UnconfiguredPortStateModel = exports.BIN_TYPE_HEIGHT_MAP = exports.combinedApiSchema = exports.fulfilmentOrderApiSchema = exports.warehouseApiSchema = exports.isInternationShipment = void 0;
const http_1 = require("./http");
Object.defineProperty(exports, "AddTotesRequest", { enumerable: true, get: function () { return http_1.AddTotesRequest; } });
Object.defineProperty(exports, "createFulfilmentOrderRequestSchema", { enumerable: true, get: function () { return http_1.createFulfilmentOrderRequestSchema; } });
const schema_1 = require("./schema");
Object.defineProperty(exports, "combinedApiSchema", { enumerable: true, get: function () { return schema_1.combinedApiSchema; } });
Object.defineProperty(exports, "featureFlagApiSchema", { enumerable: true, get: function () { return schema_1.featureFlagApiSchema; } });
Object.defineProperty(exports, "fulfilmentOrderApiSchema", { enumerable: true, get: function () { return schema_1.fulfilmentOrderApiSchema; } });
Object.defineProperty(exports, "merchantApiSchema", { enumerable: true, get: function () { return schema_1.merchantApiSchema; } });
Object.defineProperty(exports, "picklistApiSchema", { enumerable: true, get: function () { return schema_1.picklistApiSchema; } });
Object.defineProperty(exports, "systemApiSchema", { enumerable: true, get: function () { return schema_1.systemApiSchema; } });
Object.defineProperty(exports, "warehouseApiSchema", { enumerable: true, get: function () { return schema_1.warehouseApiSchema; } });
Object.defineProperty(exports, "webhookApiSchema", { enumerable: true, get: function () { return schema_1.webhookApiSchema; } });
const model_1 = require("./model");
Object.defineProperty(exports, "PickPortStateModel", { enumerable: true, get: function () { return model_1.PickPortStateModel; } });
Object.defineProperty(exports, "putawayPortSchema", { enumerable: true, get: function () { return model_1.putawayPortSchema; } });
Object.defineProperty(exports, "UnconfiguredPortStateModel", { enumerable: true, get: function () { return model_1.UnconfiguredPortStateModel; } });
const events_1 = require("./events");
Object.defineProperty(exports, "autostoreBinClosedEvent", { enumerable: true, get: function () { return events_1.autostoreBinClosedEvent; } });
Object.defineProperty(exports, "autostoreBinLocationChangedEvent", { enumerable: true, get: function () { return events_1.autostoreBinLocationChangedEvent; } });
Object.defineProperty(exports, "autostoreBinMode", { enumerable: true, get: function () { return events_1.autostoreBinMode; } });
Object.defineProperty(exports, "autostoreBinOpenedEvent", { enumerable: true, get: function () { return events_1.autostoreBinOpenedEvent; } });
Object.defineProperty(exports, "autostoreBinStateSchema", { enumerable: true, get: function () { return events_1.autostoreBinStateSchema; } });
Object.defineProperty(exports, "autostoreCompletePortState", { enumerable: true, get: function () { return events_1.autostoreCompletePortState; } });
Object.defineProperty(exports, "autostoreEvent", { enumerable: true, get: function () { return events_1.autostoreEvent; } });
Object.defineProperty(exports, "autostoreLogDownloadCompletedEvent", { enumerable: true, get: function () { return events_1.autostoreLogDownloadCompletedEvent; } });
Object.defineProperty(exports, "autostoreLogDownloadStartedEvent", { enumerable: true, get: function () { return events_1.autostoreLogDownloadStartedEvent; } });
Object.defineProperty(exports, "autostorePortDefinition", { enumerable: true, get: function () { return events_1.autostorePortDefinition; } });
Object.defineProperty(exports, "autostorePortDefinitionChangedEvent", { enumerable: true, get: function () { return events_1.autostorePortDefinitionChangedEvent; } });
Object.defineProperty(exports, "autostorePortMode", { enumerable: true, get: function () { return events_1.autostorePortMode; } });
Object.defineProperty(exports, "autostorePortModeChangedEvent", { enumerable: true, get: function () { return events_1.autostorePortModeChangedEvent; } });
Object.defineProperty(exports, "autostorePortState", { enumerable: true, get: function () { return events_1.autostorePortState; } });
Object.defineProperty(exports, "autostorePortType", { enumerable: true, get: function () { return events_1.autostorePortType; } });
Object.defineProperty(exports, "autostoreStateReceivedEvent", { enumerable: true, get: function () { return events_1.autostoreStateReceivedEvent; } });
Object.defineProperty(exports, "wcsEvent", { enumerable: true, get: function () { return events_1.wcsEvent; } });
var address_1 = require("./constants/address");
Object.defineProperty(exports, "isInternationShipment", { enumerable: true, get: function () { return address_1.isInternationShipment; } });
const BIN_TYPE_HEIGHT_MAP = {
    1: '330mm',
    2: '220mm',
    5: '425mm',
};
exports.BIN_TYPE_HEIGHT_MAP = BIN_TYPE_HEIGHT_MAP;
