"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pickPortPickingModesSelectedSchema = exports.pickPortCategoriesUnchangedSchema = exports.pickPortCategoriesSelectedSchema = exports.autostoreHandPickAllocatedItemsRetrievedSchema = exports.picklistUnassignedFromToteLocationSchema = exports.InsufficientItemsToPick = exports.PickPortHadNoPaper = exports.PickProblemRaised = exports.GreenPaperPlacedInTotes = exports.redPaperPlacedInTotesSchema = exports.cancelMissingItemResolutionFailedSchema = exports.missingItemResolutionCancelledSchema = exports.detectMissingItemAtPickingFailedSchema = exports.missingItemAtPickingDetectedSchema = exports.shortPickRaisedSchema = exports.unexpectedBinRetrievedSchema = exports.PickLocationsClosed = exports.pickPortTaskGroupAssignmentStoppedSchema = exports.pickTotesUnpushedSchema = exports.pickTotesReplacedSchema = exports.pickTotesPushedSchema = exports.totesPickingCompletedSchema = exports.LastPickUndone = exports.failedToConfirmMissingItemDuringPickSchema = exports.missingItemConfirmedSchema = exports.PickCompleted = exports.allTotesUnlinkedSchema = exports.itemReadyToPickSchema = exports.noTaskGroupsAvailableSchema = exports.picklistAssignedToToteLocationSchema = exports.taskGroupAssignedToPortSchema = exports.PickingFailedToStart = exports.PickingStarted = exports.toteLinkingProcessCompletedSchema = exports.toteLinkCancelledSchema = exports.toteLinkConfirmedSchema = exports.ToteScanFailed = exports.toteScanSucceededSchema = exports.ToteLocationScanFailed = exports.toteLocationScanSucceededSchema = exports.pickPortTotesPlacedSchema = exports.pickItemRemovedFromToteSchema = exports.pickItemAddedToToteSchema = exports.pickPortClosingFailedSchema = exports.pickPortClosedSchema = exports.pickPortClosingSchema = exports.pickBinReturnedSchema = exports.pickPortOpeningFailedSchema = exports.pickPortOpenedSchema = exports.pickPortOpeningSchema = void 0;
exports.PickEventNames = exports.PickEvent = exports.pickItemBarcodeValidatedSchema = exports.pickItemBarcodeValidationFailedSchema = exports.removeEmptyTotesFromPortsSchema = exports.updateLinkedTotesAtPortSchema = exports.itemsRecoveredFromUnexpectedToteExitSchema = exports.determineZeroCountCrossCheckRequiredFailedSchema = exports.zeroCountCrossCheckRequiredSchema = exports.unlockPickPortFailedSchema = exports.pickPortUnlockedSchema = exports.lockPickPortFailedSchema = exports.pickPortLockedSchema = exports.disableConveyorAtPortFailureSchema = exports.conveyorDisabledAtPortSchema = exports.enableConveyorAtPortFailureSchema = exports.conveyorEnabledAtPortSchema = exports.pickPortPickingModesQueueClearedSchema = void 0;
const zod_1 = require("zod");
const model_1 = require("../model");
const pickingMode_1 = require("../model/pickingMode");
const base_1 = require("./base");
const port_1 = require("./port");
exports.pickPortOpeningSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICK_PORT_OPENING'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
    }),
});
exports.pickPortOpenedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICK_PORT_OPENED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        contentCodes: zod_1.z.number().array().optional(),
        categories: pickingMode_1.autostoreCategorySchema.array().optional(),
        pickingModes: pickingMode_1.pickingModeNameSchema.array().optional(),
    }),
});
exports.pickPortOpeningFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICK_PORT_OPENING_FAILED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        previousStatus: zod_1.z.optional(model_1.PickPortStatus),
    }),
});
exports.pickBinReturnedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICK_BIN_RETURNED'),
    payload: zod_1.z.object({
        autostoreBinId: base_1.autostoreBinIdSchema,
        binId: zod_1.z.string().min(1),
        portId: base_1.portIdSchema,
        autostoreTaskId: zod_1.z.number().optional(),
        taskGroupId: zod_1.z.string().optional(),
    }),
});
exports.pickPortClosingSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICK_PORT_CLOSING'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        usingConveyorSystem: zod_1.z.boolean().optional(),
    }),
});
exports.pickPortClosedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICK_PORT_CLOSED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        usingConveyorSystem: zod_1.z.boolean().optional(),
    }),
});
exports.pickPortClosingFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICK_PORT_CLOSING_FAILED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        previousStatus: zod_1.z.optional(model_1.PickPortStatus),
        usingConveyorSystem: zod_1.z.boolean().optional(),
    }),
});
exports.pickItemAddedToToteSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICK_ITEM_ADDED_TO_TOTE'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        picklistId: base_1.StringId,
        toteId: base_1.StringId,
        merchantId: base_1.StringId,
        barcode: base_1.StringId,
        quantity: zod_1.z.number().nonnegative().int(),
        binId: base_1.StringId,
        autostoreBinId: zod_1.z.number().positive().int(),
        applyWms694Fix: zod_1.z.boolean().optional(),
    }),
});
exports.pickItemRemovedFromToteSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICK_ITEM_REMOVED_FROM_TOTE'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        picklistId: base_1.StringId,
        toteId: base_1.StringId,
        merchantId: base_1.StringId,
        barcode: base_1.StringId,
        quantity: zod_1.z.number().nonnegative().int(),
        binId: base_1.StringId,
        autostoreBinId: zod_1.z.number().positive().int(),
        applyWms694Fix: zod_1.z.boolean().optional(),
    }),
});
exports.pickPortTotesPlacedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICK_PORT_TOTES_PLACED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        usingConveyorSystem: zod_1.z.boolean().optional(),
    }),
});
exports.toteLocationScanSucceededSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TOTE_LOCATION_SCAN_SUCCEEDED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        locationId: base_1.StringId,
        usingConveyorSystem: zod_1.z.boolean().optional(),
    }),
});
exports.ToteLocationScanFailed = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TOTE_LOCATION_SCAN_FAILED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        locationId: base_1.StringId,
    }),
});
exports.toteScanSucceededSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TOTE_SCAN_SUCCEEDED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        locationId: base_1.StringId,
        toteId: base_1.StringId,
        usingConveyorSystem: zod_1.z.boolean().optional(),
    }),
});
exports.ToteScanFailed = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TOTE_SCAN_FAILED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        toteId: base_1.StringId,
    }),
});
exports.toteLinkConfirmedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TOTE_LINK_CONFIRMED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        locationId: base_1.StringId,
        toteId: base_1.StringId,
        usingConveyorSystem: zod_1.z.boolean().optional(),
    }),
});
exports.toteLinkCancelledSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TOTE_LINK_CANCELLED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        locationId: base_1.StringId,
        toteId: base_1.StringId,
    }),
});
exports.toteLinkingProcessCompletedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TOTE_LINKING_PROCESS_COMPLETED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
    }),
});
exports.PickingStarted = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICKING_STARTED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
    }),
});
exports.PickingFailedToStart = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICKING_FAILED_TO_START'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
    }),
});
exports.taskGroupAssignedToPortSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TASK_GROUP_ASSIGNED_TO_PORT'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        taskGroupId: base_1.StringId,
    }),
});
exports.picklistAssignedToToteLocationSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICKLIST_ASSIGNED_TO_TOTE_LOCATION'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        locationId: base_1.StringId,
        picklistId: base_1.StringId,
        toteId: base_1.StringId,
    }),
});
exports.noTaskGroupsAvailableSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('NO_TASK_GROUP_AVAILABLE'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
    }),
});
exports.itemReadyToPickSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('ITEM_READY_TO_PICK'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        item: model_1.retailUnitSchema,
        quantity: zod_1.z.number().int().gt(0),
        quantityPerPicklist: zod_1.z.array(zod_1.z.object({
            picklistId: zod_1.z.string().min(1),
            quantityToPick: zod_1.z.number().positive(),
        })),
        partitionNumber: zod_1.z.number().int().gt(0),
        // please note that the taskId here is autostoreTaskId instead of WMS taskId
        taskId: zod_1.z.optional(zod_1.z.number()),
    }),
});
exports.allTotesUnlinkedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('ALL_TOTES_UNLINKED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
    }),
});
exports.PickCompleted = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICK_COMPLETED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        itemPicked: base_1.StringId,
        merchantId: base_1.StringId.optional(),
        pickLocations: zod_1.z.array(zod_1.z.object({
            fulfilmentOrderId: zod_1.z.string().min(1),
            externalFulfilmentId: zod_1.z.string().min(1).optional(),
            picklistId: zod_1.z.string().min(1),
            toteId: zod_1.z.string().min(1),
            locationId: zod_1.z.string().min(1),
            quantityPicked: zod_1.z.number().positive(),
        })),
        binId: zod_1.z.string().min(1),
        partitionNumber: zod_1.z.number().int().gt(0),
        autostoreTaskId: zod_1.z.number(),
        taskGroupId: zod_1.z.string().min(1),
    }),
});
const pickLocationSchema = zod_1.z.object({
    fulfilmentOrderId: zod_1.z.string().min(1),
    externalFulfilmentId: zod_1.z.string().min(1).optional(),
    picklistId: zod_1.z.string().min(1),
    toteId: zod_1.z.string().min(1),
    locationId: zod_1.z.string().min(1),
    quantityPicked: zod_1.z.number().positive(),
});
exports.missingItemConfirmedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('MISSING_ITEM_CONFIRMED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        expectedRetailUnitId: base_1.StringId,
        merchantId: base_1.StringId,
        barcode: zod_1.z.string(),
        pickLocations: zod_1.z.array(pickLocationSchema),
        binId: zod_1.z.string().min(1),
        autostoreBinId: zod_1.z.number(),
        partitionNumber: zod_1.z.number().int().positive(),
        autostoreTaskId: zod_1.z.number(),
        taskGroupId: zod_1.z.string().min(1),
        toteIds: zod_1.z.array(zod_1.z.string()),
        picklistIds: zod_1.z.array(zod_1.z.string()),
        fulfilmentOrderIds: zod_1.z.array(zod_1.z.string()),
    }),
});
exports.failedToConfirmMissingItemDuringPickSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('FAILED_TO_CONFIRM_MISSING_ITEM_DURING_PICK'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        reason: zod_1.z.string(),
        currentPickItem: zod_1.z.string(),
    }),
});
exports.LastPickUndone = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('LAST_PICK_UNDONE'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        itemUnpicked: base_1.StringId,
        quantityRemovedPerLocation: zod_1.z.array(zod_1.z.number().nonnegative()),
        picklistIds: zod_1.z.array(base_1.StringId),
        fulfilmentOrderIds: zod_1.z.array(base_1.StringId),
    }),
});
exports.totesPickingCompletedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TOTES_PICKING_COMPLETED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        toteIds: zod_1.z.array(base_1.StringId),
        picklistIds: zod_1.z.array(base_1.StringId),
        fulfilmentOrderIds: zod_1.z.array(base_1.StringId),
        toteIdsWithMissingPaper: zod_1.z.array(zod_1.z.string()).optional(),
    }),
});
exports.pickTotesPushedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICK_TOTES_PUSHED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        toteIds: zod_1.z.array(base_1.StringId),
        usingConveyorSystem: zod_1.z.boolean().optional(),
        toteZoneRequests: zod_1.z
            .array(zod_1.z.object({
            toteId: zod_1.z.string(),
            zoneRequests: zod_1.z.string().array(),
            cmcEligibilityResult: zod_1.z.string().optional(),
        }))
            .optional(),
    }),
});
exports.pickTotesReplacedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICK_TOTES_REPLACED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        locationIds: zod_1.z.array(zod_1.z.string()),
        usingConveyorSystem: zod_1.z.boolean().optional(),
    }),
});
exports.pickTotesUnpushedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICK_TOTES_UNPUSHED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        toteIds: zod_1.z.array(base_1.StringId),
    }),
});
exports.pickPortTaskGroupAssignmentStoppedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICK_PORT_TASK_GROUP_ASSIGNMENT_STOPPED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
    }),
});
exports.PickLocationsClosed = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICK_LOCATIONS_CLOSED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        locationIds: zod_1.z.array(base_1.StringId),
    }),
});
exports.unexpectedBinRetrievedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('UNEXPECTED_BIN_RETRIEVED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        autostoreBinId: zod_1.z.number().positive(),
        binId: zod_1.z.string().min(1),
        autostoreTaskId: zod_1.z.number().positive(),
        reason: zod_1.z.enum([
            'UNKNOWN_TASK_GROUP',
            'UNKNOWN_TASK',
            'TASK_GROUP_ALREADY_COMPLETED',
            'TASK_ALREADY_COMPLETED',
            'NO_PICKLISTS_WANT_BIN',
        ]),
    }),
});
exports.shortPickRaisedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('SHORT_PICK_RAISED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        currentBin: zod_1.z.object({
            autostoreBinId: zod_1.z.number().positive(),
            partitionNumber: zod_1.z.number().positive(),
            quantityAvailableBeforeShortPick: zod_1.z.number().nonnegative(),
        }),
        currentTaskGroup: zod_1.z.object({
            id: zod_1.z.string().min(1),
            autostoreTaskGroupId: zod_1.z.number().positive().optional(),
            remainingTaskIds: zod_1.z.array(zod_1.z.string().min(1)),
        }),
        affectedTaskGroupIds: zod_1.z.array(zod_1.z.string().min(1)),
        quantityAvailableForOtherTaskGroups: zod_1.z.number().nonnegative(),
        item: zod_1.z.object({
            id: base_1.StringId,
            barcode: base_1.StringId,
            sku: base_1.StringId.optional(),
            merchantId: base_1.StringId,
            quantityOrdered: zod_1.z.number().positive(),
            quantityAvailable: zod_1.z.number().nonnegative(),
            quantityToPick: zod_1.z.number().nonnegative(),
        }),
        picklistsFulfillable: zod_1.z.array(zod_1.z.object({
            id: base_1.StringId,
            fulfilmentOrderId: base_1.StringId,
            quantityOrdered: zod_1.z.number().positive(),
            quantityToPick: zod_1.z.number().positive(),
        })),
        picklistsMissingItems: zod_1.z.array(zod_1.z.object({
            id: base_1.StringId,
            fulfilmentOrderId: base_1.StringId,
            quantityMissing: zod_1.z.number().positive(),
            isResolutionPicklist: zod_1.z.boolean().optional(),
        })),
        applyWms694Fix: zod_1.z.boolean().optional(),
    }),
});
exports.missingItemAtPickingDetectedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('MISSING_ITEM_AT_PICKING_DETECTED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
    }),
});
exports.detectMissingItemAtPickingFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('DETECT_MISSING_ITEM_AT_PICKING_FAILED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        reason: zod_1.z.enum([
            'PORT_NOT_FOUND',
            'INVALID_PORT_STATUS',
            'INVALID_PORT_TYPE',
        ]),
    }),
});
exports.missingItemResolutionCancelledSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('MISSING_ITEM_RESOLUTION_CANCELLED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
    }),
});
exports.cancelMissingItemResolutionFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CANCEL_MISSING_ITEM_RESOLUTION_FAILED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        reason: zod_1.z.enum([
            'PORT_NOT_FOUND',
            'INVALID_PORT_STATUS',
            'INVALID_PORT_TYPE',
        ]),
    }),
});
exports.redPaperPlacedInTotesSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('RED_PAPER_PLACED_IN_TOTES'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        toteIds: zod_1.z.array(base_1.StringId),
        picklistIds: zod_1.z.array(base_1.StringId),
        fulfilmentOrderIds: zod_1.z.array(base_1.StringId.optional()),
    }),
});
exports.GreenPaperPlacedInTotes = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('GREEN_PAPER_PLACED_IN_TOTES'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        toteIds: zod_1.z.array(base_1.StringId),
        picklistIds: zod_1.z.array(base_1.StringId),
    }),
});
exports.PickProblemRaised = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICK_PROBLEM_RAISED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        problemDescription: zod_1.z.string(),
    }),
});
exports.PickPortHadNoPaper = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICK_PORT_HAD_NO_PAPER'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        paperVariant: model_1.PaperVariant,
    }),
});
exports.InsufficientItemsToPick = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('INSUFFICIENT_ITEMS_TO_PICK'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        quantityOrdered: zod_1.z.number().positive(),
        quantityAvailable: zod_1.z.number().nonnegative(),
    }),
});
exports.picklistUnassignedFromToteLocationSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICKLIST_UNASSIGNED_FROM_TOTE_LOCATION'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        locationId: base_1.StringId,
        picklistId: base_1.StringId,
        toteId: base_1.StringId,
    }),
});
exports.autostoreHandPickAllocatedItemsRetrievedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('AUTOSTORE_HAND_PICK_ALLOCATED_ITEMS_RETRIEVED'),
    payload: zod_1.z.object({
        binId: base_1.StringId,
        autostoreBinId: zod_1.z.number(),
        partitionNumber: zod_1.z.number(),
        retailUnit: zod_1.z.object({
            merchantId: base_1.StringId,
            barcode: zod_1.z.string(),
        }),
        previousQuantity: zod_1.z.number(),
        updatedQuantity: zod_1.z.number(),
        binIsEmpty: zod_1.z.boolean(),
    }),
});
exports.pickPortCategoriesSelectedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICK_PORT_CATEGORIES_SELECTED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        categories: pickingMode_1.autostoreCategorySchema.array(),
        pickingModes: pickingMode_1.pickingModeNameSchema.array().optional(),
    }),
});
exports.pickPortCategoriesUnchangedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICK_PORT_CATEGORIES_UNCHANGED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        categories: pickingMode_1.autostoreCategorySchema.array(),
        pickingModes: pickingMode_1.pickingModeNameSchema.array().optional(),
    }),
});
exports.pickPortPickingModesSelectedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICK_PORT_PICKING_MODES_SELECTED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        pickingModesRequested: pickingMode_1.pickingModeNameSchema.array(),
    }),
});
exports.pickPortPickingModesQueueClearedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICK_PORT_PICKING_MODES_QUEUE_CLEARED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        pickingModesRemovedFromQueue: pickingMode_1.pickingModeNameSchema.array(),
    }),
});
const pickPortStatusSchema = zod_1.z.enum([
    'OPENING',
    'OPEN',
    'CLOSING',
    'PLACING_TOTES',
    'LINKING',
    'PICK_COMPLETE',
    'PICK_CONFIRM_MISSING_ITEM',
    'PUSHING',
    'REPLACING_TOTES',
    'CLOSED',
    'AWAITING_TASK_GROUP_ASSIGNMENT',
    'AWAITING_BIN',
    'NO_TASK_GROUP',
    'READY',
    'LOCATIONS_CLOSED',
    'UNEXPECTED_BIN',
    'PLACING_RED_PAPER',
    'PLACING_GREEN_PAPER',
    'ERROR_CLOSING_BIN',
    'READY_TO_RETRY_PICKING',
    'PLACING_MISSING_ITEM_PAPER',
    'CONFIRMING_MISSING_ITEM_DETECTION',
    'ZERO_COUNT_CROSS_CHECK',
    'ZERO_COUNT_CROSS_CHECK_ERROR',
]);
exports.conveyorEnabledAtPortSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CONVEYOR_ENABLED_AT_PORT'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
    }),
});
exports.enableConveyorAtPortFailureSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('FAILED_TO_ENABLE_CONVEYOR_AT_PORT'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema.or(zod_1.z.string()),
        reason: zod_1.z.discriminatedUnion('outcome', [
            zod_1.z.object({ outcome: zod_1.z.literal('INVALID_PORT_ID'), portId: zod_1.z.string() }),
            zod_1.z.object({
                outcome: zod_1.z.literal('PICK_PORT_NOT_FOUND'),
                portId: base_1.portIdSchema,
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('INVALID_PORT_STATUS'),
                portId: base_1.portIdSchema,
                actualStatus: pickPortStatusSchema.exclude(['CLOSED']),
                expectedStatus: zod_1.z.literal('CLOSED'),
            }),
        ]),
    }),
});
exports.conveyorDisabledAtPortSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CONVEYOR_DISABLED_AT_PORT'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
    }),
});
exports.disableConveyorAtPortFailureSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('FAILED_TO_DISABLE_CONVEYOR_AT_PORT'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema.or(zod_1.z.string()),
        reason: zod_1.z.discriminatedUnion('outcome', [
            zod_1.z.object({ outcome: zod_1.z.literal('INVALID_PORT_ID'), portId: zod_1.z.string() }),
            zod_1.z.object({
                outcome: zod_1.z.literal('PICK_PORT_NOT_FOUND'),
                portId: base_1.portIdSchema,
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('INVALID_PORT_STATUS'),
                portId: base_1.portIdSchema,
                actualStatus: pickPortStatusSchema.exclude(['CLOSED']),
                expectedStatus: zod_1.z.literal('CLOSED'),
            }),
        ]),
    }),
});
exports.pickPortLockedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICK_PORT_LOCKED'),
    payload: zod_1.z.object({
        portId: zod_1.z.number(),
        reason: zod_1.z.enum([
            'MANUAL_LOCK',
            'TASK_GROUP_ASSIGNED_WHEN_PICKLISTS_PRESENT',
            'TASK_GROUP_WAS_CANCELLED',
            'TASK_GROUP_ASSIGNED_WITH_INSUFFICIENT_STOCK',
            'TASK_GROUP_ASSIGNED_WITH_CANCELLED_PICKLISTS',
            'TASK_GROUP_ASSIGNED_WITH_INVALID_FULFILMENT_ORDERS',
            'ABNORMAL_PICK_QUANTITY',
            'PICK_COUNT_MISMATCH',
            'PICKLIST_NOT_ASSIGNED_TO_TOTE',
            'OVER_PICKED_PICKLIST_LINE_ITEM',
            'CONVEYOR_TOTES_NOT_MATCHING_TOTE_LOCATIONS',
            'TOTE_ALREADY_LINKED_AT_ANOTHER_TOTE_LOCATION',
            'TASK_GROUP_MISSING_BINS',
        ]),
        picklistIds: zod_1.z.string().array().optional(),
    }),
});
exports.lockPickPortFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('LOCK_PICK_PORT_FAILED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        reason: zod_1.z.enum([
            'MANUAL_LOCK',
            'TASK_GROUP_ASSIGNED_WHEN_PICKLISTS_PRESENT',
            'TASK_GROUP_WAS_CANCELLED',
            'TASK_GROUP_ASSIGNED_WITH_INSUFFICIENT_STOCK',
            'TASK_GROUP_ASSIGNED_WITH_CANCELLED_PICKLISTS',
            'TASK_GROUP_ASSIGNED_WITH_INVALID_FULFILMENT_ORDERS',
            'ABNORMAL_PICK_QUANTITY',
            'PICK_COUNT_MISMATCH',
            'PICKLIST_NOT_ASSIGNED_TO_TOTE',
            'OVER_PICKED_PICKLIST_LINE_ITEM',
            'CONVEYOR_TOTES_NOT_MATCHING_TOTE_LOCATIONS',
            'TOTE_ALREADY_LINKED_AT_ANOTHER_TOTE_LOCATION',
            'TASK_GROUP_MISSING_BINS',
        ]),
    }),
});
exports.pickPortUnlockedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICK_PORT_UNLOCKED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
    }),
});
exports.unlockPickPortFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('UNLOCK_PICK_PORT_FAILED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        reason: zod_1.z.string(),
    }),
});
exports.zeroCountCrossCheckRequiredSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('ZERO_COUNT_CROSS_CHECK_REQUIRED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        binId: base_1.StringId,
        partitionNumber: zod_1.z.number().int().positive(),
        partitionQuantity: zod_1.z.number().int(),
    }),
});
exports.determineZeroCountCrossCheckRequiredFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('DETERMINE_ZERO_COUNT_CROSS_CHECK_REQUIRED_FAILED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        binId: base_1.StringId.optional(),
        partitionNumber: zod_1.z.number().int().positive().optional(),
        reason: zod_1.z.enum([
            'PORT_NOT_FOUND',
            'NO_CURRENT_BIN',
            'INVALID_PORT_STATUS',
            'NO_PICK_ASSIGNED',
            'PARTITION_NOT_FOUND',
        ]),
    }),
});
const toteToUpdateAtPortSchema = zod_1.z.object({
    locationId: zod_1.z.string(),
    oldToteId: zod_1.z.string(),
    newToteId: zod_1.z.string(),
    picklistId: zod_1.z.string().optional(),
    fulfilmentOrderId: zod_1.z.string().optional(),
});
exports.itemsRecoveredFromUnexpectedToteExitSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('ITEMS_RECOVERED_FROM_UNEXPECTED_TOTE_EXIT'),
    payload: zod_1.z.object({
        portId: zod_1.z.number(),
        locationId: zod_1.z.string(),
        toteIds: zod_1.z.string().array().length(2),
        fromToteId: zod_1.z.string(),
        toToteId: zod_1.z.string(),
        picklistId: zod_1.z.string(),
        fulfilmentOrderId: zod_1.z.string().optional(),
        contents: zod_1.z
            .object({
            retailUnitKey: zod_1.z.string(),
            quantity: zod_1.z.number().int().nonnegative(),
        })
            .array(),
    }),
});
exports.updateLinkedTotesAtPortSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('UPDATE_LINKED_TOTES_AT_PORT'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        totesToUpdateAtPort: toteToUpdateAtPortSchema.array(),
    }),
});
exports.removeEmptyTotesFromPortsSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('REMOVE_EMPTY_TOTES_FROM_PORT'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        toteIds: model_1.toteSchema.shape.id.array(),
    }),
});
exports.pickItemBarcodeValidationFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICK_ITEM_BARCODE_VALIDATION_FAILED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        reason: zod_1.z.discriminatedUnion('outcome', [
            zod_1.z.object({
                outcome: zod_1.z.literal('INVALID_PORT_STATUS'),
                expectedPortStatus: zod_1.z.literal('OPEN'),
                currentPortStatus: zod_1.z.string(),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('INVALID_PORT_TYPE'),
                expectedType: zod_1.z.literal('PICK'),
                actualType: zod_1.z.string(),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('PORT_NOT_FOUND'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('INVALID_PICK_ITEM_BARCODE'),
                expectedBarcode: zod_1.z.string(),
                scannedBarcode: zod_1.z.string(),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('NO_CURRENT_PICK_ASSIGNED_TO_PORT'),
            }),
        ]),
    }),
});
exports.pickItemBarcodeValidatedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICK_ITEM_BARCODE_VALIDATED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        barcode: zod_1.z.string(),
    }),
});
exports.PickEvent = zod_1.z.union([
    exports.pickPortOpeningSchema,
    exports.pickPortOpenedSchema,
    exports.pickPortOpeningFailedSchema,
    exports.pickPortClosingSchema,
    exports.pickPortClosedSchema,
    exports.pickPortClosingFailedSchema,
    exports.pickBinReturnedSchema,
    port_1.binRequestedSchema,
    port_1.binArrivedSchema,
    port_1.BinRetrievalTimedOut,
    exports.pickItemAddedToToteSchema,
    exports.pickItemRemovedFromToteSchema,
    exports.pickPortTotesPlacedSchema,
    exports.toteLocationScanSucceededSchema,
    exports.ToteLocationScanFailed,
    exports.toteScanSucceededSchema,
    exports.ToteScanFailed,
    exports.toteLinkConfirmedSchema,
    exports.toteLinkCancelledSchema,
    exports.toteLinkingProcessCompletedSchema,
    exports.taskGroupAssignedToPortSchema,
    exports.picklistAssignedToToteLocationSchema,
    exports.noTaskGroupsAvailableSchema,
    exports.PickingStarted,
    exports.PickingFailedToStart,
    exports.itemReadyToPickSchema,
    exports.allTotesUnlinkedSchema,
    exports.PickCompleted,
    exports.missingItemConfirmedSchema,
    exports.failedToConfirmMissingItemDuringPickSchema,
    exports.totesPickingCompletedSchema,
    exports.LastPickUndone,
    exports.pickTotesPushedSchema,
    exports.pickTotesReplacedSchema,
    exports.pickTotesUnpushedSchema,
    exports.pickPortTaskGroupAssignmentStoppedSchema,
    exports.PickLocationsClosed,
    exports.unexpectedBinRetrievedSchema,
    exports.shortPickRaisedSchema,
    exports.redPaperPlacedInTotesSchema,
    exports.missingItemAtPickingDetectedSchema,
    exports.detectMissingItemAtPickingFailedSchema,
    exports.missingItemResolutionCancelledSchema,
    exports.cancelMissingItemResolutionFailedSchema,
    exports.GreenPaperPlacedInTotes,
    exports.PickProblemRaised,
    exports.PickPortHadNoPaper,
    exports.InsufficientItemsToPick,
    exports.picklistUnassignedFromToteLocationSchema,
    port_1.closeBinFailedSchema,
    port_1.retryCloseBinSucceededSchema,
    exports.autostoreHandPickAllocatedItemsRetrievedSchema,
    exports.pickPortCategoriesSelectedSchema,
    exports.pickPortCategoriesUnchangedSchema,
    exports.conveyorEnabledAtPortSchema,
    exports.enableConveyorAtPortFailureSchema,
    exports.conveyorDisabledAtPortSchema,
    exports.disableConveyorAtPortFailureSchema,
    exports.pickPortLockedSchema,
    exports.lockPickPortFailedSchema,
    exports.pickPortUnlockedSchema,
    exports.unlockPickPortFailedSchema,
    exports.zeroCountCrossCheckRequiredSchema,
    exports.determineZeroCountCrossCheckRequiredFailedSchema,
    exports.removeEmptyTotesFromPortsSchema,
    exports.updateLinkedTotesAtPortSchema,
    exports.itemsRecoveredFromUnexpectedToteExitSchema,
    exports.pickPortPickingModesSelectedSchema,
    exports.pickPortPickingModesQueueClearedSchema,
    exports.pickItemBarcodeValidationFailedSchema,
    exports.pickItemBarcodeValidatedSchema,
]);
exports.PickEventNames = exports.PickEvent.options.map((opt) => opt.shape.type.value);
