import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Button } from '../../../../../components/warehouse/Button';
import { NumericInput } from '../../../../../components/warehouse/NumericInput';

type Props = {
  quantity: number;
  onUpdateQuantity: (quantity: number) => void;
};

export const UpdateQuantityButton = ({ quantity, onUpdateQuantity }: Props) => {
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [newQuantity, setNewQuantity] = useState(quantity);

  useEffect(() => setNewQuantity(quantity), [quantity]);

  const updateQuantity = () => {
    onUpdateQuantity(newQuantity);
    setIsModalShowing(false);
  };

  return (
    <>
      <Button
        onClick={() => setIsModalShowing(true)}
        testId="putaway-edit-quantity"
        variant="warning"
      >
        <Typography style={{ fontWeight: 'bold' }} align="center">
          SET QTY: {quantity}
        </Typography>
      </Button>
      <Dialog open={isModalShowing} onClose={() => setIsModalShowing(false)}>
        <DialogTitle>How many items to put away?</DialogTitle>
        <DialogContent>
          <NumericInput
            value={newQuantity}
            onChange={(e) => {
              const value = Number(e.target.value) || 0;
              setNewQuantity(value);
            }}
            setValue={(v) => setNewQuantity(v)}
            minValue={0}
            maxValue={1000000}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="secondary"
            onClick={() => setIsModalShowing(false)}
            testId="cancel-putaway-quantity"
          >
            Cancel
          </Button>
          <Button
            onClick={updateQuantity}
            variant="primary"
            testId="update-putaway-quantity"
          >
            Update Quantity
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
