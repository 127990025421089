import styled from 'styled-components';

export const ImageWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  position: relative;
  background: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.grey[5]};
  border-radius: 5px;
  box-sizing: border-box;
  width: 75px;
  height: 75px;
`;

export const ItemImg = styled.img`
  width: auto;
  height: auto;
  max-width: 73px;
  max-height: 73px;
  overflow: hidden;
  border-radius: 5px;

  & .substitute {
    width: auto;
    height: auto;
    max-width: 73px;
    max-height: 73px;
    overflow: hidden;
    border-radius: 5px;
  }
`;

export const QuantityWrapper = styled.div`
  text-align: center;
  position: absolute;
  padding: 2px 15px;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);

  background: ${({ theme }) => theme.palette.grey[4]};
  border: 2px solid ${({ theme }) => theme.palette.common.white};
  border-radius: 200px;
`;
