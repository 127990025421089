import {
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import LoopIcon from '@material-ui/icons/Loop';
import { Warehouse } from 'api-schema/lib';
import { PortConfigurationType } from 'api-schema/lib/model';
import { Container } from '../../../components/admin/Container';
import { SectionHeader } from '../../../components/admin/SectionHeader';
import { portTypes } from './PortsOverview';

type Props = {
  warehouse?: Warehouse;
  portsData: PortConfigurationType[];
  handleChange: (portId: number, value: string) => void;
  refreshPorts: () => void;
};

export const PortsOverviewView = ({
  warehouse,
  portsData,
  handleChange,
  refreshPorts,
}: Props) => {
  if (!warehouse) {
    return <h3>Warehouse not found</h3>;
  }

  return (
    <Container>
      <SectionHeader>Ports</SectionHeader>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Port ID</TableCell>
            <TableCell align="right">Type</TableCell>
            <TableCell align="right">Autostore Bin ID</TableCell>
            <TableCell align="right">
              <span className="fa-flip-horizontal">
                <IconButton
                  aria-label="delete"
                  onClick={refreshPorts}
                  style={{ animationDirection: 'reverse' }}
                >
                  <LoopIcon />
                </IconButton>
              </span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {portsData.map((port) => {
            return (
              <TableRow key={port.portId}>
                <TableCell>{port.portId}</TableCell>
                <TableCell align="right">
                  <FormControl>
                    <Select
                      value={port.type}
                      onChange={(e) => {
                        if (typeof e.target.value === 'string') {
                          handleChange(port.portId, e.target.value);
                        }
                      }}
                    >
                      {portTypes.map((type) => (
                        <MenuItem value={type}>{type}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell align="right">
                  {port.currentAutostoreBinId}
                </TableCell>
                <TableCell />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Container>
  );
};
