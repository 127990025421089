"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.jobOutcomesSchema = exports.jobErrorOutcomesSchema = exports.jobSuccessOutcomeSchema = void 0;
const zod_http_schemas_1 = require("zod-http-schemas");
exports.jobSuccessOutcomeSchema = zod_http_schemas_1.z.object({
    outcome: zod_http_schemas_1.z.literal('SUCCESS'),
});
exports.jobErrorOutcomesSchema = zod_http_schemas_1.z.discriminatedUnion('outcome', [
    zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('JOB_NOT_PUSHED') }),
    zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('UNKNOWN_QUEUE'), queue: zod_http_schemas_1.z.string() }),
    zod_http_schemas_1.z.object({
        outcome: zod_http_schemas_1.z.literal('JOB_TYPE_NOT_WHITELISTED_FOR_QUEUE'),
        queue: zod_http_schemas_1.z.string(),
        jobWhitelist: zod_http_schemas_1.z.string().array(),
    }),
    zod_http_schemas_1.z.object({
        outcome: zod_http_schemas_1.z.literal('COULDNT_PARSE_JOB'),
        error: zod_http_schemas_1.z.string(),
    }),
]);
exports.jobOutcomesSchema = zod_http_schemas_1.z.union([
    exports.jobSuccessOutcomeSchema,
    exports.jobErrorOutcomesSchema,
]);
