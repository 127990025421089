import { Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { FormikProps } from 'formik';
import { Button } from '../../../components/admin/Button';
import { Container } from '../../../components/admin/Container';
import { Input } from '../../../components/admin/Input';
import { FormRow } from '../../../elements/admin/FormRow';
import { getFormHandlers } from '../../../utils/forms/getFormHandlers';
import { ScanTransferModelType } from './ScanTransfer.model';

export const ScanTransferView = ({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  handleSubmit,
  isSubmitting,
}: FormikProps<ScanTransferModelType>) => {
  const { handleInputTouch, handleInputChange } = getFormHandlers({
    setFieldTouched,
    setFieldValue,
  });
  return (
    <Container>
      <Typography style={{ marginBottom: 12 }}>
        Scan a transfer id to begin the putaway process.
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormRow spacingBottom={1}>
          <Input
            fullWidth
            label="Scan transfer"
            name="transferId"
            type="text"
            onChange={handleInputChange}
            value={values.transferId}
            error={errors.transferId}
            touched={touched.transferId}
            onBlur={handleInputTouch}
          />
        </FormRow>
        <FormRow>
          <Button
            fullWidth
            variant="heavy"
            type="submit"
            disabled={isSubmitting}
            icon={Check}
            alignIcon="left"
            data-testid="start-putaway"
          >
            Start Putaway
          </Button>
        </FormRow>
      </form>
    </Container>
  );
};
