import { Modal } from '../../../../components/warehouse/Modal';
import { Loading } from '../../../pickStation/components/Loading';

export function PackingTroubleshootLabelPrintingModal({
  open,
}: {
  open: boolean;
}) {
  return (
    <Modal
      isVisible={open}
      contentFit={true}
      data-testid="printing-packing-ts-label-modal"
    >
      <Loading message="Printing troubleshoot label" flexFlow="row" />
    </Modal>
  );
}
