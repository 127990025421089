import { PickPortStateType } from 'api-schema/lib';
import { ReactElement, useEffect, useRef } from 'react';

import { ReactComponent as WorkstationSVG } from './svgs/workstation.svg';

import { PickPortWithHydratedEntities } from 'api-schema/lib/model';
import { pluralise } from '../../../utils/pluralise';
import { PickCount, PickPortTotes } from './PickPort.components';
import {
  PickIndicator,
  Totes,
  Workstation,
  Wrapper,
} from './PickPort.elements';
import { handlePortState, usePickIndicatorState } from './PickPort.methods';

export type PickPortProps = {
  portState: PickPortWithHydratedEntities;
};

export function PickPort({ portState }: PickPortProps): ReactElement {
  const pickIndicatorElement = useRef<HTMLSpanElement | null>(null);
  const pickPortWrapperElement = useRef<HTMLDivElement | null>(null);
  const workstationElement = useRef<HTMLDivElement | null>(null);
  const totesElement = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    handlePortState(
      portState,
      workstationElement.current,
      totesElement.current
    );
  }, [portState]);

  const indicatorState = usePickIndicatorState(
    portState,
    pickIndicatorElement,
    pickPortWrapperElement
  );

  return (
    <Wrapper ref={pickPortWrapperElement} data-chromatic="ignore">
      <PickIndicator
        ref={pickIndicatorElement}
        x={indicatorState.x}
        y={indicatorState.y}
        isShortPick={portState.currentPick?.isShortPick}
        isVisible={!!portState.currentPick?.item}
        data-testId="pick-indicator"
      >
        Take {pluralise('item', portState.currentPick?.quantity || 0)}
      </PickIndicator>

      <Workstation ref={workstationElement}>
        <WorkstationSVG width="450" height="auto" data-testid="workstation" />
      </Workstation>

      <Totes ref={totesElement}>
        <PickPortTotes
          width="500"
          height="auto"
          pickCount={getPickCountFromState(portState)}
          toteIds={portState.toteLocations.map((location) => location.toteId)}
          isDebugging={portState.shouldUseConveyorSystem}
        />
      </Totes>
    </Wrapper>
  );
}

const getPickCountFromState = (portState: PickPortStateType): PickCount => {
  return portState.toteLocations.reduce((pickCount, location, idx) => {
    return {
      ...pickCount,
      [idx + 1]: location.pickCount,
    };
  }, {});
};
