import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(1)};

  background: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.grey[4]};
  border-radius: 7px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
`;

export const AlertMessageWrapper = styled.div`
  margin: 0;
  margin-bottom: 10px;
`;

export const AlertMessageTextWrapper = styled.span`
  display: inline;
`;

export const ItemsToBeFulfilled = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const ItemGroup = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.palette.grey[6]};
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacing(1)};
`;
