import { ReactElement } from 'react';
import { AlertMessage } from '../../../../components/warehouse/AlertMessage';
import { AlertMessageTypes } from '../../../../components/warehouse/AlertMessage/AlertMessage';
import { Button } from '../../../../components/warehouse/Button';
import { Container } from '../../../../components/warehouse/Container';
import { Wrapper } from './PickPortError.elements';

export type PickPortErrorProps = {
  alertType: AlertMessageTypes;
  message: string;
  showRetryButton: boolean;
  retryCommand?: () => Promise<void>;
};

export function PickPortError({
  alertType,
  message,
  showRetryButton,
  retryCommand,
}: PickPortErrorProps): ReactElement {
  return (
    <Container padding="none" alignCenter>
      <Wrapper>
        <AlertMessage type={alertType}>{message}</AlertMessage>
        {showRetryButton && (
          <Button variant="tertiary" onClick={retryCommand}>
            Retry
          </Button>
        )}
      </Wrapper>
    </Container>
  );
}
