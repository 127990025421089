import { ChangeEvent, ReactElement, useState } from 'react';
import { ReactComponent as TroubleshootIcon } from '../../../../assets/img/icons/troubleshoot-icon.svg';
import { Button } from '../../../../components/warehouse/Button';
import { Modal } from '../../../../components/warehouse/Modal';
import { TextArea } from '../../../../components/warehouse/TextArea';
import { Text, Wrapper } from './FreeInputTroubleModal.elements';

export type FreeInputTroubleshootModalProps = {
  closeModal: () => void;
  submitIssue: (value: string) => void;
};

export const FreeInputTroubleshootModal = ({
  closeModal,
  submitIssue,
}: FreeInputTroubleshootModalProps): ReactElement => {
  const [value, setValue] = useState<string>();
  const [hasError, setHasError] = useState(false);

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    setHasError(!e.target.value);
  };

  return (
    <Modal
      isVisible
      variant="warning"
      header={{ text: 'Troubleshoot', icon: <TroubleshootIcon /> }}
      actions={[
        <Button fullWidth variant="secondary" onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          fullWidth
          variant="primary"
          onClick={() => {
            if (value) {
              submitIssue(value);
            }
          }}
        >
          Submit
        </Button>,
      ]}
    >
      <Wrapper>
        <Text>Tell us what went wrong</Text>
        <TextArea
          value={value}
          onChange={onChange}
          placeholder="Start typing..."
          hasError={hasError}
        />
      </Wrapper>
    </Modal>
  );
};
