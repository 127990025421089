import { StorageUnit, Warehouse } from 'api-schema';
import { useHistory } from 'react-router-dom';
import { STORAGE_UNIT_PATHS } from '../router/paths';
import { generatePathWithWarehouseId } from '../utils/generatePathWithWarehouseId';

type History = ReturnType<typeof useHistory>;

export const printStorageBarcodes = (
  storages: StorageUnit[],
  history: History,
  warehouse: Warehouse
) => {
  const ids = storages.map((storage) => storage.id);
  const storageType = storages[0].storageType;

  if (
    storages.filter((storage) => storage.storageType !== storageType).length
  ) {
    throw new Error(`Cannot print multiple storage types`);
  }
  history.push(
    generatePathWithWarehouseId(STORAGE_UNIT_PATHS.PRINT_STORAGE, {
      warehouseId: warehouse?.id,
    }),
    {
      ids,
      storageType,
    }
  );
};
