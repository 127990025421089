import { IntermediaryStationCommand } from 'api-schema/lib/commands/intermediaryStation';
import { LabelTroubleshootCommandsType } from 'api-schema/lib/commands/labelTroubleshoot';
import { PackAndDispatchCommandsType } from 'api-schema/lib/commands/packAndDispatch';
import { PickCommandsType } from 'api-schema/lib/commands/pick';
import { PutawayCommandsType } from 'api-schema/lib/commands/putaway';
import { ShortPickTroubleshootCommandsType } from 'api-schema/lib/commands/shortPickTroubleshoot';
import {
  LabelTroubleshootEventType,
  PackAndDispatchEventType,
  PickSocketEventType,
  PutawaySocketEventType,
  ShortPickTroubleshootEventType,
} from 'api-schema/lib/events';
import { IntermediaryStationEvent } from 'api-schema/lib/events/intermediaryStation';
import {
  CommandResultMessageType,
  CommandsType,
  IntermediaryStationCommandResultMessage,
  LabelTroubleshootCommandResultMessageType,
  PackAndDispatchCommandResultMessageType,
  PickCommandResultMessageType,
  PutawayCommandResultMessageType,
  ShortPickTroubleshootCommandResultMessageType,
  SocketEventType,
} from 'api-schema/lib/websocket';
import { createContext } from 'react';

type UnsubscribeHandler = () => void;
export type SubscriberCallback<SocketEvent> = (event: SocketEvent) => void;

type SocketContextType<
  Command extends CommandsType,
  CommandResultMessage extends CommandResultMessageType,
  SocketEvent extends SocketEventType,
> = {
  dispatchCommand: (command: Command) => Promise<CommandResultMessage>;
  subscribeToEvents: (
    callback: SubscriberCallback<SocketEvent>
  ) => UnsubscribeHandler;
};

const SocketContextCreator = <
  Command extends CommandsType,
  CommandResultMessage extends CommandResultMessageType,
  SocketEvent extends SocketEventType,
>() =>
  createContext<SocketContextType<Command, CommandResultMessage, SocketEvent>>({
    dispatchCommand: (command) => {
      console.log('No context for command', command);
      return new Promise((resolve, reject) => reject());
    },
    subscribeToEvents: () => () => {},
  });

export const PutawaySocketContext = SocketContextCreator<
  PutawayCommandsType,
  PutawayCommandResultMessageType,
  PutawaySocketEventType
>();

export const PickSocketContext = SocketContextCreator<
  PickCommandsType,
  PickCommandResultMessageType,
  PickSocketEventType
>();

export const PackAndDispatchSocketContext = SocketContextCreator<
  PackAndDispatchCommandsType,
  PackAndDispatchCommandResultMessageType,
  PackAndDispatchEventType
>();

export const ShortPickTroubleshootSocketContext = SocketContextCreator<
  ShortPickTroubleshootCommandsType,
  ShortPickTroubleshootCommandResultMessageType,
  ShortPickTroubleshootEventType
>();

export const LabelTroubleshootSocketContext = SocketContextCreator<
  LabelTroubleshootCommandsType,
  LabelTroubleshootCommandResultMessageType,
  LabelTroubleshootEventType
>();

export const IntermediaryStationSocketContext = SocketContextCreator<
  IntermediaryStationCommand,
  IntermediaryStationCommandResultMessage,
  IntermediaryStationEvent
>();
