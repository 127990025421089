import { ParcelSize } from 'api-schema/lib';
import { Container } from '../../../../../components/warehouse/Container';
import { Text } from '../../../../../components/warehouse/Text';
import { Toggle } from '../../../../../components/warehouse/Toggle/Toggle';
import { ParcelOptionType } from '../../../../../constants/parcel';
import {
  ButtonWrapper,
  TileButton,
  TitleWrapper,
  ToggleWrapper,
} from './ParcelSelectionPanel.elements';

export type ParcelSelectionPanelProps = {
  titleText?: string;
  buttons: ParcelOptionType[];
  multipleParcelsEnabled: boolean;
  isInternationalShipment: boolean;
  parcelSize?: ParcelSize;
  onClick: (text: ParcelSize) => void;
  onMultipleParcelsToggle: () => void;
};

export function ParcelSelectionPanel({
  titleText,
  buttons,
  onClick,
  multipleParcelsEnabled,
  onMultipleParcelsToggle,
  isInternationalShipment,
  parcelSize,
}: ParcelSelectionPanelProps) {
  return (
    <Container padding="all" contentGap={false}>
      <TitleWrapper>
        <Text variant="h2" tag="span" weight="medium">
          Carton
        </Text>
        {/* {TODO(FCG-303): update this toggle to have disabled styling} */}
        {!isInternationalShipment && (
          <ToggleWrapper>
            <Text variant="h2" tag="span" weight="medium">
              Multiple Parcels
            </Text>
            <Toggle
              name="multipleParcels"
              value="multipleParcels"
              checked={multipleParcelsEnabled}
              onChange={onMultipleParcelsToggle}
            />
          </ToggleWrapper>
        )}
      </TitleWrapper>
      <ButtonWrapper>
        {buttons.map((btn) => (
          <TileButton
            key={btn.value}
            onClick={() => onClick(btn.value)}
            $count={buttons.length}
            data-testid={`${btn.text.toLowerCase()}-tile-button`}
            $isSelected={parcelSize === btn.value}
          >
            {btn.customLabel && (
              <Text variant="h2" weight="medium" tag="span">
                {btn.customLabel}
              </Text>
            )}
            <Text variant="body1" tag="span">{`${
              btn.text
            } ${titleText?.toLowerCase()}`}</Text>
          </TileButton>
        ))}
      </ButtonWrapper>
    </Container>
  );
}
