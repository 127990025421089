export const MAIN_PATHS = {
  ADMIN_HOME: '/admin',
  ADD_WAREHOUSE: '/admin/add-warehouse',
} as const;

export const WAREHOUSE_PATHS = {
  OVERVIEW: '/admin/warehouse/:warehouseId?/overview',
  STORAGE_UNITS: '/admin/warehouse/:warehouseId?/storage-units/setup',
  EDIT_WAREHOUSE: '/admin/warehouse/:warehouseId?/overview/edit',
} as const;

export const STORAGE_UNIT_PATHS = {
  AUTOSTORE: '/admin/warehouse/:warehouseId?/storage-units/setup/autostore',
  ISLE_STORAGE:
    '/admin/warehouse/:warehouseId?/storage-units/setup/isle-storage',
  FLOOR_STORAGE:
    '/admin/warehouse/:warehouseId?/storage-units/setup/floor-storage',
  ORDER_TOTE: '/admin/warehouse/:warehouseId?/storage-units/setup/order-tote',
  CMC_TOTE: '/admin/warehouse/:warehouseId?/storage-units/setup/cmc-tote',
  STORAGE_CREATED: '/admin/warehouse/:warehouseId?/storage-units/setup/created',
  PRINT_STORAGE: '/admin/warehouse/:warehouseId?/storage-units/print',
} as const;

export const DEVICE_PATHS = {
  OVERVIEW: '/admin/warehouse/:warehouseId?/devices/',
};

export const TRANSFER_PATHS = {
  OVERVIEW: '/admin/warehouse/:warehouseId?/transfers',
};

export const PORT_PATHS = {
  OVERVIEW: '/admin/warehouse/:warehouseId?/ports',
};

export const DEV_PATHS = {
  FULFILMENT_ORDER: '/admin/dev/fulfilment-order',
  AUTH: '/admin/dev/authentication',
};

export const PATHS = {
  MAIN_PATHS,
  WAREHOUSE_PATHS,
  STORAGE_UNIT_PATHS,
  DEVICE_PATHS,
  PORT_PATHS,
  DEV_PATHS,
} as const;
