import { Box } from '../../../../components/warehouse/Box';
import { Button } from '../../../../components/warehouse/Button';
import { Modal } from '../../../../components/warehouse/Modal';
import { Text } from '../../../../components/warehouse/Text';
import { getWarehouseTheme } from '../../../../themes/WarehouseTheme';

export interface ToteAdjustmentConfirmModalProps {
  isVisible: boolean;
  onConfirm: () => void;
}

export const ToteAdjustmentConfirmModal = ({
  isVisible,
  onConfirm,
}: ToteAdjustmentConfirmModalProps): JSX.Element => {
  const theme = getWarehouseTheme(false);
  return (
    <Modal
      isVisible={isVisible}
      actions={[
        <Button
          key={'tote-adjustment-confirm-button'}
          fullWidth
          onClick={onConfirm}
          testId="tote-adjustment-confirm-button"
        >
          Confirm
        </Button>,
      ]}
    >
      <Box padding={theme.spacing(2)}>
        <Text variant="h1" weight="medium">
          Please confirm that the vary tote is adjusted
        </Text>
      </Box>
    </Modal>
  );
};
