import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { isNotErrorResponse } from '../../../apiClient';
import { SnackbarDefaults } from '../../../constants/snackbarDefaults';
import { useApiClient } from '../../../hooks/useApiClient';
import { useWarehouseParam } from '../../../hooks/useWarehouseParam';
import { useWarehousesApi } from '../../../hooks/useWarehousesApi';
import { STORAGE_UNIT_PATHS } from '../../../router/paths';
import { useAppState } from '../../../store';
import { transformZodErrorsToFormik } from '../../../utils/forms/transformZodErrorsToFormik';
import { generatePathWithWarehouseId } from '../../../utils/generatePathWithWarehouseId';
import { AddAutoStoreView } from './AddAutoStoreView';
import { SetupAutoStoreFormValues, SetupAutoStoreSchema } from './models';

export function StorageUnitAddAutoStore(): ReactElement {
  useWarehouseParam();
  const {
    appState: { currentWarehouse: warehouse },
  } = useAppState();
  const [, refreshWarehouses] = useWarehousesApi();
  const apiClient = useApiClient();
  const history = useHistory();

  const createAutostoreStorageUnits = async (opts: any) => {
    const { data, status } = await apiClient.post(
      `/warehouses/:id/storage-units/autostore-bins`,
      {
        params: { id: opts.warehouseId },
        body: opts.details,
      }
    );
    if (isNotErrorResponse(data, status)) {
      refreshWarehouses();
      return data;
    }
    throw new Error('Failed to create autostore bins');
  };

  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik<SetupAutoStoreFormValues>({
    initialValues: {
      numBins: 1,
      partsPerBin: '1',
      binHeight: 2,
    },
    onSubmit: async (values) => {
      try {
        const result = await createAutostoreStorageUnits({
          warehouseId: warehouse?.id,
          details: {
            binType: values.binHeight,
            partitionsPerBin: parseInt(values.partsPerBin, 10),
            numBins: values.numBins,
          },
        });

        enqueueSnackbar(
          `Successfully created ${result.ids.length} AutoStore Bin(s)`,
          SnackbarDefaults
        );
        setTimeout(() => {
          history.push(
            generatePathWithWarehouseId(STORAGE_UNIT_PATHS.STORAGE_CREATED, {
              warehouseId: warehouse?.id,
            }),
            result
          );
        }, 3000);
      } catch (e) {
        console.error(e);
        enqueueSnackbar('Failed to create storage units', {
          ...SnackbarDefaults,
          variant: 'error',
        });
      }
    },
    validate:
      transformZodErrorsToFormik<SetupAutoStoreFormValues>(
        SetupAutoStoreSchema
      ),
  });

  if (!warehouse) {
    return <h3>Warehouse not found</h3>;
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <AddAutoStoreView {...formik} warehouse={warehouse} />;
}
