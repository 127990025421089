"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PutawayEvent = exports.binEmptinessCheckFailedSchema = exports.putawayBinRequestFailedSchema = exports.warehouseWithNoAutostoreOutcomeSchema = exports.warehouseNotFoundOutcomeSchema = exports.unhandledAutostoreManagerOutcomeSchema = exports.noMatchingBinsReadyOutcome = exports.noMatchingBinsOutcome = exports.putawayBinRequestedSchema = exports.binEmptinessCheckedSchema = exports.closePutawayPortFailedSchema = exports.putawayPortClosedSchema = exports.completePutawayFailedSchema = exports.configurePartitionsFailedSchema = exports.partitionsConfiguredSchema = exports.returnPutawayBinFailedSchema = exports.putawayBinReturnedSchema = exports.PlaceOrRemoveItemsInPartitionFailed = exports.putawayItemsRemovedFromPartitionSchema = exports.putawayItemsPlacedInPartitionSchema = exports.removePutawayItemFailedSchema = exports.putawayItemRemovedSchema = exports.addPutawayItemFailedSchema = exports.putawayItemAddedSchema = exports.PutawayCompleted = exports.PutawayPortOpenFailed = exports.putawayPortOpenedSchema = void 0;
const zod_1 = require("zod");
const model_1 = require("../model");
const base_1 = require("./base");
const port_1 = require("./port");
exports.putawayPortOpenedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PUTAWAY_PORT_OPENED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        contentCodes: zod_1.z.number().int().gte(0).array(),
    }),
});
exports.PutawayPortOpenFailed = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('OPEN_PUTAWAY_PORT_FAILED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
    }),
});
exports.PutawayCompleted = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PUTAWAY_COMPLETED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        transferId: base_1.StringId,
    }),
});
exports.putawayItemAddedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PUTAWAY_ITEM_ADDED'),
    payload: zod_1.z.object({
        transferId: base_1.StringId,
        portId: base_1.portIdSchema,
        autostoreBinId: base_1.autostoreBinIdSchema,
        binId: zod_1.z.string().min(1),
        partitionNumber: zod_1.z.number().int().nonnegative(),
        retailUnitBarcode: zod_1.z.string().min(1),
    }),
});
exports.addPutawayItemFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('ADD_PUTAWAY_ITEM_FAILED'),
    payload: zod_1.z.object({
        reason: zod_1.z.union([
            zod_1.z.literal('NO_BARCODE_RETAIL_UNIT_MATCH'),
            zod_1.z.literal('INVALID_TARGET_PARTITION'),
            zod_1.z.literal('NO_CURRENT_BIN'),
            zod_1.z.literal('INVALID_PORT_TYPE'),
            zod_1.z.literal('PORT_NOT_FOUND'),
            zod_1.z.literal('NO_CURRENT_TRANSFER'),
        ]),
        transferId: base_1.StringId,
        portId: base_1.portIdSchema,
        partitionNumber: zod_1.z.number().int().nonnegative(),
        retailUnitBarcode: zod_1.z.string().min(1),
    }),
});
exports.putawayItemRemovedSchema = exports.putawayItemAddedSchema.extend({
    type: zod_1.z.literal('PUTAWAY_ITEM_REMOVED'),
});
exports.removePutawayItemFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PUTAWAY_REMOVE_ITEM_FAILED'),
    payload: zod_1.z.object({
        reason: zod_1.z.union([
            zod_1.z.literal('NO_BARCODE_RETAIL_UNIT_MATCH'),
            zod_1.z.literal('INVALID_TARGET_PARTITION'),
            zod_1.z.literal('NO_CURRENT_BIN'),
            zod_1.z.literal('INVALID_PORT_TYPE'),
            zod_1.z.literal('PORT_NOT_FOUND'),
            zod_1.z.literal('NO_CURRENT_TRANSFER'),
            zod_1.z.literal('TARGET_PARTITION_EMPTY'),
        ]),
        transferId: base_1.StringId,
        portId: base_1.portIdSchema,
        partitionNumber: zod_1.z.number().int().nonnegative(),
        retailUnitBarcode: zod_1.z.string().nonempty(),
    }),
});
exports.putawayItemsPlacedInPartitionSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PUTAWAY_ITEMS_PLACED_IN_PARTITION'),
    payload: zod_1.z.object({
        transferId: base_1.StringId,
        portId: base_1.portIdSchema,
        autostoreBinId: base_1.autostoreBinIdSchema,
        partitionNumber: zod_1.z.number().int().nonnegative(),
        retailUnitBarcode: zod_1.z.string().min(1),
        quantityAdded: zod_1.z.number().positive(),
        quantityInput: zod_1.z.number().optional(),
        currentQuantityInPartition: zod_1.z.number().optional(),
        hasBeenAdjusted: zod_1.z.boolean().optional(),
        originalQuantityAdded: zod_1.z.number().optional(),
    }),
});
exports.putawayItemsRemovedFromPartitionSchema = exports.putawayItemsPlacedInPartitionSchema.extend({
    type: zod_1.z.literal('PUTAWAY_ITEMS_REMOVED_FROM_PARTITION'),
    payload: exports.putawayItemsPlacedInPartitionSchema.shape.payload
        .omit({ quantityAdded: true })
        .extend({ quantityRemoved: zod_1.z.number().positive() }),
});
exports.PlaceOrRemoveItemsInPartitionFailed = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PUTAWAY_PLACE_OR_REMOVE_ITEMS_IN_PARTITION_FAILED'),
    payload: zod_1.z
        .object({
        transferId: base_1.StringId,
        portId: base_1.portIdSchema,
        partitionNumber: zod_1.z.number().int().nonnegative(),
        retailUnitBarcode: zod_1.z.string().min(1),
        quantity: zod_1.z.number().nonnegative(),
    })
        .and(zod_1.z.union([
        zod_1.z.object({
            reason: zod_1.z.literal('PORT_NOT_FOUND'),
        }),
        zod_1.z.object({
            reason: zod_1.z.literal('INVALID_PORT_TYPE'),
            expectedPortType: zod_1.z.literal('PUTAWAY'),
            actualPortType: model_1.portTypeSchema,
        }),
        zod_1.z.object({
            reason: zod_1.z.literal('NO_CURRENT_BIN'),
        }),
        zod_1.z.object({
            reason: zod_1.z.literal('NO_CURRENT_TRANSFER'),
            autostoreBinId: base_1.autostoreBinIdSchema,
        }),
        zod_1.z.object({
            reason: zod_1.z.literal('TRANSFER_ID_MISMATCH'),
            autostoreBinId: base_1.autostoreBinIdSchema,
            expectedTransferId: zod_1.z.string(),
            actualTransferId: zod_1.z.string(),
        }),
        zod_1.z.object({
            reason: zod_1.z.literal('BARCODE_DOES_NOT_MATCH_ANY_RETAIL_UNIT'),
            autostoreBinId: base_1.autostoreBinIdSchema,
        }),
        zod_1.z.object({
            reason: zod_1.z.literal('PARTITION_NOT_FOUND'),
            autostoreBinId: base_1.autostoreBinIdSchema,
            currentBinId: zod_1.z.string(),
        }),
        zod_1.z.object({
            reason: zod_1.z.literal('BARCODE_IS_DIFFERENT_FROM_BIN_ITEM'),
            expectedBarcode: zod_1.z.string(),
            actualBarcode: zod_1.z.string(),
        }),
    ])),
});
exports.putawayBinReturnedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PUTAWAY_BIN_RETURNED'),
    payload: zod_1.z.object({
        autostoreBinId: base_1.autostoreBinIdSchema,
        binId: zod_1.z.string().min(1),
        portId: base_1.portIdSchema,
        // This prop is added since WMS-458, which will be handled in reducer/transfer
        transferId: base_1.StringId.optional(),
        putawayMode: model_1.putawayModeSchema.optional(),
    }),
});
exports.returnPutawayBinFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('RETURN_PUTAWAY_BIN_FAILED'),
    payload: zod_1.z.discriminatedUnion('outcome', [
        zod_1.z.object({
            outcome: zod_1.z.literal('WAREHOUSE_NOT_FOUND'),
            warehouseId: zod_1.z.string(),
            portId: base_1.portIdSchema,
        }),
        zod_1.z.object({
            outcome: zod_1.z.literal('WAREHOUSE_WITH_AUTOSTORE_NOT_FOUND'),
            warehouseId: zod_1.z.string(),
            portId: base_1.portIdSchema,
        }),
        zod_1.z.object({
            outcome: zod_1.z.literal('PORT_NOT_FOUND'),
            warehouseId: zod_1.z.string(),
            portId: zod_1.z.number(),
        }),
        zod_1.z.object({
            outcome: zod_1.z.literal('INVALID_PORT_TYPE'),
            warehouseId: zod_1.z.string(),
            portId: zod_1.z.number(),
            actualPortType: model_1.portTypeSchema,
            expectedPortType: zod_1.z.literal('PUTAWAY'),
        }),
        zod_1.z.object({
            outcome: zod_1.z.literal('INVALID_PORT_STATUS'),
            warehouseId: zod_1.z.string(),
            portId: zod_1.z.number(),
            currentPortStatus: model_1.putawayPortStatusSchema,
            expectedPortStatus: zod_1.z.literal('OPEN'),
        }),
        zod_1.z.object({
            outcome: zod_1.z.literal('NO_CURRENT_BIN'),
            warehouseId: zod_1.z.string(),
            portId: zod_1.z.number(),
        }),
        zod_1.z.object({
            outcome: zod_1.z.literal('NO_CURRENT_TRANSFER'),
            warehouseId: zod_1.z.string(),
            portId: zod_1.z.number(),
        }),
        zod_1.z.object({
            outcome: zod_1.z.literal('PUTAWAY_MODE_NOT_FOUND'),
            warehouseId: zod_1.z.string(),
            portId: zod_1.z.number(),
        }),
        zod_1.z.object({
            outcome: zod_1.z.literal('AUTOSTORE_ERROR'),
            autostoreError: zod_1.z.string(),
            portId: base_1.portIdSchema,
            binId: zod_1.z.string(),
            autostoreBinId: zod_1.z.number(),
        }),
    ]),
});
exports.partitionsConfiguredSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PARTITIONS_CONFIGURED'),
    payload: zod_1.z.object({
        autostoreBinId: base_1.autostoreBinIdSchema,
        binId: zod_1.z.string().min(1),
        portId: base_1.portIdSchema,
        numberOfPartitions: zod_1.z.number().int().positive(),
    }),
});
exports.configurePartitionsFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CONFIGURE_PARTITIONS_FAILED'),
    payload: zod_1.z.object({
        reason: zod_1.z.union([
            zod_1.z.literal('PORT_NOT_FOUND'),
            zod_1.z.literal('INVALID_PORT_TYPE'),
            zod_1.z.literal('NO_CURRENT_TRANSFER'),
            zod_1.z.literal('NO_CURRENT_BIN'),
            zod_1.z.literal('BIN_NOT_EMPTY'),
        ]),
        portId: base_1.portIdSchema,
        numberOfPartitions: zod_1.z.number().int().positive(),
    }),
});
exports.completePutawayFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('COMPLETE_PUTAWAY_FAILED'),
    payload: zod_1.z.object({
        reason: zod_1.z.union([
            zod_1.z.literal('NO_CURRENT_BIN'),
            zod_1.z.literal('NO_CURRENT_TRANSFER'),
            zod_1.z.literal('NO_AUTOSTORE'),
            zod_1.z.literal('PORT_NOT_FOUND'),
            zod_1.z.literal('INVALID_PORT_TYPE'),
            zod_1.z.literal('INVALID_PORT_STATUS'),
            zod_1.z.literal('CLOSE_BIN_FAILED'),
            zod_1.z.literal('CLOSE_PORT_FAILED'),
        ]),
        portId: base_1.portIdSchema,
    }),
});
exports.putawayPortClosedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PUTAWAY_PORT_CLOSED'),
    payload: zod_1.z.object({
        portId: zod_1.z.number(),
    }),
});
exports.closePutawayPortFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CLOSE_PUTAWAY_PORT_FAILED'),
    payload: zod_1.z.discriminatedUnion('outcome', [
        zod_1.z.object({ outcome: zod_1.z.literal('WAREHOUSE_NOT_FOUND') }),
        zod_1.z.object({ outcome: zod_1.z.literal('WAREHOUSE_WITH_AUTOSTORE_NOT_FOUND') }),
        zod_1.z.object({ outcome: zod_1.z.literal('PORT_NOT_FOUND'), portId: zod_1.z.number() }),
        zod_1.z.object({
            outcome: zod_1.z.literal('INVALID_PORT_TYPE'),
            portId: zod_1.z.number(),
            expectedPortType: zod_1.z.literal('PUTAWAY'),
            actualPortType: zod_1.z.enum(['UNCONFIGURED', 'PICK', 'PUTAWAY']),
        }),
        zod_1.z.object({
            outcome: zod_1.z.literal('INVALID_PORT_STATUS'),
            portId: zod_1.z.number(),
            currentPortStatus: zod_1.z.enum(['OPEN', 'CLOSED', 'AWAITING_BIN', 'READY']),
            expectedPortStatus: zod_1.z.literal('OPEN'),
        }),
        zod_1.z.object({
            outcome: zod_1.z.literal('AUTOSTORE_ERROR'),
            autostoreError: zod_1.z.string(),
        }),
    ]),
});
exports.binEmptinessCheckedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('BIN_EMPTINESS_CHECKED'),
    payload: zod_1.z.object({
        autostoreBinId: base_1.autostoreBinIdSchema,
        binId: zod_1.z.string().min(1),
        portId: base_1.portIdSchema,
        isEmpty: zod_1.z.boolean(),
    }),
});
exports.putawayBinRequestedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PUTAWAY_BIN_REQUESTED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        autostoreBinId: base_1.autostoreBinIdSchema,
        binId: zod_1.z.string().min(1),
    }),
});
exports.noMatchingBinsOutcome = zod_1.z.object({
    outcome: zod_1.z.literal('NO_MATCHING_BINS_EXIST'),
    autostoreError: zod_1.z.string(),
});
exports.noMatchingBinsReadyOutcome = zod_1.z.object({
    outcome: zod_1.z.literal('NO_MATCHING_BINS_READY'),
    autostoreError: zod_1.z.string(),
});
exports.unhandledAutostoreManagerOutcomeSchema = zod_1.z.object({
    outcome: zod_1.z.literal('UNEXPECTED_AUTOSTORE_ERROR'),
    autostoreError: zod_1.z.string(),
});
exports.warehouseNotFoundOutcomeSchema = zod_1.z.object({
    outcome: zod_1.z.literal('WAREHOUSE_NOT_FOUND'),
    warehouseId: zod_1.z.string(),
});
exports.warehouseWithNoAutostoreOutcomeSchema = zod_1.z.object({
    outcome: zod_1.z.literal('WAREHOUSE_WITH_AUTOSTORE_NOT_FOUND'),
    warehouseId: zod_1.z.string(),
});
const noCurrentBinOutcome = zod_1.z.object({
    outcome: zod_1.z.literal('NO_CURRENT_BIN'),
    warehouseId: zod_1.z.string(),
    currentAutostoreBinId: zod_1.z.number().optional(),
});
exports.putawayBinRequestFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PUTAWAY_BIN_REQUEST_FAILED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        reason: zod_1.z.union([
            exports.noMatchingBinsOutcome,
            exports.noMatchingBinsReadyOutcome,
            exports.unhandledAutostoreManagerOutcomeSchema,
            exports.warehouseNotFoundOutcomeSchema,
            exports.warehouseWithNoAutostoreOutcomeSchema,
            noCurrentBinOutcome,
        ]),
    }),
});
exports.binEmptinessCheckFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CHECK_BIN_EMPTINESS_FAILED'),
    payload: zod_1.z.object({
        reason: zod_1.z.union([
            zod_1.z.literal('PORT_NOT_FOUND'),
            zod_1.z.literal('INVALID_PORT_TYPE'),
            zod_1.z.literal('NO_CURRENT_BIN'),
        ]),
        portId: base_1.portIdSchema,
        isEmpty: zod_1.z.boolean(),
    }),
});
exports.PutawayEvent = zod_1.z.union([
    exports.putawayPortOpenedSchema,
    exports.PutawayPortOpenFailed,
    exports.PutawayCompleted,
    port_1.binArrivedSchema,
    port_1.binRequestedSchema,
    exports.putawayBinRequestedSchema,
    exports.putawayBinRequestFailedSchema,
    exports.binEmptinessCheckedSchema,
    exports.binEmptinessCheckFailedSchema,
    exports.putawayBinReturnedSchema,
    exports.returnPutawayBinFailedSchema,
    port_1.BinRetrievalTimedOut,
    exports.putawayItemAddedSchema,
    exports.addPutawayItemFailedSchema,
    exports.putawayItemRemovedSchema,
    exports.removePutawayItemFailedSchema,
    exports.partitionsConfiguredSchema,
    exports.configurePartitionsFailedSchema,
    exports.completePutawayFailedSchema,
    exports.closePutawayPortFailedSchema,
    exports.putawayPortClosedSchema,
]);
