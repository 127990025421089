import { RetailUnit as RetailUnitType } from 'api-schema/lib';
import { RetailUnit } from '../../../../../components/warehouse/RetailUnit';
import { Text } from '../../../../../components/warehouse/Text';
import * as Elements from '../PickingInformation.elements';

export const Header = ({
  isShortPick,
  item,
}: {
  isShortPick: boolean;
  item?: RetailUnitType;
}): React.ReactElement => (
  <Elements.Header>
    {isShortPick && (
      <Text variant="h3" weight="medium">
        Short pick
      </Text>
    )}
    {item && <RetailUnit item={item} hasError={isShortPick} />}
  </Elements.Header>
);
