import {
  Button,
  Fab,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import LoopIcon from '@material-ui/icons/Loop';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useWarehouseParam } from '../../../hooks/useWarehouseParam';
import { LoadingStatus } from '../../../hooks/useWarehousesApi';
import { WAREHOUSE_PATHS } from '../../../router/paths';
import { useAppState } from '../../../store';
import { setCurrentWarehouse } from '../../../store/actions';
import { generatePathWithWarehouseId } from '../../../utils/generatePathWithWarehouseId';
import { useStyles } from './styles';

type Props = {
  status: LoadingStatus;
  onRefreshClick: () => void;
};

export const AdminHome: FC<Props> = ({ status, onRefreshClick }: Props) => {
  useWarehouseParam();
  const history = useHistory();
  const classes = useStyles();
  const {
    appDispatch,
    appState: { warehouses },
  } = useAppState();
  const createWarehouseClickHandler = (uuid: string) => () => {
    appDispatch(setCurrentWarehouse(uuid));
    history.push(`/admin/warehouse/overview`);
  };

  const onFabClickHandler = () => {
    history.push('/admin/add-warehouse');
  };

  const onEditClickHandler = (uuid: string) => () => {
    appDispatch(setCurrentWarehouse(uuid));
    history.push(
      generatePathWithWarehouseId(WAREHOUSE_PATHS.EDIT_WAREHOUSE, {
        warehouseId: uuid,
      })
    );
  };

  return (
    <>
      <Paper elevation={0}>
        <Typography
          variant="h1"
          gutterBottom={true}
          className={classes.sectionTitle}
        >
          Warehouses Admin
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Is test</TableCell>
              <TableCell>
                <span className="fa-flip-horizontal">
                  <IconButton
                    aria-label="delete"
                    onClick={onRefreshClick}
                    className={`${status === 'LOADING' ? 'fa-spin' : ''}`}
                    style={{ animationDirection: 'reverse' }}
                  >
                    <LoopIcon />
                  </IconButton>
                </span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-testid="warehouse-list">
            {warehouses.map((w) => {
              if (!w.id) {
                console.error(
                  `Warehouse data corrupted; missing uuid: ${JSON.stringify(w)}`
                );
                return null;
              }
              return (
                <TableRow key={w.id}>
                  <TableCell>{w.name}</TableCell>
                  <TableCell>{w.address}</TableCell>
                  <TableCell>{w.id}</TableCell>
                  <TableCell>{w.isTest ? 'true' : 'false'}</TableCell>
                  <TableCell>
                    <Button
                      data-testid="view-warehouse"
                      onClick={createWarehouseClickHandler(w.id)}
                    >
                      View
                    </Button>
                    <Button onClick={onEditClickHandler(w.id)}>Edit</Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
      <Fab color="primary" className={classes.fab} onClick={onFabClickHandler}>
        <Add />
      </Fab>
    </>
  );
};
