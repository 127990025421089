import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useRouteMatch } from 'react-router-dom';
import { isNotErrorResponse } from '../../../apiClient';
import { usePutawayPortSocket } from '../../../components/common/SocketProvider';
import { SnackbarDefaults } from '../../../constants/snackbarDefaults';
import { useApiClient } from '../../../hooks/useApiClient';
import { handleCommandRejectedResultWithSnackbar } from '../../../utils/commands';
import { transformZodErrorsToFormik } from '../../../utils/forms/transformZodErrorsToFormik';
import { ScanTransferModel, ScanTransferModelType } from './ScanTransfer.model';
import { ScanTransferView } from './ScanTransfer.view';

type RouteParams = { warehouseId: string; portId: string };

export const ScanTransfer = () => {
  const {
    params: { warehouseId, portId },
  } = useRouteMatch<RouteParams>();
  const { enqueueSnackbar } = useSnackbar();
  const apiClient = useApiClient();
  const { dispatchCommand } = usePutawayPortSocket();
  const formik = useFormik<ScanTransferModelType>({
    initialValues: {
      transferId: '',
    },
    onSubmit: async (values) => {
      try {
        const { data, status } = await apiClient.post(
          '/warehouses/:id/transfers/:transferId/scanned',
          {
            params: {
              id: warehouseId,
              transferId: values.transferId,
            },
            body: {
              portId,
            },
          }
        );
        if (isNotErrorResponse(data, status)) {
          enqueueSnackbar(
            `Transfer scanned. Opening port & beginning putaway...`,
            SnackbarDefaults
          );
          const commandResult = await dispatchCommand({
            type: 'OPEN_PUTAWAY_PORT',
          });
          handleCommandRejectedResultWithSnackbar(
            enqueueSnackbar,
            commandResult
          );
        } else {
          // TODO(SKUT-864):
          enqueueSnackbar(data.error, {
            ...SnackbarDefaults,
            variant: 'error',
          });
        }
      } catch (error) {
        // error displayed by apiClient
      }
    },
    validate:
      transformZodErrorsToFormik<ScanTransferModelType>(ScanTransferModel),
  });
  return <ScanTransferView {...formik} />;
};
