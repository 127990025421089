import { warehouseColours } from '../constants/colours';

import { ReactElement } from 'react';
import { ThemeProvider } from 'styled-components';
import { largeFontSizeSet, smallFontSizeSet } from './WarehouseFontSets';

export const SPACING_BASE = 10;
const TABLET_MODAL_WIDTH = 600;

export const getWarehouseTheme = (useLargeFontSizeSet: boolean) => {
  const fontSizeSet = useLargeFontSizeSet ? largeFontSizeSet : smallFontSizeSet;
  return {
    currentFontSizeSet: useLargeFontSizeSet ? 'large' : 'standard',
    palette: {
      ...warehouseColours,
    },
    spacing: (n: number) => `${n * SPACING_BASE}px`,
    typography: {
      htmlFontSize: 16, // Base font size
      pxToRem(pixels: number): number {
        return pixels / this.htmlFontSize;
      },
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 30,
      ...fontSizeSet,
    },
    shape: {
      borderRadius: useLargeFontSizeSet ? 5 : 4,
      modalSize: `${TABLET_MODAL_WIDTH}px`,
    },
    weight: {
      regular: 400,
      medium: 500,
      bold: 700,
    },
    zIndex: {
      modalBackdrop: 10000,
      modal: 10010,
    },
  };
};

export function WarehouseThemeProvider({
  children,
  useLargeFontSet = true,
}: {
  children: ReactElement;
  useLargeFontSet?: boolean;
}): ReactElement {
  return (
    <ThemeProvider theme={getWarehouseTheme(useLargeFontSet)}>
      {children}
    </ThemeProvider>
  );
}
