// TODO (WMS-1598) Delete this file and use just the picking mode name
export const PORT_CATEGORY = {
  STANDARD: 1,
  SAME_DAY: 2,
  PRIORITY: 3,
  B2B: 4,
  MANUAL_PACK: 5,
} as const;

export const PICKING_MODE = {
  STANDARD: { name: 'STANDARD', autostoreCategory: 1 },
  SAMEDAY: { name: 'SAMEDAY', autostoreCategory: 2 },
  PRIORITY: { name: 'PRIORITY', autostoreCategory: 3 },
  B2B: { name: 'B2B', autostoreCategory: 4 },
  MANUAL_PACK: { name: 'MANUAL_PACK', autostoreCategory: 5 },
} as const;

type Values<T> = T[keyof T];
export type PortCategoryEnumValueType = Values<typeof PORT_CATEGORY>;

export const PORT_CATEGORY_LABEL = {
  [PORT_CATEGORY.STANDARD]: 'Standard',
  [PORT_CATEGORY.SAME_DAY]: 'Same Day',
  [PORT_CATEGORY.PRIORITY]: 'Priority',
  [PORT_CATEGORY.B2B]: 'B2B',
  [PORT_CATEGORY.MANUAL_PACK]: 'Manual Pack',
} as const;
