"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.autostoreCompletePortState = exports.autostorePortState = exports.autostoreBinStateSchema = exports.autostorePortDefinition = exports.autostorePortType = exports.autostorePortMode = exports.autostoreBinMode = exports.autostoreStateReceivedEvent = exports.autostorePortDefinitionChangedEvent = exports.autostoreLogDownloadCompletedEvent = exports.autostoreLogDownloadStartedEvent = exports.autostoreBinLocationChangedEvent = exports.autostoreBinClosedEvent = exports.autostoreBinOpenedEvent = exports.autostorePortModeChangedEvent = exports.autostoreEvent = exports.wcsEvent = exports.fulfilmentOrderDeletedSchema = exports.LabelTroubleshootEvent = exports.ShortPickTroubleshootEvent = exports.PackAndDispatchEvent = exports.TaskGroupEvent = exports.PicklistEvent = exports.RetailUnitEvent = exports.fulfilmentOrderAcceptedSchema = exports.InventoryEvent = exports.PickSocketEvent = exports.PICK_SOCKET_EVENT_NAMES = exports.PutawaySocketEvent = exports.PUTAWAY_SOCKET_EVENT_NAMES = exports.portConfigurationChangedSchema = exports.storageUnitsStatusChangedSchema = exports.warehouseAddedSchema = exports.toteStorageCreatedSchema = exports.AutostoreIntegrated = exports.AutostoreBinsInducted = exports.AutostoreBinsCreated = exports.FulfilmentOrderEvent = exports.WarehouseEvent = exports.StorageEvent = exports.PortEvent = exports.PutawayEvent = exports.transferEventSchema = exports.CombinedEvents = void 0;
const zod_1 = require("zod");
const fulfilmentOrder_1 = require("./fulfilmentOrder");
Object.defineProperty(exports, "fulfilmentOrderAcceptedSchema", { enumerable: true, get: function () { return fulfilmentOrder_1.fulfilmentOrderAcceptedSchema; } });
Object.defineProperty(exports, "fulfilmentOrderDeletedSchema", { enumerable: true, get: function () { return fulfilmentOrder_1.fulfilmentOrderDeletedSchema; } });
Object.defineProperty(exports, "FulfilmentOrderEvent", { enumerable: true, get: function () { return fulfilmentOrder_1.FulfilmentOrderEvent; } });
const intermediaryStation_1 = require("./intermediaryStation");
const inventory_1 = require("./inventory");
Object.defineProperty(exports, "InventoryEvent", { enumerable: true, get: function () { return inventory_1.InventoryEvent; } });
const labelTroubleshoot_1 = require("./labelTroubleshoot");
Object.defineProperty(exports, "LabelTroubleshootEvent", { enumerable: true, get: function () { return labelTroubleshoot_1.LabelTroubleshootEvent; } });
const merchant_1 = require("./merchant");
const packAndDispatch_1 = require("./packAndDispatch");
Object.defineProperty(exports, "PackAndDispatchEvent", { enumerable: true, get: function () { return packAndDispatch_1.PackAndDispatchEvent; } });
const pick_1 = require("./pick");
const picklist_1 = require("./picklist");
Object.defineProperty(exports, "PicklistEvent", { enumerable: true, get: function () { return picklist_1.PicklistEvent; } });
const port_1 = require("./port");
Object.defineProperty(exports, "portConfigurationChangedSchema", { enumerable: true, get: function () { return port_1.portConfigurationChangedSchema; } });
Object.defineProperty(exports, "PortEvent", { enumerable: true, get: function () { return port_1.PortEvent; } });
const putaway_1 = require("./putaway");
Object.defineProperty(exports, "PutawayEvent", { enumerable: true, get: function () { return putaway_1.PutawayEvent; } });
const retailUnit_1 = require("./retailUnit");
Object.defineProperty(exports, "RetailUnitEvent", { enumerable: true, get: function () { return retailUnit_1.RetailUnitEvent; } });
const shortPickTroubleshoot_1 = require("./shortPickTroubleshoot");
Object.defineProperty(exports, "ShortPickTroubleshootEvent", { enumerable: true, get: function () { return shortPickTroubleshoot_1.ShortPickTroubleshootEvent; } });
const sockets_1 = require("./sockets");
Object.defineProperty(exports, "PickSocketEvent", { enumerable: true, get: function () { return sockets_1.PickSocketEvent; } });
Object.defineProperty(exports, "PICK_SOCKET_EVENT_NAMES", { enumerable: true, get: function () { return sockets_1.PICK_SOCKET_EVENT_NAMES; } });
Object.defineProperty(exports, "PutawaySocketEvent", { enumerable: true, get: function () { return sockets_1.PutawaySocketEvent; } });
Object.defineProperty(exports, "PUTAWAY_SOCKET_EVENT_NAMES", { enumerable: true, get: function () { return sockets_1.PUTAWAY_SOCKET_EVENT_NAMES; } });
const storage_1 = require("./storage");
Object.defineProperty(exports, "AutostoreBinsCreated", { enumerable: true, get: function () { return storage_1.AutostoreBinsCreated; } });
Object.defineProperty(exports, "AutostoreBinsInducted", { enumerable: true, get: function () { return storage_1.AutostoreBinsInducted; } });
Object.defineProperty(exports, "StorageEvent", { enumerable: true, get: function () { return storage_1.StorageEvent; } });
Object.defineProperty(exports, "storageUnitsStatusChangedSchema", { enumerable: true, get: function () { return storage_1.storageUnitsStatusChangedSchema; } });
Object.defineProperty(exports, "toteStorageCreatedSchema", { enumerable: true, get: function () { return storage_1.toteStorageCreatedSchema; } });
const taskGroup_1 = require("./taskGroup");
Object.defineProperty(exports, "TaskGroupEvent", { enumerable: true, get: function () { return taskGroup_1.TaskGroupEvent; } });
const transfer_1 = require("./transfer");
Object.defineProperty(exports, "transferEventSchema", { enumerable: true, get: function () { return transfer_1.transferEventSchema; } });
const warehouse_1 = require("./warehouse");
Object.defineProperty(exports, "AutostoreIntegrated", { enumerable: true, get: function () { return warehouse_1.AutostoreIntegrated; } });
Object.defineProperty(exports, "warehouseAddedSchema", { enumerable: true, get: function () { return warehouse_1.warehouseAddedSchema; } });
Object.defineProperty(exports, "WarehouseEvent", { enumerable: true, get: function () { return warehouse_1.WarehouseEvent; } });
const conveyor_1 = require("./conveyor");
const system_1 = require("./system");
const wcs_1 = require("./wcs");
Object.defineProperty(exports, "autostoreBinClosedEvent", { enumerable: true, get: function () { return wcs_1.autostoreBinClosedEvent; } });
Object.defineProperty(exports, "autostoreBinLocationChangedEvent", { enumerable: true, get: function () { return wcs_1.autostoreBinLocationChangedEvent; } });
Object.defineProperty(exports, "autostoreBinMode", { enumerable: true, get: function () { return wcs_1.autostoreBinMode; } });
Object.defineProperty(exports, "autostoreBinOpenedEvent", { enumerable: true, get: function () { return wcs_1.autostoreBinOpenedEvent; } });
Object.defineProperty(exports, "autostoreBinStateSchema", { enumerable: true, get: function () { return wcs_1.autostoreBinStateSchema; } });
Object.defineProperty(exports, "autostoreCompletePortState", { enumerable: true, get: function () { return wcs_1.autostoreCompletePortState; } });
Object.defineProperty(exports, "autostoreEvent", { enumerable: true, get: function () { return wcs_1.autostoreEvent; } });
Object.defineProperty(exports, "autostoreLogDownloadCompletedEvent", { enumerable: true, get: function () { return wcs_1.autostoreLogDownloadCompletedEvent; } });
Object.defineProperty(exports, "autostoreLogDownloadStartedEvent", { enumerable: true, get: function () { return wcs_1.autostoreLogDownloadStartedEvent; } });
Object.defineProperty(exports, "autostorePortDefinition", { enumerable: true, get: function () { return wcs_1.autostorePortDefinition; } });
Object.defineProperty(exports, "autostorePortDefinitionChangedEvent", { enumerable: true, get: function () { return wcs_1.autostorePortDefinitionChangedEvent; } });
Object.defineProperty(exports, "autostorePortMode", { enumerable: true, get: function () { return wcs_1.autostorePortMode; } });
Object.defineProperty(exports, "autostorePortModeChangedEvent", { enumerable: true, get: function () { return wcs_1.autostorePortModeChangedEvent; } });
Object.defineProperty(exports, "autostorePortState", { enumerable: true, get: function () { return wcs_1.autostorePortState; } });
Object.defineProperty(exports, "autostorePortType", { enumerable: true, get: function () { return wcs_1.autostorePortType; } });
Object.defineProperty(exports, "autostoreStateReceivedEvent", { enumerable: true, get: function () { return wcs_1.autostoreStateReceivedEvent; } });
Object.defineProperty(exports, "wcsEvent", { enumerable: true, get: function () { return wcs_1.wcsEvent; } });
const CombinedEvents = zod_1.z.union([
    warehouse_1.WarehouseEvent,
    storage_1.StorageEvent,
    transfer_1.transferEventSchema,
    putaway_1.PutawayEvent,
    pick_1.PickEvent,
    port_1.PortEvent,
    inventory_1.InventoryEvent,
    fulfilmentOrder_1.FulfilmentOrderEvent,
    retailUnit_1.RetailUnitEvent,
    picklist_1.PicklistEvent,
    taskGroup_1.TaskGroupEvent,
    packAndDispatch_1.PackAndDispatchEvent,
    intermediaryStation_1.intermediaryStationEventSchema,
    conveyor_1.conveyorWebhookEventSchema,
    shortPickTroubleshoot_1.ShortPickTroubleshootEvent,
    labelTroubleshoot_1.LabelTroubleshootEvent,
    retailUnit_1.RetailUnitEvent,
    merchant_1.merchantEventSchema,
    system_1.systemEventSchema,
]);
exports.CombinedEvents = CombinedEvents;
