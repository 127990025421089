import { ReactElement } from 'react';
import { TipsContainer } from './Tips.elements';

export type TipsProps = {
  icon?: ReactElement;
  text: string;
};

export function Tips({ icon, text }: TipsProps): ReactElement {
  return (
    <TipsContainer>
      {icon && icon}
      <span>{text}</span>
    </TipsContainer>
  );
}
