import { AlertMessage } from '../../../../components/warehouse/AlertMessage';
import { AlertMessageTypes } from '../../../../components/warehouse/AlertMessage/AlertMessage';
import { Box } from '../../../../components/warehouse/Box';

export type PackAndPrintErrorType =
  | 'INCOMPLETE_PACKING'
  | 'INSERT_OPTION_NOT_SELECTED'
  | null;

export type PackAndPrintErrorProps = {
  variant: PackAndPrintErrorType;
};

const mapErrorToUserMessage = (variant: PackAndPrintErrorType): string => {
  switch (variant) {
    case 'INCOMPLETE_PACKING':
      return 'Please complete each step. A label must be printed and attached to the parcel.';
    case 'INSERT_OPTION_NOT_SELECTED':
      return 'Please make sure the inserts are added or select the “No insert” option if there are none available.';
  }
  return '';
};
export function PackAndPrintError({ variant }: PackAndPrintErrorProps) {
  if (!variant) {
    return <></>;
  }

  return (
    <Box marginBottom="20px" data-testid="pack-and-print-error">
      <AlertMessage type={AlertMessageTypes.Error}>
        {mapErrorToUserMessage(variant)}
      </AlertMessage>
    </Box>
  );
}
