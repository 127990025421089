import React, { ReactElement } from 'react';
import { Header, PortBody, StationBody, Wrapper } from './Layout.elements';

export interface LayoutProps {
  header?: React.ReactNode;
  children?: React.ReactNode;
  danger?: boolean;
  type?: 'PORT' | 'STATION';
}

export function Layout({
  header,
  children,
  danger,
  type,
}: LayoutProps): ReactElement {
  const Body = type === 'PORT' ? PortBody : StationBody;
  return (
    <Wrapper>
      {header && <Header danger={danger}>{header}</Header>}
      <Body>{children}</Body>
    </Wrapper>
  );
}
