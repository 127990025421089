"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.pickingModeSchema = exports.pickingModeNameSchema = exports.autostoreCategorySchema = exports.pickingModeAutoStoreCategories = void 0;
const z = __importStar(require("zod"));
exports.pickingModeAutoStoreCategories = {
    STANDARD: z.literal(1),
    SAMEDAY: z.literal(2),
    PRIORITY: z.literal(3),
    B2B: z.literal(4),
    MANUAL_PACK: z.literal(5),
};
exports.autostoreCategorySchema = z.union([
    exports.pickingModeAutoStoreCategories.STANDARD,
    exports.pickingModeAutoStoreCategories.SAMEDAY,
    exports.pickingModeAutoStoreCategories.PRIORITY,
    exports.pickingModeAutoStoreCategories.B2B,
    exports.pickingModeAutoStoreCategories.MANUAL_PACK,
]);
const standardPickingModeNameSchema = z.literal('STANDARD');
const samedayPickingModeNameSchema = z.literal('SAMEDAY');
const priorityPickingModeNameSchema = z.literal('PRIORITY');
const b2bPickingModeNameSchema = z.literal('B2B');
const manualPackPickingModeNameSchema = z.literal('MANUAL_PACK');
exports.pickingModeNameSchema = z.union([
    standardPickingModeNameSchema,
    samedayPickingModeNameSchema,
    priorityPickingModeNameSchema,
    b2bPickingModeNameSchema,
    manualPackPickingModeNameSchema,
]);
exports.pickingModeSchema = z.union([
    z.object({
        name: standardPickingModeNameSchema,
        autostoreCategory: exports.pickingModeAutoStoreCategories.STANDARD,
    }),
    z.object({
        name: samedayPickingModeNameSchema,
        autostoreCategory: exports.pickingModeAutoStoreCategories.SAMEDAY,
    }),
    z.object({
        name: priorityPickingModeNameSchema,
        autostoreCategory: exports.pickingModeAutoStoreCategories.PRIORITY,
    }),
    z.object({
        name: b2bPickingModeNameSchema,
        autostoreCategory: exports.pickingModeAutoStoreCategories.B2B,
    }),
    z.object({
        name: manualPackPickingModeNameSchema,
        autostoreCategory: exports.pickingModeAutoStoreCategories.MANUAL_PACK,
    }),
]);
