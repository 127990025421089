"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PickCommands = exports.validateItemBarcodeSchema = exports.pushCompletedTotesSchema = exports.determineZeroCountCrossCheckRequiredSchema = exports.CheckPickBinHasArrived = exports.RetryCloseBin = exports.RaiseNoPaperProblem = exports.RaisePickProblem = exports.PlacePaperInTotes = exports.UpdateAvailablePickQuantity = exports.UndoPushPickTotes = exports.ReplacePickTotes = exports.RetryPushPickTotesToConveyor = exports.PushPickTotes = exports.UndoPreviousPick = exports.GetNextItem = exports.cancelMissingItemResolutionSchema = exports.detectMissingItemSchema = exports.confirmMissingItemDuringPickSchema = exports.completePickSchema = exports.UnlinkAllTotes = exports.RetryStartPicking = exports.CompleteToteLinking = exports.CancelToteLink = exports.ConfirmToteLink = exports.ScanTote = exports.ScanToteLocation = exports.PlaceTotes = exports.ClosePort = exports.OpenPort = void 0;
const z = __importStar(require("zod"));
exports.OpenPort = z.object({
    type: z.literal('OPEN_PICK_PORT'),
});
exports.ClosePort = z.object({
    type: z.literal('CLOSE_PORT'),
});
exports.PlaceTotes = z.object({
    type: z.literal('PLACE_TOTES'),
});
exports.ScanToteLocation = z.object({
    type: z.literal('SCAN_TOTE_LOCATION'),
    locationId: z.string(),
});
exports.ScanTote = z.object({
    type: z.literal('SCAN_TOTE'),
    locationId: z.string(),
    toteId: z.string(),
});
exports.ConfirmToteLink = z.object({
    type: z.literal('CONFIRM_TOTE_LINK'),
    locationId: z.string(),
    toteId: z.string(),
});
exports.CancelToteLink = z.object({
    type: z.literal('CANCEL_TOTE_LINK'),
    locationId: z.string(),
    toteId: z.string(),
});
exports.CompleteToteLinking = z.object({
    type: z.literal('COMPLETE_TOTE_LINKING'),
});
exports.RetryStartPicking = z.object({
    type: z.literal('RETRY_START_PICKING'),
});
exports.UnlinkAllTotes = z.object({
    type: z.literal('UNLINK_ALL_TOTES'),
});
exports.completePickSchema = z.object({
    type: z.literal('COMPLETE_PICK'),
});
exports.confirmMissingItemDuringPickSchema = z.object({
    type: z.literal('CONFIRM_MISSING_ITEM_DURING_PICK'),
    currentPickItem: z.string(),
});
exports.detectMissingItemSchema = z.object({
    type: z.literal('DETECT_MISSING_ITEM'),
});
exports.cancelMissingItemResolutionSchema = z.object({
    type: z.literal('CANCEL_MISSING_ITEM_RESOLUTION'),
});
exports.GetNextItem = z.object({
    type: z.literal('GET_NEXT_ITEM'),
});
exports.UndoPreviousPick = z.object({
    type: z.literal('UNDO_PREVIOUS_PICK'),
});
exports.PushPickTotes = z.object({
    type: z.literal('PUSH_PICK_TOTES'),
});
exports.RetryPushPickTotesToConveyor = z.object({
    type: z.literal('RETRY_PUSH_PICK_TOTES_TO_CONVEYOR'),
    toteIds: z.array(z.string()),
});
exports.ReplacePickTotes = z.object({
    type: z.literal('REPLACE_PICK_TOTES'),
});
exports.UndoPushPickTotes = z.object({
    type: z.literal('UNDO_PUSH_PICK_TOTES'),
});
exports.UpdateAvailablePickQuantity = z.object({
    type: z.literal('UPDATE_AVAILABLE_PICK_QUANTITY'),
    availableQuantity: z.number().nonnegative(),
});
exports.PlacePaperInTotes = z.object({
    type: z.literal('PLACE_PAPER_IN_TOTES'),
});
exports.RaisePickProblem = z.object({
    type: z.literal('RAISE_PICK_PROBLEM'),
    problemDescription: z.string(),
});
exports.RaiseNoPaperProblem = z.object({
    type: z.literal('RAISE_NO_PAPER_PROBLEM'),
});
exports.RetryCloseBin = z.object({
    type: z.literal('RETRY_CLOSE_BIN'),
});
exports.CheckPickBinHasArrived = z.object({
    type: z.literal('CHECK_PICK_BIN_HAS_ARRIVED'),
});
exports.determineZeroCountCrossCheckRequiredSchema = z.object({
    type: z.literal('DETERMINE_ZERO_COUNT_CROSS_CHECK_REQUIRED'),
});
exports.pushCompletedTotesSchema = z.object({
    type: z.literal('PUSH_COMPLETED_TOTES'),
});
exports.validateItemBarcodeSchema = z.object({
    type: z.literal('VALIDATE_PICK_ITEM_BARCODE'),
    barcode: z.string(),
});
exports.PickCommands = z.union([
    exports.OpenPort,
    exports.ClosePort,
    exports.PlaceTotes,
    exports.ScanToteLocation,
    exports.ScanTote,
    exports.ConfirmToteLink,
    exports.CancelToteLink,
    exports.RetryStartPicking,
    exports.UnlinkAllTotes,
    exports.completePickSchema,
    exports.confirmMissingItemDuringPickSchema,
    exports.detectMissingItemSchema,
    exports.cancelMissingItemResolutionSchema,
    exports.GetNextItem,
    exports.UndoPreviousPick,
    exports.PushPickTotes,
    exports.RetryPushPickTotesToConveyor,
    exports.ReplacePickTotes,
    exports.UndoPushPickTotes,
    exports.UpdateAvailablePickQuantity,
    exports.PlacePaperInTotes,
    exports.RaisePickProblem,
    exports.RaiseNoPaperProblem,
    exports.RetryCloseBin,
    exports.CheckPickBinHasArrived,
    exports.pushCompletedTotesSchema,
    exports.determineZeroCountCrossCheckRequiredSchema,
    exports.validateItemBarcodeSchema,
]);
