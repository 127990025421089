import {
  ItemToBeFulfilledType,
  ResolutionToteStatusType,
  ShortPickItemType,
} from 'api-schema/lib/model';
import { ReactElement } from 'react';
import { ReactComponent as CheckIcon } from '../../../../assets/img/icons/check-circle.svg';
import { ReactComponent as CrossCircleIcon } from '../../../../assets/img/icons/cross-circle.svg';
import { ReactComponent as DottedIcon } from '../../../../assets/img/icons/dotted-circle.svg';
import {
  AlertMessage,
  AlertMessageTypes,
} from '../../../../components/warehouse/AlertMessage';
import { RetailUnit } from '../../../../components/warehouse/RetailUnit';
import { Text } from '../../../../components/warehouse/Text';
import { warehouseColours } from '../../../../constants/colours';
import { isNever } from '../../../../utils/isNever';
import { pluralise } from '../../../../utils/pluralise';
import { ListItemsTitle } from '../ListItemsTitle';
import {
  AlertMessageTextWrapper,
  AlertMessageWrapper,
  ItemGroup,
  ItemsToBeFulfilled,
  Wrapper,
} from './ListItems.elements';

export type ListItemType = 'fulfilled' | 'toBeFulfilled' | 'unfulfillable';

export type ListItemsProps = {
  items: ShortPickItemType[] | ItemToBeFulfilledType[];
  type: ListItemType;
  fulfilledToteId: string;
};

export function ListItems({ items, type, fulfilledToteId }: ListItemsProps) {
  const quantity = isItemToBePickedArray(items)
    ? items.reduce((total, { item }) => total + item.quantity, 0)
    : items.reduce((total, item) => total + item.quantity, 0);
  const pluralisedItemText = pluralise('item', quantity, false);
  return (
    <Wrapper>
      <ListItemsTitle
        listItemType={type}
        title={`${quantity} ${getTitleFromType(type, pluralisedItemText)} `}
        sideText={type === 'fulfilled' ? `Tote #${fulfilledToteId}` : ''}
        icon={getIconFromType(type)}
      />
      {type === 'toBeFulfilled' && isItemToBePickedArray(items) && (
        <>
          {items.length > 1 && (
            <AlertMessageWrapper>
              <AlertMessage type={AlertMessageTypes.Default}>
                <AlertMessageTextWrapper>
                  This order requires items from{' '}
                  <Text variant="body2" weight="medium" margin={0} tag="span">
                    {items.length} resolution totes.
                  </Text>{' '}
                </AlertMessageTextWrapper>
              </AlertMessage>
            </AlertMessageWrapper>
          )}
          {items.length > 1 ? (
            <ItemsToBeFulfilled>
              {items.map((item) => (
                <ItemGroup>{getResolutionToteElement(item)}</ItemGroup>
              ))}
            </ItemsToBeFulfilled>
          ) : (
            getResolutionToteElement(items[0])
          )}
        </>
      )}
      {type === 'unfulfillable' && (
        <AlertMessageWrapper>
          <AlertMessage type={AlertMessageTypes.Warning}>
            <AlertMessageTextWrapper>
              <Text variant="body2" weight="medium" margin={0} tag="span">
                Some items are unfulfillable.
              </Text>{' '}
              <Text variant="body2" weight="regular" margin={0} tag="span">
                Please contact the merchant to determine how to proceed with
                this order.
              </Text>
            </AlertMessageTextWrapper>
          </AlertMessage>
        </AlertMessageWrapper>
      )}
      {type !== 'toBeFulfilled' &&
        !isItemToBePickedArray(items) &&
        items.map((item) => {
          return (
            <RetailUnit
              variant="secondary"
              hasError={false}
              item={{
                barcode: item.retailUnit.barcode,
                quantity: item.quantity,
                name: item.retailUnit.name,
                imageSrc: item.retailUnit.imageSrc,
                options: item.retailUnit.options,
              }}
              showQuantity
            />
          );
        })}
    </Wrapper>
  );
}

const getTitleFromType = (
  type: ListItemType,
  pluralisedItemText: string
): string => {
  switch (type) {
    case 'fulfilled':
      return `fulfilled ${pluralisedItemText}`;
    case 'toBeFulfilled':
      return `${pluralisedItemText} to be fulfilled`;
    case 'unfulfillable':
      return `unfulfillable ${pluralisedItemText}`;
    default:
      return isNever(type);
  }
};

const getIconFromType = (type: ListItemType): JSX.Element => {
  switch (type) {
    case 'fulfilled':
      return <CheckIcon stroke={warehouseColours.success.main} />;
    case 'toBeFulfilled':
      return <DottedIcon stroke={warehouseColours.info.dark} />;
    case 'unfulfillable':
      return <CrossCircleIcon fill={warehouseColours.danger.base} />;
    default:
      return isNever(type);
  }
};

const getResolutionToteMessage = (
  resolutionToteId: string | undefined,
  resolutionToteStatus: ResolutionToteStatusType,
  itemQuantity: number
): JSX.Element => {
  if (resolutionToteStatus === 'COMPLETE') {
    return (
      <AlertMessageTextWrapper>
        Resolution tote{' '}
        <Text variant="body2" weight="medium" margin={0} tag="span">
          #{resolutionToteId}
        </Text>{' '}
        contains the {pluralise('item', itemQuantity, false)} required for this
        order.
      </AlertMessageTextWrapper>
    );
  }

  if (resolutionToteId) {
    return (
      <AlertMessageTextWrapper>
        Resolution tote{' '}
        <Text variant="body2" weight="medium" margin={0} tag="span">
          #{resolutionToteId}
        </Text>{' '}
        contains the {pluralise('item', itemQuantity, false)} required for this
        order. This tote is still being processed in Picking.
      </AlertMessageTextWrapper>
    );
  }

  return (
    <>
      The {pluralise('item', itemQuantity, false)} required for this order{' '}
      {itemQuantity > 1 ? 'are' : 'is'} awaiting assignment in Picking.
    </>
  );
};

const getResolutionToteElement = ({
  item,
  tote,
}: ItemToBeFulfilledType): ReactElement => (
  <>
    <AlertMessageWrapper>
      <AlertMessage
        type={
          tote.toteStatus === 'COMPLETE'
            ? AlertMessageTypes.Info
            : AlertMessageTypes.Warning
        }
      >
        {getResolutionToteMessage(
          tote.toteId,
          tote.toteStatus || 'PENDING',
          item.quantity
        )}
      </AlertMessage>
    </AlertMessageWrapper>
    <RetailUnit
      variant="secondary"
      hasError={false}
      item={{ ...item.retailUnit, quantity: item.quantity }}
      showQuantity
    />
  </>
);

const isItemToBePicked = (
  item: ShortPickItemType | ItemToBeFulfilledType
): item is ItemToBeFulfilledType => 'item' in item && 'tote' in item;

const isItemToBePickedArray = (
  items: ShortPickItemType[] | ItemToBeFulfilledType[]
): items is Array<ItemToBeFulfilledType> => items.some(isItemToBePicked);
