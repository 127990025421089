import { ReactNode } from 'react';
import {
  Action,
  Body,
  Content,
  Header,
  HeaderText,
  IconWrapper,
  Wrapper,
} from './Modal.elements';

export type ModalVariant = 'default' | 'warning' | 'danger';

export type ModalHeaderProps = {
  icon?: ReactNode;
  text?: string;
};

export type ModalProps = {
  isVisible: boolean;
  variant?: ModalVariant;
  header?: ModalHeaderProps;
  children?: ReactNode | ReactNode[];
  actions?: ReactNode[];
  contentFit?: boolean;
};

export function Modal({
  isVisible,
  variant,
  header,
  children,
  actions,
  contentFit,
}: ModalProps) {
  return isVisible ? (
    <Wrapper>
      <Content $contentFit={contentFit}>
        {header && (
          <Header $variant={variant}>
            {header.icon && <IconWrapper>{header.icon}</IconWrapper>}
            <HeaderText>{header.text}</HeaderText>
          </Header>
        )}
        {children && <Body>{children}</Body>}
        {actions && <Action>{actions}</Action>}
      </Content>
    </Wrapper>
  ) : null;
}
