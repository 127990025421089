import { ReactComponent as TroubleshootIcon } from '../../../../assets/img/icons/troubleshoot-icon.svg';
import { Button } from '../../../../components/warehouse/Button';
import { Container } from '../../../../components/warehouse/Container';
import { RetailUnit } from '../../../../components/warehouse/RetailUnit';
import { pluralise } from '../../../../utils/pluralise';
import { CorePackAndDispatchProps } from '../../PackAndDispatch.model';
import { PackingContainer } from '../PackingContainer';
import { ItemListContainer } from './CheckItems.elements';
import { getTotalQuantity } from './CheckItems.methods';

export const CheckItems = (props: CorePackAndDispatchProps): JSX.Element => {
  const totalItems = getTotalQuantity(props.items);

  return (
    <PackingContainer
      step={1}
      stickyFooter={[
        <Button
          variant="warning"
          icon={<TroubleshootIcon />}
          onClick={props.onCannotPackOrderBtnClick}
        >
          Cannot pack order
        </Button>,
        <Button onClick={props.onComplete} testId="check-complete-button">
          Check complete
        </Button>,
      ]}
      title={`Check ${pluralise('item', totalItems)}`}
      orderNumber={props.orderNumber}
      recipient={props.recipient}
      merchantName={props.merchantName}
    >
      <Container padding="none">
        <ItemListContainer>
          {props.items.map((item, index) => (
            <RetailUnit
              key={index}
              item={item}
              hasError={false}
              showQuantity={true}
              variant="secondary"
            />
          ))}
        </ItemListContainer>
      </Container>
    </PackingContainer>
  );
};
