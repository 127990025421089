import styled from 'styled-components';

export const GeneratingLabelWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 157px;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const ErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

export const PrintLabelButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const TroubleshootButtonsWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 16px;
`;
