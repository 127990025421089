import { PaperVariantType } from 'api-schema/lib/model/shortPickTroubleshoot';
import { ReactElement } from 'react';
import { ReactComponent as TroubleshootIcon } from '../../../../assets/img/icons/troubleshoot-icon.svg';
import { Button } from '../../../../components/warehouse/Button';
import { List } from '../../../../components/warehouse/List';
import { Modal } from '../../../../components/warehouse/Modal';

export type PaperTroubleshootModalProps = {
  paperVariant: PaperVariantType;
  closeModal: () => void;
  noPaperAvailable: () => void;
  otherIssue: () => void;
};

export const PaperTroubleshootModal = ({
  paperVariant,
  closeModal,
  noPaperAvailable,
  otherIssue,
}: PaperTroubleshootModalProps): ReactElement => (
  <Modal
    isVisible
    header={{ text: 'Troubleshoot', icon: <TroubleshootIcon /> }}
    variant="warning"
    actions={[
      <Button fullWidth variant="secondary" onClick={closeModal}>
        Cancel
      </Button>,
    ]}
  >
    <List
      items={[
        {
          text: `No ${paperVariant} paper available`,
          onClick: noPaperAvailable,
        },
        { text: 'Other', onClick: otherIssue },
      ]}
    />
  </Modal>
);
