"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.systemEventSchema = exports.eventSkippedSchema = void 0;
const zod_1 = require("zod");
const base_1 = require("./base");
exports.eventSkippedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('EVENT_SKIPPED'),
    payload: zod_1.z.object({
        skippedEventId: zod_1.z.number().int(),
        skippedEventType: zod_1.z.string(),
        reason: zod_1.z.union([zod_1.z.literal('INCIDENT_RECOVERY'), zod_1.z.literal('OTHER')]),
        user: zod_1.z.string().email(),
    }),
});
exports.systemEventSchema = exports.eventSkippedSchema; // change to z.union later
