import { PaperVariantType } from 'api-schema/lib/model/shortPickTroubleshoot';
import styled, { css } from 'styled-components';

type Props = {
  $variant: PaperVariantType;
};

export const Body = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ColouredText = styled.span<Props>`
  ${({ $variant, theme }) => {
    switch ($variant) {
      case 'green':
        return css`
          color: #007d58;
        `;
      case 'red':
        return css`
          color: ${theme.palette.danger.main};
        `;
      default:
        return css`
          color: ${theme.palette.text.primary};
        `;
    }
  }};
`;
