import { Box } from '@material-ui/core';
import styled from 'styled-components';

type ContainerProps = {
  $active?: boolean;
};

type PartitionBoxProps = {
  $target?: boolean;
  $numPartitions: 1 | 2 | 4 | 8;
};
export const Container = styled(Box)<ContainerProps>`
  background-color: ${({ theme, $active }) =>
    $active ? theme.palette.primary.main : theme.palette.primary.light};
  border-radius: 6px;
  cursor: pointer;
`;

export const GuideContainer = styled(Box)<ContainerProps>`
  padding: ${({ theme }) => theme.spacing(2)}px;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ theme, $active }) =>
    $active ? '#FFF' : theme.palette.primary.main};
`;

export const Circle = styled(Box)<ContainerProps>`
  border-width: 2px;
  border-style: solid;
  border-color: ${({ theme, $active }) =>
    $active ? '#FFF' : theme.palette.primary.main};
  border-radius: 100%;
`;

export const InnerCircle = styled.div`
  background-color: white;
  border-radius: 100%;
  margin: -${({ theme }) => theme.spacing(2)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const PartitionBox = styled.div<PartitionBoxProps>`
  background-color: ${({ theme, $target: target }) =>
    target ? theme.palette.primary.main : theme.palette.primary.light};
  color: white;
  font-size: larger;
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
