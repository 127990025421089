import { ReactElement, useState } from 'react';
import { usePickPortSocket } from '../../../../components/common/SocketProvider';
import { Button } from '../../../../components/warehouse/Button';
import { Container } from '../../../../components/warehouse/Container';
import { Text } from './RetryCloseBin.elements';

export function RetryCloseBin(): ReactElement {
  const { dispatchCommand } = usePickPortSocket();
  const [showError, setShowError] = useState(false);

  const handleRetryCloseBinClick = async () => {
    const command = await dispatchCommand({
      type: 'RETRY_CLOSE_BIN',
    });
    const isError = command.result.outcome !== 'SUCCESS';
    if (isError) {
      setShowError(true);
    }
  };

  return (
    <Container padding="none" alignCenter>
      <Text data-testid="place-ports-text">
        There was an error closing the bin.
      </Text>
      {showError && (
        <Text>
          There was an error when trying to close the bin. Please contact
          support.
        </Text>
      )}
      <Button variant="primary" onClick={handleRetryCloseBinClick}>
        Try close bin again
      </Button>
    </Container>
  );
}
