"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allInventoryBodySchema = exports.inventoryResponseBodySchema = void 0;
const zod_http_schemas_1 = require("zod-http-schemas");
const model_1 = require("../model");
exports.inventoryResponseBodySchema = zod_http_schemas_1.z.union([
    zod_http_schemas_1.z.object({
        // TODO(FCG-143) update outcome
        outcome: zod_http_schemas_1.z.literal('SUCCESS'),
        retailUnitsWithInventory: zod_http_schemas_1.z.array(model_1.retailUnitsWithInventorySchema),
    }),
    zod_http_schemas_1.z.object({
        // TODO(FCG-143) update outcome
        outcome: zod_http_schemas_1.z.literal('ERROR'),
    }),
]);
exports.allInventoryBodySchema = zod_http_schemas_1.z.object({
    allocated: zod_http_schemas_1.z.record(zod_http_schemas_1.z.string(), zod_http_schemas_1.z.union([zod_http_schemas_1.z.number(), zod_http_schemas_1.z.nan()])),
    incoming: zod_http_schemas_1.z.record(zod_http_schemas_1.z.string(), zod_http_schemas_1.z.union([zod_http_schemas_1.z.number(), zod_http_schemas_1.z.nan()])),
    missing: zod_http_schemas_1.z.record(zod_http_schemas_1.z.string(), zod_http_schemas_1.z.union([zod_http_schemas_1.z.number(), zod_http_schemas_1.z.nan()])),
    picked: zod_http_schemas_1.z.record(zod_http_schemas_1.z.string(), zod_http_schemas_1.z.union([zod_http_schemas_1.z.number(), zod_http_schemas_1.z.nan()])),
});
