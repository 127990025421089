"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetCmcGenesysConfigResponseBody = exports.FinishCmcGenesysParcelRequestBody = exports.LabelCmcGenesysParcelRequestBody = exports.ExitCmcGenesysToteRequestBody = exports.MeasureCmcGenesysParcelRequestBody = exports.CmcMeasurements = void 0;
const zod_http_schemas_1 = require("zod-http-schemas");
const cmcGenesys_1 = require("../model/cmcGenesys");
exports.CmcMeasurements = zod_http_schemas_1.z.object({
    widthMm: zod_http_schemas_1.z.number().nonnegative(),
    heightMm: zod_http_schemas_1.z.number().nonnegative(),
    lengthMm: zod_http_schemas_1.z.number().nonnegative(),
    totalWeightGrams: zod_http_schemas_1.z.number().positive(),
    cartonWeightGrams: zod_http_schemas_1.z.number().positive(),
});
exports.MeasureCmcGenesysParcelRequestBody = zod_http_schemas_1.z.object({
    rejectionReasonCode: zod_http_schemas_1.z.enum(['0', '1', '2', '3', '4', '5', '6']),
    measurements: exports.CmcMeasurements,
});
exports.ExitCmcGenesysToteRequestBody = zod_http_schemas_1.z.object({
    result: zod_http_schemas_1.z.enum(['0', '1', '2', '3', '4', '5']),
    /*
      0 = WRONG ID / Varytote without tracking
      1 = Varytote Empty (Regular processed)
      2 = Varytote not Empty
      3 = Check on the Outbound disabled (no check if empty)
      4 = Varytote affected by error (see ACK error list) It will be sent to Fix Station (if option is enabled)
      5 = undocumented
    */
});
exports.LabelCmcGenesysParcelRequestBody = zod_http_schemas_1.z.object({
    statusFlag: zod_http_schemas_1.z.enum(['0', '1']),
    event: zod_http_schemas_1.z.string(), // see constants/cmcGenesys.ts
});
exports.FinishCmcGenesysParcelRequestBody = zod_http_schemas_1.z.object({
    cmcStatusCode: zod_http_schemas_1.z.string(),
    cmcEventCode: zod_http_schemas_1.z.string(), // see constants/cmcGenesys.ts
});
exports.GetCmcGenesysConfigResponseBody = cmcGenesys_1.cmcGenesysConfigSchema.array();
