import styled, {
  css,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';
import { ChipBase } from '../Chip/Chip.elements';

export const chipVariants = ['numberInput', 'scan'] as const;

export type ChipVariant = (typeof chipVariants)[number];

export type WrapperProps = {
  $variant: ChipVariant;
};

const colourMap = (
  variant: WrapperProps['$variant']
): FlattenInterpolation<ThemeProps<any>> => {
  switch (variant) {
    case 'scan':
      return css`
        background-color: ${({ theme }) => theme.palette.orangeYellow.main};
        color: ${({ theme }) => theme.palette.orangeYellow.contrastText};
      `;
    default:
      return css`
        background-color: ${({ theme }) => theme.palette.metallicBronze.main};
        color: ${({ theme }) => theme.palette.metallicBronze.contrastText};
      `;
  }
};

export const Wrapper = styled(ChipBase)<WrapperProps>`
  ${({ $variant }) => colourMap($variant)};
`;
