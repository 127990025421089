import { Box } from '@material-ui/core';
import {
  PartitionsPerBin,
  PartitionWithRetailUnit,
  PutawayPort,
  RetailUnit,
} from 'api-schema';
import {
  PortBinStateType,
  PutawayPortWithCurrentBin,
} from 'api-schema/lib/model';
import { FeatureFlags } from 'api-schema/lib/model/featureFlags';
import { Loadable } from '../../../elements/admin/Loadable';
import { InterruptState } from '../../../store/reducer';
import { ScanTransfer } from '../ScanTransfer';
import { BinSectionController } from './components/BinSectionController';
import { PutawayPanel } from './components/PutawayPanel/PutawayPanel';
import { Summary } from './components/Summary';
import { TransferDetails } from './components/TransferDetails';

export const shouldUseNumberInput = (portState: PutawayPort) => {
  return portState.putawayMode === 'NUMBER_INPUT';
};

type Props = {
  portState?: PutawayPortWithCurrentBin;
  featureFlags?: FeatureFlags;
  interruptState: InterruptState;
  nextBinPartitions: PartitionsPerBin;
  activeBinPartitions: PartitionsPerBin;
  targetPartition: number;
  activeProduct?: RetailUnit;
  activeBarcode?: string;
  hasActiveTransfer: boolean;
  onCheckBinEmptiness: (isEmpty: boolean) => void;
  onDoSwapBin: () => void;
  onCancelSwapBin: () => void;
  setNextBinPartitions: (partitions: PartitionsPerBin) => void;
  onSetBinConfiguration: () => Promise<void>;
  onCheckBinHasArrived: () => void;
  isManualBinArrivedEnabled: boolean;
  onPartitionClick: (partition: number) => void;
  onSwapBin: () => void;
  setScanInputEl: (ref: HTMLInputElement | undefined) => void;
  onCompletePutaway: () => void;
  onChangeItem: (type: 'ADD_PUTAWAY_ITEM' | 'REMOVE_PUTAWAY_ITEM') => void;
  onBarcodeChange: (value: string) => void;
  retailUnitBarcode: string;
  onClearBarcode: () => void;
  partitions?: PartitionWithRetailUnit[];
  binWeight: number;
  isShowingSummary: boolean;
  onConfirmCompleteTransfer: () => void;
  onCancelCompleteTransfer: () => void;
  onSetItemQuantity: (quantity: number) => void;
  onBarcodeFullyScanned: () => void;
  lastBin?: PortBinStateType;
};

export const PutawayTransferView = ({
  portState,
  interruptState,
  onCheckBinEmptiness,
  onCancelSwapBin,
  onDoSwapBin,
  nextBinPartitions,
  setNextBinPartitions,
  onSetBinConfiguration,
  onCheckBinHasArrived,
  isManualBinArrivedEnabled,
  activeBinPartitions,
  targetPartition,
  onPartitionClick,
  onSwapBin,
  setScanInputEl,
  onCompletePutaway,
  activeProduct,
  onBarcodeChange,
  onChangeItem,
  retailUnitBarcode,
  partitions,
  onClearBarcode,
  binWeight,
  isShowingSummary,
  onConfirmCompleteTransfer,
  onCancelCompleteTransfer,
  hasActiveTransfer,
  onSetItemQuantity,
  onBarcodeFullyScanned,
  lastBin,
  featureFlags,
}: Props) => {
  if (!portState) {
    return (
      <div style={{ display: 'inline-block', textAlign: 'center' }}>
        <Loadable isLoading={!portState} />
      </div>
    );
  }
  const { currentTransfer, currentBin, status, portId } = portState;
  const selectedPartition = currentBin?.partitions?.find(
    (p) => p.partitionNumber === targetPartition
  );
  const targetTransferItem =
    selectedPartition?.retailUnit?.barcode || retailUnitBarcode;

  const useNumberInput = shouldUseNumberInput(portState);

  return (
    <>
      <PutawayPanel
        currentTransfer={currentTransfer}
        putawayMode={portState?.putawayMode}
        featureFlags={featureFlags}
        portId={portId}
      />
      {/*
        TODO: the 'flow' management of everything here needs fixing.
        Got flow in isShowingSummary, interrupt state, port status, and
        if currentTransfer exists. It's way too much and spread out.
      */}
      {isShowingSummary ? (
        <Summary
          currentTransfer={currentTransfer}
          onCancelCompletePutaway={onCancelCompleteTransfer}
          onConfirmCompletePutaway={onConfirmCompleteTransfer}
          lastBin={lastBin}
          useNumberInput={useNumberInput}
        />
      ) : (
        <Box display="flex">
          <Box flex="1 0" mx={1}>
            <BinSectionController
              status={status}
              activeBinPartitions={activeBinPartitions}
              setNextBinPartitions={setNextBinPartitions}
              nextBinPartitions={nextBinPartitions}
              onSetBinConfiguration={onSetBinConfiguration}
              onCheckBinHasArrived={onCheckBinHasArrived}
              isManualBinArrivedEnabled={isManualBinArrivedEnabled}
              currentBin={currentBin}
              interruptState={interruptState}
              targetPartition={targetPartition}
              retailUnitBarcode={retailUnitBarcode}
              onBarcodeChange={onBarcodeChange}
              onCancelSwapBin={onCancelSwapBin}
              onDoSwapBin={onDoSwapBin}
              onSwapBin={onSwapBin}
              onCheckBinEmptiness={onCheckBinEmptiness}
              onChangeItem={onChangeItem}
              setScanInputEl={setScanInputEl}
              activeProduct={activeProduct}
              onPartitionClick={onPartitionClick}
              onClearBarcode={onClearBarcode}
              partitions={partitions}
              binWeight={binWeight}
              hasActiveTransfer={hasActiveTransfer}
              onSetItemQuantity={onSetItemQuantity}
              onBarcodeFullyScanned={onBarcodeFullyScanned}
              useNumberInput={useNumberInput}
            />
          </Box>
          <Box flex="1 0" mx={1}>
            {currentTransfer ? (
              <TransferDetails
                transfer={currentTransfer}
                disabled={status === 'AWAITING_BIN'}
                onCompletePutaway={onCompletePutaway}
                targetTransferItem={targetTransferItem}
                useNumberInput={useNumberInput}
                currentBin={lastBin}
              />
            ) : (
              <ScanTransfer />
            )}
          </Box>
        </Box>
      )}
    </>
  );
};
