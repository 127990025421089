import { Box, Divider, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { PartitionsPerBin } from 'api-schema';
import { Button } from '../../../../../components/admin/Button';
import { Container } from '../../../../../components/admin/Container';
import { BinRadio } from '../BinRadio';

export type Props = {
  nextBinPartitions: PartitionsPerBin;
  setNextBinPartitions: (partitions: PartitionsPerBin) => void;
  onSetBinConfiguration: () => Promise<void>;
  partitions: PartitionsPerBin[];
};

export const BinConfigSelectorView = ({
  nextBinPartitions,
  setNextBinPartitions,
  onSetBinConfiguration,
  partitions,
}: Props) => {
  return (
    <>
      <Container>
        <Typography>Bin partitions</Typography>
        <Divider />
        <Box display="grid" gridTemplateColumns="1fr 1fr">
          {partitions.map((partition) => (
            <BinRadio
              key={partition}
              active={nextBinPartitions === partition}
              numPartitions={partition}
              onClick={() => setNextBinPartitions(partition)}
            />
          ))}
        </Box>
      </Container>
      <Box>
        <Button
          fullWidth
          variant="heavy"
          icon={Check}
          alignIcon="left"
          onClick={onSetBinConfiguration}
          data-testid="set-bin-config"
        >
          Set bin configuration
        </Button>
      </Box>
    </>
  );
};
