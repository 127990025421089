import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { FC } from 'react';
import { isNotErrorResponse } from '../../../apiClient';
import { SnackbarDefaults } from '../../../constants/snackbarDefaults';
import { useApiClient } from '../../../hooks/useApiClient';
import { useWarehousesApi } from '../../../hooks/useWarehousesApi';
import { useAppState } from '../../../store';
import { transformZodErrorsToFormik } from '../../../utils/forms/transformZodErrorsToFormik';
import { pluralise } from '../../../utils/pluralise';
import { AddAutostoreView } from './AddAutostore.View';
import { AddAutostoreFormSchema, AddAutostoreFormType } from './model';

export const AddAutostore: FC = () => {
  const {
    appState: { currentWarehouse: warehouse },
  } = useAppState();

  const [, refreshWarehouses] = useWarehousesApi();
  const { enqueueSnackbar } = useSnackbar();
  const apiClient = useApiClient();

  const createAutostore = async (formData: AddAutostoreFormType) => {
    if (!warehouse?.id) {
      throw new Error('Cannot intergate autostore, warehouse id not found');
    }
    const { data, status } = await apiClient.post(
      '/warehouses/:id/devices/autostore',
      {
        params: { id: warehouse?.id },
        body: formData,
      }
    );
    if (isNotErrorResponse(data, status)) {
      refreshWarehouses();
      return data;
    }
    throw new Error('Failed to integrate autostore');
  };

  const formik = useFormik<AddAutostoreFormType>({
    initialValues: { networkAddress: '' },
    onSubmit: async (formData) => {
      try {
        const result = await createAutostore(formData);
        enqueueSnackbar(
          `Successfully integrated Autostore. Added ${pluralise(
            'bin',
            result.numberOfBinsFound
          )} and found ${pluralise('port', result.numberOfPortsFound)}.`,
          SnackbarDefaults
        );
      } catch (e) {
        if (!(e instanceof Error)) {
          return;
        }
        console.error(e);
        enqueueSnackbar(e.message, { ...SnackbarDefaults, variant: 'error' });
      }
    },
    validate: transformZodErrorsToFormik<AddAutostoreFormType>(
      AddAutostoreFormSchema
    ),
  });
  return <AddAutostoreView {...formik} warehouse={warehouse} />;
};
