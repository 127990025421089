import { Box } from '../../../../components/warehouse/Box';
import { Button } from '../../../../components/warehouse/Button';
import { Modal } from '../../../../components/warehouse/Modal';
import { Text } from '../../../../components/warehouse/Text';
import { ButtonWrapper } from './ConfirmSendToteToManualPackModal.elements';

export interface ConfirmSendToteToManualPackModalProps {
  isVisible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const ConfirmSendToteToManualPackModal = ({
  isVisible,
  onCancel,
  onConfirm,
}: ConfirmSendToteToManualPackModalProps): JSX.Element => {
  return (
    <Modal
      isVisible={isVisible}
      actions={[
        <ButtonWrapper key="cancel-btn-wrapper">
          <Button
            variant="secondary"
            fullWidth
            onClick={onCancel}
            testId="cancel-send-tote-to-manual-pack-button"
          >
            Cancel
          </Button>
        </ButtonWrapper>,
        <ButtonWrapper key="confirm-btn-wrapper">
          <Button
            fullWidth
            onClick={onConfirm}
            testId="send-to-manual-pack-button"
          >
            Yes, proceed
          </Button>
        </ButtonWrapper>,
      ]}
    >
      <Box padding="24px">
        <Text variant="h1" weight="medium">
          Proceed with sending this tote to the manual packing area?
        </Text>
      </Box>
    </Modal>
  );
};
