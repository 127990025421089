import { ReactNode } from 'react';
import { Stepper } from '../Stepper';
import { FooterButtonAlignmentType, StickyContainer } from '../StickyContainer';

import {
  Content,
  HeaderWrapper,
  SideTextContainer,
  StationContainerBody,
  StationContainerWrapper,
  StepperWrapper,
  SubTitle,
  TitleContainer,
} from './StationContainer.elements';

export interface StationContainerProps {
  stepper?: {
    steps: string[];
    currentStep: number;
  };
  children: ReactNode;
  stickyFooter?: ReactNode[];
  title: string | ReactNode;
  subTitle?: string;
  sideText?: ReactNode;
  footerButtonAlignType?: FooterButtonAlignmentType;
}

export const StationContainer = ({
  children,
  stepper,
  stickyFooter,
  title,
  subTitle,
  sideText,
  footerButtonAlignType,
}: StationContainerProps): JSX.Element => {
  return (
    <StationContainerWrapper>
      <StickyContainer
        stickyFooter={stickyFooter}
        footerButtonAlignType={footerButtonAlignType}
      >
        <StationContainerBody>
          {stepper && (
            <StepperWrapper>
              <Stepper
                steps={stepper.steps}
                currentStep={stepper.currentStep}
              />
            </StepperWrapper>
          )}
          <HeaderWrapper>
            <TitleContainer id="containerTitle">
              {title}
              {subTitle && <SubTitle>{subTitle}</SubTitle>}
            </TitleContainer>
            {sideText && <SideTextContainer>{sideText}</SideTextContainer>}
          </HeaderWrapper>
          <Content>{children}</Content>
        </StationContainerBody>
      </StickyContainer>
    </StationContainerWrapper>
  );
};
