import React, { ReactElement, useEffect, useState } from 'react';
import { CSSProperties } from 'styled-components';
import {
  AlertMessage,
  AlertMessageTypes,
} from '../../../../components/warehouse/AlertMessage';
import { Button } from '../../../../components/warehouse/Button';
import { Container } from '../../../../components/warehouse/Container';
import { Spinner } from '../../../../components/warehouse/Spinner';
import { CHECK_BIN_HAS_ARRIVED_MESSAGE } from '../../PickStation/PickStation';
import { Wrapper } from '../PickPortError/PickPortError.elements';
import { Message } from './Loading.elements';

export interface LoadingProps {
  message: string;
  flexFlow?: CSSProperties['flexFlow'];
  onCheckBinHasArrived?: () => void;
  alertMessage?: React.ReactNode;
  alertMessageType?: AlertMessageTypes;
  isManualBinArrivedEnabled?: boolean;
}

export function Loading({
  message,
  flexFlow,
  onCheckBinHasArrived,
  alertMessage,
  alertMessageType,
  isManualBinArrivedEnabled,
}: LoadingProps): ReactElement {
  const [isButtonVisible, setButtonVisibility] = useState(false);
  const DISPLAY_CHECK_BIN_BUTTON_TIMEOUT_MS = 60000;

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setButtonVisibility(true);
    }, DISPLAY_CHECK_BIN_BUTTON_TIMEOUT_MS);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  if (onCheckBinHasArrived && alertMessage && alertMessageType) {
    const handleCLick = () => {
      setButtonVisibility(false);
      onCheckBinHasArrived();
    };
    return (
      <Container alignCenter flexFlow={flexFlow}>
        <Spinner />
        <Message data-testid="loading-message">{message}</Message>
        {alertMessage && (
          <AlertMessage type={alertMessageType}>{alertMessage}</AlertMessage>
        )}
        {isButtonVisible && isManualBinArrivedEnabled && (
          <Wrapper>
            <AlertMessage type={AlertMessageTypes.Warning}>
              {CHECK_BIN_HAS_ARRIVED_MESSAGE}
            </AlertMessage>
            <Button variant="primary" onClick={handleCLick}>
              Confirm bin has arrived
            </Button>
          </Wrapper>
        )}
      </Container>
    );
  }

  return (
    <Container alignCenter flexFlow={flexFlow}>
      <Spinner />
      <Message data-testid="loading-message">{message}</Message>
    </Container>
  );
}
