"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.intermediaryStationCommandOutcomeSchema = exports.sendToteToManualPackOutcomeSchema = exports.confirmToteAdjustmentOutcomeSchema = exports.adjustIntermediaryStationToteOutcomeSchema = exports.closeIntermediaryStationOutcomeSchema = exports.openIntermediaryStationOutcomeSchema = exports.unableToUpdateToteZoneOutcomeSchema = exports.unableToCompleteToteZoneOutcomeSchema = exports.toteNotOnConveyorOutcomeSchema = exports.unableToGetConveyorBayStateOutcomeSchema = exports.unableToGetConveyorToteStateOutcomeSchema = exports.conveyorClientErrorOutcome = exports.unexpectedToteAtIntermediaryStationOutcomeSchema = exports.noFulfilmentOrderAtIntermediaryStationOutcomeSchema = exports.noToteAtIntermediaryStationOutcomeSchema = exports.invalidIntermediaryStationStatusOutcomeSchema = void 0;
const zod_1 = require("zod");
const generic_1 = require("./generic");
const intermediaryStationOutcomeStatuses = zod_1.z.enum([
    'CLOSED',
    'AWAITING_TOTE',
    'ADJUSTING_TOTE',
    'AWAITING_ADJUSTMENT_CONFIRMATION',
]);
const intermediaryStationNotFoundOutcomeSchema = zod_1.z.object({
    outcome: zod_1.z.literal('INTERMEDIARY_STATION_NOT_FOUND'),
    stationId: zod_1.z.number(),
});
exports.invalidIntermediaryStationStatusOutcomeSchema = zod_1.z.object({
    outcome: zod_1.z.literal('INVALID_INTERMEDIARY_STATION_STATUS'),
    expectedStatuses: intermediaryStationOutcomeStatuses.array(),
    actualStatus: intermediaryStationOutcomeStatuses,
});
exports.noToteAtIntermediaryStationOutcomeSchema = zod_1.z.object({
    outcome: zod_1.z.literal('NO_TOTE_AT_INTERMEDIARY_STATION'),
    stationId: zod_1.z.number(),
});
exports.noFulfilmentOrderAtIntermediaryStationOutcomeSchema = zod_1.z.object({
    outcome: zod_1.z.literal('NO_FULFILMENT_ORDER_AT_INTERMEDIARY_STATION'),
    stationId: zod_1.z.number(),
});
exports.unexpectedToteAtIntermediaryStationOutcomeSchema = zod_1.z.object({
    outcome: zod_1.z.literal('UNEXPECTED_TOTE_AT_INTERMEDIARY_STATION'),
    expectedToteId: zod_1.z.string(),
    actualToteId: zod_1.z.string(),
    stationId: zod_1.z.number(),
});
exports.conveyorClientErrorOutcome = zod_1.z.enum([
    'EXCEPTION',
    'NOT_FOUND',
    'PORT_ZONE_NOT_FOUND',
    'PORT_BAYS_NOT_FOUND',
]);
exports.unableToGetConveyorToteStateOutcomeSchema = zod_1.z.object({
    outcome: zod_1.z.literal('UNABLE_TO_GET_CONVEYOR_TOTE_STATE'),
    toteId: zod_1.z.string(),
    conveyorClientErrorOutcome: zod_1.z.object({
        outcome: exports.conveyorClientErrorOutcome,
        error: zod_1.z.string().optional(),
    }),
});
exports.unableToGetConveyorBayStateOutcomeSchema = zod_1.z.object({
    outcome: zod_1.z.literal('UNABLE_TO_GET_CONVEYOR_BAY_STATE'),
    toteId: zod_1.z.string(),
    bayId: zod_1.z.string(),
    conveyorClientErrorOutcome: zod_1.z.object({
        outcome: exports.conveyorClientErrorOutcome,
        error: zod_1.z.string().optional(),
    }),
});
exports.toteNotOnConveyorOutcomeSchema = zod_1.z.object({
    outcome: zod_1.z.literal('TOTE_NOT_ON_CONVEYOR'),
    toteId: zod_1.z.string(),
});
exports.unableToCompleteToteZoneOutcomeSchema = zod_1.z.object({
    outcome: zod_1.z.literal('UNABLE_TO_COMPLETE_TOTE_ZONE'),
    toteId: zod_1.z.string(),
    zoneId: zod_1.z.string(),
    jobId: zod_1.z.string(),
    conveyorClientErrorOutcome: zod_1.z.object({
        outcome: exports.conveyorClientErrorOutcome,
        error: zod_1.z.string().optional(),
    }),
});
exports.unableToUpdateToteZoneOutcomeSchema = zod_1.z.object({
    outcome: zod_1.z.literal('UNABLE_TO_UPDATE_TOTE_ZONE'),
    toteId: zod_1.z.string(),
    zoneId: zod_1.z.string(),
    jobId: zod_1.z.string(),
    conveyorClientErrorOutcome: zod_1.z.object({
        outcome: exports.conveyorClientErrorOutcome,
        error: zod_1.z.string().optional(),
    }),
});
exports.openIntermediaryStationOutcomeSchema = zod_1.z.discriminatedUnion('outcome', [
    generic_1.Success,
    intermediaryStationNotFoundOutcomeSchema,
    exports.invalidIntermediaryStationStatusOutcomeSchema,
]);
exports.closeIntermediaryStationOutcomeSchema = zod_1.z.discriminatedUnion('outcome', [generic_1.Success, intermediaryStationNotFoundOutcomeSchema]);
exports.adjustIntermediaryStationToteOutcomeSchema = zod_1.z.discriminatedUnion('outcome', [
    generic_1.Success,
    intermediaryStationNotFoundOutcomeSchema,
    exports.invalidIntermediaryStationStatusOutcomeSchema,
    exports.noToteAtIntermediaryStationOutcomeSchema,
    exports.noFulfilmentOrderAtIntermediaryStationOutcomeSchema,
    exports.unexpectedToteAtIntermediaryStationOutcomeSchema,
    exports.unableToGetConveyorToteStateOutcomeSchema,
    exports.toteNotOnConveyorOutcomeSchema,
]);
exports.confirmToteAdjustmentOutcomeSchema = zod_1.z.discriminatedUnion('outcome', [
    generic_1.Success,
    intermediaryStationNotFoundOutcomeSchema,
    exports.invalidIntermediaryStationStatusOutcomeSchema,
    exports.toteNotOnConveyorOutcomeSchema,
    exports.unableToGetConveyorToteStateOutcomeSchema,
    exports.unableToGetConveyorBayStateOutcomeSchema,
    exports.unexpectedToteAtIntermediaryStationOutcomeSchema,
    exports.noFulfilmentOrderAtIntermediaryStationOutcomeSchema,
    exports.noToteAtIntermediaryStationOutcomeSchema,
    exports.unableToCompleteToteZoneOutcomeSchema,
]);
exports.sendToteToManualPackOutcomeSchema = zod_1.z.discriminatedUnion('outcome', [
    generic_1.Success,
    intermediaryStationNotFoundOutcomeSchema,
    exports.toteNotOnConveyorOutcomeSchema,
    exports.noFulfilmentOrderAtIntermediaryStationOutcomeSchema,
    exports.noToteAtIntermediaryStationOutcomeSchema,
    exports.unableToGetConveyorToteStateOutcomeSchema,
    exports.unableToUpdateToteZoneOutcomeSchema,
]);
exports.intermediaryStationCommandOutcomeSchema = zod_1.z.union([
    exports.openIntermediaryStationOutcomeSchema,
    exports.closeIntermediaryStationOutcomeSchema,
    exports.adjustIntermediaryStationToteOutcomeSchema,
    exports.confirmToteAdjustmentOutcomeSchema,
    exports.sendToteToManualPackOutcomeSchema,
]);
