import styled from 'styled-components';

interface StickyElementWrapperProps {
  $buttonAlignmentType?: 'space-between' | 'left' | 'right' | 'center';
}

export const StickyContainerWrapper = styled.div`
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const MainContent = styled.div`
  flex: 1;
  height: 100%;
  overflow-y: auto;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.grey[2]};
`;

export const StickyElementWrapper = styled.div<StickyElementWrapperProps>`
  display: flex;
  background-color: ${({ theme }) => theme.palette.grey[2]};
  padding: ${({ theme }) => `${theme.spacing(2)}`} 24px;

  justify-content: ${({ $buttonAlignmentType }) => {
    switch ($buttonAlignmentType) {
      case 'space-between':
        return 'space-between';
      case 'left':
        return 'start';
      case 'right':
        return 'end';
      case 'center':
        return 'center';
      default:
        return 'space-between';
    }
  }};

  button {
    height: 60px;
  }

  &.sticky {
    box-shadow: 0px -4px 4px 0px #0000000d;
  }
`;
