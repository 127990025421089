import styled, { css } from 'styled-components';
import { ModalVariant } from './Modal';

interface VariantProps {
  $variant?: ModalVariant;
}

export const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 0, 0, 0.4);
  z-index: ${({ theme }) => theme.zIndex.modalBackdrop};
`;

export const Content = styled.div<{ $contentFit?: boolean }>`
  ${(p) =>
    !p.$contentFit &&
    css`
      width: 736px;
      background-color: ${({ theme }) => theme.palette.background.paper};
    `}
  height: auto;
  max-height: calc(100% - ${({ theme }) => theme.spacing(8)});
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin: ${({ theme }) => theme.spacing(4)} 0;
  overflow: clip;
  z-index: ${({ theme }) => theme.zIndex.modal};
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
`;

export const Header = styled.div<VariantProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  ${({ $variant, theme }) => {
    switch ($variant) {
      case 'default':
        return css`
          background: ${theme.palette.grey[3]};
        `;
      case 'warning':
        return css`
          background: ${theme.palette.warning.main};
        `;
      case 'danger':
        return css`
          color: ${theme.palette.primary.contrastText};
          background: ${theme.palette.error.main};
        `;
      default:
        return css`
          background: ${theme.palette.grey[3]};
        `;
    }
  }};
`;

export const HeaderText = styled.span`
  display: inline-block;
  vertical-align: middle;
  ${({ theme }) => {
    return theme.currentFontSizeSet === 'large'
      ? css`
          font-size: ${({ theme }) => theme.typography.h3.fontSize};
          font-weight: ${({ theme }) => theme.typography.h3.fontWeight};
        `
      : css`
          font-size: ${({ theme }) => theme.typography.h1.fontSize};
          font-weight: ${({ theme }) => theme.typography.h1.fontWeight};
          line-height: ${({ theme }) => theme.typography.h1.lineHeight};
        `;
  }};
`;

export const IconWrapper = styled.div`
  ${({ theme }) => {
    return theme.currentFontSizeSet === 'large'
      ? css`
          width: 44px;
        `
      : css`
          width: 33px;
        `;
  }};

  display: flex;
  align-items: center;
  margin-right: 10px;

  svg {
    width: inherit;
    height: inherit;
  }

  img {
    width: inherit;
    height: inherit;
  }
`;

export const HeaderIcon = styled.img`
  margin-right: 10px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) =>
    theme.currentFontSizeSet === 'standard'
      ? `${theme.spacing(3)} ${theme.spacing(2)}`
      : theme.spacing(4)};
  width: 100%;
  overflow: auto;
`;

export const Action = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid ${({ theme }) => theme.palette.grey[5]};
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(2)};
`;
