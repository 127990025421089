import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useIntermediaryStationSocket } from '../../../components/common/SocketProvider/useSocket';
import { SnackbarDefaults } from '../../../constants/snackbarDefaults';
import { useAppState } from '../../../store';
import { ToteAdjustmentView } from './ToteAdjustment.view';

type ToteAdjustmentProps = {
  confirmToteAdjustmentModalVisible?: boolean;
};

export const ToteAdjustment = ({
  confirmToteAdjustmentModalVisible = false,
}: ToteAdjustmentProps) => {
  const { appState } = useAppState();
  const intermediaryStationState = appState.intermediaryStationState;
  const fulfilmentOrder = intermediaryStationState?.fulfilmentOrder;
  const insertStatus = fulfilmentOrder?.insertStatus;
  const customerName = fulfilmentOrder?.customer.name;
  const externalOrderReference = fulfilmentOrder?.externalOrderReference;
  const lineItems = fulfilmentOrder?.lineItems;
  // Get a sum of quantity from lineItems
  const itemCount = lineItems?.reduce((total, { quantity }) => {
    return total + quantity;
  }, 0);
  const merchantName = fulfilmentOrder?.merchantName;
  const fulfilmentOrderId = fulfilmentOrder?.id;
  const isInsertRequired = appState.intermediaryStationState?.isInsertRequired;
  const usePackingInsertButtons =
    appState.featureFlags?.showIntermediaryStationInsertButtons === true;
  const packingNote = appState.intermediaryStationState?.packingNote;

  const [
    showConfirmSendToteToManualPackModal,
    setShowConfirmSendToteToManualPackModal,
  ] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { dispatchCommand } = useIntermediaryStationSocket();
  const handleAdjustmentComplete = async () => {
    const toteId = intermediaryStationState?.toteId;
    if (!toteId) {
      enqueueSnackbar(
        'Unable to get Tote ID at intermediary station. Please refresh and try again, and contact support if issue persists',
        {
          ...SnackbarDefaults,
          variant: 'error',
        }
      );
      return;
    }
    await dispatchCommand({
      type: 'COMPLETE_TOTE_ADJUSTMENT',
      toteId,
    });
  };

  const handleToteAdjustmentConfirm = () => {
    const toteId = intermediaryStationState?.toteId;
    if (!toteId) {
      enqueueSnackbar(
        'Unable to get Tote ID at intermediary station. Please refresh and try again, and contact support if issue persists',
        {
          ...SnackbarDefaults,
          variant: 'error',
        }
      );
      return;
    }
    dispatchCommand({
      type: 'CONFIRM_TOTE_ADJUSTMENT',
      toteId,
    });
  };

  const sendToManualPack = async () => {
    const toteId = intermediaryStationState?.toteId;
    if (!toteId) {
      enqueueSnackbar(
        'Unable to get Tote ID at intermediary station. Please refresh and try again, and contact support if issue persists',
        {
          ...SnackbarDefaults,
          variant: 'error',
        }
      );
      return;
    }
    dispatchCommand({
      type: 'SEND_TOTE_TO_MANUAL_PACK',
      toteId,
    });
  };

  return (
    <ToteAdjustmentView
      customerName={customerName}
      externalOrderReference={externalOrderReference}
      itemCount={itemCount}
      merchantName={merchantName}
      fulfilmentOrderId={fulfilmentOrderId}
      isInsertRequired={!!isInsertRequired}
      insertStatus={insertStatus}
      packingNote={packingNote}
      shouldUseInsertButtons={usePackingInsertButtons}
      confirmToteAdjustmentModalVisible={confirmToteAdjustmentModalVisible}
      confirmSendToteToManualPackModalVisible={
        showConfirmSendToteToManualPackModal
      }
      handleToteAdjustmentConfirm={handleToteAdjustmentConfirm}
      handleAdjustmentComplete={handleAdjustmentComplete}
      pressSendToManualPack={() =>
        setShowConfirmSendToteToManualPackModal(true)
      }
      confirmSendToteToManualPack={sendToManualPack}
      cancelSendToManualPack={() =>
        setShowConfirmSendToteToManualPackModal(false)
      }
    />
  );
};
