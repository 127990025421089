import styled from 'styled-components';

interface FormRowProps {
  spacingBottom?: number;
  $justifyContent?: string;
  $responsive?: boolean;
}

export const FormRow = styled.div<FormRowProps>`
  ${({ theme, $responsive = true }) =>
    !$responsive ? theme.breakpoints.up('sm') : '@media screen'} {
    display: flex;

    > div {
      flex: 1;
    }

    // Any sibling (all children except first)
    > * + * {
      margin-left: ${({ theme }) => theme.spacing(2)}px;
    }
  }

  ${({ $justifyContent }) =>
    $justifyContent ? `justify-content: ${$justifyContent}` : null};

  ${({ spacingBottom = 1, theme }) =>
    spacingBottom ? `margin-bottom: ${theme.spacing(spacingBottom)}px` : null};
`;
