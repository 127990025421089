import { useEffect, useState } from 'react';
import { isNotErrorResponse } from '../apiClient';
import { useAppState } from '../store';
import { setIsAuthenticated, setWarehouses } from '../store/actions';
import { useApiClient } from './useApiClient';

export type LoadingStatus = 'LOADING' | 'READY';

type UseWarehousesReturn = [LoadingStatus, () => void];

export const useWarehousesApi = (): UseWarehousesReturn => {
  const { appDispatch } = useAppState();
  const apiClient = useApiClient();
  const [status, setStatus] = useState<LoadingStatus>('READY');

  const refreshWarehouses = async () => {
    if (status === 'LOADING') {
      return;
    }
    setStatus('LOADING');
    try {
      const { data, status } = await apiClient.get('/warehouses');
      if (isNotErrorResponse(data, status)) {
        appDispatch(setIsAuthenticated(true));
        appDispatch(setWarehouses(data.warehouses ?? []));
        setStatus('READY');
      }
    } catch (error) {
      // error displayed by apiClient
    }
  };

  useEffect(() => {
    refreshWarehouses();
    // Even with useCallback this was resulting in a loop when we add refreshWarehouses to the list of deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [status, refreshWarehouses];
};
