import {
  CommandResultMessage,
  CommandResultMessageType,
  CommandsType,
  PickDispatchCommandMessageType,
  PutawayDispatchCommandMessageType,
  SocketEventType,
  SocketType,
} from 'api-schema/lib/websocket';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { useInterval } from '../../../hooks/useInterval';
import { useAppState } from '../../../store';
import {
  setFeatureFlags,
  setIntermediaryStationState,
  setIsAuthenticated,
  setLabelTroubleshootState,
  setPackAndDispatchState,
  setPortState,
  setShortPickTroubleshootState,
  updateCurrentWarehouse,
} from '../../../store/actions';
import { createRandomString } from '../../../utils/random';
import {
  IntermediaryStationSocketContext,
  LabelTroubleshootSocketContext,
  PackAndDispatchSocketContext,
  PickSocketContext,
  PutawaySocketContext,
  ShortPickTroubleshootSocketContext,
  SubscriberCallback,
} from './context';

import { PackAndDispatchCommandsType } from 'api-schema/lib/commands/packAndDispatch';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { handleServerCommands } from '../../../utils/commands/handleServerCommands';
import { isNever } from '../../../utils/isNever';

type Props = {
  baseUrl: string;
  portId?: string;
  stationId?: string;
  warehouseId: string;
  intermediaryStationId?: string;
  providerType:
    | 'pick'
    | 'putaway'
    | 'packAndDispatch'
    | 'shortPickTroubleshoot'
    | 'labelTroubleshoot'
    | 'intermediaryStation';
  children: ReactNode;
};

type DispatchCommandMessageType =
  | PickDispatchCommandMessageType
  | PutawayDispatchCommandMessageType
  | PackAndDispatchCommandsType;

type CommandKey = string;
type EmitCommand = (sequenceId: number, socket: Socket) => CommandKey;

const getSocketType = (type: Props['providerType']): { type: SocketType } => {
  switch (type) {
    case 'pick':
      return { type: 'PICK_PORT' };
    case 'putaway':
      return { type: 'PUTAWAY_PORT' };
    case 'packAndDispatch':
      return { type: 'PACK_STATION' };
    case 'shortPickTroubleshoot':
      return { type: 'SHORT_PICK_TROUBLESHOOT_STATION' };
    case 'labelTroubleshoot':
      return { type: 'LABEL_TROUBLESHOOT_STATION' };
    case 'intermediaryStation':
      return { type: 'INTERMEDIARY_STATION' };
    default:
      return { type: 'PICK_PORT' };
  }
};

export const SocketProvider = <
  Command extends CommandsType,
  CommandResultMessage extends CommandResultMessageType,
  SocketEvent extends SocketEventType,
>({
  baseUrl,
  portId,
  stationId,
  warehouseId,
  intermediaryStationId,
  providerType,
  children,
}: Props) => {
  const [authToken] = useLocalStorage<string>('AUTH_TOKEN');
  const [socket, setSocket] = useState<Socket | undefined>();
  const [sequenceNumber, setSequenceNumber] = useState<number>(0);

  const { current: subscribers } = useRef<
    Map<Symbol, SubscriberCallback<SocketEvent>>
  >(new Map());
  const { current: commandPromiseMap } = useRef<
    Map<
      string,
      [(commandResult: CommandResultMessage) => void, (error: any) => void]
    >
  >(new Map());

  const { current: dispatchQueue } = useRef<Array<[CommandKey, EmitCommand]>>(
    []
  );

  const { appDispatch } = useAppState();

  useEffect(() => {
    const newSocket = io(baseUrl, {
      auth: {
        token: `Bearer ${authToken}`,
      },
      query: {
        warehouseId,
        ...(portId && { portId }),
        ...(stationId && { portId: stationId }),
        ...(intermediaryStationId && { intermediaryStationId }),
        ...getSocketType(providerType),
      },
    });

    newSocket.on('message', (message) => {
      appDispatch(setIsAuthenticated(true));
      if (message.serverCommandType) {
        handleServerCommands(message);
      }
      if (message.portState) {
        appDispatch(setPortState(message.portState));
      }
      if (message.state) {
        appDispatch(setPackAndDispatchState(message.state));
      }
      if (message.shortPickTroubleshootState) {
        appDispatch(
          setShortPickTroubleshootState(message.shortPickTroubleshootState)
        );
      }
      if (message.labelTroubleshootState) {
        appDispatch(setLabelTroubleshootState(message.labelTroubleshootState));
      }
      if (message.intermediaryStationState) {
        appDispatch(
          setIntermediaryStationState(message.intermediaryStationState)
        );
      }
      if (message.event) {
        subscribers.forEach((callback) => callback(message.event));
      }
      if (message.featureFlags) {
        appDispatch(setFeatureFlags(message.featureFlags));
      }

      if (message.currentWarehouse) {
        appDispatch(updateCurrentWarehouse(message.currentWarehouse));
      }

      if (message.sequenceNumber) {
        setSequenceNumber(() => message.sequenceNumber);

        handleDispatch(
          dispatchQueue,
          message.sequenceNumber,
          newSocket,
          commandPromiseMap
        );
      }

      if (message.commandKey) {
        if (commandPromiseMap.has(message.commandKey)) {
          const [resolve, reject] = commandPromiseMap.get(message.commandKey)!;
          const result = CommandResultMessage.safeParse(message);
          if (result.success) {
            // @ts-ignore
            // TODO(WMS-735): address orphaned todo
            // eslint-disable-next-line todo-plz/ticket-ref
            // TODO: remove ts-ignore and figure out the cause
            resolve(result.data);
          } else {
            reject(result.error);
          }
          commandPromiseMap.delete(message.commandKey);
        }
      }
    });

    setSocket(newSocket);
    return () => {
      newSocket.close();
    };
    // eslint-disable-next-line
  }, [portId, warehouseId, appDispatch, baseUrl, stationId, authToken]);

  const dispatchCommand = (command: Command) => {
    const commandKey = createRandomString(12);
    const promise: Promise<CommandResultMessage> = new Promise(
      (resolve, reject) => {
        commandPromiseMap.set(commandKey, [resolve, reject]);
      }
    );

    const emitCommand = getEmitCommand(commandKey, command);

    dispatchQueue.push([commandKey, emitCommand]);

    /*
      This will begin a chain of dispatching if there are no commands waiting to be settled that have began dispatching (this can be assumed if the commandPromiseMap is larger than the dispatchQueue)
      Otherwise it will wait for the command to be settled before dispatching the next command
    */

    const shouldDispatch = dispatchQueue.length === commandPromiseMap.size;
    if (shouldDispatch) {
      handleDispatch(dispatchQueue, sequenceNumber, socket, commandPromiseMap);
    }

    return promise;
  };

  // if undispatched commands build up (i.e. socket was undefined when they attempted dispatch) then this will try to dispatch them
  useInterval(() => {
    if (dispatchQueue.length === commandPromiseMap.size) {
      handleDispatch(dispatchQueue, sequenceNumber, socket, commandPromiseMap);
    }
  }, 100);

  const handleDispatch = (
    dispatchQueue: Array<[CommandKey, EmitCommand]>,
    sequenceNumber: number,
    socket: Socket | undefined,
    commandPromiseMapInstance: typeof commandPromiseMap
  ) => {
    const dispatchedCommandKey = dispatchFirstCommandInQueue(
      dispatchQueue,
      sequenceNumber,
      socket
    );
    if (dispatchedCommandKey) {
      enforcePromiseLifetime<typeof commandPromiseMapInstance>(
        dispatchedCommandKey,
        commandPromiseMapInstance,
        1000
      );
    }
  };

  const subscribeToEvents = (callback: any) => {
    const sym = Symbol();
    subscribers.set(sym, callback);
    return () => subscribers.delete(sym);
  };

  switch (providerType) {
    case 'pick':
      return (
        <PickSocketContext.Provider
          value={{
            // @ts-ignore
            // TODO(WMS-735): address orphaned todo
            // eslint-disable-next-line todo-plz/ticket-ref
            // TODO: remove ts-ignore and figure out the cause
            dispatchCommand,
            subscribeToEvents,
          }}
        >
          {children}
        </PickSocketContext.Provider>
      );
    case 'putaway':
      return (
        <PutawaySocketContext.Provider
          value={{
            // @ts-ignore
            // TODO(WMS-735): address orphaned todo
            // eslint-disable-next-line todo-plz/ticket-ref
            // TODO: remove ts-ignore and figure out the cause
            dispatchCommand,
            subscribeToEvents,
          }}
        >
          {children}
        </PutawaySocketContext.Provider>
      );
    case 'packAndDispatch':
      return (
        <PackAndDispatchSocketContext.Provider
          value={{
            // @ts-ignore
            // TODO(WMS-735): address orphaned todo
            // eslint-disable-next-line todo-plz/ticket-ref
            // TODO: remove ts-ignore and figure out the cause
            dispatchCommand,
            subscribeToEvents,
          }}
        >
          {children}
        </PackAndDispatchSocketContext.Provider>
      );

    case 'shortPickTroubleshoot':
      return (
        <ShortPickTroubleshootSocketContext.Provider
          value={{
            // @ts-ignore
            // TODO(WMS-735): address orphaned todo
            // eslint-disable-next-line todo-plz/ticket-ref
            // TODO: remove ts-ignore and figure out the cause
            dispatchCommand,
            subscribeToEvents,
          }}
        >
          {children}
        </ShortPickTroubleshootSocketContext.Provider>
      );

    case 'labelTroubleshoot':
      return (
        <LabelTroubleshootSocketContext.Provider
          value={{
            // @ts-ignore
            // TODO(WMS-735): address orphaned todo
            // eslint-disable-next-line todo-plz/ticket-ref
            // TODO: remove ts-ignore and figure out the cause
            dispatchCommand,
            subscribeToEvents,
          }}
        >
          {children}
        </LabelTroubleshootSocketContext.Provider>
      );

    case 'intermediaryStation':
      return (
        <IntermediaryStationSocketContext.Provider
          value={{
            // @ts-ignore
            // TODO(WMS-735): address orphaned todo
            // eslint-disable-next-line todo-plz/ticket-ref
            // TODO: remove ts-ignore and figure out the cause
            dispatchCommand,
            subscribeToEvents,
          }}
        >
          {children}
        </IntermediaryStationSocketContext.Provider>
      );
    default:
      return isNever(providerType);
  }
};

const getEmitCommand = (
  commandKey: string,
  command: CommandsType
): EmitCommand => {
  return (sequenceNumber, socket) => {
    const message: DispatchCommandMessageType = {
      commandKey,
      // @ts-ignore
      // TODO(WMS-735): address orphaned todo
      // eslint-disable-next-line todo-plz/ticket-ref
      // TODO: remove ts-ignore and figure out the cause
      command,
      sequenceNumber,
    };
    socket.emit('message', message);
    return commandKey;
  };
};

const dispatchFirstCommandInQueue = (
  dispatchQueue: Array<[CommandKey, EmitCommand]>,
  sequenceNumber: number,
  socket: Socket | undefined
): CommandKey | null => {
  if (!socket) {
    return null;
  }
  const nextDispatch = dispatchQueue.shift();

  if (!nextDispatch) {
    return null;
  }

  const [commandKey, command] = nextDispatch;
  command(sequenceNumber, socket);

  return commandKey;
};

// If a command is not settled within a certain amount of time it will be removed from the commandPromiseMap and rejected so that other commands be dispatched
const enforcePromiseLifetime = <M extends Map<string, any>>(
  commandKey: string,
  commandPromiseMap: M,
  timeout: number
) => {
  setTimeout(() => {
    if (!commandPromiseMap.has(commandKey)) {
      return;
    }
    const [reject] = commandPromiseMap.get(commandKey);
    reject(`Promise with ${commandKey}, did not settle within ${timeout}ms`);
    commandPromiseMap.delete(commandKey);
  }, timeout);
};
