import { transparentize } from 'polished';
import styled from 'styled-components';
import { ReactComponent as CheckMark } from '../../../../assets/img/icons/check-mark.svg';
import { ReactComponent as Cross } from '../../../../assets/img/icons/cross.svg';
import { Button } from '../../../../components/warehouse/Button';

export const CrossIcon = styled(Cross)`
  width: 22px;
  height: 22px;
`;

export const CheckIcon = styled(CheckMark)`
  width: 22px;
  height: 22px;
`;

export const StepContentWrapper = styled.div`
  margin: 0px;
  width: fill-available;

  &:first-child {
    align-items: start;
  }
`;

export const OrderOverviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
  padding: 20px;
  padding-top: 32px;
  margin-bottom: 20px;

  background: ${({ theme }) => theme.palette.grey[1]};
  border: 1px solid ${({ theme }) => transparentize(0.4, theme.palette.grey[5])};
  border-radius: 5px;
`;

export const ThumbnailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  gap: 36px 25px;
  flex-wrap: wrap;
  padding-bottom: 25px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[4]};
`;

export const ParcelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

export const ParcelInfoWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const AlertWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
`;

export const AddInsertsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AddInsertButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 20px;
`;

export const AddInsertButton = styled(Button)`
  width: 11rem;
`;
