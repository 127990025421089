import { useRef } from 'react';

export function usePerformDebounced<T>(
  action: (value: T) => unknown,
  delay: number
): (value: T) => unknown {
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  return (value: T) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      action(value);
    }, delay);
  };
}
