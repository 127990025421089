import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const BinMessageBox = styled(Box)`
  color: ${({ theme }) => theme.palette.primary.dark};
  font-size: larger;
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 16/9;
`;
