import { Modal } from '../../../../components/warehouse/Modal';
import { Loading } from '../../../pickStation/components/Loading';

export function NetworkPrintingModal({ open }: { open: boolean }) {
  return (
    <Modal
      isVisible={open}
      contentFit={true}
      data-testid="network-printing-label-modal"
    >
      <Loading message="Printing label" flexFlow="row" />
    </Modal>
  );
}
