"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabelTroubleshootStation = exports.LabelTroubleshootStationStatus = void 0;
const zod_1 = require("zod");
const fulfilmentOrder_1 = require("../http/fulfilmentOrder");
exports.LabelTroubleshootStationStatus = zod_1.z.enum([
    'CLOSED',
    'AWAITING_SCAN',
    'EDITING_ADDRESS',
]);
exports.LabelTroubleshootStation = zod_1.z.object({
    warehouseId: zod_1.z.string(),
    stationId: zod_1.z.number().positive(),
    status: exports.LabelTroubleshootStationStatus,
    fulfilmentOrderId: zod_1.z.string().min(1).optional(),
    merchantName: zod_1.z.string().min(1).optional(),
    merchantReference: zod_1.z.string().min(1).optional(),
    recipientName: zod_1.z.string().min(1).optional(),
    address: zod_1.z.optional(fulfilmentOrder_1.PostalAddressSchema),
});
