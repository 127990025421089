import styled, { css } from 'styled-components';

export const Text = styled.span`
  position: relative;
  text-align: left;

  ${({ theme }) => {
    return css`
      font-size: ${theme.typography.body1.fontSize};
      font-weight: 400;
      line-height: ${theme.typography.body1.lineHeight};
      color: ${theme.palette.text.placeholder};
    `;
  }};
`;
