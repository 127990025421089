export const isWeakNever = (x: never, shouldThrow = true) => {
  const msg = `Never case reached with unexpected value ${x}`;
  if (shouldThrow) {
    throw new Error(msg);
  } else {
    console.error(msg);
  }
};

export const isNever = (x: never): never => {
  throw new Error(`Received value for never: ${x}`);
};
