import styled from 'styled-components';

export const AutoStoreResumedContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #e5e5e5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 16px;
`;
