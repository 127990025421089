import styled, { css } from 'styled-components';

interface FieldProps {
  hasError?: boolean;
}

export const Field = styled.textarea<FieldProps>`
  position: relative;
  width: 100%;
  height: 100%;
  resize: none;

  ${({ theme, hasError }) => {
    return css`
      padding: 8px 16px;

      border: 1px solid ${theme.palette.grey[5]};
      border-radius: ${theme.shape.borderRadius}px;

      box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.15);

      font-family: ${theme.typography.fontFamily};
      font-size: ${theme.typography.body1.fontSize};
      font-weight: ${theme.weight.regular};
      line-height: ${theme.typography.body1.lineHeight};

      &::placeholder {
        color: ${theme.palette.text.placeholder};
      }

      &:focus-within {
        outline: none;
        border-color: ${theme.palette.primary.main};
        box-shadow:
          0 0 0 4px rgba(0, 125, 88, 0.4),
          inset 0 2px 2px rgba(0, 0, 0, 0.15);
      }

      ${hasError &&
      css`
        border-color: ${theme.palette.error.main};
        &:focus-within {
          outline: none;
          border: 1px solid ${theme.palette.error.main};
          box-shadow:
            0 0 0 4px rgba(205, 9, 9, 0.4),
            inset 0 2px 2px rgba(0, 0, 0, 0.15);
        }
      `}
    `;
  }};
`;
