import { ReactElement } from 'react';

import { ImgWrapper, Wrapper } from './Tile.elements';

export interface TileProps {
  label?: string;
  linkTo?: string;
  backgroundImgSrc: string;
}

export const Tile = ({
  label = 'Tile',
  linkTo = '#',
  backgroundImgSrc = '',
}: TileProps): ReactElement => (
  <Wrapper to={linkTo}>
    <ImgWrapper backgroundImgSrc={backgroundImgSrc} />
    <h2>{label}</h2>
  </Wrapper>
);
