import { useAppState } from '../store';
import { useWarehousesApi } from './useWarehousesApi';

/**
 * This hook needs to be rewritten to get a single warehouse from API
 * Wrapping the existing useWarehouses hook so that this function can be replaced in place in the future.
 */
export const useWarehouse = (warehouseId: string) => {
  const {
    appState: { warehouses },
  } = useAppState();
  const [status] = useWarehousesApi();
  return [status, warehouses.find(({ id }) => id === warehouseId)] as const;
};
