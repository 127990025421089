import styled from 'styled-components';

import { Heading as HeadingBase } from '../../../elements/admin/Heading';

interface WrapperProps {
  collapse: boolean;
  spacing: number;
}

export const Wrapper = styled.div<WrapperProps>`
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]};
  padding: ${({ theme, spacing }) => theme.spacing(spacing)}px;
  margin: ${({ collapse, theme, spacing }) =>
    collapse
      ? `${theme.spacing(spacing) * -1}px ${
          theme.spacing(spacing) * -1
        }px ${theme.spacing(3)}px`
      : `0 0 ${theme.spacing(2)}`};
`;

export const Heading = HeadingBase;
