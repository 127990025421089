import { useSnackbar } from 'notistack';
import { Button } from '../../../../components/admin/Button';
import { Container } from '../../../../components/admin/Container';
import { usePutawayPortSocket } from '../../../../components/common/SocketProvider';
import { handleCommandRejectedResultWithSnackbar } from '../../../../utils/commands';
import { BinCheckMessage } from './BinCheck/BinCheck.elements';

type Props = {
  hasActiveTransfer: boolean;
};

export const ClosedPort = ({ hasActiveTransfer }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { dispatchCommand } = usePutawayPortSocket();

  const onClick = async () => {
    const commandResult = await dispatchCommand({
      type: 'OPEN_PUTAWAY_PORT',
    });
    handleCommandRejectedResultWithSnackbar(enqueueSnackbar, commandResult);
  };

  return (
    <Container>
      <BinCheckMessage>PORT CLOSED</BinCheckMessage>
      <Button
        onClick={onClick}
        variant="heavy"
        fullWidth
        disabled={!hasActiveTransfer}
      >
        OPEN PORT/GET BIN
      </Button>
    </Container>
  );
};
