import { PutawayPortWithCurrentBin } from 'api-schema/lib/model';
import { defaultMemoize } from 'reselect';

/**
 * Calculates the total weight of the current bin (in grams) by looking at every
 * item in each partition and getting the weight of each item.
 */
export const getCurrentBinWeightInGrams = defaultMemoize(
  (currentBin: PutawayPortWithCurrentBin['currentBin'] | undefined) => {
    if (!currentBin) {
      return 0;
    }

    let weight = 0;
    // loop over each partition and get it's quantity * weight
    currentBin.partitions.forEach((partition) => {
      if (partition.retailUnit?.weightGrams) {
        weight += partition.quantity * partition.retailUnit?.weightGrams;
      }
    });

    return weight;
  }
);
