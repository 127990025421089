import { EditWarehouseRequestBodyType } from 'api-schema/lib';
import { EditWarehouseRequestBody } from 'api-schema/lib/http';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { isNotErrorResponse } from '../../../apiClient';
import { SnackbarDefaults } from '../../../constants/snackbarDefaults';
import { useApiClient } from '../../../hooks/useApiClient';
import { useWarehouseParam } from '../../../hooks/useWarehouseParam';
import { useAppState } from '../../../store';
import { setWarehouses } from '../../../store/actions';
import { transformZodErrorsToFormik } from '../../../utils/forms/transformZodErrorsToFormik';
import { EditWarehouseView } from './EditWarehouse.view';

export const EditWarehouse = () => {
  useWarehouseParam();
  const {
    appState: { warehouses, currentWarehouse: warehouse },
    appDispatch,
  } = useAppState();
  const { enqueueSnackbar } = useSnackbar();
  const apiClient = useApiClient();
  const [previousWarehouse, setPreviousWarehouse] = useState(warehouse);

  const formik = useFormik<EditWarehouseRequestBodyType>({
    initialValues: warehouse
      ? {
          name: warehouse.name,
          address: warehouse.address,
          isTest: warehouse.isTest || false,
          autostore: {
            networkAddress: warehouse.autostore?.networkAddress || '',
          },
        }
      : {
          name: '',
          address: '',
          isTest: false,
          autostore: {
            networkAddress: '',
          },
        },
    onSubmit: async (formData) => {
      try {
        if (!warehouse?.id) {
          throw Error('No warehouse found');
        }
        const { data, status } = await apiClient.put('/warehouses/:id', {
          body: formData,
          params: {
            id: warehouse.id,
          },
        });
        if (isNotErrorResponse(data, status)) {
          const index = warehouses.findIndex((w) => w.id === warehouse.id);
          const updatedWarehouses = [...warehouses];
          if (index === -1) {
            throw Error('Could not find warehouse to update in local state');
          }
          updatedWarehouses[index] = data;
          appDispatch(setWarehouses(updatedWarehouses));
          enqueueSnackbar('Successfully updated warehouse.', SnackbarDefaults);
        } else {
          // TODO(SKUT-864):
          throw Error(data.error);
        }
      } catch (e) {
        if (!(e instanceof Error)) {
          return;
        }
        console.error(e);
        // error displayed by apiClient
      }
      return;
    },
    validate: transformZodErrorsToFormik<EditWarehouseRequestBodyType>(
      EditWarehouseRequestBody
    ),
  });

  useEffect(() => {
    if (warehouse && warehouse !== previousWarehouse) {
      formik.setValues({
        address: warehouse.address,
        isTest: warehouse.isTest ? true : false,
        name: warehouse.name,
        autostore: {
          networkAddress: warehouse.autostore?.networkAddress || '',
        },
      });
      setPreviousWarehouse(warehouse);
    }
  }, [warehouse, previousWarehouse, formik]);

  return <EditWarehouseView {...formik} warehouse={warehouse} />;
};
