"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.autostoreStateReceivedEvent = exports.autostoreCompletePortState = exports.autostorePortErrorStatusChangedEventSchema = exports.autostorePortState = exports.autostoreBinStateSchema = exports.autostorePortDefinition = exports.autostorePortType = exports.autostorePortMode = exports.autostoreBinMode = exports.autostorePortDefinitionChangedEvent = exports.autostoreLogDownloadCompletedEvent = exports.autostoreLogDownloadStartedEvent = exports.autostoreBinLocationChangedEvent = exports.autostoreBinClosedEvent = exports.autostoreBinOpenedEvent = exports.autostorePortModeChangedEvent = exports.autostoreEvent = exports.wcsEvent = void 0;
const zod_1 = require("zod");
const autostore_1 = require("./autostore");
Object.defineProperty(exports, "autostoreBinClosedEvent", { enumerable: true, get: function () { return autostore_1.autostoreBinClosedEvent; } });
Object.defineProperty(exports, "autostoreBinLocationChangedEvent", { enumerable: true, get: function () { return autostore_1.autostoreBinLocationChangedEvent; } });
Object.defineProperty(exports, "autostoreBinMode", { enumerable: true, get: function () { return autostore_1.autostoreBinMode; } });
Object.defineProperty(exports, "autostoreBinOpenedEvent", { enumerable: true, get: function () { return autostore_1.autostoreBinOpenedEvent; } });
Object.defineProperty(exports, "autostoreBinStateSchema", { enumerable: true, get: function () { return autostore_1.autostoreBinStateSchema; } });
Object.defineProperty(exports, "autostoreCompletePortState", { enumerable: true, get: function () { return autostore_1.autostoreCompletePortState; } });
Object.defineProperty(exports, "autostoreLogDownloadCompletedEvent", { enumerable: true, get: function () { return autostore_1.autostoreLogDownloadCompletedEvent; } });
Object.defineProperty(exports, "autostoreLogDownloadStartedEvent", { enumerable: true, get: function () { return autostore_1.autostoreLogDownloadStartedEvent; } });
Object.defineProperty(exports, "autostorePortDefinition", { enumerable: true, get: function () { return autostore_1.autostorePortDefinition; } });
Object.defineProperty(exports, "autostorePortDefinitionChangedEvent", { enumerable: true, get: function () { return autostore_1.autostorePortDefinitionChangedEvent; } });
Object.defineProperty(exports, "autostorePortErrorStatusChangedEventSchema", { enumerable: true, get: function () { return autostore_1.autostorePortErrorStatusChangedEventSchema; } });
Object.defineProperty(exports, "autostorePortMode", { enumerable: true, get: function () { return autostore_1.autostorePortMode; } });
Object.defineProperty(exports, "autostorePortModeChangedEvent", { enumerable: true, get: function () { return autostore_1.autostorePortModeChangedEvent; } });
Object.defineProperty(exports, "autostorePortState", { enumerable: true, get: function () { return autostore_1.autostorePortState; } });
Object.defineProperty(exports, "autostorePortType", { enumerable: true, get: function () { return autostore_1.autostorePortType; } });
Object.defineProperty(exports, "autostoreStateReceivedEvent", { enumerable: true, get: function () { return autostore_1.autostoreStateReceivedEvent; } });
const autostoreEvent = zod_1.z.discriminatedUnion('type', [
    autostore_1.autostorePortModeChangedEvent,
    autostore_1.autostoreBinOpenedEvent,
    autostore_1.autostoreBinClosedEvent,
    autostore_1.autostoreBinLocationChangedEvent,
    autostore_1.autostoreLogDownloadStartedEvent,
    autostore_1.autostoreLogDownloadCompletedEvent,
    autostore_1.autostorePortDefinitionChangedEvent,
    autostore_1.autostorePortErrorStatusChangedEventSchema,
    autostore_1.autostoreStateReceivedEvent,
]);
exports.autostoreEvent = autostoreEvent;
// TODO(WMS-735): address orphaned todo
// eslint-disable-next-line todo-plz/ticket-ref
// TODO: Remove this duplicate when we have other sets of WCS events
// Zod will complain if you only give it one element in the array
const wcsEvent = autostoreEvent;
exports.wcsEvent = wcsEvent;
