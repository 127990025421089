"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SHORT_PICK_TROUBLESHOOT_SOCKET_EVENT_NAMES = exports.ShortPickTroubleshootEvent = exports.ShortPickedOrderCancelled = exports.ShortPickToteScanSucceeded = exports.ShortPickIncorrectToteScanned = exports.ShortPickResolveDeferred = exports.ShortPickResolutionToteScanned = exports.shortPickResolvedSchema = exports.ShortPickTroubleshootClosed = exports.ShortPickTroubleshootStarted = void 0;
const z = __importStar(require("zod"));
const base_1 = require("./base");
exports.ShortPickTroubleshootStarted = base_1.eventBaseSchema.extend({
    type: z.literal('SHORT_PICK_TROUBLESHOOT_STARTED'),
    payload: z.object({
        stationId: z.number(),
    }),
});
exports.ShortPickTroubleshootClosed = base_1.eventBaseSchema.extend({
    type: z.literal('SHORT_PICK_TROUBLESHOOT_CLOSED'),
    payload: z.object({
        stationId: z.number(),
    }),
});
exports.shortPickResolvedSchema = base_1.eventBaseSchema.extend({
    type: z.literal('SHORT_PICK_RESOLVED'),
    payload: z.object({
        stationId: z.number().positive(),
        fulfilmentOrderId: z.string().min(1),
        toteId: z.string().min(1),
        picklistId: z.string().min(1),
        resolutionToteIds: z.array(z.string()).optional(),
        resolutionPicklistIds: z.array(z.string()).optional(),
    }),
});
exports.ShortPickResolutionToteScanned = base_1.eventBaseSchema.extend({
    type: z.literal('SHORT_PICK_RESOLUTION_TOTE_SCANNED'),
    payload: z.object({
        stationId: z.number(),
        resolutionToteId: base_1.StringId,
    }),
});
exports.ShortPickResolveDeferred = base_1.eventBaseSchema.extend({
    type: z.literal('SHORT_PICK_RESOLVE_DEFERRED'),
    payload: z.object({
        stationId: z.number(),
        orderId: z.string().optional(),
    }),
});
exports.ShortPickIncorrectToteScanned = base_1.eventBaseSchema.extend({
    type: z.literal('SHORT_PICK_INCORRECT_TOTE_SCANNED'),
    payload: z.object({
        stationId: z.number(),
        incorrectToteId: base_1.StringId.optional(),
    }),
});
exports.ShortPickToteScanSucceeded = base_1.eventBaseSchema.extend({
    type: z.literal('SHORT_PICK_TOTE_SCAN_SUCCEEDED'),
    payload: z.object({
        stationId: z.number(),
        toteId: base_1.StringId,
        orderPicklistId: base_1.StringId,
        resolutionPicklistIds: z.array(base_1.StringId),
        fulfilmentOrderId: base_1.StringId,
    }),
});
exports.ShortPickedOrderCancelled = base_1.eventBaseSchema.extend({
    type: z.literal('SHORT_PICKED_ORDER_CANCELLED'),
    payload: z.object({
        stationId: z.number(),
        fulfilmentOrderId: z.string().min(1),
    }),
});
exports.ShortPickTroubleshootEvent = z.union([
    exports.ShortPickToteScanSucceeded,
    exports.ShortPickIncorrectToteScanned,
    exports.ShortPickResolutionToteScanned,
    exports.shortPickResolvedSchema,
    exports.ShortPickTroubleshootClosed,
    exports.ShortPickTroubleshootStarted,
    exports.ShortPickResolveDeferred,
    exports.ShortPickedOrderCancelled,
]);
exports.SHORT_PICK_TROUBLESHOOT_SOCKET_EVENT_NAMES = exports.ShortPickTroubleshootEvent.options.map((opt) => opt.shape.type.value);
