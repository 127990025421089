import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppState } from '../store';
import { setCurrentWarehouse } from '../store/actions';

export type WarehouseRouteParams = {
  warehouseId: string | undefined;
};

export const useWarehouseParam = () => {
  const { appState, appDispatch } = useAppState();
  const { warehouses } = appState;
  const { warehouseId } = useParams<{ warehouseId?: string }>();

  const updateCurrentWarehouse = useCallback(
    (uuid: string) => {
      appDispatch(setCurrentWarehouse(uuid));
    },
    [appDispatch]
  );

  useEffect(() => {
    const shouldUpdateWarehouse = warehouses?.length > 0 && warehouseId;
    if (shouldUpdateWarehouse) {
      updateCurrentWarehouse(warehouseId);
    }
  }, [warehouseId, warehouses, updateCurrentWarehouse]);
};
