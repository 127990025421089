"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RetailUnitEvent = exports.retailUnitRecordRemoved = void 0;
const zod_http_schemas_1 = require("zod-http-schemas");
const base_1 = require("./base");
const transfer_1 = require("./transfer");
exports.retailUnitRecordRemoved = base_1.eventBaseSchema.extend({
    type: zod_http_schemas_1.z.literal('RETAIL_UNIT_RECORD_REMOVED'),
    payload: zod_http_schemas_1.z.object({
        warehouseId: zod_http_schemas_1.z.string().min(1),
        merchantId: zod_http_schemas_1.z.string().min(1),
        barcode: zod_http_schemas_1.z.string().min(1),
    }),
});
exports.RetailUnitEvent = zod_http_schemas_1.z.union([
    transfer_1.transferCreatedSchema,
    exports.retailUnitRecordRemoved,
]);
