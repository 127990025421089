import React, { ReactElement, useReducer } from 'react';
import { AppActions, AppInitialState, AppReducer, AppState } from './reducer';

type AppProviderProps = { children: React.ReactNode; initialState?: AppState };
type AppDispatch = (action: AppActions) => void;

const AppStateContext = React.createContext<AppState | undefined>(undefined);
const AppDispatchContext = React.createContext<AppDispatch | undefined>(
  undefined
);

function AppStateProvider({
  children,
  initialState = AppInitialState,
}: AppProviderProps): ReactElement {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
}

function useAppState(): {
  appState: AppState;
  appDispatch: AppDispatch;
} {
  const appState = React.useContext(AppStateContext);
  if (appState === undefined) {
    throw new Error('useAppState must be used within a AppProvider');
  }

  const appDispatch = React.useContext(AppDispatchContext);
  if (appDispatch === undefined) {
    throw new Error('useAppDispatch must be used within a AppProvider');
  }
  return { appState, appDispatch };
}

export { AppStateProvider, useAppState };
