import { Typography } from '@material-ui/core';
import { AddAutostore } from '../../../components/admin/AddAutostore';
import { Container } from '../../../components/admin/Container';
import { SectionHeader } from '../../../components/admin/SectionHeader';
import { useStorageUnitsApi } from '../../../hooks/useStorageUnitsApi';
import { useWarehouseParam } from '../../../hooks/useWarehouseParam';
import { useAppState } from '../../../store';

export const Devices = () => {
  useWarehouseParam();
  const {
    appState: { currentWarehouse: warehouse },
  } = useAppState();
  const [storageData] = useStorageUnitsApi(warehouse?.id, 'AUTOSTORE_BIN');

  return warehouse?.autostore ? (
    <Container>
      <SectionHeader>Autostore</SectionHeader>
      <Typography>
        <strong>ID:</strong> {warehouse.autostore.id}
      </Typography>
      <Typography>
        <strong>Network Address:</strong> {warehouse.autostore.networkAddress}
      </Typography>
      <Typography>
        <strong>Number of bins:</strong> {storageData.length}
      </Typography>
    </Container>
  ) : (
    <AddAutostore />
  );
};
