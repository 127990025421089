import { useLocalStorage } from './useLocalStorage';

type PickStationConfig = {
  kind: 'PICK_STATION';
  warehouseId: string;
  port: string;
};

type ReplenishmentPortConfig = {
  kind: 'REPLENISHMENT_PORT';
  warehouseId: string;
  port: string;
};

type HandheldConfig = {
  // This one is somewhat theoretical atm, but if we had smartphones with barcode scanners
  kind: 'HANDHELD';
  warehouseId: string;
  deviceId: string;
};

type UnconfiguredDevice = {
  kind: 'UNCONFIGURED';
};

type PackStationConfig = {
  kind: 'PACK_STATION';
  warehouseId: string;
  stationId: string;
};

type ShortPickTroubleshootConfig = {
  kind: 'SHORT_PICK_TROUBLESHOOT';
  warehouseId: string;
  stationId: string;
};

type LabelTroubleshootConfig = {
  kind: 'LABEL_TROUBLESHOOT';
  warehouseId: string;
  stationId: string;
};

export type DeviceConfig =
  | PickStationConfig
  | ReplenishmentPortConfig
  | HandheldConfig
  | UnconfiguredDevice
  | PackStationConfig
  | ShortPickTroubleshootConfig
  | LabelTroubleshootConfig;

export const useDeviceConfig = () => {
  return useLocalStorage<DeviceConfig>('DEVICE_CONFIG', {
    kind: 'UNCONFIGURED',
  });
};
