import { Transfer } from 'api-schema';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { isNotErrorResponse } from '../apiClient';
import { useApiClient } from './useApiClient';

export type SetTransferAction = Dispatch<SetStateAction<Transfer[]>>;

export const useTransfersApi = (
  warehouseId?: string
): [Transfer[], SetTransferAction] => {
  const [transferData, setTransferData] = useState<Transfer[]>([]);
  const apiClient = useApiClient();
  useEffect(() => {
    if (!warehouseId) {
      return;
    }
    (async () => {
      try {
        const { data, status } = await apiClient.get(
          '/warehouses/:id/transfers',
          {
            params: { id: warehouseId },
          }
        );
        if (isNotErrorResponse(data, status)) {
          setTransferData(data.transfers);
        }
      } catch (error) {
        // error displayed by apiClient
      }
    })();
  }, [warehouseId, apiClient]);

  return [transferData, setTransferData];
};
