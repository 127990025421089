import { DeviceConfigForm } from '../screens/DeviceConfigForm';
import { AdminThemeProvider } from '../themes/AdminTheme';

function DeviceConfigApp() {
  return (
    <AdminThemeProvider>
      <DeviceConfigForm />
    </AdminThemeProvider>
  );
}

export { DeviceConfigApp };
