import { Modal } from '../../../../components/warehouse/Modal';
import { Loading } from '../../../pickStation/components/Loading';

export type WaitingForToteModalProps = {
  isVisible: boolean;
};

export function WaitingForToteModal({ isVisible }: WaitingForToteModalProps) {
  return (
    <Modal
      isVisible={isVisible}
      contentFit={true}
      data-testid="waiting-for-tote-modal"
    >
      <Loading message="Waiting for tote" flexFlow="row" />
    </Modal>
  );
}
