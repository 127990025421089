import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;

  p {
    margin: 0;
  }
`;
