import styled from 'styled-components';
import { Text } from '../Text';

export const StationLockedContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.error.light};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2.4)};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const LockMessage = styled(Text)`
  max-width: 930px;
  padding: 0 50px;
  text-align: center;
`;
