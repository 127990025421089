import styled from 'styled-components';

import Container from '@material-ui/core/Container';

export const Wrapper = styled(Container)`
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.palette.grey[200]};
  padding-top: ${({ theme }) => theme.spacing(2)}px;
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-top: ${({ theme }) => theme.spacing(3)}px;
    padding-bottom: ${({ theme }) => theme.spacing(3)}px;
  }
`;
