import { DataGrid } from '@material-ui/data-grid';
import SortIcon from '@material-ui/icons/Sort';
import styled from 'styled-components';

import { CLASSNAME_SORTED_CELL } from './Table.methods';

export const Wrapper = styled.div`
  background: white;
  width: 100%;
  display: flex;
  height: 100%;
  border-top: 1px solid ${({ theme }) => theme.palette.grey[300]};
  border-radius: ${({ theme }) =>
    `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`};
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;

  > div {
    flex-grow: 1;
    height: auto !important;
  }

  // Style overrides for the table
  .MuiDataGrid-colCell {
    font-weight: bold;
    color: ${({ theme }) => theme.palette.primary.main};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    :not(.MuiDataGrid-colCellSortable) .MuiSvgIcon-fontSizeSmall {
      display: none;
    }
  }
  // Force all sort icons to appear to the right.
  // (number columns reverse alignment by default)
  .MuiDataGrid-colCell-draggable > span {
    order: 1;
  }

  .MuiDataGrid-root {
    border-top: none;
    border-radius: ${({ theme }) =>
      `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`};
    width: auto !important;
  }

  .MuiDataGrid-colCellWrapper {
    align-items: flex-start;
  }

  .MuiDataGrid-row a {
    color: inherit;
    text-decoration: none;
    display: block;
    width: 100%;
  }
`;

export const Data = DataGrid;

export const HeaderCell = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const DescendingSortIcon = styled(SortIcon)`
  margin-left: auto;
  display: flex;
  fill: ${({ theme }) => theme.palette.primary.main};
`;

export const AscendingSortIcon = styled(DescendingSortIcon)`
  transform: scaleY(-1);
`;

export const HeaderSortIcon = styled(SortIcon)`
  margin-left: auto;
  display: flex;
  opacity: 0.3;
  width: 18px;
  height: 18px;
  transform: translate(-3px, 1px) scaleY(-1);

  .${CLASSNAME_SORTED_CELL} & {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    display: none;
  }
`;

export const Header = styled.div`
  padding: ${({ theme }) => theme.spacing(2)}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Filters = styled.div`
  justify-self: self-end;
`;
