"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PutawayCommands = exports.CompletePutaway = exports.CheckBinEmpty = exports.SwapBin = exports.CheckBinHasArrived = exports.ConfigurePartitions = exports.RemovePutawayItem = exports.AddPutawayItem = exports.PlaceItemsInPartition = exports.OpenPort = void 0;
const z = __importStar(require("zod"));
exports.OpenPort = z.object({
    type: z.literal('OPEN_PUTAWAY_PORT'),
});
exports.PlaceItemsInPartition = z.object({
    type: z.literal('PLACE_ITEMS_IN_PARTITION'),
    partitionNumber: z.number().int().positive(),
    barcode: z.string().min(1),
    transferId: z.string().min(1),
    quantity: z.number().min(0).max(1000000),
});
exports.AddPutawayItem = z.object({
    type: z.literal('ADD_PUTAWAY_ITEM'),
    partitionNumber: z.number().int().positive(),
    barcode: z.string().min(1),
    transferId: z.string().min(1),
});
exports.RemovePutawayItem = z.object({
    type: z.literal('REMOVE_PUTAWAY_ITEM'),
    partitionNumber: z.number().int().positive(),
    barcode: z.string().min(1),
    transferId: z.string().min(1),
});
exports.ConfigurePartitions = z.object({
    type: z.literal('CONFIGURE_PARTITIONS'),
    numberOfPartitions: z.number().int().positive(),
});
exports.CheckBinHasArrived = z.object({
    type: z.literal('CHECK_PUTAWAY_BIN_HAS_ARRIVED'),
});
exports.SwapBin = z.object({
    type: z.literal('SWAP_BIN'),
});
exports.CheckBinEmpty = z.object({
    type: z.literal('CHECK_BIN_EMPTINESS'),
    isEmpty: z.boolean(),
});
exports.CompletePutaway = z.object({
    type: z.literal('COMPLETE_PUTAWAY'),
});
exports.PutawayCommands = z.union([
    exports.OpenPort,
    exports.AddPutawayItem,
    exports.RemovePutawayItem,
    exports.SwapBin,
    exports.CheckBinEmpty,
    exports.CompletePutaway,
    exports.ConfigurePartitions,
    exports.PlaceItemsInPartition,
    exports.CheckBinHasArrived,
]);
