import { Transfer } from 'api-schema';
import { PortBinStateType } from 'api-schema/lib/model';
import { TransferDetailsView } from './TransferDetails.view';

type Props = {
  transfer: Transfer;
  disabled: boolean;
  onCompletePutaway: () => void;
  targetTransferItem?: string;
  currentBin?: PortBinStateType;
  useNumberInput: boolean;
};

export const TransferDetails = ({
  transfer,
  disabled,
  onCompletePutaway,
  targetTransferItem,
  currentBin,
  useNumberInput,
}: Props) => {
  return (
    <TransferDetailsView
      transfer={transfer}
      onCompletePutaway={onCompletePutaway}
      disabled={disabled}
      targetTransferItem={targetTransferItem}
      currentBin={currentBin}
      useNumberInput={useNumberInput}
    />
  );
};
