import { ChangeEvent, KeyboardEvent, ReactNode, useState } from 'react';
import { SuccessMessageType } from '../../../screens/shortPickTroubleshoot/ShortPickTroubleshoot';
import { AlertMessage, AlertMessageTypes } from '../AlertMessage';
import { Box } from '../Box';
import { InputField } from '../InputField';
import { Text } from '../Text';
import { Content, InputWrapper, Wrapper } from './ToteScan.elements';

type AlertType = {
  message: ReactNode;
  type: AlertMessageTypes;
};

export type ToteScanProps = {
  headerText?: string;
  placeholderText?: string;
  alert?: AlertType;
  tips?: AlertType;
  successMessage: SuccessMessageType | undefined;
  scanToteHandler: (toteId: string) => Promise<void>;
  margin?: number;
};

export function ToteScan({
  headerText,
  placeholderText,
  scanToteHandler,
  alert,
  tips,
  successMessage,
  margin = 80,
}: ToteScanProps) {
  const [toteId, setToteId] = useState<string>('');

  const DEFAULT_HEADER_TEXT = 'Scan an order tote or troubleshoot label';
  const DEFAULT_PLACEHOLDER_TEXT =
    'Scan or enter the ID on the tote or troubleshoot label';

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setToteId(e.target.value.trim());
  };

  const handleEnterKey = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && toteId) {
      await scanToteHandler(toteId);
      setToteId('');
    }
  };

  const handleBlur = async () => {
    if (!toteId) {
      return;
    }
    await scanToteHandler(toteId);
    setToteId('');
  };

  return (
    <Wrapper $margin={margin}>
      <Box>
        {successMessage && (
          <Box margin="0 0 20px 0">
            <AlertMessage type={successMessage.type}>
              {successMessage.textElement}
            </AlertMessage>
          </Box>
        )}
        <Content alignCenter={false}>
          <InputWrapper>
            <Text variant="h1" weight="medium" tag="span">
              {headerText ?? DEFAULT_HEADER_TEXT}
            </Text>
            <InputField
              value={toteId}
              placeholderText={placeholderText ?? DEFAULT_PLACEHOLDER_TEXT}
              hasError={alert?.type === AlertMessageTypes.Error}
              name="OrderToteId"
              id="OrderToteId"
              onChange={handleChange}
              onKeyPress={handleEnterKey}
              onBlur={handleBlur}
              showIcon={false}
              autoFocus={true}
            />
          </InputWrapper>
          {alert?.message && (
            <AlertMessage type={alert.type ?? AlertMessageTypes.Error}>
              {alert.message}
            </AlertMessage>
          )}
          {tips?.message && (
            <AlertMessage type={tips.type ?? AlertMessageTypes.Default}>
              {tips.message}
            </AlertMessage>
          )}
        </Content>
      </Box>
    </Wrapper>
  );
}
