import { ReactElement } from 'react';
import { ReactComponent as DangerIcon } from '../../../../assets/img/icons/error-circle.svg';
import { Button } from '../../../../components/warehouse/Button';
import { Modal } from '../../../../components/warehouse/Modal';
import { Text } from '../../../../components/warehouse/Text';

export type CancelOrderModalProps = {
  closeModal: () => void;
  cancelOrder: () => void;
};

export const CancelOrderModal = ({
  closeModal,
  cancelOrder,
}: CancelOrderModalProps): ReactElement => {
  return (
    <Modal
      isVisible
      variant="danger"
      header={{ icon: <DangerIcon />, text: 'Cancel order?' }}
      actions={[
        <Button variant="secondary" fullWidth onClick={closeModal}>
          Do not cancel order
        </Button>,
        <Button variant="danger" fullWidth onClick={cancelOrder}>
          Cancel order
        </Button>,
      ]}
    >
      <Text variant="body2" weight="regular" margin={0}>
        The order fulfilment should only be cancelled at the merchant's request.
        This action cannot be undone.
      </Text>{' '}
    </Modal>
  );
};
