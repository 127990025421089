import { AddTotesRequestType, Warehouse } from 'api-schema';
import { FormikProps } from 'formik';
import React, { ReactElement } from 'react';
import { Button } from '../../../components/admin/Button';
import { Container } from '../../../components/admin/Container';
import { Input } from '../../../components/admin/Input';
import { SectionHeader } from '../../../components/admin/SectionHeader';
import { FormRow } from '../../../elements/admin/FormRow';
import { Loadable } from '../../../elements/admin/Loadable';
import { getFormHandlers } from '../../../utils/forms/getFormHandlers';
import { generatePathWithWarehouseId } from '../../../utils/generatePathWithWarehouseId';

type Event = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

export function AddOrderToteView({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  handleSubmit,
  isSubmitting,
  warehouse,
}: FormikProps<AddTotesRequestType> & {
  warehouse: Warehouse;
}): ReactElement {
  const { handleInputTouch } = getFormHandlers({
    setFieldValue,
    setFieldTouched,
  });
  const handleNumTotesChange = ({ target: { value } }: Event) =>
    setFieldValue('numTotes', parseInt(value, 10));
  return (
    <Container>
      <SectionHeader>Add Order Totes</SectionHeader>
      <form onSubmit={handleSubmit}>
        <FormRow spacingBottom={1}>
          <Input
            label="Number of totes"
            name="numTotes"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            onChange={handleNumTotesChange}
            value={values.numTotes}
            error={errors.numTotes}
            touched={touched.numTotes}
            onBlur={handleInputTouch}
          />
        </FormRow>
        <FormRow>
          <Button type="submit" disabled={isSubmitting}>
            <Loadable isLoading={isSubmitting}>Create Totes</Loadable>
          </Button>
          <Button
            variant="secondary"
            href={generatePathWithWarehouseId(
              `/admin/warehouse/:warehouseId?/overview`,
              {
                warehouseId: warehouse.id,
              }
            )}
          >
            Cancel
          </Button>
        </FormRow>
      </form>
    </Container>
  );
}
