import { StorageUnitCreationResult } from 'api-schema';
import { useHistory, useLocation } from 'react-router-dom';
import { useWarehouseParam } from '../../../hooks/useWarehouseParam';
import { STORAGE_UNIT_PATHS } from '../../../router/paths';
import { useAppState } from '../../../store';
import { generatePathWithWarehouseId } from '../../../utils/generatePathWithWarehouseId';
import { StorageUnitCreatedView } from './StorageUnitCreated.view';

export const StorageUnitCreated = () => {
  useWarehouseParam();
  const {
    appState: { currentWarehouse },
  } = useAppState();
  const { state } = useLocation<StorageUnitCreationResult>();
  const history = useHistory();
  const handlePrintClick = () => {
    history.push(
      generatePathWithWarehouseId(STORAGE_UNIT_PATHS.PRINT_STORAGE, {
        warehouseId: currentWarehouse?.id,
      }),
      {
        ids: state.ids,
        storageType: state.storageType,
      }
    );
  };
  return <StorageUnitCreatedView {...state} onPrintClick={handlePrintClick} />;
};
