const pluralise = (
  term: string,
  count: number,
  includeCount = true,
  suffix = 's'
) => {
  return `${includeCount ? `${count} ` : ''}${term}${
    count !== 1 ? suffix : ''
  }`;
};

export { pluralise };
