import styled from 'styled-components';

export const TextWrapper = styled.div`
  font-weight: ${({ theme }) => theme.weight.normal};
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  padding: ${({ theme }) => theme.spacing(1)};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 60px;
`;
