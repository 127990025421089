import { ReactElement } from 'react';
import { Animation, Wrapper } from './Spinner.elements';

export function Spinner(): ReactElement {
  return (
    <Wrapper>
      <Animation />
    </Wrapper>
  );
}
