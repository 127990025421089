"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommandResultMessage = exports.InvalidSequencedResultMessage = exports.socketStatePayloadSchema = exports.portStateChangeMessageSchema = exports.PickPortStateChangeMessage = exports.AutostoreStatusChangeMessage = exports.intermediaryStationStateChangeMessageSchema = exports.LabelTroubleshootStateChangeMessage = exports.ShortPickTroubleshootStateChangeMessage = exports.PackAndDispatchStateChangeMessage = exports.intermediaryStationCommandResultMessageSchema = exports.LabelTroubleshootCommandResultMessage = exports.ShortPickTroubleshootCommandResultMessage = exports.PackAndDispatchCommandResultMessage = exports.PickCommandResultMessage = exports.PickDispatchCommandMessage = exports.WarehouseStateChangeMessage = exports.PutawayCommandResultMessage = exports.PutawayDispatchCommandMessage = exports.PutawayPortStateChangeMessage = exports.socketTypeSchema = void 0;
const zod_1 = require("zod");
const outcomes_1 = require("./commands/outcomes");
const packAndDispatch_1 = require("./commands/outcomes/packAndDispatch");
const pick_1 = require("./commands/pick");
const putaway_1 = require("./commands/putaway");
const events_1 = require("./events");
const sockets_1 = require("./events/sockets");
const model_1 = require("./model");
const featureFlags_1 = require("./model/featureFlags");
const intermediaryStation_1 = require("./model/intermediaryStation");
const CommandKey = zod_1.z.string().min(1);
exports.socketTypeSchema = zod_1.z.enum([
    'PUTAWAY_PORT',
    'PICK_PORT',
    'SHORT_PICK_TROUBLESHOOT_STATION',
    'LABEL_TROUBLESHOOT_STATION',
    'PACK_STATION',
    'INTERMEDIARY_STATION',
]);
exports.PutawayPortStateChangeMessage = zod_1.z.object({
    portState: model_1.putawayPortSchema,
    event: events_1.PutawaySocketEvent,
});
exports.PutawayDispatchCommandMessage = zod_1.z.object({
    command: putaway_1.PutawayCommands,
    commandKey: CommandKey,
    sequenceNumber: zod_1.z.number().positive(),
});
exports.PutawayCommandResultMessage = zod_1.z.object({
    commandKey: CommandKey,
    result: outcomes_1.PutawayCommandsOutcome,
    sequenceNumber: zod_1.z.number().positive(),
});
const socketCommandResultMessageSchema = zod_1.z.object({
    commandKey: CommandKey,
    result: zod_1.z.union([
        outcomes_1.PutawayCommandsOutcome,
        outcomes_1.PickCommandsOutcome,
        outcomes_1.intermediaryStationCommandOutcomeSchema,
        packAndDispatch_1.PackAndDispatchCommandsOutcome,
        outcomes_1.ShortPickTroubleshootCommandsOutcome,
        outcomes_1.LabelTroubleshootCommandsOutcome,
    ]),
    sequenceNumber: zod_1.z.number().positive(),
});
exports.WarehouseStateChangeMessage = zod_1.z.object({
    currentWarehouse: model_1.warehouseSchema.optional(),
    event: sockets_1.WarehouseStateChangedSocketEvents,
});
exports.PickDispatchCommandMessage = zod_1.z.object({
    command: pick_1.PickCommands,
    commandKey: CommandKey,
    sequenceNumber: zod_1.z.number().positive(),
});
exports.PickCommandResultMessage = zod_1.z.object({
    commandKey: CommandKey,
    result: outcomes_1.PickCommandsOutcome,
    sequenceNumber: zod_1.z.number().positive(),
});
exports.PackAndDispatchCommandResultMessage = zod_1.z.object({
    commandKey: CommandKey,
    result: packAndDispatch_1.PackAndDispatchCommandsOutcome,
    sequenceNumber: zod_1.z.number().positive(),
});
exports.ShortPickTroubleshootCommandResultMessage = zod_1.z.object({
    commandKey: CommandKey,
    result: outcomes_1.ShortPickTroubleshootCommandsOutcome,
    sequenceNumber: zod_1.z.number().positive(),
});
exports.LabelTroubleshootCommandResultMessage = zod_1.z.object({
    commandKey: CommandKey,
    result: outcomes_1.LabelTroubleshootCommandsOutcome,
    sequenceNumber: zod_1.z.number().positive(),
});
exports.intermediaryStationCommandResultMessageSchema = zod_1.z.object({
    commandKey: CommandKey,
    result: outcomes_1.intermediaryStationCommandOutcomeSchema,
    sequenceNumber: zod_1.z.number().positive(),
});
exports.PackAndDispatchStateChangeMessage = zod_1.z.object({
    state: model_1.PackAndDispatchClientStateModel,
    event: events_1.PackAndDispatchEvent,
});
exports.ShortPickTroubleshootStateChangeMessage = zod_1.z.object({
    shortPickTroubleshootState: model_1.ShortPickTroubleshootStation,
});
exports.LabelTroubleshootStateChangeMessage = zod_1.z.object({
    labelTroubleshootState: model_1.LabelTroubleshootStation,
});
exports.intermediaryStationStateChangeMessageSchema = zod_1.z.object({
    intermediaryStationState: intermediaryStation_1.intermediaryStationSocketStateSchema,
});
exports.AutostoreStatusChangeMessage = zod_1.z.object({
    currentWarehouse: zod_1.z.object({
        autostore: model_1.autostore.omit({ id: true, networkAddress: true }),
    }),
});
exports.PickPortStateChangeMessage = zod_1.z.object({
    portState: model_1.pickPortWithHydratedEntitiesSchema,
    event: events_1.PickSocketEvent,
});
const websocketPortSchema = zod_1.z.discriminatedUnion('type', [
    model_1.unconfiguredPortWithCurrentBinSchema,
    model_1.putawayPortWithCurrentBinSchema,
    model_1.pickPortWithHydratedEntitiesSchema,
]);
exports.portStateChangeMessageSchema = websocketPortSchema.and(zod_1.z.object({
    currentBin: model_1.autostoreBinWithRetailUnitsSchema.optional(),
    currentWarehouse: model_1.warehouseSchema.optional(),
    currentTranfer: model_1.transferSchema.optional(),
}));
exports.socketStatePayloadSchema = zod_1.z.union([
    exports.PackAndDispatchStateChangeMessage,
    zod_1.z.object({
        portState: exports.portStateChangeMessageSchema.optional(),
        featureFlags: featureFlags_1.featureFlagsSchema.optional(),
    }),
    zod_1.z.object({
        labelTroubleshootState: exports.LabelTroubleshootStateChangeMessage.or(zod_1.z.object({})),
    }),
    zod_1.z.object({
        shortPickTroubleshootState: exports.ShortPickTroubleshootStateChangeMessage.or(zod_1.z.object({})),
    }),
    zod_1.z.object({
        // TODO (FCM-201): Use a read model schema here instead of the write model
        intermediaryStationState: intermediaryStation_1.intermediaryStationSocketStateSchema.or(zod_1.z.object({})),
    }),
]);
exports.InvalidSequencedResultMessage = zod_1.z.object({
    result: zod_1.z.object({
        outcome: zod_1.z.literal('INVALID_SEQUENCE_NUMBER'),
    }),
});
exports.CommandResultMessage = zod_1.z.union([
    exports.PickCommandResultMessage,
    exports.PutawayCommandResultMessage,
    exports.PackAndDispatchCommandResultMessage,
    exports.ShortPickTroubleshootCommandResultMessage,
    exports.LabelTroubleshootCommandResultMessage,
    exports.InvalidSequencedResultMessage,
    exports.intermediaryStationCommandResultMessageSchema,
]);
