import styled from 'styled-components';
import { Text } from '../Text';

export const StartStationLabel = styled(Text)`
  text-align: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: inherit;
  padding: ${({ theme }) => theme.spacing(2)};
`;
