import { usePortsApi } from '../../../hooks/usePortsApi';
import { useAppState } from '../../../store';

import { useSnackbar } from 'notistack';
import { isNotErrorResponse } from '../../../apiClient';
import { SnackbarDefaults } from '../../../constants/snackbarDefaults';
import { useApiClient } from '../../../hooks/useApiClient';
import { useWarehouseParam } from '../../../hooks/useWarehouseParam';
import { PortsOverviewView } from './PortsOverview.view';

export const portTypes = ['PICK', 'PUTAWAY', 'UNCONFIGURED'] as const;

export const PortsOverview = () => {
  useWarehouseParam();
  const {
    appState: { currentWarehouse: warehouse },
  } = useAppState();
  const [portsData, refreshPorts, setPortsData] = usePortsApi(warehouse?.id);
  const apiClient = useApiClient();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = async (portId: number, value: string) => {
    if (!warehouse?.id) {
      return;
    }
    const ports = [...portsData];
    const port = ports.find((p) => p.portId === portId);
    if (port) {
      const type = portTypes.find((portType) => portType === value);
      if (type) {
        port.type = type;
        try {
          const { data, status } = await apiClient.put(
            '/warehouses/:id/ports/:portId',
            {
              params: { id: warehouse.id, portId: `${portId}` },
              body: { type: port.type },
            }
          );
          if (isNotErrorResponse(data, status)) {
            setPortsData(data.ports);
          } else {
            enqueueSnackbar(data.error, {
              ...SnackbarDefaults,
              variant: 'error',
            });
          }
        } catch (error) {
          // error displayed by apiClient
        }
      }
    }
  };

  return (
    <PortsOverviewView
      warehouse={warehouse}
      portsData={portsData}
      refreshPorts={refreshPorts}
      handleChange={handleChange}
    />
  );
};
