import { useRouteMatch } from 'react-router-dom';
import { SocketProvider } from '../components/common/SocketProvider';
import { baseUrl } from '../constants/config';
import { useWarehouse } from '../hooks/useWarehouse';
import { PickStation } from '../screens/pickStation/PickStation';
import { WarehouseThemeProvider } from '../themes/WarehouseTheme';

type RouteParams = { warehouseId: string; portId: string };

export const PickStationApp = () => {
  const {
    params: { portId, warehouseId },
  } = useRouteMatch<RouteParams>();

  useWarehouse(warehouseId);

  return (
    <WarehouseThemeProvider useLargeFontSet>
      <SocketProvider
        baseUrl={baseUrl}
        portId={portId}
        warehouseId={warehouseId}
        providerType="pick"
      >
        <main>
          <PickStation />
        </main>
      </SocketProvider>
    </WarehouseThemeProvider>
  );
};
