"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.picklistApiSchema = void 0;
const zod_http_schemas_1 = require("zod-http-schemas");
const picklistAllocationResultSchema = zod_http_schemas_1.z.object({
    success: zod_http_schemas_1.z.array(zod_http_schemas_1.z.object({
        picklistId: zod_http_schemas_1.z.string(),
        storageUnitId: zod_http_schemas_1.z.string(),
    })),
    failed: zod_http_schemas_1.z.object({
        picklistAlreadyAssignedToStorageUnit: zod_http_schemas_1.z.string().array(),
        storageUnitAlreadyHasPicklist: zod_http_schemas_1.z.string().array(),
        notFound: zod_http_schemas_1.z.object({
            picklistIds: zod_http_schemas_1.z.string().array(),
            storageUnitIds: zod_http_schemas_1.z.string().array(),
        }),
        invalidPicklistStatus: zod_http_schemas_1.z
            .object({
            id: zod_http_schemas_1.z.string(),
            status: zod_http_schemas_1.z.string(),
        })
            .array(),
        invalidStorageUnitType: zod_http_schemas_1.z
            .object({
            id: zod_http_schemas_1.z.string(),
            storageType: zod_http_schemas_1.z.string(),
        })
            .array(),
        invalidStorageUnitStatus: zod_http_schemas_1.z
            .object({
            id: zod_http_schemas_1.z.string(),
            status: zod_http_schemas_1.z.string(),
        })
            .array(),
        incorrectWarehouseId: zod_http_schemas_1.z
            .object({
            storageUnitId: zod_http_schemas_1.z.string(),
            warehouseId: zod_http_schemas_1.z.string(),
        })
            .array(),
    }),
});
const picklistUnallocationResultSchema = zod_http_schemas_1.z.object({
    success: zod_http_schemas_1.z.string().array(),
    failed: zod_http_schemas_1.z.object({
        notFound: zod_http_schemas_1.z.string().array(),
        picklistToteMismatch: zod_http_schemas_1.z
            .object({
            picklistId: zod_http_schemas_1.z.string(),
            storageUnitId: zod_http_schemas_1.z.string(),
        })
            .array(),
        toteNotEmpty: zod_http_schemas_1.z
            .object({
            picklistId: zod_http_schemas_1.z.string(),
            storageUnitId: zod_http_schemas_1.z.string(),
        })
            .array(),
    }),
});
const completePicksResultSchema = zod_http_schemas_1.z.object({
    success: zod_http_schemas_1.z
        .object({
        picklistId: zod_http_schemas_1.z.string(),
        fulfilmentOrderId: zod_http_schemas_1.z.string(),
        merchantId: zod_http_schemas_1.z.string(),
        toteId: zod_http_schemas_1.z.string(),
        items: zod_http_schemas_1.z
            .object({
            quantity: zod_http_schemas_1.z.number().positive(),
            barcode: zod_http_schemas_1.z.string(),
        })
            .array(),
    })
        .array(),
    failed: zod_http_schemas_1.z.object({
        notFound: zod_http_schemas_1.z.string().array(),
        invalidPicklistStatus: zod_http_schemas_1.z
            .object({
            picklistId: zod_http_schemas_1.z.string(),
            status: zod_http_schemas_1.z.string(),
        })
            .array(),
        picklistNotAssignedToStorageUnit: zod_http_schemas_1.z.string().array(),
        picklistToteNotFound: zod_http_schemas_1.z
            .object({
            picklistId: zod_http_schemas_1.z.string(),
            storageUnitId: zod_http_schemas_1.z.string(),
        })
            .array(),
        storageUnitIncorrectType: zod_http_schemas_1.z.string().array(),
        toteNotEmpty: zod_http_schemas_1.z
            .object({
            picklistId: zod_http_schemas_1.z.string(),
            toteId: zod_http_schemas_1.z.string(),
        })
            .array(),
    }),
});
exports.picklistApiSchema = (0, zod_http_schemas_1.createHttpSchema)({
    'POST /:warehouseId/picklists/autostore-hand-picking/assign-to-totes': {
        requestBody: zod_http_schemas_1.z.object({
            picklistStorageUnitIdPairs: zod_http_schemas_1.z.array(zod_http_schemas_1.z.object({
                storageUnitId: zod_http_schemas_1.z.string(),
                picklistId: zod_http_schemas_1.z.string(),
            })),
        }),
        responseBody: zod_http_schemas_1.z.union([
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('SUCCESS'),
                success: picklistAllocationResultSchema.shape.success,
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('PARTIAL_SUCCESS'),
                ...picklistAllocationResultSchema.shape,
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('NO_PICKLISTS_ASSIGNED_TO_TOTES'),
                failed: picklistAllocationResultSchema.shape.failed,
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('WAREHOUSE_NOT_FOUND'),
            }),
        ]),
    },
    'POST /:warehouseId/picklists/autostore-hand-picking/unassign-from-totes': {
        requestBody: zod_http_schemas_1.z.object({
            picklistIds: zod_http_schemas_1.z.array(zod_http_schemas_1.z.string()),
        }),
        responseBody: zod_http_schemas_1.z.union([
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('SUCCESS'),
                success: picklistUnallocationResultSchema.shape.success,
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('PARTIAL_SUCCESS'),
                ...picklistUnallocationResultSchema.shape,
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('NO_PICKLISTS_UNASSIGNED_FROM_TOTES'),
                failed: picklistUnallocationResultSchema.shape.failed,
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('WAREHOUSE_NOT_FOUND'),
            }),
        ]),
    },
    'POST /:warehouseId/picklists/autostore-hand-picking/complete-picks': {
        requestBody: zod_http_schemas_1.z.object({
            picklistIds: zod_http_schemas_1.z.array(zod_http_schemas_1.z.string()),
        }),
        responseBody: zod_http_schemas_1.z.union([
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('SUCCESS'),
                success: completePicksResultSchema.shape.success,
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('PARTIAL_SUCCESS'),
                ...completePicksResultSchema.shape,
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('NO_AUTOSTORE_HAND_PICKS_COMPLETED'),
                failed: completePicksResultSchema.shape.failed,
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('WAREHOUSE_NOT_FOUND'),
            }),
        ]),
    },
});
