import { createTheme } from '@material-ui/core/styles';
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/styles';
import { ReactElement } from 'react';
import { ThemeProvider } from 'styled-components';

import { colours } from '../constants/colours';

export const theme = createTheme({
  palette: {
    ...colours,
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    h1: {
      fontWeight: 400,
      fontSize: '1.5rem',
      lineHeight: 1.334,
      letterSpacing: '0em',
      color: colours.primary.main,
      marginBottom: 32,
    },
    h2: {
      fontWeight: 500,
      fontSize: '1.25rem',
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
    },
    h4: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
      marginBottom: 16,
      color: colours.primary.main,
    },
  },
});

export type AdminThemeInterface = typeof theme;

export function AdminThemeProvider({
  children,
}: {
  children: ReactElement;
}): ReactElement {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
      </ThemeProvider>
    </StylesProvider>
  );
}
