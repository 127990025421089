import { ReactElement } from 'react';
import { ReactComponent as NavigateNext } from '../../../assets/img/icons/navigate-next.svg';
import { ListItem, Wrapper } from './List.elements';

export type ListItemType = {
  text: string;
  onClick: () => void;
};

export type ListProps = {
  items: ListItemType[];
};

export const List = ({ items }: ListProps): ReactElement => {
  return (
    <Wrapper>
      {items.map((item) => (
        // TODO(FCG-448): Replace this ListItem component with the new ListItem.tsx file
        <ListItem onClick={item.onClick}>
          {item.text}
          <NavigateNext />
        </ListItem>
      ))}
    </Wrapper>
  );
};
