"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fulfilmentOrderNotFoundOutcomeSchema = exports.DispatchFulfilmentOrderResponse = exports.CancelFulfilmentOrderResponse = exports.OrderAlreadyAssignedOutcome = void 0;
const zod_1 = require("zod");
const model_1 = require("../../model");
const generic_1 = require("./generic");
const taskGroups_1 = require("./taskGroups");
exports.OrderAlreadyAssignedOutcome = zod_1.z.object({
    outcome: zod_1.z.literal('ORDER_ALREADY_ASSIGNED'),
    allowedOrderStatuses: zod_1.z.array(model_1.fulfilmentOrderSchema.shape.status),
    currentOrderStatus: model_1.fulfilmentOrderSchema.shape.status,
    allowedPicklistStatuses: zod_1.z.array(model_1.picklistStatusSchema),
    currentPicklistStatuses: zod_1.z.array(model_1.picklistStatusSchema),
});
exports.CancelFulfilmentOrderResponse = zod_1.z.union([
    zod_1.z.object({
        outcome: zod_1.z.literal('SUCCESS'),
    }),
    exports.OrderAlreadyAssignedOutcome,
    ...taskGroups_1.cancelTaskGroupErrorOutcomes,
]);
exports.DispatchFulfilmentOrderResponse = zod_1.z.union([
    generic_1.Success,
    zod_1.z.object({
        outcome: zod_1.z.literal('INVALID_STATUS'),
        expectedStatuses: zod_1.z.array(model_1.fulfilmentOrderStatusSchema),
        actualStatus: model_1.fulfilmentOrderStatusSchema,
    }),
]);
exports.fulfilmentOrderNotFoundOutcomeSchema = zod_1.z.object({
    outcome: zod_1.z.literal('FULFILMENT_ORDER_NOT_FOUND'),
    fulfilmentOrderId: zod_1.z.string().optional(),
});
