import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.8)};
  width: 100%;
`;

export const Text = styled.div`
  position: relative;
  text-align: left;
  align-self: start;

  ${({ theme }) => {
    return css`
      font-family: ${theme.typography.body1.fontFamily};
      font-size: ${theme.typography.body1.fontSize};
      font-weight: ${theme.weight.regular};
      line-height: ${theme.typography.body1.lineHeight};

      color: ${theme.palette.text.primary};
    `;
  }};
`;
