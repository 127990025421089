import styled from 'styled-components';

export const AutoStoreStoppedWorkingContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.error.light};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

export const TextWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const ErrorList = styled.ul`
  margin: 0;
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
`;

export const TipsWrapper = styled.div`
  margin-top: 16px;
`;
