import { ReactElement } from 'react';
import styled from 'styled-components';
import { BaseTextComponent, Text } from '../../../components/warehouse/Text';

type InputHeaderProps = {
  children: ReactElement | ReactElement[];
  label?: string;
  fullWidth?: boolean;
};

const Wrapper = styled.span<{ $fullWidth?: boolean }>`
  display: inline-flex;
  flex-direction: column;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
  ${BaseTextComponent} {
    margin: 0;
  }
`;

export const InputHeader = ({
  label,
  fullWidth,
  children,
}: InputHeaderProps): ReactElement => {
  if (!label) {
    return <>{children}</>;
  }
  return (
    <Wrapper $fullWidth={fullWidth}>
      <Text tag="span">{label}</Text>
      {children}
    </Wrapper>
  );
};
