import { Typography } from '@material-ui/core';
import { ReactNode } from 'react';
import styled from 'styled-components';

export interface HeadingProps {
  variant: 'h1' | 'h2' | 'h4';
  children: string | ReactNode;
  $withMargin?: boolean;
}

export const Heading = styled(Typography)<HeadingProps>`
  ${({ $withMargin = true }) => (!$withMargin ? 'margin: 0' : null)};
`;
