import styled from 'styled-components';

export const ChipBase = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 2px ${({ theme }) => theme.spacing(1.25)};
  border-radius: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(1)};
  font-size: 1.5rem;
  line-height: 1.5;
  background-color: ${({ theme }) =>
    theme.palette.category.background.standard};
`;
