import { Divider, Typography } from '@material-ui/core';
import { RetailUnit } from 'api-schema/lib';
import { Container } from '../../../../../components/admin/Container';
import { ContainerItem } from './ProductDetails.elements';

type Props = {
  activeProduct?: RetailUnit;
};

export const ProductDetails = ({ activeProduct }: Props) => {
  if (!activeProduct) {
    return null;
  }

  return (
    <Container>
      <Typography>
        <strong>Product details</strong>
      </Typography>
      <Divider />
      <ContainerItem marginTop="10px">{activeProduct.name}</ContainerItem>
      <ContainerItem>
        <span>SKU:</span> {activeProduct.sku}
      </ContainerItem>
      <ContainerItem>
        <span>Barcode:</span> {activeProduct.barcode}
      </ContainerItem>
    </Container>
  );
};
