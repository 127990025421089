import { ReactComponent as ErrorIcon } from '../../../../assets/img/icons/error-circle.svg';
import { Button } from '../../../../components/warehouse/Button';
import { Modal } from '../../../../components/warehouse/Modal';
import { Text } from '../../../../components/warehouse/Text';

export type ClosePortModalProps = {
  cancel: () => void;
  close: () => void;
  picksAreInProgress: boolean;
};

export const ClosePortModal = ({
  cancel,
  close,
  picksAreInProgress,
}: ClosePortModalProps) => (
  <Modal
    isVisible
    variant="danger"
    header={{
      icon: <ErrorIcon />,
      text: 'Close port?',
    }}
    actions={[
      <Button
        testId="close-port-modal--cancel-button"
        variant="secondary"
        fullWidth
        onClick={cancel}
      >
        Cancel
      </Button>,
      <Button
        testId="close-port-modal--close-button"
        variant="danger"
        fullWidth
        onClick={close}
      >
        {picksAreInProgress ? 'Begin closing procedure' : 'Close port'}
      </Button>,
    ]}
  >
    <Text variant="body1">
      {picksAreInProgress
        ? 'This action will begin the closing procedure. You will be directed to complete the remaining totes.'
        : 'This action will immediately end the picking process.'}
    </Text>
  </Modal>
);
