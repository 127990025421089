import { InfoRounded } from '@material-ui/icons';
import { ReactElement } from 'react';
import { ReactComponent as StopIcon } from '../../../assets/img/stop-large-red.svg';
import { Text } from '../Text';
import { Tips } from '../Tips/Tips';
import {
  AutoStoreStoppedWorkingContainer,
  ErrorList,
  TextWrapper,
  TipsWrapper,
} from './AutoStoreStoppedWorking.elements';

export type AutoStoreStoppedWorkingProps = {
  genericReason?: string;
  errorText: string;
  error?: {
    mode?: string;
    status?: string;
    stopCode?: string;
    reason?: string;
    chargePercent?: string;
  };
};

export function AutoStoreStoppedWorking({
  genericReason,
  errorText,
  error,
}: AutoStoreStoppedWorkingProps): ReactElement {
  return (
    <AutoStoreStoppedWorkingContainer>
      <StopIcon />
      <TextWrapper>
        <Text variant="h3" weight="medium" tag="span">
          The AutoStore has stopped running.
        </Text>
        {genericReason && (
          <Text variant="body2" weight="regular" tag="span">
            <Text variant="body2" weight="medium" tag="span">
              Reason:
            </Text>{' '}
            {genericReason}
          </Text>
        )}
        {error && (
          <ErrorList>
            <li>
              <Text weight="medium" tag="span">
                Mode:
              </Text>{' '}
              {error.mode} ({error.status})
            </li>
            <li>
              <Text weight="medium" tag="span">
                Stop code:
              </Text>{' '}
              {error.stopCode} ({error.reason})
            </li>
            <li>
              <Text weight="medium" tag="span">
                Charge percent:
              </Text>{' '}
              {error.chargePercent}
            </li>
          </ErrorList>
        )}
      </TextWrapper>
      <TipsWrapper>
        <Tips icon={<InfoRounded fontSize="large" />} text={errorText} />
      </TipsWrapper>
    </AutoStoreStoppedWorkingContainer>
  );
}
