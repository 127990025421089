"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LABEL_TROUBLESHOOT_SOCKET_EVENT_NAMES = exports.LabelTroubleshootEvent = exports.TroubleshootLabelScanFailed = exports.TroubleshootLabelScanned = exports.LabelTroubleshootStationClosed = exports.LabelTroubleshootStationOpened = void 0;
const zod_1 = require("zod");
const base_1 = require("./base");
exports.LabelTroubleshootStationOpened = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('LABEL_TROUBLESHOOT_STATION_OPENED'),
    payload: zod_1.z.object({
        stationId: zod_1.z.number().positive(),
    }),
});
exports.LabelTroubleshootStationClosed = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('LABEL_TROUBLESHOOT_STATION_CLOSED'),
    payload: zod_1.z.object({
        stationId: zod_1.z.number().positive(),
    }),
});
exports.TroubleshootLabelScanned = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TROUBLESHOOT_LABEL_SCANNED'),
    payload: zod_1.z.object({
        stationId: zod_1.z.number().positive(),
        fulfilmentOrderId: zod_1.z.string().min(1),
        externalOrderReference: zod_1.z.string().min(1),
    }),
});
exports.TroubleshootLabelScanFailed = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TROUBLESHOOT_LABEL_SCAN_FAILED'),
    payload: zod_1.z.object({
        stationId: zod_1.z.number().positive(),
        orderIdScanned: zod_1.z.string(),
    }),
});
exports.LabelTroubleshootEvent = zod_1.z.union([
    exports.LabelTroubleshootStationOpened,
    exports.LabelTroubleshootStationClosed,
    exports.TroubleshootLabelScanned,
    exports.TroubleshootLabelScanFailed,
]);
exports.LABEL_TROUBLESHOOT_SOCKET_EVENT_NAMES = exports.LabelTroubleshootEvent.options.map((opt) => opt.shape.type.value);
