import { ReactElement } from 'react';
import { ReactComponent as CheckCircleIcon } from '../../../assets/img/check-circle-large-blue.svg';
import { warehouseColours } from '../../../constants/colours';
import { Text } from '../Text';
import {
  AutoStoreResumedContainer,
  ButtonWrapper,
} from './AutoStoreResumed.elements';

export type AutoStoreResumedProps = {
  Button?: ReactElement;
};

export function AutoStoreResumed({
  Button,
}: AutoStoreResumedProps): ReactElement {
  return (
    <AutoStoreResumedContainer>
      <CheckCircleIcon
        width="240"
        height="240"
        fill={warehouseColours.success.main}
      />
      <Text variant="h3" weight="medium" tag="span">
        The AutoStore has resumed.
      </Text>
      {Button && <ButtonWrapper>{Button}</ButtonWrapper>}
    </AutoStoreResumedContainer>
  );
}
