import MuiTab from '@material-ui/core/Tab';
import TabList from '@material-ui/core/Tabs';
import styled, { css } from 'styled-components';

interface WrapperProps {
  $borders: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  background-color: ${({ theme }) => theme.palette.common.white};
  ${({ $borders, theme }) =>
    $borders
      ? css`
          border: 1px solid ${theme.palette.grey[300]};
          border-bottom: none;
        `
      : null}
`;

export const TabsContainer = TabList;

export const Tab = styled(MuiTab)`
  color: ${({ theme }) => theme.palette.grey[700]};

  &.Mui-selected {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
