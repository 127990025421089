import { ReactElement } from 'react';
import { ReactComponent as EditCircleIcon } from '../../../assets/img/icons/edit-circle.svg';
import { ReactComponent as EmptyCircleIcon } from '../../../assets/img/icons/empty-circle.svg';
import { ReactComponent as CheckCircleIcon } from '../../../assets/img/stepper-icon-check-circle.svg';
import { warehouseColours } from '../../../constants/colours';
import {
  IconWrapper,
  ProgressWrapper,
  Step,
  StepContent,
  StepperProgressBar,
  StepperWrapper,
  Title,
  Wrapper,
} from './Stepper.elements';

export interface StepperProps {
  steps: string[];
  currentStep: number;
}

const getProgress = (index: number, currentStep: number) => {
  if (index < currentStep) {
    return 100;
  } else if (index > currentStep) {
    return 0;
  } else {
    return 90;
  }
};

const getIcon = (index: number, currentStep: number) => {
  if (index < currentStep) {
    return <CheckCircleIcon />;
  } else if (index > currentStep) {
    return <EmptyCircleIcon fill={warehouseColours.grey[4]} />;
  } else {
    return <EditCircleIcon fill={warehouseColours.secondary.main} />;
  }
};

export function Stepper({ steps, currentStep }: StepperProps): ReactElement {
  return (
    <Wrapper>
      <StepperWrapper>
        {steps.map((step, i) => (
          <Step key={step}>
            {i !== 0 && (
              <ProgressWrapper>
                <StepperProgressBar
                  progress={getProgress(i, currentStep - 1)}
                />
              </ProgressWrapper>
            )}
            <StepContent>
              <IconWrapper>{getIcon(i, currentStep - 1)}</IconWrapper>
              <Title>{step}</Title>
            </StepContent>
          </Step>
        ))}
      </StepperWrapper>
    </Wrapper>
  );
}
