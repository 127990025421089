import styled, { css } from 'styled-components';
import { StepNumber } from '../StepNumber';

export const PickingStep = styled.div`
  margin-right: ${({ theme }) => theme.spacing(2)};
  border-top: 1px solid ${({ theme }) => theme.palette.grey[4]};
  padding-top: ${({ theme }) => theme.spacing(4)};

  &:first-child {
    border: none;
    padding-top: 0px;
    padding-bottom: ${({ theme }) => theme.spacing(4)};
  }
`;

export const PickingWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const PickingContentWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  font-weight: ${({ theme }) => theme.typography.body1.fontWeight};
  line-height: ${({ theme }) => theme.typography.body1.lineHeight};
  width: 100%;
`;

export const PickingStepCount = styled(StepNumber)<{ $completed?: boolean }>`
  height: 1.875rem;
  width: 2rem;

  ${({ $completed }) => {
    if ($completed) {
      return css`
        background-color: transparent;
      `;
    }
  }}
`;

export const PickingAlertWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export const PickingStepsWrapper = styled.div``;
