import { ReactElement } from 'react';
import { ReactComponent as TroubleshootIcon } from '../../../../assets/img/icons/troubleshoot-icon.svg';
import { usePickPortSocket } from '../../../../components/common/SocketProvider';
import { Button } from '../../../../components/warehouse/Button';
import { Container } from '../../../../components/warehouse/Container';
import { Text } from './PlaceTotes.elements';

export function PlaceTotes(): ReactElement {
  const { dispatchCommand } = usePickPortSocket();

  const handleTotesPlacedClick = async () => {
    await dispatchCommand({
      type: 'PLACE_TOTES',
    });
  };

  return (
    <Container
      buttons={[
        <Button variant="warning" icon={<TroubleshootIcon />} fullWidth>
          Troubleshoot
        </Button>,
      ]}
      padding="none"
      alignCenter
    >
      <Text data-testid="place-ports-text">
        Place 6 empty totes on the fixed table
      </Text>
      <Button
        variant="primary"
        onClick={handleTotesPlacedClick}
        testId="totes-placed-button"
      >
        Totes placed
      </Button>
    </Container>
  );
}
