import { GridColDef, GridRowData } from '@material-ui/data-grid';
import { BIN_TYPE_HEIGHT_MAP, StorageType, StorageUnit } from 'api-schema';
import { isNotErrorResponse } from '../../../apiClient';
import { TabItem } from '../../../components/admin/Tabs/Tabs';
import { MonoText } from '../../../elements/admin/MonoText';
import { useApiClient } from '../../../hooks/useApiClient';
import { useWarehousesApi } from '../../../hooks/useWarehousesApi';
import { useAppState } from '../../../store';
import { isWeakNever } from '../../../utils/isNever';

export const storageColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Id',
    flex: 4,
    renderCell: (row) => <MonoText>{row.id}</MonoText>,
  },
  {
    field: 'type',
    headerName: 'Type',
    flex: 3,
    sortable: true,
  },
  {
    field: 'numPartions',
    headerName: '# Partitions',
    flex: 3,
  },
  {
    field: 'size',
    headerName: 'Size',
    flex: 3,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 2,
  },
];

export const mapBinsToRow = (bin: StorageUnit): GridRowData => {
  switch (bin.storageType) {
    case 'AUTOSTORE_BIN': {
      return {
        ...bin,
        numPartions: bin.partitions.length,
        type: 'Autostore',
        size: `H: ${BIN_TYPE_HEIGHT_MAP[bin.binType]}`,
        status: bin.status === 'ACTIVE' ? 'Active' : 'Disabled',
      };
    }
    case 'TOTE': {
      return {
        ...bin,
        numPartions: '-',
        type: 'Order Tote',
        size: 'Standard',
        status: bin.status === 'ACTIVE' ? 'Active' : 'Disabled',
      };
    }
    default:
      throw isWeakNever(bin, true);
  }
};

export const getTabItems = (
  handler: (storageType?: StorageType) => void
): TabItem[] => {
  return [
    { label: 'All', onClick: () => handler() },
    { label: 'Autostore Bins', onClick: () => handler('AUTOSTORE_BIN') },
    { label: 'Order Totes', onClick: () => handler('TOTE') },
  ];
};

type DisableStorageUnitsOpts = {
  storageUnitIds: string[];
  status: StorageUnit['status'];
};

export const useDisableStorageUnits = () => {
  const [, refreshWarehouses] = useWarehousesApi();

  const {
    appState: { currentWarehouse: warehouse },
  } = useAppState();
  const apiClient = useApiClient();

  const disableStorageUnits = async (opts: DisableStorageUnitsOpts) => {
    const id = warehouse?.id;

    if (!id) {
      throw new Error('No warehouse found');
    }

    const { data, status } = await apiClient.post(
      `/warehouses/:id/storage-units`,
      {
        params: { id },
        body: {
          storageUnitIds: opts.storageUnitIds,
          status: opts.status,
        },
      }
    );

    if (isNotErrorResponse(data, status)) {
      refreshWarehouses();
      return data.storageUnits;
    }
    throw new Error('Failed to disable storage units');
  };

  return disableStorageUnits;
};
