"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dateSchema = void 0;
const zod_1 = require("zod");
exports.dateSchema = zod_1.z.preprocess((arg) => {
    if (typeof arg == 'string') {
        return new Date(arg);
    }
    return arg;
}, zod_1.z.date());
