import styled, { css } from 'styled-components';
import { AlertMessageTypes } from './AlertMessage';

interface WrapperProps {
  $type: AlertMessageTypes;
}

export const Wrapper = styled.div<WrapperProps>`
  display: inline-flex;
  gap: 10px;
  border-radius: 5px;
  width: 100%;
  padding: ${({ theme }) =>
    theme.currentFontSizeSet === 'large'
      ? theme.spacing(2)
      : theme.spacing(1.4)};

  ${({ $type }) => {
    switch ($type) {
      case AlertMessageTypes.Default:
        return css`
          background-color: ${({ theme }) => theme.palette.grey[3]};
          border: 1px solid ${({ theme }) => theme.palette.grey[5]};
        `;
      case AlertMessageTypes.Info:
        return css`
          background-color: ${({ theme }) => theme.palette.blue.light};
          border: 1px solid ${({ theme }) => theme.palette.blue.main};
        `;
      case AlertMessageTypes.Warning:
        return css`
          background-color: ${({ theme }) => theme.palette.warning.light};
          border: 1px solid ${({ theme }) => theme.palette.warning.dark};
        `;
      case AlertMessageTypes.Error:
        return css`
          background-color: ${({ theme }) => theme.palette.error.light};
          border: 1px solid ${({ theme }) => theme.palette.error.base};
        `;
      case AlertMessageTypes.Success:
        return css`
          background-color: ${({ theme }) => theme.palette.success.light};
          border: 1px solid ${({ theme }) => theme.palette.success.main};
        `;
      case AlertMessageTypes.Light:
        return css`
          background-color: ${({ theme }) => theme.palette.grey[1]};
          border: 1px solid ${({ theme }) => theme.palette.grey[5]};
        `;
      default:
        return css`
          background-color: ${({ theme }) => theme.palette.error.light};
          border: 1px solid ${({ theme }) => theme.palette.error.main};
        `;
    }
  }}

  ${({ theme }) => {
    return theme.currentFontSizeSet === 'large'
      ? css`
          font-size: ${({ theme }) => theme.typography.body3.fontSize};
          font-weight: ${({ theme }) => theme.typography.body3.fontWeight};
          line-height: ${({ theme }) => theme.typography.body3.lineHeight};
          > svg {
            width: 30px;
            height: 30px;
          }
        `
      : css`
          font-size: ${({ theme }) => theme.typography.body2.fontSize};
          font-weight: ${({ theme }) => theme.typography.body2.fontWeight};
          line-height: ${({ theme }) => theme.typography.body2.lineHeight};
          > svg {
            width: 20px;
            height: 20px;
          }
        `;
  }}

  > svg {
    flex-shrink: 0;
    margin: 3px;
  }
`;
