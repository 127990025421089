import {
  PartitionsPerBin,
  PartitionWithRetailUnit,
  RetailUnit,
} from 'api-schema/lib';
import { PutawayPortWithCurrentBin } from 'api-schema/lib/model';
import { BinGuideView } from './BinGuide.view';

type Props = {
  bin: PutawayPortWithCurrentBin['currentBin'];
  activeBinPartitions: PartitionsPerBin;
  targetPartition: number;
  onPartitionClick: (partition: number) => void;
  onSwapBin: () => void;
  setScanInputEl: (ref: HTMLInputElement | undefined) => void;
  partitions?: PartitionWithRetailUnit[];
  activeProduct?: RetailUnit;
  retailUnitBarcode: string;
  onAddItem: () => void;
  onRemoveItem: () => void;
  onChangeBarcode: (value: string) => void;
  onClearBarcode: () => void;
  binWeight: number;
  onSetItemQuantity: (quantity: number) => void;
  onBarcodeFullyScanned: () => void;
  useNumberInput: boolean;
};

export const BinGuide = ({
  bin,
  activeBinPartitions,
  targetPartition,
  onPartitionClick,
  onSwapBin,
  setScanInputEl,
  partitions,
  activeProduct,
  onAddItem,
  onRemoveItem,
  onChangeBarcode,
  retailUnitBarcode,
  onClearBarcode,
  binWeight,
  onSetItemQuantity,
  onBarcodeFullyScanned,
  useNumberInput,
}: Props) => {
  // TODO(WMS-735): address orphaned todo
  // eslint-disable-next-line todo-plz/ticket-ref
  // TODO setup a formik form for the barcode
  if (!bin) {
    return null;
  }

  const activePartition = partitions?.find(
    (p) => p.partitionNumber === targetPartition
  );

  return (
    <BinGuideView
      binId={bin.autostoreBinId}
      numPartitions={activeBinPartitions}
      onSwapBin={onSwapBin}
      onBarcodeChange={onChangeBarcode}
      onClearBarcodeClick={() => {
        onClearBarcode();
      }}
      onSubmit={onAddItem}
      onUndoClick={onRemoveItem}
      targetPartition={targetPartition}
      onPartitionClick={onPartitionClick}
      setScanInputEl={setScanInputEl}
      partitions={partitions}
      activeProduct={activeProduct}
      retailUnitBarcode={retailUnitBarcode}
      binWeight={binWeight}
      activePartition={activePartition}
      onSetItemQuantity={onSetItemQuantity}
      onBarcodeFullyScanned={onBarcodeFullyScanned}
      useNumberInput={useNumberInput}
    />
  );
};
