import { transparentize } from 'polished';
import styled from 'styled-components';

export const StationContainerWrapper = styled.div`
  max-height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-width: fill-available;
  height: 100%;

  @media (min-width: 768px) {
    min-width: 720px;
  }
`;

export const StationContainerBody = styled.div`
  background-color: ${({ theme }) => theme.palette.grey[2]};
  padding: 20px 24px;
  padding-bottom: 10px;
`;

export const StepperWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.grey[1]};
  border-radius: 10px;
  margin-bottom: ${({ theme }) => `${theme.spacing(3)}`};
`;

export const Content = styled.div`
  flex: 1;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: ${({ theme }) => `${theme.spacing(1.5)}`};
  gap: ${({ theme }) => `${theme.spacing(1)}`};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.typography.h1.fontSize};
  font-weight: ${({ theme }) => theme.typography.h1.fontWeight};
`;

export const SideTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => transparentize(0.4, theme.palette.grey[5])};
  color: ${({ theme }) => theme.palette.text.info};
  padding: 10px 10px;
  border-radius: 10px;
  height: 100%;
`;

export const SubTitle = styled.span`
  font-size: ${({ theme }) => theme.typography.h3.fontSize};
  font-weight: ${({ theme }) => theme.weight.regular};
  color: ${({ theme }) => theme.palette.text.placeholder};
  margin-right: 5px;
  line-height: ${({ theme }) => theme.typography.h3.lineHeight};
`;
