import React, { ReactNode, useEffect, useRef, useState } from 'react';
import {
  MainContent,
  StickyContainerWrapper,
  StickyElementWrapper,
} from './StickyContainer.elements';

export type FooterButtonAlignmentType =
  | 'space-between'
  | 'left'
  | 'right'
  | 'center';

export interface StickyContainerProps {
  children: ReactNode | ReactNode[];
  stickyFooter?: ReactNode[];
  footerButtonAlignType?: FooterButtonAlignmentType;
}

const StickyContainer = ({
  children,
  stickyFooter,
  footerButtonAlignType,
}: StickyContainerProps) => {
  const buttonGroupRef = useRef<HTMLDivElement>(null);
  const [buttonGroupClassname, setButtonGroupClassname] = useState<string>('');

  useEffect(() => {
    if (buttonGroupRef && buttonGroupRef.current) {
      const bottom = buttonGroupRef.current.getBoundingClientRect().bottom;

      if (bottom >= window.innerHeight) {
        setButtonGroupClassname('sticky');
        return;
      }
    }

    setButtonGroupClassname('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerHeight, buttonGroupRef, buttonGroupRef.current]);

  return (
    <StickyContainerWrapper>
      <MainContent>{children}</MainContent>
      {stickyFooter && (
        <StickyElementWrapper
          ref={buttonGroupRef}
          className={buttonGroupClassname}
          $buttonAlignmentType={footerButtonAlignType}
        >
          {stickyFooter.map((Item, i) => {
            return <React.Fragment key={i}>{Item}</React.Fragment>;
          })}
        </StickyElementWrapper>
      )}
    </StickyContainerWrapper>
  );
};

export { StickyContainer };
