import { StandardTextFieldProps } from '@material-ui/core/TextField/TextField';
import React, { ReactElement, useState } from 'react';
import { ErrorText } from '../../../elements/admin/ErrorText';
import { extractFilename } from '../../../utils/extractFilename';
import {
  FileName,
  HelperText,
  Icon,
  Input as InputElement,
  Wrapper,
} from './Input.elements';

export type InputTypes =
  | 'text'
  | 'number'
  | 'email'
  | 'password'
  | 'search'
  | 'tel'
  | 'file'
  | 'url';

export interface InputProps {
  label?: string;
  helperText?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
  type?: InputTypes;
  placeholder?: string;
  error?: string | string[];
  touched?: boolean;
  value?: string | number | null;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  onEndIconClick?: () => void;
  name?: string;
  required?: boolean;
  size?: 'small' | 'medium';
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  InputProps?: StandardTextFieldProps['InputProps'];
  onInputRef?: (el: HTMLInputElement | undefined) => void;
}

export function Input({
  label,
  helperText,
  fullWidth,
  disabled,
  multiline,
  rows,
  type = 'text',
  placeholder,
  error,
  value,
  startIcon,
  endIcon,
  name,
  required,
  size,
  touched,
  onChange,
  onBlur,
  InputProps,
  onEndIconClick,
  onInputRef,
}: InputProps): ReactElement {
  const [fileName, setFileName] = useState('');

  return (
    <Wrapper>
      <InputElement
        type={type}
        id={name}
        name={name}
        placeholder={placeholder}
        required={required}
        variant="filled"
        label={label}
        InputLabelProps={{ htmlFor: name }}
        fullWidth={fullWidth}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
        value={value}
        InputProps={{
          ...InputProps,
          startAdornment: startIcon ? (
            <Icon position="start">{startIcon}</Icon>
          ) : null,
          endAdornment: endIcon ? (
            <Icon position="end" onClick={onEndIconClick}>
              {endIcon}
            </Icon>
          ) : null,
        }}
        size={size}
        error={!!error && touched}
        onChange={(event) => {
          setFileName(extractFilename(event.target.value));
          if (onChange) {
            onChange(event);
          }
        }}
        onBlur={onBlur}
        inputRef={(el: HTMLInputElement | undefined) =>
          onInputRef && onInputRef(el)
        }
      />
      {fileName && type === 'file' && <FileName>{fileName}</FileName>}
      {helperText && !(error && touched) && (
        <HelperText>{helperText}</HelperText>
      )}
      {error && touched && <ErrorText component="p">{error}</ErrorText>}
    </Wrapper>
  );
}
