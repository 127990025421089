import {
  ScanShortPickTroubleshootToteOutcome,
  ScanShortPickTroubleshootToteOutcomeType,
} from 'api-schema/lib/commands/outcomes/shortPickTroubleshoot';
import { ReactElement, useState } from 'react';
import { useShortPickTroubleshootSocket } from '../../components/common/SocketProvider';
import { AlertMessageTypes } from '../../components/warehouse/AlertMessage';
import { Text } from '../../components/warehouse/Text';
import { useAppState } from '../../store';
import { mapCommandErrorToUserMessage } from '../../utils/commands';
import { CancelOrderModal } from './components/CancelOrderModal';
import { ConfirmReadyForPackingModal } from './components/ConfirmReadyForPackingModal';
import { ProceedWithUnfulfillableItemsModal } from './components/ProceedWithUnfulfillableItemsModal';
import { ShortPickTroubleshootStationView } from './ShortPickTroubleshoot.view';

export type ScanToteErrorType = {
  type: AlertMessageTypes;
  message: string;
};

export type SuccessMessageType = {
  type: AlertMessageTypes;
  textElement: ReactElement;
};

export const ShortPickTroubleshootStation = () => {
  const {
    appState: { shortPickTroubleshootState },
  } = useAppState();
  const { dispatchCommand } = useShortPickTroubleshootSocket();
  const [scanToteError, setScanToteError] = useState<
    ScanToteErrorType | undefined
  >(undefined);
  const [modal, setModal] = useState<ReactElement | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<
    SuccessMessageType | undefined
  >(undefined);

  if (!shortPickTroubleshootState) {
    // TODO(WMS-735): address orphaned todo
    // eslint-disable-next-line todo-plz/ticket-ref
    // TODO some sort of error screen?
    return <>Uh oh, something went wrong!</>;
  }

  const closeModal = () => setModal(undefined);

  const dispatchOpenShortPickTroubleshoot = async () => {
    await dispatchCommand({
      type: 'OPEN_SHORT_PICK_TROUBLESHOOT_STATION',
    });
  };

  const dispatchCloseShortPickTroubleshoot = async () => {
    await dispatchCommand({
      type: 'CLOSE_SHORT_PICK_TROUBLESHOOT_STATION',
    });
  };

  const dispatchResolveLater = async () => {
    await dispatchCommand({
      type: 'DEFER_SHORT_PICK_RESOLVE',
    });
    setSuccessMessage(undefined);
  };

  const dispatchResolveShortPick = async () => {
    const orderTote = shortPickTroubleshootState.currentToteId;
    const { result } = await dispatchCommand({
      type: 'RESOLVE_SHORT_PICK',
    });
    setModal(undefined);
    if (result.outcome === 'SUCCESS') {
      setSuccessMessage({
        type: AlertMessageTypes.Success,
        textElement: (
          <Text variant="body2" weight="regular" margin={0} tag="span">
            <Text variant="body2" weight="medium" margin={0} tag="span">
              Troubleshooting complete.
            </Text>{' '}
            Please transfer order tote #{orderTote} to Packing.
          </Text>
        ),
      });
    }
  };

  const dispatchCancelShortPickedOrder = async () => {
    const orderTote = shortPickTroubleshootState.currentToteId;
    const { result } = await dispatchCommand({
      type: 'CANCEL_SHORT_PICKED_ORDER',
    });
    setModal(undefined);
    if (result.outcome === 'SUCCESS') {
      setSuccessMessage({
        type: AlertMessageTypes.Info,
        textElement: (
          <Text variant="body2" weight="regular" margin={0} tag="span">
            <Text variant="body2" weight="medium" margin={0} tag="span">
              Order tote #{orderTote} was cancelled.
            </Text>{' '}
            Please alert your Manager.
          </Text>
        ),
      });
    }
  };

  const getAlertMessageType = (
    outcome: ScanShortPickTroubleshootToteOutcomeType['outcome']
  ): AlertMessageTypes => {
    switch (outcome) {
      case 'RESOLUTION_TOTE_ID_SCANNED':
        return AlertMessageTypes.Info;
      case 'RESOLUTION_TOTE_NOT_REQUIRED':
        return AlertMessageTypes.Warning;
      default:
        return AlertMessageTypes.Error;
    }
  };

  const isScanShortPickTroubleshootToteOutcome = (
    outcome: string
  ): outcome is ScanShortPickTroubleshootToteOutcomeType['outcome'] => {
    return !!ScanShortPickTroubleshootToteOutcome.options.find(
      (option) => option.shape.outcome.value === outcome
    );
  };

  const dispatchScanShortPickTroubleshootTote = async (toteId: string) => {
    const { result } = await dispatchCommand({
      type: 'SCAN_SHORT_PICK_TROUBLESHOOT_TOTE',
      toteId,
    });
    if (result.outcome === 'SUCCESS') {
      setScanToteError(undefined);
      return;
    }
    if (isScanShortPickTroubleshootToteOutcome(result.outcome)) {
      const message = mapCommandErrorToUserMessage(result);
      setScanToteError({
        message,
        type: getAlertMessageType(result.outcome),
      });
    }
  };

  const showConfirmReadyForPackingModal = () => {
    setModal(
      <ConfirmReadyForPackingModal
        closeModal={closeModal}
        readyForPacking={dispatchResolveShortPick}
      />
    );
  };

  const showProceedWithUnfulfillableItemsModal = (
    hasItemsToBeFulfilled: boolean
  ) => {
    setModal(
      <ProceedWithUnfulfillableItemsModal
        closeModal={closeModal}
        proceedToPacking={dispatchResolveShortPick}
        hasItemsToBeFulfilled={hasItemsToBeFulfilled}
      />
    );
  };

  const showCancelOrderModal = () => {
    setModal(
      <CancelOrderModal
        closeModal={closeModal}
        cancelOrder={dispatchCancelShortPickedOrder}
      />
    );
  };

  const markReadyForPacking = () => {
    if (shortPickTroubleshootState.items?.missing.length) {
      showProceedWithUnfulfillableItemsModal(
        shortPickTroubleshootState.items.inResolutionTote.length > 0
      );
    } else {
      showConfirmReadyForPackingModal();
    }
  };

  return (
    <ShortPickTroubleshootStationView
      clientState={shortPickTroubleshootState}
      scanToteError={scanToteError}
      dispatchOpenShortPickTroubleshoot={dispatchOpenShortPickTroubleshoot}
      dispatchCloseShortPickTroubleshoot={dispatchCloseShortPickTroubleshoot}
      dispatchScanShortPickTroubleshootTote={
        dispatchScanShortPickTroubleshootTote
      }
      dispatchResolveLater={dispatchResolveLater}
      markReadyForPacking={markReadyForPacking}
      cancelOrder={showCancelOrderModal}
      modal={modal}
      successMessage={successMessage}
    />
  );
};
