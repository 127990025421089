import { FormikErrors } from 'formik';
import { z } from 'zod';

import { lensPath, mergeAll, over } from 'ramda';

const zodErrorMap: z.ZodErrorMap = (issue, ctx) => {
  // If there is already a custom error message, return it
  if (issue.message) {
    return { message: issue.message };
  }

  const isInvalidNumber =
    issue.code === z.ZodIssueCode.invalid_type && issue.expected === 'number';

  if (isInvalidNumber) {
    return {
      message: `Please enter a valid ${issue.path[issue.path.length - 1]}`,
    };
  }

  return { message: ctx.defaultError };
};

export function transformZodErrorsToFormik<Values>(
  schema: z.ZodSchema<Values>
) {
  return function (values: Values): FormikErrors<Values> {
    const parseResult = schema.safeParse(values, { errorMap: zodErrorMap });

    if (!parseResult.success) {
      let errors: Record<string, string | Array<Record<string, string>>> = {};

      parseResult.error.issues.forEach((error) => {
        errors = mergeAll([
          errors,
          over(lensPath(error.path), () => error.message, errors),
        ]);
      });

      return errors as FormikErrors<Values>;
    }
    return {};
  };
}
