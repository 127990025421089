import { Divider, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import React, { ReactElement, useCallback } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { WarehouseRouteParams } from '../../../hooks/useWarehouseParam';
import { findBestLinkLabelForRoute } from '../../../router/util';
import { useAppState } from '../../../store';
import { setCurrentWarehouse } from '../../../store/actions';
import { Select } from '../Select';
import { NavigationMenu } from './Navigation.components';
import { Drawer, Section, Wrapper } from './Navigation.elements';

type Navigate = (path: string, state: WarehouseRouteParams) => void;

export interface NavigationLink {
  label: string;
  icon: React.FC;
  path?: string;
  onClick?: (item: NavigationLink, navigate: Navigate) => void;
}

export interface NavigationProps {
  navigationLinks?: NavigationLink[];
  settingsLinks?: NavigationLink[];
  devLinks?: NavigationLink[];
  navigate: Navigate;
}

export function Navigation({
  navigationLinks,
  settingsLinks,
  devLinks,
  navigate,
}: NavigationProps): ReactElement {
  const { appState, appDispatch } = useAppState();
  const { warehouses, currentWarehouse } = appState;
  const location = useLocation();
  const history = useHistory();
  const currentRoute = location.pathname;

  const allLinks: NavigationLink[] = [
    ...(navigationLinks || []),
    ...(settingsLinks || []),
    ...(devLinks || []),
  ];
  const allLinksWithoutWarehouseParam = allLinks.map((link) => ({
    ...link,
    ...(link?.path
      ? { path: generatePath(link.path, { warehouseId: undefined }) }
      : {}),
  }));
  const pathWithoutWarehouseId = currentWarehouse?.id
    ? currentRoute.split(`/${currentWarehouse.id}`).join('')
    : currentRoute;
  const currentScreen = findBestLinkLabelForRoute(
    allLinksWithoutWarehouseParam,
    pathWithoutWarehouseId
  );

  const updateCurrentWarehouse = useCallback(
    (uuid: string) => {
      appDispatch(setCurrentWarehouse(uuid));
    },
    [appDispatch]
  );

  const selectWarehouseHandler = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: any;
    }>
  ) => {
    const newWarehouseId = event.target.value;
    if (typeof newWarehouseId === 'string') {
      updateCurrentWarehouse(event.target.value);
      const [beforeWarehouse, afterWarehouse] =
        currentRoute.split('/warehouse');
      if (beforeWarehouse === currentRoute) {
        return;
      }
      const afterRouteCleaned = currentWarehouse?.id
        ? afterWarehouse.split(`${currentWarehouse.id}/`).join('')
        : afterWarehouse;
      const newRoute =
        beforeWarehouse +
        `/warehouse/${event.target.value}` +
        afterRouteCleaned;

      history.replace(newRoute);
    }
  };

  return (
    <>
      <Wrapper>
        <Drawer
          variant="permanent"
          className={'Drawer--is-open'}
          data-testid="Drawer"
        >
          <Section>
            {navigationLinks?.length && (
              <NavigationMenu
                links={navigationLinks}
                onClick={(item: NavigationLink) => {
                  if (item.onClick) {
                    item.onClick(item, navigate);
                  } else if (item.path) {
                    navigate(item.path, {
                      warehouseId: currentWarehouse?.id,
                    });
                  }
                }}
                currentScreen={currentScreen}
              />
            )}
          </Section>
          <Section separator={false}>
            {warehouses.length ? (
              <Select
                fullWidth
                label="Current warehouse"
                defaultValue={currentWarehouse?.id ?? ''}
                value={currentWarehouse?.id ?? ''}
                name="WarehouseSelect"
                testID="WarehouseSelect"
                onChange={selectWarehouseHandler}
              >
                {warehouses.map((warehouse) => (
                  <MenuItem key={warehouse.id} value={warehouse.id}>
                    {warehouse.name}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Typography>No warehouses Added</Typography>
            )}
            {settingsLinks?.length && (
              <NavigationMenu
                links={settingsLinks}
                onClick={(item: NavigationLink) => {
                  if (item.onClick) {
                    item.onClick(item, navigate);
                  } else if (item.path) {
                    navigate(item.path, {
                      warehouseId: currentWarehouse?.id,
                    });
                  }
                }}
                currentScreen={currentScreen}
              />
            )}
            {/* TODO: add check for if in dev environment */}
            <Divider />
            <Typography style={{ marginTop: '10px' }}>Dev helpers</Typography>
            {devLinks?.length && (
              <NavigationMenu
                links={devLinks}
                onClick={(item: NavigationLink) => {
                  if (item.onClick) {
                    item.onClick(item, navigate);
                  } else if (item.path) {
                    navigate(item.path, {
                      warehouseId: currentWarehouse?.id,
                    });
                  }
                }}
                currentScreen={currentScreen}
              />
            )}
          </Section>
        </Drawer>
      </Wrapper>
    </>
  );
}
