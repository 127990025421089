"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.intermediaryStationSocketStateSchema = exports.intermediaryStationSchema = exports.intermediaryStationStatuses = void 0;
const zod_1 = require("zod");
const featureFlags_1 = require("./featureFlags");
const fulfilmentOrder_1 = require("./fulfilmentOrder");
const merchant_1 = require("./merchant");
exports.intermediaryStationStatuses = zod_1.z.enum([
    'CLOSED',
    'AWAITING_TOTE',
    'ADJUSTING_TOTE',
    'AWAITING_ADJUSTMENT_CONFIRMATION',
]);
exports.intermediaryStationSchema = zod_1.z.object({
    warehouseId: zod_1.z.string(),
    conveyorBayId: zod_1.z.string(),
    stationId: zod_1.z.number(),
    toteId: zod_1.z.string().optional(),
    fulfilmentOrderId: zod_1.z.string().optional(),
    status: exports.intermediaryStationStatuses,
    releaseChannel: featureFlags_1.releaseChannel.optional(),
});
exports.intermediaryStationSocketStateSchema = exports.intermediaryStationSchema.extend({
    fulfilmentOrder: fulfilmentOrder_1.fulfilmentOrderSchema.optional(),
    merchantConfig: merchant_1.merchantWarehouseConfigSchema.optional(),
    packingNote: zod_1.z.string().optional(),
    isInsertRequired: zod_1.z.boolean().optional(),
});
