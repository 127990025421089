"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.systemApiSchema = void 0;
const zod_http_schemas_1 = require("zod-http-schemas");
exports.systemApiSchema = (0, zod_http_schemas_1.createHttpSchema)({
    'POST /warehouses/:warehouseId/skip-events': {
        requestBody: zod_http_schemas_1.z.object({
            user: zod_http_schemas_1.z.string().email(),
            eventsToSkip: zod_http_schemas_1.z.array(zod_http_schemas_1.z.object({
                id: zod_http_schemas_1.z.number(),
                type: zod_http_schemas_1.z.string(),
                reason: zod_http_schemas_1.z.union([zod_http_schemas_1.z.literal('INCIDENT_RECOVERY'), zod_http_schemas_1.z.literal('OTHER')]),
            })),
        }),
        responseBody: zod_http_schemas_1.z.union([
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('USER_IS_EMPTY') }),
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('EVENT_NOT_FOUND'), eventId: zod_http_schemas_1.z.number() }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('UNMATCHED_EVENT_TYPE'),
                eventId: zod_http_schemas_1.z.number(),
                expectedType: zod_http_schemas_1.z.string(),
                actualType: zod_http_schemas_1.z.string(),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('UNMATCHED_WAREHOUSE_ID'),
                eventId: zod_http_schemas_1.z.number(),
                expectedWarehouseId: zod_http_schemas_1.z.string(),
                actualWarehouseId: zod_http_schemas_1.z.string(),
            }),
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('SUCCESS') }),
        ]),
    },
});
