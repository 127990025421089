"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.intermediaryStationApiSchema = void 0;
const zod_1 = require("zod");
const zod_http_schemas_1 = require("zod-http-schemas");
const model_1 = require("../model");
exports.intermediaryStationApiSchema = (0, zod_http_schemas_1.createHttpSchema)({
    'POST /warehouses/:warehouseId/intermediary-station': {
        requestBody: zod_1.z.object({
            conveyorBayId: zod_1.z.string(),
        }),
        responseBody: zod_1.z.union([
            zod_1.z.object({
                outcome: zod_1.z.literal('SUCCESS'),
                stationId: zod_1.z.number(),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('INVALID_CONVEYOR_BAY_ID'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('CONVEYOR_BAY_NOT_FOUND'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('UNABLE_TO_GET_CONVEYOR_BAY_STATE'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('INVALID_BAY_TASK_TYPE'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('NO_SUCH_MULTIPURPOSE_BAY'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('CONVEYOR_BAY_ALREADY_ASSIGNED'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('CONVEYOR_NETWORK_ADDRESS_NOT_FOUND'),
            }),
        ]),
    },
    'POST /warehouses/:warehouseId/intermediary-station/configure': {
        requestBody: zod_1.z.object({
            stationId: zod_1.z.number(),
            conveyorBayId: zod_1.z.string(),
        }),
        responseBody: zod_1.z.union([
            zod_1.z.object({
                outcome: zod_1.z.literal('SUCCESS'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('INVALID_CONVEYOR_BAY_ID'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('INTERMEDIARY_STATION_NOT_FOUND'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('CONVEYOR_BAY_NOT_FOUND'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('UNABLE_TO_GET_CONVEYOR_BAY_STATE'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('INVALID_BAY_TASK_TYPE'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('INVALID_INTERMEDIARY_STATION_STATUS'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('NO_SUCH_MULTIPURPOSE_BAY'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('CONVEYOR_BAY_ALREADY_ASSIGNED'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('CONVEYOR_NETWORK_ADDRESS_NOT_FOUND'),
            }),
        ]),
    },
    'GET /warehouses/:warehouseId/intermediary-stations': {
        responseBody: zod_1.z.object({
            outcome: zod_1.z.literal('SUCCESS'),
            stations: zod_1.z.array(zod_1.z.object({
                intermediaryStation: model_1.intermediaryStationSchema,
                fulfilmentOrder: model_1.fulfilmentOrderSchema.or(zod_1.z.null()),
            })),
        }),
    },
});
