import React, { ReactElement } from 'react';

import { Tab, TabsContainer, Wrapper } from './Tabs.elements';

export interface TabItem {
  label: string;
  onClick?: () => void;
}

export interface TabsProps {
  items: TabItem[];
  scrollable?: boolean;
  withBorders?: boolean;
}

function a11yProps(index: number) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

export function Tabs({
  items,
  scrollable = false,
  withBorders = false,
}: TabsProps): ReactElement {
  const [currentTabIndex, setCurrentTabIndex] = React.useState(0);
  return (
    <Wrapper $borders={withBorders}>
      <TabsContainer
        value={currentTabIndex}
        onChange={(_, newValue) => setCurrentTabIndex(newValue)}
        indicatorColor="primary"
        variant={scrollable ? 'scrollable' : 'standard'}
      >
        {items.map((item, i: number) => (
          <Tab
            key={item.label}
            value={i}
            label={item.label}
            onClick={item.onClick}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...a11yProps(i)}
          />
        ))}
      </TabsContainer>
    </Wrapper>
  );
}
