import { FormHelperText, InputAdornment, TextField } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { InputTypes } from './Input';

interface InputProps {
  type: InputTypes;
}

export const Input = styled(TextField)<InputProps>`
  ${({ type }) =>
    type === 'file'
      ? css`
          input[type='file'] {
            opacity: 0;
          }
        `
      : null}
`;

export const Icon = InputAdornment;

export const HelperText = styled(FormHelperText)`
  margin-bottom: ${({ theme }) => theme.spacing(1.5) * -1}px;
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const FileName = styled.span`
  position: absolute;
  top: ${({ theme }) => theme.spacing(3)}px;
  left: ${({ theme }) => theme.spacing(2)}px;
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
`;
