import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface TileProps {
  backgroundImgSrc: string;
}

export const Wrapper = styled(Link)`
  border: 1px solid ${({ theme }) => theme.palette.grey[700]};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  color: ${({ theme }) => theme.palette.grey[700]};
  display: block;
  height: 160px;
  padding: ${({ theme }) => theme.spacing(2.5)}px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  > h2 {
    padding: ${({ theme }) => theme.spacing(2.5)}px 0 0 0;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 40px;
    width: 100%;
  }
`;

export const ImgWrapper = styled.div<TileProps>`
  background: url(${(props) => props.backgroundImgSrc}) no-repeat center;
  height: calc(100% - 40px);
`;
