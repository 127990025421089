import { StorageType, StorageUnit } from 'api-schema';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SnackbarDefaults } from '../../../constants/snackbarDefaults';
import { useStorageUnitsApi } from '../../../hooks/useStorageUnitsApi';
import { useWarehouseParam } from '../../../hooks/useWarehouseParam';
import { WAREHOUSE_PATHS } from '../../../router/paths';
import { useAppState } from '../../../store';
import { generatePathWithWarehouseId } from '../../../utils/generatePathWithWarehouseId';
import { printStorageBarcodes } from '../../../utils/printStorageBarcodes';
import { useDisableStorageUnits } from './WarehouseOverview.methods';
import { WarehouseOverviewView } from './WarehouseOverview.View';

export const WarehouseOverview: React.FC = () => {
  useWarehouseParam();
  const {
    appState: { currentWarehouse: warehouse },
  } = useAppState();
  const history = useHistory();
  const [selectedIds, setSelectedIds] = useState<Array<string | number>>([]);
  const [storageType, setStorageType] = useState<StorageType | undefined>();
  const [storageData, setStorageData] = useStorageUnitsApi(
    warehouse?.id,
    storageType
  );
  const disableStorageUnits = useDisableStorageUnits();
  const { enqueueSnackbar } = useSnackbar();

  if (!warehouse) {
    return <h3>Warehouse not found</h3>;
  }

  const selectedStorageUnits = storageData.filter((storageUnit) =>
    selectedIds.includes(storageUnit.id)
  );

  const handlePrintLabelClick = () => {
    if (selectedStorageUnits) {
      printStorageBarcodes(selectedStorageUnits, history, warehouse);
    }
  };

  const handleStorageTypeClick = (storageType?: StorageType) => {
    setStorageType(storageType);
  };

  const onEditWarehouseClick = () => {
    history.push(
      generatePathWithWarehouseId(WAREHOUSE_PATHS.EDIT_WAREHOUSE, {
        warehouseId: warehouse.id,
      })
    );
  };

  const handleDisableStorageUnitsClick = async () => {
    if (!selectedStorageUnits?.length) {
      return;
    }

    const storageUnitIds = selectedStorageUnits.map((su) => su.id);
    const storageUnits = await disableStorageUnits({
      storageUnitIds,
      status: 'DISABLED',
    });

    const updatedStorageData = storageData.map((storage): StorageUnit => {
      if (!storageUnitIds.includes(storage.id)) {
        return storage;
      }

      return { ...storage, status: 'DISABLED' };
    });

    setStorageData(updatedStorageData);

    enqueueSnackbar(
      `Disabled ${storageUnits.length} storage units`,
      SnackbarDefaults
    );
  };

  return (
    <WarehouseOverviewView
      warehouse={warehouse}
      selectedIds={selectedIds}
      setSelectedIds={setSelectedIds}
      onPrintLabelClick={handlePrintLabelClick}
      onDisableStorageUnitsClick={handleDisableStorageUnitsClick}
      onStorageTypeClick={handleStorageTypeClick}
      storageUnits={storageData}
      onEditWarehouseClick={onEditWarehouseClick}
    />
  );
};
