"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fulfilmentOrderSummaryRecordSchema = exports.fulfilmentOrderSummarySchema = void 0;
const zod_1 = require("zod");
const model_1 = require("../model");
// To work with the fulfilmentOrder reducer, the fulfilmentOrderSummary must implement the minimumFulfilmentOrder schema
exports.fulfilmentOrderSummarySchema = model_1.minimumFulfilmentOrderSchema.extend({
    labelPaths: model_1.fulfilmentOrderSchema.shape.labelPaths.optional(),
    externalOrderReference: model_1.fulfilmentOrderSchema.shape.externalOrderReference.optional(),
    merchantName: model_1.fulfilmentOrderSchema.shape.merchantName.optional(),
    serviceCode: model_1.fulfilmentOrderSchema.shape.serviceCode.optional(),
    carrierCode: model_1.fulfilmentOrderSchema.shape.carrierCode.optional(),
    insertStatus: model_1.fulfilmentOrderSchema.shape.insertStatus.optional(),
    trackingNumber: model_1.fulfilmentOrderSchema.shape.trackingNumber.optional(),
    parcels: model_1.fulfilmentOrderSchema.shape.parcels.optional(),
    updatedAt: zod_1.z.date().optional(),
    completedAt: zod_1.z.date().optional(),
    autostoreBinIdsUsed: zod_1.z.array(zod_1.z.number()),
    toteIdUsed: zod_1.z.string().optional(),
    picklists: model_1.picklistSchema.array().optional(),
});
const picklistRecordSchema = model_1.picklistSchema.extend({
    createdAt: zod_1.z.string().datetime().optional(),
    deadline: zod_1.z.string().datetime(),
});
exports.fulfilmentOrderSummaryRecordSchema = exports.fulfilmentOrderSummarySchema.extend({
    picklists: picklistRecordSchema.array().optional(),
});
