import Button from '@material-ui/core/Button';
import styled, { css } from 'styled-components';
import { ButtonVariant } from './Button';

interface WrapperProps {
  $buttonType: ButtonVariant;
}

export const Wrapper = styled(Button)<WrapperProps>`
  ${({ $buttonType, theme }) => {
    switch ($buttonType) {
      case 'primary':
        return css`
          background-color: ${theme.palette.primary.light};
        `;
      case 'heavy':
        return css`
          background-color: ${theme.palette.primary.main};
          padding-top: ${theme.spacing(2)}px;
          padding-bottom: ${theme.spacing(2)}px;
        `;
      default:
        return css`
          background-color: transparent;
          border-color: ${theme.palette.primary.main};
        `;
    }
  }}
  box-shadow: none;
  color: ${({ theme, $buttonType }) =>
    $buttonType === 'heavy' ? 'white' : theme.palette.primary.main};

  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[50]};
    color: ${({ theme }) => theme.palette.primary.main};
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }
`;

export const Icon = styled.span`
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  line-height: 1;
`;
