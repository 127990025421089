import React, { ReactElement } from 'react';
import { ReactComponent as CheckCircleIcon } from '../../../assets/img/icons/check-circle.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/img/icons/error-circle.svg';
import { ReactComponent as InfoIcon } from '../../../assets/img/icons/info-circle.svg';
import { ReactComponent as WarningIcon } from '../../../assets/img/icons/troubleshoot-icon.svg';
import { warehouseColours } from '../../../constants/colours';
import { Wrapper } from './AlertMessage.elements';

export enum AlertMessageTypes {
  Error = 'ERROR',
  Warning = 'WARNING',
  Success = 'SUCCESS',
  Info = 'INFO',
  Light = 'LIGHT',
  Default = 'DEFAULT',
}

export interface AlertMessageProps {
  children: React.ReactNode;
  type: AlertMessageTypes;
}

const getIcon = (type: AlertMessageTypes) => {
  switch (type) {
    case AlertMessageTypes.Default:
      return <InfoIcon fill={warehouseColours.info.dark} />;
    case AlertMessageTypes.Info:
      return <InfoIcon />;
    case AlertMessageTypes.Error:
      return <ErrorIcon fill={warehouseColours.danger.main} />;
    case AlertMessageTypes.Warning:
      return <WarningIcon />;
    case AlertMessageTypes.Success:
      return <CheckCircleIcon fill={warehouseColours.success.main} />;
    case AlertMessageTypes.Light:
      return <></>;
    default:
      return <ErrorIcon />;
  }
};

export function AlertMessage({
  children,
  type,
}: AlertMessageProps): ReactElement {
  return (
    <Wrapper $type={type} data-testid="alert-message">
      {getIcon(type)}
      {children}
    </Wrapper>
  );
}
