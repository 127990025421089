import { useFormik } from 'formik';
import {
  ChangeEvent,
  KeyboardEvent,
  MutableRefObject,
  ReactElement,
  useRef,
  useState,
} from 'react';
import { ReactComponent as TroubleshootIcon } from '../../../../assets/img/icons/troubleshoot-icon.svg';
import { usePickPortSocket } from '../../../../components/common/SocketProvider';
import { Button } from '../../../../components/warehouse/Button';
import { Container } from '../../../../components/warehouse/Container';
import { InputField } from '../../../../components/warehouse/InputField';
import { StepNumber } from '../../../../components/warehouse/StepNumber';
import { getFormHandlers } from '../../../../utils/forms/getFormHandlers';
import { transformZodErrorsToFormik } from '../../../../utils/forms/transformZodErrorsToFormik';
import { Loading } from '../Loading';
import { ResetLinksModal } from '../ResetLinksModal';
import { ToteLinkingFormSchema, ToteLinkingFormType } from './model';
import { BodyText, HeaderText, Wrapper } from './ToteLinkingForm.elements';

const LOCATION_FIELD_ID = 'locationIdField';
const TOTE_FIELD_ID = 'toteIdField';
const DEFAULT_TOTE_PLACEHOLDER = 'Scan tote ID';

export interface ToteLinkingFormProps {
  currentLocation: number;
  isLastToteLinked: boolean;
  locationToLinkCount: number;
  isRelinking: boolean;
}

export const locationColours: Record<number, string> = {
  1: 'green',
  2: 'orange',
  3: 'blue',
  4: 'yellow',
  5: 'purple',
  6: 'red',
} as const;

type FieldState = 'VALID' | 'INVALID' | null;

export function ToteLinkingForm({
  currentLocation,
  isLastToteLinked,
  locationToLinkCount,
  isRelinking,
}: ToteLinkingFormProps): ReactElement {
  const { dispatchCommand } = usePickPortSocket();

  const formik = useFormik<ToteLinkingFormType>({
    initialValues: {
      locationId: '',
      toteId: '',
    },
    onSubmit: async (formData) => {
      const { result } = await dispatchCommand({
        type: 'CONFIRM_TOTE_LINK',
        locationId: formData.locationId,
        toteId: formData.toteId,
      });

      if (result.outcome !== 'SUCCESS') {
        //TODO we need to find out what the error actually was
        setFieldError('locationId', 'Invalid');
        setFieldError('toteId', 'Invalid');
        return;
      }

      formik.resetForm();
      locationRef.current?.focus();
    },
    validate: transformZodErrorsToFormik<ToteLinkingFormType>(
      ToteLinkingFormSchema
    ),
    validateOnChange: false,
    validateOnBlur: false,
  });

  const { setFieldTouched, setFieldValue, setFieldError } = formik;

  const { handleInputChange } = getFormHandlers({
    setFieldTouched,
    setFieldValue,
  });

  const [locationState, setLocationState] = useState<FieldState>(null);
  const [toteState, setToteState] = useState<FieldState>(null);
  const [totePlaceholder, setTotePlaceholder] = useState(
    DEFAULT_TOTE_PLACEHOLDER
  );
  const [resetModalFlag, setResetModalFlag] = useState<boolean>(false);

  const locationRef = useRef<HTMLInputElement>(null);
  const toteRef = useRef<HTMLInputElement>(null);
  const confirmButtonRef = useRef<HTMLButtonElement>(null);

  const validateLocationField = async () => {
    await setFieldTouched('locationId', true);
    const errors = await formik.validateForm();

    if (errors.locationId) {
      return;
    }

    const { locationId } = formik.values;

    const { result } = await dispatchCommand({
      type: 'SCAN_TOTE_LOCATION',
      locationId,
    });

    setFieldTouched('toteId', false);

    if (result.outcome !== 'SUCCESS') {
      setFieldError('locationId', 'Invalid');
      setLocationState('INVALID');
      setFieldValue('locationId', '');
      locationRef.current?.focus();
      setFieldTouched('toteId', false);
      return;
    }

    setLocationState('VALID');
    setFieldError('locationId', undefined);
  };

  const validateToteField = async () => {
    await setFieldTouched('toteId', true);
    const errors = await formik.validateForm();
    setTotePlaceholder(DEFAULT_TOTE_PLACEHOLDER);

    if (errors.toteId) {
      return;
    }

    const { locationId, toteId } = formik.values;

    const { result } = await dispatchCommand({
      type: 'SCAN_TOTE',
      locationId,
      toteId,
    });

    if (result.outcome !== 'SUCCESS') {
      setFieldError('toteId', 'Invalid');
      setToteState('INVALID');
      setFieldValue('toteId', '');
      toteRef.current?.focus();

      if (result.outcome === 'INVALID_TOTE' && result.error) {
        setTotePlaceholder(result.error);
      }

      return;
    }

    setToteState('VALID');
    setFieldError('toteId', undefined);
  };

  const handleEnterKey =
    (
      nextField: MutableRefObject<HTMLInputElement | HTMLButtonElement | null>
    ) =>
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        nextField.current?.focus();
      }
    };

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldTouched(e.target.name, false);

    if (e.target.id === LOCATION_FIELD_ID) {
      setLocationState(null);
    }

    if (e.target.id === TOTE_FIELD_ID) {
      setToteState(null);
    }

    handleInputChange(e);
  };

  const handleUnlinkClick = async () => {
    const { result } = await dispatchCommand({
      type: 'CANCEL_TOTE_LINK',
      locationId: formik.values.locationId,
      toteId: formik.values.toteId,
    });

    if (result.outcome === 'SUCCESS') {
      formik.resetForm();
      locationRef.current?.focus();
    }
  };

  const Buttons = [
    <Button
      variant="secondary"
      fullWidth
      onClick={() => {
        setResetModalFlag(true);
      }}
      key="tote-linking-form-reset-links-button"
    >
      {isRelinking ? 'Reset links' : 'Reset all links'}
    </Button>,
    <Button
      variant="warning"
      icon={<TroubleshootIcon />}
      fullWidth
      key="tote-linking-form-troubleshoot-button"
    >
      Troubleshoot
    </Button>,
  ];

  const currentColour = locationColours[currentLocation];
  const haveAllLocationsLinked = currentLocation === -1;

  // When all locations are either in a LINKED state, this spinner
  // will be shown until the picking starts
  if (haveAllLocationsLinked) {
    return <Loading message="Linking Totes" />;
  }

  return (
    <Container buttons={Buttons} padding="vertical" alignCenter>
      <Wrapper>
        <HeaderText>Link the totes</HeaderText>
        {/* TODO(WMS-1469) use PickingSteps component */}
        <BodyText data-testid="step-1-text">
          <StepNumber>1</StepNumber> Scan the {currentColour} location on the
          table
        </BodyText>
        <BodyText data-testid="step-2-text">
          <StepNumber>2</StepNumber> Scan the tote in the {currentColour}{' '}
          location
        </BodyText>
        <InputField
          placeholderText={`Scan ${currentColour} location`}
          isValid={
            formik.touched.locationId &&
            !formik.errors.locationId &&
            locationState === 'VALID'
          }
          hasError={
            (formik.touched.locationId && !!formik.errors.locationId) ||
            locationState === 'INVALID'
          }
          onChange={handleFieldChange}
          onBlur={validateLocationField}
          onKeyPress={handleEnterKey(toteRef)}
          id={LOCATION_FIELD_ID}
          name="locationId"
          value={formik.values.locationId}
          ref={locationRef}
          fullWidth
          autoFocus
        />
        <InputField
          placeholderText={totePlaceholder}
          hasError={
            (formik.touched.toteId && !!formik.errors.toteId) ||
            toteState === 'INVALID'
          }
          onChange={handleFieldChange}
          onBlur={validateToteField}
          onKeyPress={handleEnterKey(confirmButtonRef)}
          id={TOTE_FIELD_ID}
          name="toteId"
          value={formik.values.toteId}
          ref={toteRef}
          fullWidth
          embeddedButton={
            formik.touched.toteId &&
            !formik.errors.toteId &&
            toteState === 'VALID' && (
              <Button
                variant="tertiary"
                size="compact"
                onClick={handleUnlinkClick}
              >
                Unlink
              </Button>
            )
          }
        />
        <Button
          variant="primary"
          fullWidth
          onClick={formik.submitForm}
          testId="confirm-link-button"
          ref={confirmButtonRef}
        >
          {isLastToteLinked ? 'Start picking' : 'Confirm link'}
        </Button>
        <ResetLinksModal
          isVisible={resetModalFlag}
          hideModal={() => setResetModalFlag(false)}
          unlinkTotes={async () => {
            await dispatchCommand({
              type: 'UNLINK_ALL_TOTES',
            });
            setResetModalFlag(false);
            setFieldValue('locationId', '');
            setFieldValue('toteId', '');
            locationRef.current?.focus();
          }}
          locationCount={locationToLinkCount}
          isRelinking={isRelinking}
        />
      </Wrapper>
    </Container>
  );
}
