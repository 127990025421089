import { ItemToBeFulfilledType, ShortPickItemType } from 'api-schema/lib/model';
import { Box } from '../../../../components/warehouse/Box';
import { Button } from '../../../../components/warehouse/Button';
import { StickyContainer } from '../../../../components/warehouse/StickyContainer';
import { Text } from '../../../../components/warehouse/Text';
import { ListItems } from '../ListItems';
import {
  RecipientText,
  StickyFooterInnerWrapper,
  TitleInnerWrapper,
} from './ShortPickTroubleshootToteCheck.elements';

export type ShortPickTroubleshootToteCheckProps = {
  resolveLater: () => void;
  markReadyForPacking: () => void;
  cancelOrder: () => void;
  orderId: string;
  fulfilledItems: ShortPickItemType[];
  toBeFulfilledItems: ItemToBeFulfilledType[];
  unfulfillableItems: ShortPickItemType[];
  recipientName: string;
  merchantName: string;
  fulfilledToteId: string;
};

export function ShortPickTroubleshootToteCheck({
  resolveLater,
  markReadyForPacking,
  cancelOrder,
  orderId,
  recipientName,
  merchantName,
  fulfilledToteId,
  fulfilledItems,
  toBeFulfilledItems,
  unfulfillableItems,
}: ShortPickTroubleshootToteCheckProps) {
  return (
    <StickyContainer
      footerButtonAlignType="space-between"
      stickyFooter={[
        <StickyFooterInnerWrapper>
          {unfulfillableItems.length > 0 ? (
            <Button variant="danger" onClick={cancelOrder}>
              Cancel order
            </Button>
          ) : undefined}
        </StickyFooterInnerWrapper>,
        <StickyFooterInnerWrapper>
          <Button variant="secondary" onClick={resolveLater}>
            Resolve later
          </Button>
          <Button
            onClick={markReadyForPacking}
            disabled={toBeFulfilledItems.some(
              ({ tote }) => tote.toteStatus === 'PENDING'
            )}
            testId="packing-ready-button"
          >
            Ready for packing
          </Button>
        </StickyFooterInnerWrapper>,
      ]}
    >
      <Box padding="0 24px">
        <Box as="header" paddingTop="20px">
          <TitleInnerWrapper>
            <Text variant="h1" tag="h1" weight="medium" margin={0}>
              Order #{orderId}
            </Text>
            <div>
              <RecipientText variant="body1" tag="span" weight="medium">
                Recipient:
              </RecipientText>
              <Text variant="body1" tag="span">
                {recipientName}
              </Text>
            </div>
          </TitleInnerWrapper>
          <Text variant="body1" tag="h2" margin={0}>
            {merchantName}
          </Text>
        </Box>
        {unfulfillableItems.length > 0 && (
          <Box as="section" padding="10px 0">
            <ListItems
              type="unfulfillable"
              fulfilledToteId={fulfilledToteId}
              items={unfulfillableItems}
            />
          </Box>
        )}
        {toBeFulfilledItems.length > 0 && (
          <Box as="section" padding="10px 0">
            <ListItems
              type="toBeFulfilled"
              fulfilledToteId={fulfilledToteId}
              items={toBeFulfilledItems}
            />
          </Box>
        )}
        {fulfilledItems.length > 0 && (
          <Box as="section" padding="10px 0">
            <ListItems
              type="fulfilled"
              fulfilledToteId={fulfilledToteId}
              items={fulfilledItems}
            />
          </Box>
        )}
      </Box>
    </StickyContainer>
  );
}
