import { ReactComponent as ErrorCircleIcon } from '../../../../assets/img/icons/error-circle.svg';
import { Button } from '../../../../components/warehouse/Button';
import { Modal } from '../../../../components/warehouse/Modal';
import { Text } from '../../../../components/warehouse/Text';
import { pluralise } from '../../../../utils/pluralise';

export type ResetLinksModalProps = {
  isVisible: boolean;
  unlinkTotes: () => void;
  hideModal: () => void;
  locationCount: number;
  isRelinking: boolean;
};

export const ResetLinksModal = ({
  isVisible,
  unlinkTotes,
  hideModal,
  locationCount,
  isRelinking,
}: ResetLinksModalProps) => (
  <Modal
    isVisible={isVisible}
    variant="danger"
    header={{
      text: isRelinking ? 'Reset links?' : 'Reset all links?',
      icon: <ErrorCircleIcon />,
    }}
    actions={[
      <Button variant="secondary" fullWidth onClick={hideModal}>
        Cancel
      </Button>,
      <Button
        variant="danger"
        fullWidth
        onClick={unlinkTotes}
        children={isRelinking ? 'Reset links' : 'Reset all links'}
      ></Button>,
    ]}
  >
    <Text variant="body1">
      {isRelinking
        ? `This action will unlink the ${pluralise(
            'replenished tote',
            locationCount
          )} from the system. You will need to relink each tote to start picking.`
        : `This action will unlink all ${pluralise(
            'tote',
            locationCount
          )} from the system. You will need to relink each tote to start picking.`}
    </Text>
  </Modal>
);
