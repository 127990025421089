import { Box, CircularProgress } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useEffect, useState } from 'react';
import { Button } from '../../../../../components/admin/Button';
import { Container } from '../../../../../components/admin/Container';
import { BinMessageBox } from './LoadingSpinnerPanel.elements';

type Props = {
  message?: string;
  onCheckBinHasArrived?: () => void;
  isManualBinArrivedEnabled?: boolean;
};

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const LoadingSpinnerPanel = ({
  message,
  onCheckBinHasArrived,
  isManualBinArrivedEnabled,
}: Props) => {
  const [isButtonVisible, setButtonVisibility] = useState(false);
  const DISPLAY_CHECK_BIN_BUTTON_TIMEOUT_MS = 60000;

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setButtonVisibility(true);
    }, DISPLAY_CHECK_BIN_BUTTON_TIMEOUT_MS);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  if (onCheckBinHasArrived) {
    const handleCLick = () => {
      setButtonVisibility(false);
      onCheckBinHasArrived();
    };

    return (
      <Container>
        <Box>
          {isButtonVisible && isManualBinArrivedEnabled && (
            <Alert severity="error">
              Please make sure the bin has arrived before pressing the button.
            </Alert>
          )}
        </Box>
        <BinMessageBox>
          <CircularProgress />
          {message && <Box marginLeft="15px">{message}</Box>}
        </BinMessageBox>
        <Box>
          {isButtonVisible && (
            <Button
              fullWidth
              variant="heavy"
              onClick={handleCLick}
              data-testid="check-bin-has-arrived"
            >
              Confirm bin has arrived
            </Button>
          )}
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <BinMessageBox>
        <CircularProgress />
        {message && <Box marginLeft="15px">{message}</Box>}
      </BinMessageBox>
    </Container>
  );
};
