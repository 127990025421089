import { Button } from '../../../../components/warehouse/Button';
import { Modal } from '../../../../components/warehouse/Modal';
import { Text } from '../../../../components/warehouse/Text';

export type NextItemModalProps = {
  goBack: () => void;
  nextItem: () => void;
};

export const NextItemModal = ({ goBack, nextItem }: NextItemModalProps) => (
  <Modal
    isVisible={true}
    actions={[
      <Button variant="secondary" fullWidth onClick={goBack}>
        Cancel
      </Button>,
      <Button variant="primary" fullWidth onClick={nextItem} id="next-item">
        Yes, confirm
      </Button>,
    ]}
  >
    <Text variant="h3">Confirm pick?</Text>
  </Modal>
);
