import styled from 'styled-components';

export type ButtonBaseProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonBase = styled.button`
  box-sizing: border-box;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-decoration: none;
  background-color: transparent;
  font-family: inherit;
  padding: 0;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
`;
