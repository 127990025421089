import {
  List,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { ReactElement } from 'react';

import { NavigationLink } from './Navigation';
import * as Navigation from './Navigation.elements';

interface MenuProps {
  links: NavigationLink[];
  onClick: (item: NavigationLink) => void;
  currentScreen: string;
}

export const NavigationMenu = ({
  links,
  onClick,
  currentScreen,
}: MenuProps): ReactElement => (
  <List component="nav">
    {links.map((item) => (
      <Navigation.Item
        button
        key={item.label}
        selected={item.label === currentScreen}
        onClick={() => onClick(item)}
      >
        <ListItemIcon>
          <item.icon />
        </ListItemIcon>
        <ListItemText primary={item.label} />
      </Navigation.Item>
    ))}
  </List>
);

interface MobileHeaderProps {
  onClickMenu: () => void;
  title: string;
}

export const MobileHeader = ({
  onClickMenu,
  title,
}: MobileHeaderProps): ReactElement => (
  <Navigation.AppBar
    color="inherit"
    position="fixed"
    data-testid="mobile-AppBar"
  >
    <Toolbar>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={onClickMenu}
      >
        <MenuIcon />
      </IconButton>
      <Typography variant="h6">{title}</Typography>
    </Toolbar>
  </Navigation.AppBar>
);
