import { Button } from '../../../../components/warehouse/Button';
import { TroubleshootLabelModal } from '../TroubleshootLabelModal';
import {
  ButtonWrapper,
  LabelPrintingWrapper,
  PdfView,
} from './TroubleshootLabelPrinting.elements';

export type TroubleshootLabelPrintingProps = {
  labelUrl: string;
  showTranferModal: boolean;
  revertToTroubleshootLabel: () => Promise<void>;
  handlerParcelTransfer: () => Promise<void>;
  printingCompleteButtonClick: () => void;
};

export const TroubleshootLabelPrinting = ({
  labelUrl,
  showTranferModal,
  printingCompleteButtonClick,
  revertToTroubleshootLabel,
  handlerParcelTransfer,
}: TroubleshootLabelPrintingProps): JSX.Element => {
  return (
    <>
      <LabelPrintingWrapper>
        <ButtonWrapper>
          <Button
            variant="primary"
            onClick={printingCompleteButtonClick}
            testId="troubleshoot-label-printing-complete-button"
          >
            Printing complete
          </Button>
        </ButtonWrapper>
        <PdfView src={labelUrl} />
        <TroubleshootLabelModal
          onCancel={revertToTroubleshootLabel}
          onTransferred={handlerParcelTransfer}
          isVisible={showTranferModal}
        />
      </LabelPrintingWrapper>
    </>
  );
};
