import { PartitionsPerBin } from 'api-schema';
import { PartitionContainer } from '../BinGuide/BinGuide.elements';
import { Container, GuideContainer, PartitionBox } from './BinRadio.elements';

type Props = {
  numPartitions: PartitionsPerBin;
  onClick: Function;
  active?: boolean;
};

export const BinRadio = ({ numPartitions, active, onClick }: Props) => {
  return (
    <Container
      m={2}
      p={2}
      display={'flex'}
      alignItems={'center'}
      $active={active}
      onClick={() => onClick()}
      role="radio"
      aria-checked={active}
      data-testid={`bin-partition-radio-${numPartitions}`}
    >
      <GuideContainer flexGrow={1} $active={active}>
        <PartitionContainer $numPartitions={numPartitions}>
          {Array.from({ length: numPartitions }, (_, i) => (
            <PartitionBox
              key={i}
              $target={!active}
              $numPartitions={numPartitions}
              className="partition-box"
            />
          ))}
        </PartitionContainer>
      </GuideContainer>
    </Container>
  );
};
