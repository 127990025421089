import { StorageType, StorageUnit } from 'api-schema';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { isNotErrorResponse } from '../apiClient';
import { useApiClient } from './useApiClient';

export type SetStorageUnitsAction = Dispatch<SetStateAction<StorageUnit[]>>;

export const useStorageUnitsApi = (
  warehouseId?: string,
  storageType?: StorageType
): [StorageUnit[], SetStorageUnitsAction] => {
  const [storageData, setStorageData] = useState<StorageUnit[]>([]);
  const apiClient = useApiClient();
  useEffect(() => {
    if (!warehouseId) {
      return;
    }
    (async () => {
      const { data, status } = await apiClient.get(
        '/warehouses/:id/storage-units',
        {
          params: { id: warehouseId },
          queryParams: { type: storageType },
        }
      );
      if (isNotErrorResponse(data, status)) {
        setStorageData(data.storageUnits);
      }
    })();
  }, [warehouseId, storageType, apiClient]);

  return [storageData, setStorageData];
};
