"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PackAndDispatchClientStateModel = exports.PackAndDispatchStateModel = exports.labelPrintingFailureReasonsSchema = exports.stringIdSchema = exports.labelPathsSchema = exports.parcelCountSchema = exports.insertStatus = exports.parcelSizeSchema = exports.PACKING_STATION_STATUSES = void 0;
const z = __importStar(require("zod"));
const featureFlags_1 = require("./featureFlags");
const picklist_1 = require("./picklist");
const storage_1 = require("./storage");
exports.PACKING_STATION_STATUSES = [
    'OPEN',
    'CLOSED',
    'CHECKING',
    'SELECTING_PARCEL',
    'PACKING_AND_LABEL_PRINTING',
    'PARCEL_SORTING',
    'TROUBLESHOOT_LABEL_PRINTING',
    'TROUBLESHOOT_LABEL_COMPLETED',
    'LABEL_PRINTING',
    'LABEL_PRINTING_NETWORK',
];
exports.parcelSizeSchema = z.enum([
    'EXTRA_SMALL',
    'SMALL',
    'MEDIUM',
    'LARGE',
]);
exports.insertStatus = z.enum(['SKIPPED', 'INSERTED']);
exports.parcelCountSchema = z.number().gte(1).optional();
exports.labelPathsSchema = z.object({
    pdf: z.string(),
    zpl: z.string().optional(),
});
exports.stringIdSchema = z.string().min(1);
exports.labelPrintingFailureReasonsSchema = z.union([
    z.literal('FAILED_TO_FETCH_LABEL'),
    z.literal('FAILED_TO_PRINT_LABEL'),
    z.literal('FAILED_TO_CONNECT_PRINTER'),
    z.literal('FAILED_TO_ENQUEUE_PRINTING_JOB'),
    z.literal('FULFILMENT_ORDER_NOT_FOUND'),
    z.literal('PACKING_STATION_NOT_FOUND'),
    z.literal('PICKLIST_ID_NOT_FOUND'),
    z.literal('PACKING_TROUBLESHOOT_REASON_NOT_FOUND'),
    z.literal('PRINTER_CLIENT_NOT_FOUND'),
    z.literal('INVALID_PACKING_STATION_STATE'),
    z.literal('INVALID_PACKING_STATION_STATUS'),
    z.literal('INVALID_FULFILMENT_ORDER_STATUS'),
]);
exports.PackAndDispatchStateModel = z.object({
    warehouseId: z.string(),
    status: z.enum(exports.PACKING_STATION_STATUSES),
    stationId: z.number(),
    toteId: z.string().optional(),
    recipientName: z.string().optional(),
    picklistId: exports.stringIdSchema.optional(),
    lastSuccessfulOrderId: exports.stringIdSchema.optional(),
    labelPaths: exports.labelPathsSchema.optional(),
    fulfilmentOrderId: z.string().optional(),
    parcelSize: exports.parcelSizeSchema.optional(),
    hasLabelError: z.boolean().optional(),
    troubleshootLabelPath: exports.labelPathsSchema.optional(),
    merchantName: z.string().optional(),
    tags: z.array(z.string()).optional(),
    releaseChannel: featureFlags_1.releaseChannel.optional(),
    isInternationalShipment: z.boolean().optional(),
    merchantId: z.string().optional(),
    parcelCount: exports.parcelCountSchema.optional(),
    insertStatus: exports.insertStatus.optional(),
    isPrintingTroubleshootLabel: z.boolean().optional(),
    isTroubleshooting: z.boolean().optional(),
    labelPrintingErrorMessage: z.string().optional(),
    hasInsertSelectedError: z.boolean().optional(),
    hasLabelNotPrintedError: z.boolean().optional(),
    labelPrintAttempts: z.number().optional(),
    isResolvingTroubleshootOrder: z.boolean().optional(),
    blindCountTotalItemQuantity: z.number().optional(),
});
exports.PackAndDispatchClientStateModel = exports.PackAndDispatchStateModel.extend({
    tote: storage_1.toteSchema.optional(),
    picklist: picklist_1.picklistSchema.optional(),
    isInsertRequired: z.boolean().optional(),
    packingNote: z.string().optional(),
});
