import React, { ForwardedRef, forwardRef, ReactElement } from 'react';
import { debounce } from '../../../utils/debounce';
import { Text, Wrapper } from './Button.elements';

export type ButtonVariant =
  | 'primary'
  | 'primaryLight'
  | 'secondary'
  | 'tertiary'
  | 'danger'
  | 'dangerLight'
  | 'warning'
  | 'ghost'
  | 'criticalMessagePrimary'
  | 'criticalMessageGhost';
export type SizeVariant = 'large' | 'normal' | 'compact';

export type ButtonProps = {
  children: React.ReactNode;
  variant?: ButtonVariant;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  icon?: React.ReactNode;
  size?: SizeVariant;
  fullWidth?: boolean;
  type?: 'submit' | 'reset' | 'button';
  id?: string;
  testId?: string;
  disabled?: boolean;
  debounceTimeoutMs?: number;
  className?: string;
};

export const Button = forwardRef(
  (
    {
      children,
      variant = 'primary',
      icon: Icon,
      size = 'normal',
      fullWidth = false,
      type = 'button',
      onClick,
      id,
      testId,
      disabled,
      debounceTimeoutMs,
      className,
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ): ReactElement => {
    const onClickHandler = onClick
      ? debounce(onClick, debounceTimeoutMs)
      : undefined;

    return (
      <Wrapper
        variant={variant}
        type={type}
        size={size}
        fullWidth={fullWidth}
        onClick={onClickHandler}
        id={id}
        data-testid={testId}
        ref={ref}
        disabled={disabled}
        className={className}
      >
        {Icon}
        <Text>{children}</Text>
      </Wrapper>
    );
  }
);
