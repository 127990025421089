import { ErrorBoundary } from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AdminApp } from './main/AdminApp';
import { DeviceConfigApp } from './main/DeviceConfigApp';
import { PackAndDispatchStationApp } from './main/PackAndDispatch';
import { PickStationApp } from './main/PickStationApp';
import { PutawayPortApp } from './main/PutawayPortApp';
import { STORAGE_UNIT_PATHS } from './router/paths';
import { PrintBarcodes } from './screens/admin/PrintBarcodes';
import { AppStateProvider } from './store';

import '@fortawesome/fontawesome-free/css/all.min.css';
import { AuthTokenMissingBanner } from './components/common/AuthTokenMissingBanner';
import './index.css';
import { IntermediaryStationApp } from './main/IntermediaryStationApp';
import { Notifications } from './main/Notifications';
import { ShortPickTroubleshootStationApp } from './main/ShortPickTroubleshootApp';

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary fallback={<>An error has occurred.</>}>
      <AppStateProvider>
        <Notifications>
          <BrowserRouter>
            <AuthTokenMissingBanner />
            <Switch>
              <Route
                exact
                path={STORAGE_UNIT_PATHS.PRINT_STORAGE}
                component={PrintBarcodes}
              />
              <Route path="/admin" component={AdminApp} />
              <Route
                path="/warehouse/:warehouseId/replenishment-port/:portId"
                component={PutawayPortApp}
              />
              <Route
                path="/warehouse/:warehouseId/pick-station/:portId"
                component={PickStationApp}
              />
              <Route
                path="/warehouse/:warehouseId/pack-and-dispatch/:stationId"
                component={PackAndDispatchStationApp}
              />
              <Route
                path="/warehouse/:warehouseId/intermediary-station/:stationId"
                component={IntermediaryStationApp}
              />
              <Route
                path="/warehouse/:warehouseId/short-pick-troubleshoot/:stationId"
                component={ShortPickTroubleshootStationApp}
              />
              <Route path="/" component={DeviceConfigApp} />
            </Switch>
          </BrowserRouter>
        </Notifications>
      </AppStateProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
