import { Checkbox } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { useApiClient } from '../../../hooks/useApiClient';

type Props = {
  onSubmit: () => void;
};

export const AddWarehouse: React.FC<Props> = ({ onSubmit }) => {
  const [name, setName] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [isTest, setIsTest] = useState<boolean>(false);
  const apiClient = useApiClient();

  const onSubmitHandler = (e: any) => {
    e.preventDefault();
    (async () => {
      try {
        await apiClient.post('/warehouses', {
          body: {
            name,
            address,
            isTest,
          },
        });
        setName('');
        setAddress('');
        setIsTest(false);
        onSubmit();
      } catch (error) {
        // error displayed by apiClient
      }
    })();
  };

  return (
    <div>
      <h2>Add Warehouse</h2>
      <form onSubmit={onSubmitHandler}>
        <div>
          <label htmlFor="name">Warehouse Name</label>
          <input
            type="text"
            name="name"
            id="name"
            onChange={(e) => setName(e.target.value)}
            placeholder="e.g. Bourke Road"
          />
        </div>
        <div>
          <label htmlFor="address">Address</label>
          <input
            type="text"
            name="address"
            id="address"
            onChange={(e) => setAddress(e.target.value)}
            placeholder="e.g. 123 Fake St"
          />
        </div>
        <div>
          <label htmlFor="isTest">Is test:</label>
          <Checkbox
            name="isTest"
            id="isTest"
            onChange={(e) => setIsTest(e.target.checked)}
            checked={isTest}
          />
        </div>
        <button onClick={onSubmitHandler}>ADD</button>
      </form>
    </div>
  );
};
