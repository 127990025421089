import LoopIcon from '@material-ui/icons/Loop';
import { FC } from 'react';

type Props = {
  isLoading: boolean;
};

export const Loadable: FC<Props> = ({ children, isLoading }) => (
  <>
    {isLoading ? (
      <div className="fa-spin" style={{ animationDirection: 'reverse' }}>
        <LoopIcon />
      </div>
    ) : (
      children
    )}
  </>
);
