import styled from 'styled-components';

export const LabelPrintingWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-width: fill-available;

  @media (min-width: 768px) {
    min-width: 720px;
  }
`;

export const ButtonWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(2)};
  align-self: flex-end;
`;

export const PdfView = styled.iframe`
  flex: 1;
`;
