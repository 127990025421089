import { mix } from 'polished';
import styled, { css } from 'styled-components';
import { ButtonBase } from '../../../../../components/warehouse/ButtonBase';
import { SPACING_BASE } from '../../../../../themes/WarehouseTheme';

type TileButtonProps = {
  $count: number;
  $isSelected?: boolean;
};

export const TileButton = styled(ButtonBase)<TileButtonProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  flex: 1 0
    ${({ $count }) =>
      ` calc((100% - ${($count - 1) * (SPACING_BASE * 1.6)}px) / ${$count});`};
  box-sizing: border-box;
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  font-weight: ${({ theme }) => theme.typography.body1.fontWeight};
  border-color: ${({ theme }) => theme.palette.grey[5]};
  box-shadow: 0 3px 3px 0px rgba(0, 0, 0, 0.2);
  background: ${({ theme }) => theme.palette.grey[1]};

  &:active {
    border: 3px solid ${({ theme }) => theme.palette.success.main};
    background: ${({ theme }) =>
      mix(0.6, theme.palette.success.light, theme.palette.common.white)};
  }

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      border: 3px solid ${({ theme }) => theme.palette.success.main};
      background: ${({ theme }) =>
        mix(0.6, theme.palette.success.light, theme.palette.common.white)};
    `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(1.6)};
  height: 100%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
`;
