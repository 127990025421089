import { ReactElement } from 'react';
import ItemNotFoundImage from '../../../assets//img/item-not-found.svg';
import {
  Barcode,
  ImgWrapper,
  ItemImg,
  ItemInfoWrapper,
  Name,
  Options,
  QuantityWrapper,
  RetailUnitWrapperVariant,
  Wrapper,
} from './RetailUnit.elements';

export interface RetailUnitProps {
  item: {
    quantity?: number;
    barcode: string;
    name?: string;
    options?: string[];
    imageSrc?: string;
  };
  hasError: boolean;
  showQuantity?: boolean;
  variant?: RetailUnitWrapperVariant;
}

export interface PickListItem {
  barcode: string;
  name?: string;
  options?: string[];
  imageSrc?: string;
  quantity?: number;
}

export function RetailUnit({
  hasError,
  item,
  showQuantity,
  variant,
}: RetailUnitProps): ReactElement {
  return (
    <Wrapper $hasError={hasError} $variant={variant}>
      {showQuantity && item.quantity! >= 0 && (
        <QuantityWrapper>{item.quantity}</QuantityWrapper>
      )}
      <ImgWrapper>
        <ItemImg src={item.imageSrc || ItemNotFoundImage} alt="Item image" />
      </ImgWrapper>
      <ItemInfoWrapper data-testid="retail-unit--item-info">
        <Barcode data-testid="retail-unit--item-barcode">
          {item.barcode}
        </Barcode>
        {item.name && (
          <Name data-testid="retail-unit--item-name">{item.name}</Name>
        )}
        <Options>{item.options?.join(', ')}</Options>
      </ItemInfoWrapper>
    </Wrapper>
  );
}
