import { mix } from 'polished';
import styled, { css } from 'styled-components';
import { ButtonBase } from '../ButtonBase';
import { ButtonVariant, SizeVariant } from './Button';

interface WrapperProps {
  size: SizeVariant;
  variant: ButtonVariant;
  fullWidth: boolean;
  type: 'submit' | 'reset' | 'button';
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  id?: string;
  ref: React.ForwardedRef<HTMLButtonElement>;
}

type OverlayOpacity = {
  hover: number;
  active: number;
};

const overlayVariantMap = (variant: ButtonVariant): OverlayOpacity => {
  switch (variant) {
    case 'secondary':
      return {
        hover: 0.1,
        active: 0.2,
      };
    case 'tertiary':
      return {
        hover: 0.1,
        active: 0.2,
      };
    case 'warning':
      return {
        hover: 0.15,
        active: 0.3,
      };
    case 'ghost':
      return {
        hover: 0.05,
        active: 0.4,
      };
    case 'criticalMessageGhost':
      return {
        hover: 0.05,
        active: 0.1,
      };
    default:
      return {
        hover: 0.2,
        active: 0.4,
      };
  }
};

const BORDER_WIDTH = 1;

export const Wrapper = styled(ButtonBase)<WrapperProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  text-transform: none;
  font-weight: 500;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  border-width: ${BORDER_WIDTH}px;

  ${({ variant, theme }) => {
    switch (variant) {
      case 'primary':
        return css`
          color: ${theme.palette.primary.contrastText};
          background: ${theme.palette.primary.main};
          border-color: ${theme.palette.primary.dark};
        `;
      case 'primaryLight':
        return css`
          color: ${theme.palette.primary.dark};
          background: ${theme.palette.primary.light};
          border-color: ${theme.palette.primary.main};
        `;
      case 'secondary':
        return css`
          color: ${theme.palette.secondary.dark};
          background: ${theme.palette.secondary.base};
          border-color: ${theme.palette.secondary.main};
        `;
      case 'tertiary':
        return css`
          color: ${theme.palette.tertiary.contrastText};
          background: ${theme.palette.tertiary.main};
          border-color: ${theme.palette.tertiary.dark};
        `;
      case 'danger':
        return css`
          color: ${theme.palette.danger.contrastText};
          background: ${theme.palette.danger.main};
          border-color: ${theme.palette.danger.dark};
        `;
      case 'dangerLight': {
        return css`
          color: ${theme.palette.danger.base};
          background: rgba(205, 9, 9, 0.05);
          border-color: ${theme.palette.danger.main};
        `;
      }
      case 'warning':
        return css`
          color: ${theme.palette.warning.contrastText};
          background: ${theme.palette.warning.main};
          border-color: ${theme.palette.warning.dark};
        `;
      case 'ghost':
        return css`
          color: ${theme.palette.tertiary.contrastText};
          background: ${theme.palette.grey[1]};
          border-color: ${theme.palette.grey[4]};
        `;
      case 'criticalMessagePrimary':
        return css`
          color: ${theme.palette.tertiary.contrastText};
          background-color: ${mix(
            0.85,
            theme.palette.error.light,
            theme.palette.error.base
          )};
          border-color: ${theme.palette.error.main};
        `;
      case 'criticalMessageGhost':
        return css`
          color: ${theme.palette.tertiary.contrastText};
          background-color: ${theme.palette.error.light};
          border-color: rgba(148, 0, 5, 0.3);
        `;
      default:
        return css`
          color: ${theme.palette.primary.contrastText};
          background: ${theme.palette.primary.main};
          border: 1px solid ${theme.palette.primary.dark};
        `;
    }
  }};

  line-height: ${(props) => props.theme.typography.body1.lineHeight};
  font-size: ${({ theme }) => {
    if (theme.currentFontSizeSet === 'large') {
      return theme.typography.body1.fontSize;
    } else {
      return theme.typography.body2.fontSize;
    }
  }};
  border-radius: ${(props) => props.theme.shape.borderRadius}px;

  ${({ size, theme }) => {
    switch (size) {
      case 'large':
        return css`
          line-height: ${theme.typography.h1.lineHeight};
          font-size: ${theme.typography.h1.fontSize};
          padding: 20px 80px;
          border-width: 2px;
          border-radius: 10px;
        `;
      case 'compact':
        return css`
          padding: 2px 16px;
          height: ${({ theme }) => theme.spacing(5)};
        `;
      default:
        return css`
          padding: ${theme.currentFontSizeSet === 'large'
            ? '7px 40px'
            : '15.5px 20px'};
        `;
    }
  }};

  ${({ fullWidth }) => {
    if (fullWidth) {
      return css`
        width: 100%;
      `;
    }
  }};

  &:hover {
    &::before {
      position: absolute;
      content: '';
      width: calc(100% + ${BORDER_WIDTH * 2}px);
      height: calc(100% + ${BORDER_WIDTH * 2}px);
      left: -${BORDER_WIDTH}px;
      right: -${BORDER_WIDTH}px;
      border-radius: ${(props) => props.theme.shape.borderRadius}px;
      overflow: hidden;
      z-index: 0;
      ${({ variant }) => {
        if (
          ['criticalMessagePrimary', 'criticalMessageGhost'].includes(variant)
        ) {
          return css`
            background: rgba(205, 9, 9, ${overlayVariantMap(variant).hover});
          `;
        } else {
          return css`
            background: rgba(0, 0, 0, ${overlayVariantMap(variant).hover});
          `;
        }
      }}}

    }
  }

  &:active {
    &::before {
      position: absolute;
      content: '';
      width: calc(100% + ${BORDER_WIDTH * 2}px);
      height: calc(100% + ${BORDER_WIDTH * 2}px);
      left: -${BORDER_WIDTH}px;
      right: -${BORDER_WIDTH}px;
      border-radius: ${(props) => props.theme.shape.borderRadius}px;
      overflow: hidden;
      z-index: 0;
      ${({ variant }) => {
        if (
          ['criticalMessagePrimary', 'criticalMessageGhost'].includes(variant)
        ) {
          return css`
            background: rgba(205, 9, 9, ${overlayVariantMap(variant).active});
          `;
        } else {
          return css`
            background: rgba(0, 0, 0, ${overlayVariantMap(variant).active});
          `;
        }
      }}
    }
  }

  &:disabled {
    pointer-events: none;
    border: unset;
    box-shadow: unset;
    background: ${({ theme }) => theme.palette.disabled.main};
    color: ${({ theme }) => theme.palette.disabled.contrastText};

    & svg {
      fill-opacity: 60%;
    }
  }

  & svg {
    margin-right: 8px;
  }
`;

export const Text = styled.span`
  position: relative;
  z-index: 1;
  white-space: nowrap;
`;
