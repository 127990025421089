"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.cancelTaskGroupsErrorOutcomeSchema = exports.cancelTaskGroupErrorOutcomes = exports.deriveCancelTaskGroupsErrorOutcomeSchema = exports.CancelTaskGroupByIdOutcomes = void 0;
const z = __importStar(require("zod"));
const error_1 = require("../../autostore-client/error");
const model_1 = require("../../model");
const generic_1 = require("./generic");
const UnknownTaskGroup = z.object({
    outcome: z.literal('UNKNOWN_TASK_GROUP'),
    taskGroupId: z.string(),
});
const TaskGroupMissingBins = z.object({
    outcome: z.literal('TASK_GROUP_MISSING_BINS'),
    taskGroupId: model_1.taskGroupSchema.shape.id,
    taskIdsMissingBin: z.array(model_1.taskSchema.shape.id),
});
const InvalidTaskGroupStatus = z.object({
    outcome: z.literal('INVALID_TASK_GROUP_STATUS'),
    taskGroupId: z.string(),
    expectedStatuses: z.array(z.string()),
    actualStatus: z.string(),
});
const Success = z.object({
    outcome: z.literal('SUCCESS'),
    autostoreTaskGroupId: z.number().optional(),
    warehouseId: z.string(),
    picklistIds: z.array(model_1.picklistSchema.shape.id),
    fulfilmentOrderIds: model_1.fulfilmentOrderSchema.shape.id.array(),
});
exports.CancelTaskGroupByIdOutcomes = z.union([
    Success,
    generic_1.GenericError,
    UnknownTaskGroup,
    TaskGroupMissingBins,
    InvalidTaskGroupStatus,
]);
const deriveCancelTaskGroupsErrorOutcomes = [
    z.object({
        outcome: z.literal('UNKNOWN_TASK_GROUPS'),
        unknownTaskGroupIds: z.string().array(),
    }),
    z.object({
        outcome: z.literal('INVALID_TASK_GROUP_STATUSES'),
        invalidTaskGroups: z
            .object({
            taskGroupId: model_1.taskGroupSchema.shape.id,
            status: model_1.taskGroupStatusSchema,
        })
            .array(),
        validStatuses: model_1.taskGroupStatusSchema.array(),
    }),
    z.object({
        outcome: z.literal('TASK_GROUPS_WITH_NO_AUTOSTORE'),
        invalidTaskGroups: z
            .object({
            taskGroupId: model_1.taskGroupSchema.shape.id,
            warehouseId: model_1.warehouseSchema.shape.id,
        })
            .array(),
    }),
    z.object({
        outcome: z.literal('TASK_GROUP_MISSING_BINS'),
        taskGroupId: model_1.taskGroupSchema.shape.id,
        taskIdsMissingBins: z.array(model_1.taskSchema.shape.id),
    }),
];
exports.deriveCancelTaskGroupsErrorOutcomeSchema = z.union(deriveCancelTaskGroupsErrorOutcomes);
const taskGroupCancelFailedSchema = z.object({
    taskGroupId: model_1.taskGroupSchema.shape.id,
    autostoreTaskGroupId: model_1.autostoreTaskGroupIdSchema,
    reason: z.object({
        outcome: z.union([
            error_1.unhandledAutostoreManagerError,
            error_1.cancelTaskgroupErrorOutcome,
        ]),
        error: z.string().optional(),
    }),
});
const cancelledTaskGroupSchema = z.object({
    taskGroupId: model_1.taskGroupSchema.shape.id,
    picklistIds: model_1.picklistSchema.shape.id.array(),
    fulfilmentOrderIds: model_1.fulfilmentOrderSchema.shape.id.array(),
});
exports.cancelTaskGroupErrorOutcomes = [
    z.object({
        outcome: z.literal('FAILED_TO_UNSYNC_TASK_GROUPS'),
        failedToUnsyncTaskGroups: taskGroupCancelFailedSchema.array(),
        cancelledTaskGroups: cancelledTaskGroupSchema.array(),
    }),
    ...deriveCancelTaskGroupsErrorOutcomes,
];
exports.cancelTaskGroupsErrorOutcomeSchema = z.union(exports.cancelTaskGroupErrorOutcomes);
