import { PaperVariantType } from 'api-schema/lib/model/shortPickTroubleshoot';
import { ReactElement } from 'react';
import { ReactComponent as TroubleshootIcon } from '../../../../assets/img/icons/troubleshoot-icon.svg';
import { Button } from '../../../../components/warehouse/Button';
import { Container } from '../../../../components/warehouse/Container';
import { Paper } from '../../../../components/warehouse/Paper';
import { Text } from '../../../../components/warehouse/Text';
import { isNever } from '../../../../utils/isNever';
import { pluralise } from '../../../../utils/pluralise';
import { Body, ColouredText } from './PlacePaper.elements';

export type PlacePaperProps = {
  variant: PaperVariantType;
  paperPlaced: () => void;
  toteCount: number;
  troubleshoot?: () => void;
  goBack?: () => void;
};

export function PlacePaper({
  variant,
  paperPlaced,
  toteCount,
  troubleshoot,
  goBack,
}: PlacePaperProps): ReactElement {
  return (
    <Container
      buttons={[
        goBack ? (
          <Button variant="secondary" fullWidth onClick={goBack}>
            Go back
          </Button>
        ) : undefined,
        <Button
          variant="warning"
          icon={<TroubleshootIcon />}
          fullWidth
          onClick={troubleshoot}
        >
          Troubleshoot
        </Button>,
      ]}
      padding="none"
      alignCenter
    >
      <Body>
        <Paper variant={variant} />
        <Text variant="body1" weight="medium">
          Place <ColouredText $variant={variant}>{variant}</ColouredText> paper
          in the corresponding {pluralise('tote', toteCount, false)}
        </Text>
        <Button variant="primary" onClick={paperPlaced}>
          {variantToProperCase(variant)} paper placed
        </Button>
      </Body>
    </Container>
  );
}

const variantToProperCase = (variant: PaperVariantType): string => {
  switch (variant) {
    case 'green':
      return 'Green';
    case 'red':
      return 'Red';
    default:
      return isNever(variant);
  }
};
