"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartitionsPerBin = exports.autostore = exports.autostoreState = exports.autostoreStatus = exports.AutostoreBinType = void 0;
const z = __importStar(require("zod"));
exports.AutostoreBinType = z.union([
    z.literal(1),
    z.literal(2),
    z.literal(5),
]);
exports.autostoreStatus = z.union([
    z.literal('STARTED'),
    z.literal('STOPPED'),
    z.literal('UNREACHABLE'),
]);
exports.autostoreState = z.object({
    stopCode: z.number(),
    stopReason: z.string().optional(),
    mode: z.number(),
    modeText: z.string().optional(),
    chargePercent: z.number(),
});
exports.autostore = z.object({
    id: z.string().min(1),
    networkAddress: z.string().url(),
    status: exports.autostoreStatus.optional(),
    state: exports.autostoreState.optional(),
});
exports.PartitionsPerBin = z.union([
    z.literal(1),
    z.literal(2),
    z.literal(4),
]);
