import { Box } from '@material-ui/core';
import styled from 'styled-components';

type BinCheckMessageProps = {
  $aspectRatio?: number;
};

export const BinCheckMessage = styled(Box)<BinCheckMessageProps>`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  aspect-ratio: ${({ $aspectRatio }) => ($aspectRatio ? $aspectRatio : 16 / 9)};
  color: ${({ theme }) => theme.palette.primary.dark};
  font-size: larger;
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BinCheckButtons = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${({ theme }) => theme.spacing(1)}px;
  row-gap: ${({ theme }) => theme.spacing(1)}px;
`;
