import { ParcelSize } from 'api-schema/lib';
import { useReducer, useState } from 'react';
import { ReactComponent as ImportantIcon } from '../../../../assets/img/icons/important-diamond.svg';
import { ReactComponent as TroubleshootIcon } from '../../../../assets/img/icons/troubleshoot-icon.svg';
import { Box } from '../../../../components/warehouse/Box';
import { Button } from '../../../../components/warehouse/Button';
import { Modal } from '../../../../components/warehouse/Modal';
import { NumericInput } from '../../../../components/warehouse/NumericInput';
import { Text } from '../../../../components/warehouse/Text';
import { warehouseColours } from '../../../../constants/colours';
import {
  getCustomLabelFromOrderTags,
  getParcelOptionSetFromOrderTags,
  getParcelTypeFromOrderTags,
} from '../../../../constants/parcel';
import { PackingContainer } from '../PackingContainer';
import { ParcelSelectionPanel } from './ParcelSelectionPanel';
import * as Elements from './SelectParcel.elements';

export type SelectParcelProps = {
  onCannotPackOrderBtnClick: () => void;
  orderNumber: string;
  recipient: string;
  initialParcelCount?: number;
  onSelect: ({
    parcelSize,
    parcelCount,
  }: {
    parcelSize: ParcelSize;
    parcelCount: number;
  }) => void;
  merchantName?: string;
  tags?: string[];
  isInternationalShipment: boolean;
};

const MAX_CUSTOM_LABEL_LENGTH = 20;

const CustomSelectParcelHeader = ({
  tags,
}: {
  tags?: string[];
}): JSX.Element => {
  const customLabel = getCustomLabelFromOrderTags(tags);
  if (!customLabel) {
    return <>Select parcel</>;
  }

  const trimmedLabel =
    customLabel.length > MAX_CUSTOM_LABEL_LENGTH
      ? customLabel.substring(0, MAX_CUSTOM_LABEL_LENGTH) + '...'
      : customLabel;

  return (
    <Elements.TitleWrapper data-testid="custom-title-wrapper">
      <ImportantIcon fill={warehouseColours.success.main} />
      <Elements.TitleText>{`Select ${trimmedLabel} parcel`}</Elements.TitleText>
    </Elements.TitleWrapper>
  );
};

export const SelectParcel = ({
  onCannotPackOrderBtnClick,
  orderNumber,
  recipient,
  onSelect,
  merchantName,
  tags,
  isInternationalShipment,
  initialParcelCount = 1,
}: SelectParcelProps): JSX.Element => {
  const buttonOptions = getParcelOptionSetFromOrderTags(tags);
  const parcelType = getParcelTypeFromOrderTags(tags);
  const [multipleParcelsEnabled, toggleMultipleParcelsEnabled] = useReducer(
    (state) => !state,
    initialParcelCount > 1
  );
  const [parcelCount, setParcelCount] = useState(initialParcelCount || 1);
  const [selectedParcelSize, setSelectedParcelSize] = useState<
    ParcelSize | undefined
  >(undefined);
  const [isErrorModalShown, toggleErrorModalShown] = useReducer(
    (state) => !state,
    false
  );

  const handleParcelSizeSelected = (value: ParcelSize) => {
    multipleParcelsEnabled
      ? setSelectedParcelSize(value)
      : onSelect({ parcelCount: 1, parcelSize: value });
  };

  const handleParcelSelectedBtnClicked = () => {
    if (!selectedParcelSize) {
      return toggleErrorModalShown();
    }

    onSelect({
      parcelCount,
      parcelSize: selectedParcelSize,
    });
  };
  const handleMultipleParcelToggled = () => {
    setParcelCount(1);
    toggleMultipleParcelsEnabled();
  };

  return (
    <PackingContainer
      step={2}
      stickyFooter={[
        <Button
          variant="warning"
          // size="large"
          icon={<TroubleshootIcon />}
          onClick={onCannotPackOrderBtnClick}
        >
          Cannot pack order
        </Button>,
        multipleParcelsEnabled && (
          <Button variant="primary" onClick={handleParcelSelectedBtnClicked}>
            Parcel Selected
          </Button>
        ),
      ]}
      title={<CustomSelectParcelHeader tags={tags} />}
      orderNumber={orderNumber}
      recipient={recipient}
      merchantName={merchantName}
    >
      <Box height="214px">
        <>
          <ParcelSelectionPanel
            multipleParcelsEnabled={multipleParcelsEnabled}
            onMultipleParcelsToggle={handleMultipleParcelToggled}
            isInternationalShipment={isInternationalShipment}
            titleText={parcelType}
            buttons={buttonOptions}
            onClick={handleParcelSizeSelected}
            parcelSize={selectedParcelSize}
          />
          {multipleParcelsEnabled && (
            <Elements.QuantityInputWrapper>
              <Text variant="h2" tag="span" weight="medium">
                Enter quantity
              </Text>
              <NumericInput
                value={parcelCount}
                setValue={setParcelCount}
                onChange={(e) => {
                  const value = Number(e.target.value) || 1;
                  setParcelCount(value);
                }}
                minValue={1}
                margin="0"
              />
            </Elements.QuantityInputWrapper>
          )}
        </>
      </Box>
      <Modal
        variant="warning"
        header={{ text: 'No parcel size selected', icon: <TroubleshootIcon /> }}
        isVisible={isErrorModalShown}
        actions={[
          <Button fullWidth variant="ghost" onClick={toggleErrorModalShown}>
            Close
          </Button>,
        ]}
      >
        <Text>
          Please select {parcelType.toLowerCase()} size before proceeding to
          print labels.
        </Text>
      </Modal>
    </PackingContainer>
  );
};
