import { Avatar, Box } from '@material-ui/core';
import styled from 'styled-components';

export type TransferDetailsItemStatusType =
  | 'EMPTY'
  | 'SELECTED'
  | 'PENDING'
  | 'COMPLETE';

type StatusProps = {
  $status: TransferDetailsItemStatusType;
};

export const Container = styled(Box)<StatusProps>`
  background-color: ${({ theme, $status }) =>
    $status === 'SELECTED' ? theme.palette.primary.main : 'initial'};
  display: flex;
  justify-content: space-between;
  margin: ${({ theme, $status }) =>
    $status === 'SELECTED' ? `10px -${theme.spacing(3)}px` : '10px 0px'};
  padding: ${({ theme, $status }) =>
    $status === 'SELECTED' ? `10px ${theme.spacing(3)}px` : ''};
  color: ${({ theme, $status }) =>
    $status === 'SELECTED' ? theme.palette.primary.light : ''};
`;

export const Status = styled(Avatar)<StatusProps>`
  background-color: ${({ theme, $status }) => {
    switch ($status) {
      case 'SELECTED':
        return theme.palette.primary.light;
      case 'COMPLETE':
        return theme.palette.primary.accent;
      case 'PENDING':
        return theme.palette.warning.dark;
      case 'EMPTY':
        return theme.palette.error.dark;
      default:
        return theme.palette.error.dark;
    }
  }};
  color: ${({ theme, $status }) => {
    return $status === 'SELECTED'
      ? theme.palette.primary.dark
      : theme.palette.primary.light;
  }};
  width: 20px;
  height: 20px;
  svg {
    font-size: 0.9em;
    width: 0.9em;
    height: 0.9em;
  }
`;

export const Name = styled(Box)`
  display: flex;
  margin-left: 5px;
  align-items: center;
`;

export const Barcode = styled(Box)`
  display: inline;
  margin-left: 3px;
  font-size: smaller;
`;
