import { Warehouse } from 'api-schema';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAppState } from '../store';

export type WarehouseRouteParams = {
  warehouseId: Warehouse['id'];
};

export const useCurrentWarehouse = () => {
  const { appState } = useAppState();
  const { warehouses } = appState;
  const { warehouseId } = useParams<{ warehouseId?: string }>();

  const currentWarehouse = useMemo(
    () =>
      warehouses &&
      warehouses.find((warehouse) => warehouse.id === warehouseId),
    [warehouses, warehouseId]
  );

  return currentWarehouse;
};
