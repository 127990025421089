import { ReactElement } from 'react';
import { ReactComponent as TroubleshootIcon } from '../../../../assets/img/icons/troubleshoot-icon.svg';
import {
  AlertMessage,
  AlertMessageTypes,
} from '../../../../components/warehouse/AlertMessage';
import { Button } from '../../../../components/warehouse/Button';
import { Modal } from '../../../../components/warehouse/Modal';
import { Text } from '../../../../components/warehouse/Text';

export type ProceedWithUnfulfillableItemsModalProps = {
  closeModal: () => void;
  proceedToPacking: () => void;
  hasItemsToBeFulfilled: boolean;
};

export const ProceedWithUnfulfillableItemsModal = ({
  closeModal,
  proceedToPacking,
  hasItemsToBeFulfilled,
}: ProceedWithUnfulfillableItemsModalProps): ReactElement => {
  return (
    <Modal
      isVisible
      variant="warning"
      header={{
        icon: <TroubleshootIcon />,
        text: 'Proceed with unfulfillable item(s)?',
      }}
      actions={[
        <Button variant="secondary" fullWidth onClick={closeModal}>
          Cancel
        </Button>,
        <Button variant="warning" fullWidth onClick={proceedToPacking}>
          Proceed to packing
        </Button>,
      ]}
    >
      <Text variant="body2" weight="regular" margin={0}>
        This order contains unfulfillable items. The partial order fulfilment
        should only proceed at the merchant's request. Are you sure you want to
        proceed?
      </Text>
      {hasItemsToBeFulfilled && (
        <AlertMessage type={AlertMessageTypes.Info}>
          <Text variant="body2" weight="regular" margin={0}>
            Ensure all the{' '}
            <Text variant="body2" weight="medium" margin={0} tag="span">
              items to be fulfilled
            </Text>{' '}
            have been sourced from the resolution tote.
          </Text>
        </AlertMessage>
      )}
    </Modal>
  );
};
