import { ReactElement } from 'react';
import { ReactComponent as BarcodeImg } from '../../../../assets//img/barcode.svg';
import { Button } from '../../../../components/warehouse/Button';
import { Modal } from '../../../../components/warehouse/Modal';
import { Text } from '../../../../components/warehouse/Text';

export type ScanBarcodeInstructionModalProps = {
  closeModal: () => void;
};

export const ScanBarcodeInstructionModal = ({
  closeModal,
}: ScanBarcodeInstructionModalProps): ReactElement => {
  return (
    <Modal
      isVisible
      header={{
        text: 'Scan first item in bin partition',
      }}
      variant="default"
      actions={[
        <Button variant="tertiary" fullWidth onClick={closeModal}>
          Close
        </Button>,
      ]}
    >
      <BarcodeImg width={'100%'} />
      <Text margin={0} variant="body1" weight="regular" align="left">
        Scan 1D barcodes for accuracy. If only 2D barcodes (like QR codes) are
        available, manually type the barcode number found next to the product
        image.
      </Text>
    </Modal>
  );
};
