import { RetailUnit as RetailUnitType } from 'api-schema/lib';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { ReactComponent as InfoIcon } from '../../../../assets/img/icons/info-circle.svg';
import { ReactComponent as PutToLightIcon } from '../../../../assets/img/icons/put-to-light.svg';
import { Box } from '../../../../components/warehouse/Box';
import { Button } from '../../../../components/warehouse/Button';
import { Container } from '../../../../components/warehouse/Container';
import { InputField } from '../../../../components/warehouse/InputField';
import {
  PickingStepContent,
  PickingSteps,
} from '../../../../components/warehouse/PickingSteps/PickingSteps';
import { Text } from '../../../../components/warehouse/Text';
import { warehouseColours } from '../../../../constants/colours';
import { FooterButtons } from './components/FooterButtons';
import { Header } from './components/Header';
import * as Elements from './PickingInformation.elements';

import { PickItemBarcodeValidationFailed } from 'api-schema/lib/events/pick';
import { PickPortStateModelType } from 'api-schema/lib/model';
import {
  AlertMessage,
  AlertMessageTypes,
} from '../../../../components/warehouse/AlertMessage';

export type PickingInformationProps = {
  item?: RetailUnitType;
  completePick: () => void;
  triggerShortPick?: () => void;
  troubleshoot?: () => void;
  isShortPick: boolean;
  detectMissingItem?: () => void;
  showScanBarcodeInstruction?: () => void;
  validateBarcode: (barcode: string) => void;
  itemBarcodeValidation?: PickPortStateModelType['itemBarcodeValidation'];
  isUsingConveyor: boolean;
};

export function PickingInformation({
  item,
  completePick,
  troubleshoot,
  isShortPick,
  detectMissingItem,
  showScanBarcodeInstruction,
  validateBarcode,
  itemBarcodeValidation,
  isUsingConveyor,
}: PickingInformationProps): ReactElement {
  const [isInputDirty, setIsInputDirty] = useState(false);
  const [barcode, setBarcode] = useState(
    itemBarcodeValidation?.scannedBarcode ?? ''
  );
  const hasBarcodeError =
    itemBarcodeValidation?.isValid === false && !isInputDirty;

  const barcodeInput = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setIsInputDirty(false);
    if (itemBarcodeValidation === undefined) {
      setBarcode('');
    }
    // Ensure we keep focus on the input and text selected for easy scanning/overwriting
    barcodeInput.current?.select();
  }, [itemBarcodeValidation, itemBarcodeValidation?.scannedBarcode]);

  const completePickAndClearBarcode = () => {
    completePick();
  };

  const scanFirstItemInBinContent: PickingStepContent = {
    completed: itemBarcodeValidation?.isValid === true,
    withCount: true,
    stepTitle: (
      <>
        Scan first item in bin partition
        <InfoIcon
          onClick={showScanBarcodeInstruction}
          fill={warehouseColours.info.dark}
        />
      </>
    ),
    content: (
      <InputField
        ref={barcodeInput}
        placeholderText={'Scan item barcode'}
        hasError={hasBarcodeError}
        isValid={itemBarcodeValidation?.isValid}
        value={barcode}
        onChange={(e) => {
          setBarcode(e.target.value);
          setIsInputDirty(true);
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            validateBarcode(barcode);
            barcodeInput.current?.select();
          }
        }}
        id="item-barcode"
        fullWidth
        autoFocus
        disabled={itemBarcodeValidation?.isValid}
      />
    ),
    errorContent: hasBarcodeError
      ? getBarcodeErrorMessage(itemBarcodeValidation?.reason)
      : null,
  };

  const placeItemsInTotesContent: PickingStepContent = {
    completed: false,
    withCount: true,
    stepTitle: 'Place items in corresponding totes',
    content: (
      <Button
        variant="primary"
        onClick={completePickAndClearBarcode}
        testId="pick-complete-button"
        fullWidth={true}
      >
        {isShortPick ? 'Short pick complete' : 'Pick complete'}
      </Button>
    ),
    errorContent: null,
  };

  const placeItemsInTotesPtlContent: PickingStepContent = {
    stepTitle: 'Place items in corresponding totes',
    content: (
      <Box paddingLeft="2.7rem">
        <AlertMessage type={AlertMessageTypes.Light}>
          <Text>
            Press the <strong>pick confirmation button</strong> for each tote
            after placing the items
          </Text>
          <Box marginRight="1rem">
            <PutToLightIcon width="134" height="auto" />
          </Box>
        </AlertMessage>
      </Box>
    ),
  };

  const placeItemsInTotesStep = isUsingConveyor
    ? placeItemsInTotesPtlContent
    : placeItemsInTotesContent;

  return (
    <Container
      buttons={FooterButtons({
        troubleshoot,
        detectMissingItem,
        isUsingConveyor,
      })}
      padding="none"
      alignCenter
    >
      <Elements.Wrapper>
        <Header isShortPick={isShortPick} item={item} />
        <PickingSteps
          stepContents={
            itemBarcodeValidation?.isValid
              ? [scanFirstItemInBinContent, placeItemsInTotesStep]
              : [scanFirstItemInBinContent]
          }
        />
      </Elements.Wrapper>
    </Container>
  );
}

const getBarcodeErrorMessage = (
  reason: PickItemBarcodeValidationFailed['payload']['reason']
) => {
  switch (reason.outcome) {
    case 'INVALID_PICK_ITEM_BARCODE':
      return 'The barcode scanned does not match the order item. Please scan again.';
    default:
      return `There was a problem validating the barcode. Please try again or report to your manager. [${reason.outcome}]`;
  }
};
