import { IntermediaryStationStatus } from 'api-schema/lib/model';
import { useRouteMatch } from 'react-router-dom';
import { SocketProvider } from '../components/common/SocketProvider';
import { useIntermediaryStationSocket } from '../components/common/SocketProvider/useSocket';
import { Layout } from '../components/warehouse/Layout';
import { StationHeader } from '../components/warehouse/StationHeader';
import { baseUrl } from '../constants/config';
import { useWarehouse } from '../hooks/useWarehouse';
import { AwaitingTote } from '../screens/intermediaryStation/AwaitingTote';
import { IntermediaryStationClosed } from '../screens/intermediaryStation/IntermediaryStationClosed';
import { ToteAdjustment } from '../screens/intermediaryStation/ToteAdjustment';
import { useAppState } from '../store';
import { WarehouseThemeProvider } from '../themes/WarehouseTheme';

type RouteParams = { warehouseId: string; stationId: string };

export const IntermediaryStationApp = (): JSX.Element => {
  const {
    params: { warehouseId, stationId },
  } = useRouteMatch<RouteParams>();

  useWarehouse(warehouseId);

  return (
    <WarehouseThemeProvider useLargeFontSet={false}>
      <SocketProvider
        baseUrl={baseUrl}
        warehouseId={warehouseId}
        stationId={stationId}
        providerType="intermediaryStation"
      >
        <main>
          <IntermediaryStationAppView />
        </main>
      </SocketProvider>
    </WarehouseThemeProvider>
  );
};

const IntermediaryStationAppView = (): JSX.Element => {
  const {
    appState: { intermediaryStationState },
  } = useAppState();
  const { dispatchCommand } = useIntermediaryStationSocket();
  if (!intermediaryStationState) {
    return <div>Unable to fetch intermediary station</div>;
  }
  const onCloseStation = async () => {
    await dispatchCommand({
      type: 'CLOSE_INTERMEDIARY_STATION',
    });
  };
  const LayoutHeader =
    intermediaryStationState.status !== 'CLOSED' ? (
      <StationHeader
        stationHeaderTitle={`Intermediary Station ${intermediaryStationState?.stationId}`}
        closeStation={onCloseStation}
      />
    ) : undefined;
  return (
    <Layout type="STATION" header={LayoutHeader}>
      <IntermediaryStationScreen status={intermediaryStationState.status} />
    </Layout>
  );
};

type IntermediaryStationScreenProps = {
  status: IntermediaryStationStatus;
};

const IntermediaryStationScreen = ({
  status,
}: IntermediaryStationScreenProps): JSX.Element => {
  switch (status) {
    case 'CLOSED':
      return <IntermediaryStationClosed />;
    case 'AWAITING_TOTE':
      return <AwaitingTote />;
    case 'ADJUSTING_TOTE':
      return <ToteAdjustment />;
    case 'AWAITING_ADJUSTMENT_CONFIRMATION':
      return <ToteAdjustment confirmToteAdjustmentModalVisible={true} />;
    default:
      return <div>Intermediary Station in Invalid State</div>;
  }
};
