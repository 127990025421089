import styled from 'styled-components';
import { ListItemType } from '../ListItems/ListItems';

type Props = {
  $type: ListItemType;
};

export const Wrapper = styled.div<Props>`
  display: flex;
  margin: 0;
  padding: 0;
  margin-bottom: ${({ $type }) => ($type === 'fulfilled' ? '10px' : '0px')};
`;

export const IconWrapper = styled.div<Props>`
  margin: 0;
  margin-right: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 0;
`;
