import { ReactElement } from 'react';
import { Button } from '../Button';
import { Text } from '../Text';
import { HeaderWrapper } from './StationHeader.elements';

export type HeaderProps = {
  stationHeaderTitle: string;
  closeStation?: () => void;
};

export const StationHeader = ({
  closeStation,
  stationHeaderTitle,
}: HeaderProps): ReactElement | null => {
  return (
    <HeaderWrapper>
      <Text testId={'station-header'} variant="body2" weight="medium">
        {stationHeaderTitle}
      </Text>
      {closeStation && (
        <Button
          variant="tertiary"
          size="compact"
          onClick={closeStation}
          testId="close-station-button"
        >
          Close Station
        </Button>
      )}
    </HeaderWrapper>
  );
};
