import styled, { css } from 'styled-components';
import { StepNumber } from '../StepNumber';

interface StepCountProps {
  $isComplete?: boolean;
}

export const Step = styled.div`
  padding: 20px;
  border-top: 1px solid ${({ theme }) => theme.palette.grey[4]};
  margin-right: 15px;

  &:first-child {
    border: none;
    padding-top: 0px;
  }
  .step-count {
    margin-top: 20px;
    height: 30px;
    width: 30px;
  }
  .content-wrapper {
    align-items: start;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: fill-available;
  align-items: center;
  width: 100%;
  padding-top: 5px;
`;

export const StepCount = styled(StepNumber)<StepCountProps>`
  margin-right: 15px;
  height: 1.875rem;
  width: 2rem;

  ${({ $isComplete }) => {
    if ($isComplete) {
      return css`
        background-color: transparent;
      `;
    }
  }}
`;

export const AlertWrapper = styled.div`
  margin-top: 20px;

  &:first-child {
    margin-top: 0px;
  }
`;
