const DEFAULT_DEBOUNCE_TIMEOUT_MS = 1000;

export function debounce(
  func: Function,
  timeout = DEFAULT_DEBOUNCE_TIMEOUT_MS
) {
  let timer: number | undefined;
  return (...args: any) => {
    if (!timer) {
      func(...args);
    }
    window.clearTimeout(timer);
    timer = window.setTimeout(() => {
      timer = undefined;
    }, timeout);
  };
}
