import { ManualPackingCancelReason } from 'api-schema/lib/model';
import { useEffect, useReducer, useState } from 'react';
import { ReactComponent as TroubleshootSVG } from '../../../../assets/img/icons/troubleshoot-icon.svg';
import { Button } from '../../../../components/warehouse/Button';
import { ListItem } from '../../../../components/warehouse/List/ListItem';
import { Modal } from '../../../../components/warehouse/Modal';
import { Text } from '../../../../components/warehouse/Text';

const MANUAL_PACKING_CANCELLED_REASONS: Array<{
  value: ManualPackingCancelReason;
  displayText: string;
}> = [
  {
    value: 'MISSING_ITEM',
    displayText: 'Missing item(s)',
  },
  { value: 'EXCESS_ITEM', displayText: 'Excess item(s)' },
  { value: 'INCORRECT_ITEM', displayText: 'Incorrect item(s)' },
  {
    value: 'DAMAGE_ITEM',
    displayText: 'Damage item(s)',
  },
  { value: 'OTHER', displayText: 'Other' },
];

export type CannotPackOrderModalProps = {
  isOpen: boolean;
  onTroubleshotLabelPrint: (
    reasons: ManualPackingCancelReason[]
  ) => Promise<void>;
  onCancel: () => void;
};

export const CannotPackOrderModal = ({
  isOpen,
  onCancel,
  onTroubleshotLabelPrint,
}: CannotPackOrderModalProps) => {
  const [selectedReasons, setSelectedReasons] = useState<
    ManualPackingCancelReason[]
  >([]);
  useEffect(() => {
    isOpen && setSelectedReasons([]);
  }, [isOpen]);
  const [isBlockerModalOpen, toggleBlockerModalOpen] = useReducer(
    (state) => !state,
    false
  );

  const handleReasonClick = (value: ManualPackingCancelReason) => {
    if (selectedReasons.includes(value)) {
      setSelectedReasons(selectedReasons.filter((v) => v !== value));
    } else {
      setSelectedReasons([...selectedReasons, value]);
    }
  };

  const handlePrintTroubleshootLabel = () => {
    if (!selectedReasons.length) {
      return toggleBlockerModalOpen();
    }

    onTroubleshotLabelPrint(selectedReasons);
  };

  return (
    <>
      <Modal
        key="troubleshoot-modal"
        isVisible={isOpen}
        variant="warning"
        header={{ icon: <TroubleshootSVG />, text: 'Cannot pack order' }}
        actions={[
          <Button
            key="cancel-print-ts-label"
            testId="cancel-cannot-pack-order-btn"
            variant="tertiary"
            fullWidth
            onClick={onCancel}
          >
            Cancel
          </Button>,
          <Button
            key="print-ts-label"
            testId="print-ts-label-btn"
            onClick={handlePrintTroubleshootLabel}
            fullWidth
          >
            Print troubleshoot label
          </Button>,
        ]}
      >
        {MANUAL_PACKING_CANCELLED_REASONS.map((reason) => (
          <ListItem
            key={reason.value}
            value={reason.value}
            label={reason.displayText}
            selected={selectedReasons.includes(reason.value)}
            onClick={() => handleReasonClick(reason.value)}
          />
        ))}
      </Modal>
      <Modal
        key="troubleshoot-warning-modal"
        isVisible={isBlockerModalOpen}
        variant="warning"
        actions={[
          <Button
            testId="close-blocker-modal-btn"
            variant="tertiary"
            fullWidth
            onClick={toggleBlockerModalOpen}
          >
            Close
          </Button>,
        ]}
      >
        <Text variant="h2">
          Please make at least one selection in order to print the troubleshoot
          label.
        </Text>
      </Modal>
    </>
  );
};
