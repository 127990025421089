import { Box, Typography } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { CreateFulfilmentOrderRequest } from 'api-schema/lib';
import { FormikProps } from 'formik';
import { Button } from '../../../../components/admin/Button';
import { Container } from '../../../../components/admin/Container';
import { Input } from '../../../../components/admin/Input';
import { SectionHeader } from '../../../../components/admin/SectionHeader';
import { FormRow } from '../../../../elements/admin/FormRow';
import { Loadable } from '../../../../elements/admin/Loadable';
import { LineItemError } from './FulfilmentOrder';

type Props = {
  getLineItemError: (index: number) => LineItemError | undefined;
  removeLineItem: (index: number) => void;
  addLineItem: () => void;
};

export const FulfilmentOrderView = ({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  handleSubmit,
  resetForm,
  isSubmitting,
  getLineItemError,
  removeLineItem,
  addLineItem,
}: FormikProps<CreateFulfilmentOrderRequest> & Props) => {
  return (
    <>
      <SectionHeader>Create mock fulfilment order</SectionHeader>
      <Container>
        <form onSubmit={handleSubmit}>
          <Typography>Customer</Typography>
          <FormRow spacingBottom={2}>
            <Input
              label="Name"
              name="customer.name"
              type="text"
              onChange={handleChange}
              value={values.customer.name}
              error={errors.customer?.name}
              touched={touched.customer?.name}
              onBlur={handleBlur}
              fullWidth
            />
            <Input
              label="Address Line 1"
              name="customer.address.line1"
              type="text"
              onChange={handleChange}
              value={values.address?.line1}
              error={errors.address}
              touched={touched.address}
              onBlur={handleBlur}
              fullWidth
            />
            <Input
              label="Phone"
              name="customer.phone"
              type="text"
              onChange={handleChange}
              value={values.customer.phone}
              error={errors.customer?.phone}
              touched={touched.customer?.phone}
              onBlur={handleBlur}
              fullWidth
            />
          </FormRow>
          <Typography>Misc</Typography>
          <FormRow spacingBottom={2}>
            <Input
              label="Merchant ID"
              name="merchantId"
              type="text"
              onChange={handleChange}
              value={values.merchantId}
              error={errors.merchantId}
              touched={touched.merchantId}
              onBlur={handleBlur}
              fullWidth
            />
            <Input
              label="Shipping method"
              name="shippingMethod"
              type="text"
              onChange={handleChange}
              value={values.shippingMethod}
              error={errors.shippingMethod}
              touched={touched.shippingMethod}
              onBlur={handleBlur}
              fullWidth
            />
            <Input
              label="External fulfilment ID"
              name="externalFulfilmentId"
              type="text"
              onChange={handleChange}
              value={values.externalFulfilmentId}
              error={errors.externalFulfilmentId}
              touched={touched.externalFulfilmentId}
              onBlur={handleBlur}
              fullWidth
              helperText="E.g. skutopia fulfilmentShipment.id"
            />
          </FormRow>
          <Typography>Line items</Typography>
          <Box marginBottom={4}>
            {values.lineItems.map((item, index) => (
              <FormRow key={index}>
                <Input
                  label="Barcode"
                  name={`lineItems[${index}].barcode`}
                  type="text"
                  onChange={handleChange}
                  value={item.barcode}
                  error={getLineItemError(index)?.barcode}
                  touched={touched.lineItems?.[index]?.barcode}
                  onBlur={handleBlur}
                  fullWidth
                />
                <Input
                  label="Quantity"
                  name={`lineItems[${index}].quantity`}
                  type="number"
                  onChange={handleChange}
                  value={item.quantity}
                  error={getLineItemError(index)?.quantity}
                  touched={touched.lineItems?.[index]?.quantity}
                  onBlur={handleBlur}
                  fullWidth
                />
                <Button onClick={addLineItem}>
                  <Box display="flex">
                    <Add />
                  </Box>
                </Button>
                <Button
                  onClick={() => removeLineItem(index)}
                  variant="secondary"
                >
                  <Box display="flex">
                    <Delete />
                  </Box>
                </Button>
              </FormRow>
            ))}
            {values.lineItems.length === 0 && (
              <FormRow>
                <Button onClick={addLineItem}>
                  <Box display="flex">
                    <Add />
                  </Box>
                </Button>
              </FormRow>
            )}
          </Box>
          <FormRow>
            <Button type="submit" disabled={isSubmitting}>
              <Loadable isLoading={isSubmitting}>Add mock order</Loadable>
            </Button>
            <Button variant="secondary" onClick={() => resetForm()}>
              Reset
            </Button>
          </FormRow>
        </form>
      </Container>
    </>
  );
};
