"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.warehouseWithAutostoreNotFoundSchema = exports.warehouseNotFoundOutcomeSchema = void 0;
const zod_1 = require("zod");
exports.warehouseNotFoundOutcomeSchema = zod_1.z.object({
    outcome: zod_1.z.literal('WAREHOUSE_NOT_FOUND'),
    warehouseId: zod_1.z.string(),
});
exports.warehouseWithAutostoreNotFoundSchema = zod_1.z.object({
    outcome: zod_1.z.literal('WAREHOUSE_WITH_AUTOSTORE_NOT_FOUND'),
    warehouseId: zod_1.z.string(),
});
