"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.conveyorWebhookEventSchema = exports.toteSentToTroubleshootingSchema = exports.conveyorBayExitEventScehma = exports.conveyorToteEnteredPickPortEventSchema = exports.conveyorBayEntryEventSchema = void 0;
const zod_1 = require("zod");
const base_1 = require("./base");
exports.conveyorBayEntryEventSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TOTE_ENTERED_CONVEYOR_BAY'),
    payload: zod_1.z.object({
        bayId: zod_1.z.string().min(1),
        toteId: zod_1.z.string().min(1),
        toteArrivalTime: zod_1.z.string().datetime(),
    }),
});
exports.conveyorToteEnteredPickPortEventSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TOTE_ENTERED_CONVEYOR_PICK_PORT'),
    payload: zod_1.z.object({
        portId: zod_1.z.number(),
        toteId: zod_1.z.string().min(1),
        toteLocationId: zod_1.z.string(),
    }),
});
exports.conveyorBayExitEventScehma = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TOTE_EXITED_CONVEYOR_BAY'),
    payload: zod_1.z.object({
        bayId: zod_1.z.string().min(1),
        toteId: zod_1.z.string().min(1),
        toteExitTime: zod_1.z.string().datetime(),
    }),
});
exports.toteSentToTroubleshootingSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TOTE_SENT_TO_TROUBLESHOOTING'),
    payload: zod_1.z.object({
        toteId: zod_1.z.string().min(1),
        toteLinkFailureReason: zod_1.z
            .enum(['TOTE_HAS_CONTENTS', 'TOTE_HAS_PICKLIST', 'TOTE_NOT_FOUND'])
            .optional(),
        portId: zod_1.z.number().optional(),
        totePicklistId: zod_1.z.string().optional(),
        source: zod_1.z.enum(['CMC_TOTE_EXIT', 'PICK_PORT_TOTE_LINKING']),
    }),
});
exports.conveyorWebhookEventSchema = zod_1.z.union([
    exports.conveyorBayEntryEventSchema,
    exports.conveyorToteEnteredPickPortEventSchema,
    exports.conveyorBayExitEventScehma,
    exports.toteSentToTroubleshootingSchema,
]);
