import { ChangeEvent, CSSProperties, ReactElement } from 'react';
import { Field, NumericInputButton, Wrapper } from './NumericInput.elements';

export type NumericInputProps = {
  value: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setValue: (newValue: number) => void;
  minValue?: number;
  maxValue?: number;
  margin?: CSSProperties['margin'];
  size?: 'regular' | 'large';
};

export const NumericInput = ({
  value,
  onChange,
  setValue,
  minValue = Number.MIN_SAFE_INTEGER,
  maxValue = Number.MAX_SAFE_INTEGER,
  margin,
  size = 'regular',
}: NumericInputProps): ReactElement => {
  const decrementValue = () => {
    if (value > minValue) {
      setValue(value - 1);
    }
  };

  const incrementValue = () => {
    if (value < maxValue) {
      setValue(value + 1);
    }
  };

  return (
    <Wrapper $margin={margin}>
      <NumericInputButton
        $size={size}
        onClick={decrementValue}
        disabled={value <= minValue}
      >
        –
      </NumericInputButton>
      <Field value={value} inputMode="numeric" onChange={onChange} />
      <NumericInputButton
        $size={size}
        onClick={incrementValue}
        disabled={value >= maxValue}
      >
        +
      </NumericInputButton>
    </Wrapper>
  );
};
