import { RefreshClientCommandType } from 'api-schema/lib/commands/server';
import { isNever } from '../isNever';

export const handleServerCommands = (
  message: RefreshClientCommandType
): void => {
  switch (message.serverCommandType) {
    case 'REFRESH_CLIENT':
      window.location.reload();
      break;
    default:
      isNever(message.serverCommandType);
  }
};
