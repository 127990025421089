import ItemNotFoundImage from '../../../assets//img/item-not-found.svg';
import { Text } from '../Text';
import { ImageWrapper, ItemImg, QuantityWrapper } from './Thumbnail.elements';

export interface ThumbnailProps {
  imgSrc: string;
  counter?: number;
}

export function Thumbnail({ imgSrc, counter }: ThumbnailProps) {
  return (
    <ImageWrapper>
      {counter && (
        <QuantityWrapper>
          <Text variant="body2" weight="medium" tag="span">
            {counter}
          </Text>
        </QuantityWrapper>
      )}
      <ItemImg
        src={imgSrc}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = ItemNotFoundImage;
          currentTarget.classList.add('substitute');
        }}
      ></ItemImg>
    </ImageWrapper>
  );
}
