import MenuItem from '@material-ui/core/MenuItem';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { Warehouse } from 'api-schema';
import { FormikProps } from 'formik';
import React, { ReactElement } from 'react';
import { Button } from '../../../components/admin/Button';
import { Container } from '../../../components/admin/Container';
import { Input } from '../../../components/admin/Input';
import { SectionHeader } from '../../../components/admin/SectionHeader';
import { Select } from '../../../components/admin/Select';
import { FormRow } from '../../../elements/admin/FormRow';
import { getFormHandlers } from '../../../utils/forms/getFormHandlers';
import { generatePathWithWarehouseId } from '../../../utils/generatePathWithWarehouseId';
import {
  binHeightPicklist,
  PartitionsPerBinEnum,
  SetupAutoStoreFormValues,
} from './models';

type Event = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

export function AddAutoStoreView({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  handleSubmit,
  isSubmitting,
  warehouse,
}: FormikProps<SetupAutoStoreFormValues> & {
  warehouse: Warehouse;
}): ReactElement {
  const { handleInputTouch } = getFormHandlers({
    setFieldValue,
    setFieldTouched,
  });
  const createSelectChangeHandler =
    (fieldName: string): SelectInputProps['onChange'] =>
    ({ target: { value } }) =>
      setFieldValue(fieldName, value);
  const handleNumBinsChange = ({ target: { value } }: Event) =>
    setFieldValue('numBins', parseInt(value, 10));
  return (
    <Container>
      <SectionHeader>Configure AutoStore Bins</SectionHeader>
      <form onSubmit={handleSubmit}>
        <FormRow spacingBottom={1}>
          <Input
            label="Number of bins"
            name="numBins"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            onChange={handleNumBinsChange}
            value={values.numBins}
            error={errors.numBins}
            touched={touched.numBins}
            onBlur={handleInputTouch}
          />
        </FormRow>
        <FormRow spacingBottom={1}>
          <Select
            label="Partitions per bin"
            value={values.partsPerBin}
            name="partsPerBin"
            onChange={createSelectChangeHandler('partsPerBin')}
          >
            {PartitionsPerBinEnum.options.map((v) => (
              <MenuItem key={v} value={v}>
                {v}
              </MenuItem>
            ))}
          </Select>
        </FormRow>
        <FormRow spacingBottom={1}>
          <Select
            label="Bin height"
            value={values.binHeight}
            name="binHeight"
            onChange={createSelectChangeHandler('binHeight')}
          >
            {binHeightPicklist.map((item) => (
              <MenuItem key={item.key} value={item.value}>
                {item.key}
              </MenuItem>
            ))}
          </Select>
        </FormRow>
        <FormRow>
          <Button type="submit" disabled={isSubmitting}>
            Create AutoStore bins
          </Button>
          <Button
            variant="secondary"
            href={generatePathWithWarehouseId(
              `/admin/warehouse/:warehouseId?/overview`,
              {
                warehouseId: warehouse.id,
              }
            )}
          >
            Cancel
          </Button>
        </FormRow>
      </form>
    </Container>
  );
}
