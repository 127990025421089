import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { darken, saturate, transparentize } from 'polished';

export const APP_BAR_HEIGHT = 48;

const createBlurredBackground = (color: string) => ({
  backgroundColor: transparentize(0.15, darken(0.1, saturate(0.12, color))),
  backdropFilter: `blur(5px)`,
});

export const useNavStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      ...createBlurredBackground(theme.palette.success.dark),
      height: APP_BAR_HEIGHT,
      width: `100%`,
      marginLeft: 0,
      zIndex: 11111,
    },
    // necessary for content to be below app bar
    toolbar: { minHeight: APP_BAR_HEIGHT },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  })
);
