import { Link } from 'react-router-dom';
import { DEV_PATHS } from '../../../router/paths';
import { useAppState } from '../../../store';
import { TextWrapper, Wrapper } from './AuthTokenMissingBanner.elements';

export const AuthTokenMissingBanner = () => {
  const {
    appState: { isAuthenticated },
  } = useAppState();

  if (isAuthenticated) {
    return null;
  }
  return (
    <Wrapper>
      <TextWrapper>
        This device is not authenticated. Please navigate{' '}
        <Link to={DEV_PATHS.AUTH}>here</Link> and contact support for
        assistance.
      </TextWrapper>
    </Wrapper>
  );
};
