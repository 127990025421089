import { Button } from '../../../../../components/admin/Button';
import { Container } from '../../../../../components/admin/Container';
import { BinCheckButtons, BinCheckMessage } from './BinCheck.elements';

type Props = {
  checkText: string;
  leftButtonText: string;
  rightButtonText: string;
  onLeftButtonClick: () => void;
  onRightButtonClick: () => void;
};

export const BinCheck = ({
  checkText,
  leftButtonText,
  rightButtonText,
  onLeftButtonClick,
  onRightButtonClick,
}: Props) => {
  return (
    <Container>
      <BinCheckMessage>{checkText}</BinCheckMessage>
      <BinCheckButtons>
        <Button
          variant="heavy"
          onClick={onLeftButtonClick}
          data-testid="bin-check-left"
        >
          {leftButtonText}
        </Button>
        <Button
          variant="heavy"
          onClick={onRightButtonClick}
          data-testid="bin-check-right"
        >
          {rightButtonText}
        </Button>
      </BinCheckButtons>
    </Container>
  );
};
