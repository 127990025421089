import { ReactElement } from 'react';
import { Layout } from '../../../components/warehouse/Layout';
import { StartStation } from '../../../components/warehouse/StartStation';

export interface IntermediaryStationClosedProps {
  stationId: string;
  onOpenStation: () => Promise<void>;
}

export function IntermediaryStationClosedView({
  stationId,
  onOpenStation,
}: IntermediaryStationClosedProps): ReactElement {
  return (
    <Layout type="STATION">
      <StartStation
        stationHeaderText={`Intermediary Station ${stationId}`}
        startStationButtonText="Start intermediary process"
        handleOpenStationClick={onOpenStation}
      />
    </Layout>
  );
}
