import { ReactElement } from 'react';
import { ReactComponent as ExclamationIcon } from '../../../../assets/img/icons/error-circle.svg';
import { Button } from '../../../../components/warehouse/Button';
import { Modal } from '../../../../components/warehouse/Modal';
import { Text } from '../../../../components/warehouse/Text';

export type AllItemsMissingModalProps = {
  closeModal: () => void;
  updateQuantity: (availableQuantity: number) => void;
};

export const AllItemsMissingModal = ({
  closeModal,
  updateQuantity,
}: AllItemsMissingModalProps): ReactElement => {
  return (
    <Modal
      isVisible
      header={{ icon: <ExclamationIcon />, text: 'No items available?' }}
      variant="danger"
      actions={[
        <Button variant="secondary" fullWidth onClick={closeModal}>
          Cancel
        </Button>,
        <Button variant="danger" fullWidth onClick={() => updateQuantity(0)}>
          Confirm
        </Button>,
      ]}
    >
      <Text variant="body1" weight="regular">
        Please confirm no items are available in the bin.
      </Text>
    </Modal>
  );
};
