import React, { ReactElement } from 'react';
import { CSSProperties } from 'styled-components';
import { Text } from '../Text';
import {
  ButtonBar,
  ContainerPaddingType,
  Content,
  Title,
  Wrapper,
} from './Container.elements';

export type ContainerProps = {
  className?: string;
  padding?: ContainerPaddingType;
  children?: React.ReactNode;
  buttons?: React.ReactNode[];
  title?: string;
  contentGap?: boolean;
  alignCenter?: boolean;
  bottomBoxShadow?: boolean;
  flexFlow?: CSSProperties['flexFlow'];
};

export function Container({
  className,
  children,
  buttons,
  padding = 'all',
  title,
  contentGap = true,
  alignCenter = false,
  bottomBoxShadow = true,
  flexFlow,
}: ContainerProps): ReactElement {
  return (
    <Wrapper
      className={className}
      $padding={padding}
      $contentGap={contentGap}
      $bottomBoxShadow={bottomBoxShadow}
    >
      {title && (
        <Title $padding={padding}>
          <Text variant="h2" tag="span" weight="medium">
            {title}
          </Text>
        </Title>
      )}
      <Content $alignCenter={alignCenter} $flexFlow={flexFlow}>
        {children}
      </Content>
      {buttons && <ButtonBar>{buttons}</ButtonBar>}
    </Wrapper>
  );
}
