import { Box } from '../../../../components/warehouse/Box';
import { Text } from '../../../../components/warehouse/Text';

export type ResolutionToteAlertMessageProps = {
  message: string;
};

export function ResolutionToteAlertMessage({
  message,
}: ResolutionToteAlertMessageProps) {
  return (
    <Box margin="10px 10px 0 10px">
      <Text weight="medium" variant="body2" margin={0}>
        Please scan an order tote containing red paper.{' '}
      </Text>
      <Text variant="body2" margin={10}>
        {message}
      </Text>
    </Box>
  );
}
