"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShortPickTroubleshootCommandsOutcome = exports.CancelShortPickedOrderOutcome = exports.DeferShortPickResolveOutcome = exports.ResolveShortPickOutcome = exports.ScanShortPickTroubleshootToteOutcome = exports.ToteScanSucceeded = exports.CloseShortPickTroubleshootStationOutcome = exports.OpenShortPickTroubleshootStationOutcome = exports.taskGroupMissingBinsSchema = exports.FulfilmentOrderNotFound = exports.OrderNotPickedYet = exports.MissingPicklistId = exports.MissingOrderId = exports.MissingPicklistOrToteId = exports.ResolutionPicklistNotPicked = exports.ResolutionPicklistNotFound = exports.ResolutionToteNotRequired = exports.OriginalPicklistNotFound = exports.InvalidStorageType = exports.NoOrderAssigned = exports.ResolutionToteIdScanned = exports.TroubleshootingNotRequired = exports.UnknownToteIdScanned = exports.NoPicklistAssigned = exports.InvalidStationStatus = exports.StateMachineInvalidEvent = exports.StationNotFound = void 0;
const z = __importStar(require("zod"));
const model_1 = require("../../model");
const generic_1 = require("./generic");
exports.StationNotFound = z.object({
    outcome: z.literal('STATION_NOT_FOUND'),
    stationId: z.number(),
    warehouseId: z.string(),
});
exports.StateMachineInvalidEvent = z.object({
    outcome: z.literal('STATE_MACHINE_INVALID_EVENT'),
});
exports.InvalidStationStatus = z.object({
    outcome: z.literal('INVALID_STATION_STATUS'),
    currentStatus: model_1.ShortPickTroubleshootStatus,
    expectedStatus: model_1.ShortPickTroubleshootStatus.optional(),
});
exports.NoPicklistAssigned = z.object({
    outcome: z.literal('NO_PICKLIST_ASSIGNED'),
    toteId: z.string(),
});
exports.UnknownToteIdScanned = z.object({
    outcome: z.literal('UNKNOWN_TOTE_ID_SCANNED'),
    toteId: z.string(),
});
exports.TroubleshootingNotRequired = z.object({
    outcome: z.literal('TROUBLESHOOTING_NOT_REQUIRED'),
    picklistId: z.string(),
    toteId: z.string(),
});
exports.ResolutionToteIdScanned = z.object({
    outcome: z.literal('RESOLUTION_TOTE_ID_SCANNED'),
    toteId: z.string(),
    resolutionToteId: z.string(),
});
exports.NoOrderAssigned = z.object({
    outcome: z.literal('NO_ORDER_ASSIGNED'),
    fulfilmentOrderId: z.string(),
    toteId: z.string(),
});
exports.InvalidStorageType = z.object({
    outcome: z.literal('INVALID_STORAGE_TYPE'),
    toteId: z.string(),
});
exports.OriginalPicklistNotFound = z.object({
    outcome: z.literal('ORIGINAL_PICKLIST_NOT_FOUND'),
    picklistId: z.string(),
});
exports.ResolutionToteNotRequired = z.object({
    outcome: z.literal('RESOLUTION_TOTE_NOT_REQUIRED'),
    toteId: z.string(),
});
exports.ResolutionPicklistNotFound = z.object({
    outcome: z.literal('RESOLUTION_PICKLIST_NOT_FOUND'),
    toteId: z.string(),
});
exports.ResolutionPicklistNotPicked = z.object({
    outcome: z.literal('RESOLUTION_PICKLIST_NOT_PICKED'),
    resolutionPicklistIds: z.array(z.string().min(1)),
});
exports.MissingPicklistOrToteId = z.object({
    outcome: z.literal('MISSING_PICKLIST_OR_TOTE_ID'),
    picklistId: z.string().min(1).optional(),
    toteId: z.string().min(1).optional(),
});
exports.MissingOrderId = z.object({
    outcome: z.literal('MISSING_ORDER_ID'),
});
exports.MissingPicklistId = z.object({
    outcome: z.literal('MISSING_PICKLIST_ID'),
});
exports.OrderNotPickedYet = z.object({
    outcome: z.literal('ORDER_NOT_PICKED_YET'),
    toteId: z.string(),
});
exports.FulfilmentOrderNotFound = z.object({
    outcome: z.literal('FULFILMENT_ORDER_NOT_FOUND'),
    fulfilmentOrderId: z.string(),
});
exports.taskGroupMissingBinsSchema = z.object({
    outcome: z.literal('TASK_GROUP_MISSING_BINS'),
    taskGroupId: z.string().min(1),
    taskIdsMissingBin: z.array(z.string().min(1)),
});
exports.OpenShortPickTroubleshootStationOutcome = z.union([
    generic_1.Success,
    exports.InvalidStationStatus,
    exports.StationNotFound,
    exports.StateMachineInvalidEvent,
]);
exports.CloseShortPickTroubleshootStationOutcome = z.union([
    generic_1.Success,
    exports.InvalidStationStatus,
    exports.StationNotFound,
    exports.StateMachineInvalidEvent,
]);
exports.ToteScanSucceeded = generic_1.Success.extend({
    orderPicklistId: z.string().min(1),
    resolutionPicklistIds: z.array(z.string().min(1)),
    fulfilmentOrderId: z.string().min(1),
});
exports.ScanShortPickTroubleshootToteOutcome = z.union([
    exports.ToteScanSucceeded,
    exports.InvalidStationStatus,
    exports.StationNotFound,
    exports.NoPicklistAssigned,
    exports.UnknownToteIdScanned,
    exports.InvalidStorageType,
    exports.NoOrderAssigned,
    exports.ResolutionToteIdScanned,
    exports.TroubleshootingNotRequired,
    exports.ResolutionToteNotRequired,
    exports.OriginalPicklistNotFound,
    exports.StateMachineInvalidEvent,
    exports.OrderNotPickedYet,
]);
exports.ResolveShortPickOutcome = z.union([
    generic_1.Success,
    exports.InvalidStationStatus,
    exports.StationNotFound,
    exports.StateMachineInvalidEvent,
    exports.ResolutionPicklistNotPicked,
    exports.MissingPicklistOrToteId,
    exports.MissingOrderId,
]);
exports.DeferShortPickResolveOutcome = z.union([
    generic_1.Success,
    exports.InvalidStationStatus,
    exports.StationNotFound,
    exports.StateMachineInvalidEvent,
]);
exports.CancelShortPickedOrderOutcome = z.union([
    generic_1.Success,
    exports.InvalidStationStatus,
    exports.StationNotFound,
    exports.StateMachineInvalidEvent,
    exports.MissingOrderId,
    exports.MissingPicklistId,
    exports.OriginalPicklistNotFound,
    exports.FulfilmentOrderNotFound,
    exports.taskGroupMissingBinsSchema,
]);
exports.ShortPickTroubleshootCommandsOutcome = z.union([
    exports.OpenShortPickTroubleshootStationOutcome,
    exports.CloseShortPickTroubleshootStationOutcome,
    exports.ScanShortPickTroubleshootToteOutcome,
    exports.ResolveShortPickOutcome,
    exports.DeferShortPickResolveOutcome,
    exports.CancelShortPickedOrderOutcome,
]);
