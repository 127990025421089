import { PortConfigurationType } from 'api-schema/lib/model';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { isNotErrorResponse } from '../apiClient';
import { useApiClient } from './useApiClient';

export type SetPortsAction = Dispatch<SetStateAction<PortConfigurationType[]>>;

export const usePortsApi = (
  warehouseId?: string
): [PortConfigurationType[], () => void, SetPortsAction] => {
  const [portsData, setPortData] = useState<PortConfigurationType[]>([]);
  const apiClient = useApiClient();

  const refreshPorts = useCallback(async () => {
    if (!warehouseId) {
      return;
    }
    (async () => {
      try {
        const { data, status } = await apiClient.get('/warehouses/:id/ports', {
          params: { id: warehouseId },
        });
        if (isNotErrorResponse(data, status)) {
          setPortData(data.ports);
        }
      } catch (error) {
        // error displayed by apiClient
      }
    })();
  }, [setPortData, warehouseId, apiClient]);

  useEffect(() => {
    refreshPorts();
  }, [warehouseId, refreshPorts]);

  return [portsData, refreshPorts, setPortData];
};
