import * as z from 'zod';

export const DeviceConfigSchema = z.object({
  kind: z.union([
    z.literal('PICK_STATION'),
    z.literal('REPLENISHMENT_PORT'),
    z.literal('HANDHELD'),
    z.literal('PACK_STATION'),
    z.literal('SHORT_PICK_TROUBLESHOOT'),
    z.literal('LABEL_TROUBLESHOOT'),
  ]),
  warehouseId: z.string().min(1),
  deviceId: z.string().optional(),
  port: z.string().optional(),
  stationId: z.string().optional(),
});

export type DeviceConfigSchemaType = z.infer<typeof DeviceConfigSchema>;
