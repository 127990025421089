export function useThrottle(func: Function, limit: number) {
  let lastRan: number;
  return function (...args: unknown[]) {
    const now = Date.now();
    if (!lastRan || now - lastRan >= limit) {
      func(...args);
      lastRan = now;
    }
  };
}
