import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
} from '@material-ui/core';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import React, { ReactElement } from 'react';

export interface SelectProps {
  label?: string;
  children: React.ReactNode;
  value?: unknown;
  fullWidth?: boolean;
  onChange: SelectInputProps['onChange'];
  testID?: string;
  name?: string;
  defaultValue?: string | number;
}

export function Select({
  label,
  children,
  value,
  fullWidth = false,
  onChange,
  testID,
  name,
}: SelectProps): ReactElement {
  return (
    <FormControl variant="filled" fullWidth data-testid={testID}>
      {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
      <MuiSelect
        value={value}
        variant="filled"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - this prop totally exists and works!
        hiddenlabel={`'${!label}'`}
        fullWidth={fullWidth}
        onChange={onChange}
        name={name}
        inputProps={{ id: name }}
      >
        {children}
      </MuiSelect>
    </FormControl>
  );
}
