"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.retailUnitApiSchema = void 0;
const zod_http_schemas_1 = require("zod-http-schemas");
const model_1 = require("../model");
exports.retailUnitApiSchema = (0, zod_http_schemas_1.createHttpSchema)({
    'GET /retail-units': {
        responseBody: zod_http_schemas_1.z.object({
            outcome: zod_http_schemas_1.z.literal('SUCCESS'),
            retailUnits: zod_http_schemas_1.z.array(model_1.retailUnitSchema.extend({
                weightGrams: zod_http_schemas_1.z.number().positive(),
            })),
        }),
    },
});
