import { PartitionsPerBin } from 'api-schema/lib';
import { BinConfigSelectorView } from './BinConfigSelector.view';

type Props = {
  nextBinPartitions: PartitionsPerBin;
  setNextBinPartitions: (partitions: PartitionsPerBin) => void;
  onSetBinConfiguration: () => Promise<void>;
};

export const BinConfigSelector = ({
  nextBinPartitions,
  setNextBinPartitions,
  onSetBinConfiguration,
}: Props) => {
  const partitions = [1, 2, 4, 8] as PartitionsPerBin[];

  return (
    <BinConfigSelectorView
      nextBinPartitions={nextBinPartitions}
      setNextBinPartitions={setNextBinPartitions}
      onSetBinConfiguration={onSetBinConfiguration}
      partitions={partitions}
    />
  );
};
