"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabelTroubleshootCommandsOutcome = exports.ScanTroubleshootLabelOutcome = exports.CloseLabelTroubleshootStationOutcome = exports.OpenLabelTroubleshootStationOutcome = exports.InvalidOrderStatus = exports.UnknownOrderId = exports.InvalidStationStatus = exports.StationNotFound = void 0;
const z = __importStar(require("zod"));
const model_1 = require("../../model");
const generic_1 = require("./generic");
exports.StationNotFound = z.object({
    outcome: z.literal('STATION_NOT_FOUND'),
    stationId: z.number(),
    warehouseId: z.string(),
});
exports.InvalidStationStatus = z.object({
    outcome: z.literal('INVALID_STATION_STATUS'),
    currentStatus: model_1.LabelTroubleshootStationStatus,
    expectedStatus: z.union([
        model_1.LabelTroubleshootStationStatus,
        z.array(model_1.LabelTroubleshootStationStatus),
    ]),
});
exports.UnknownOrderId = z.object({
    outcome: z.literal('UNKNOWN_ORDER_ID'),
    orderId: z.string().min(1),
});
exports.InvalidOrderStatus = z.object({
    outcome: z.literal('INVALID_ORDER_STATUS'),
    actualStatus: model_1.fulfilmentOrderStatusSchema,
    expectedStatus: z.array(model_1.fulfilmentOrderStatusSchema),
});
exports.OpenLabelTroubleshootStationOutcome = z.union([
    generic_1.Success,
    exports.StationNotFound,
    exports.InvalidStationStatus,
]);
exports.CloseLabelTroubleshootStationOutcome = z.union([
    generic_1.Success,
    exports.StationNotFound,
    exports.InvalidStationStatus,
]);
exports.ScanTroubleshootLabelOutcome = z.union([
    generic_1.Success,
    exports.StationNotFound,
    exports.InvalidStationStatus,
    exports.UnknownOrderId,
    exports.InvalidOrderStatus,
]);
exports.LabelTroubleshootCommandsOutcome = z.union([
    exports.OpenLabelTroubleshootStationOutcome,
    exports.CloseLabelTroubleshootStationOutcome,
    exports.ScanTroubleshootLabelOutcome,
]);
