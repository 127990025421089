import { MenuItem } from '@material-ui/core';
import { Warehouse } from 'api-schema';
import { FormikProps } from 'formik';
import { Button } from '../../components/admin/Button';
import { Container } from '../../components/admin/Container';
import { Input } from '../../components/admin/Input';
import { SectionHeader } from '../../components/admin/SectionHeader';
import { Select } from '../../components/admin/Select';
import { FormRow } from '../../elements/admin/FormRow';
import { getFormHandlers } from '../../utils/forms/getFormHandlers';
import { DeviceConfigSchemaType } from './DeviceConfigForm.model';

type Props = FormikProps<DeviceConfigSchemaType> & {
  warehouses: Warehouse[];
};

export const DeviceConfigFormView = ({
  values,
  errors,
  touched,
  setFieldTouched,
  setFieldValue,
  handleSubmit,
  isSubmitting,
  warehouses,
}: Props) => {
  const { handleInputTouch, handleInputChange } = getFormHandlers({
    setFieldTouched,
    setFieldValue,
  });
  return (
    <Container>
      <SectionHeader>Configure Device</SectionHeader>
      <form onSubmit={handleSubmit}>
        <FormRow spacingBottom={1}>
          <Select
            onChange={({ target: { value } }) =>
              setFieldValue('warehouseId', value)
            }
            label="Warehouse"
            value={values.warehouseId}
            name="warehouseId"
          >
            {warehouses.map(({ id, name, address }) => (
              <MenuItem key={id} value={id}>
                {name} | {address}
              </MenuItem>
            ))}
          </Select>
        </FormRow>
        <FormRow spacingBottom={1}>
          <Select
            onChange={({ target: { value } }) => setFieldValue('kind', value)}
            label="Device Type"
            value={values.kind}
            name="kind"
          >
            <MenuItem value="REPLENISHMENT_PORT">Replenishment Port</MenuItem>
            <MenuItem value="PICK_STATION">Pick Station</MenuItem>
            <MenuItem value="HANDHELD">Handheld</MenuItem>
            <MenuItem value="PACK_STATION">Pack Station</MenuItem>
            <MenuItem value="SHORT_PICK_TROUBLESHOOT">
              Short Pick Troubleshoot Station
            </MenuItem>
            <MenuItem value="LABEL_TROUBLESHOOT">
              Label Troubleshoot Station
            </MenuItem>
          </Select>
        </FormRow>
        {(values.kind === 'REPLENISHMENT_PORT' ||
          values.kind === 'PICK_STATION') && (
          <FormRow spacingBottom={1}>
            <Input
              label="Port #"
              name="port"
              type="number"
              InputProps={{ inputProps: { min: 0, max: 100 } }}
              onChange={handleInputChange}
              value={values.port}
              error={errors.port}
              touched={touched.port}
              onBlur={handleInputTouch}
            />
          </FormRow>
        )}
        {values.kind === 'HANDHELD' && (
          <FormRow spacingBottom={1}>
            <Input
              label="Handheld Device Id"
              name="deviceId"
              type="text"
              onChange={handleInputChange}
              value={values.deviceId}
              error={errors.deviceId}
              touched={touched.deviceId}
              onBlur={handleInputTouch}
            />
          </FormRow>
        )}
        {(values.kind === 'PACK_STATION' ||
          values.kind === 'SHORT_PICK_TROUBLESHOOT' ||
          values.kind === 'LABEL_TROUBLESHOOT') && (
          <FormRow spacingBottom={1}>
            <Input
              label="StationId"
              name="stationId"
              type="text"
              onChange={handleInputChange}
              value={values.stationId}
              error={errors.stationId}
              touched={touched.stationId}
              onBlur={handleInputTouch}
            />
          </FormRow>
        )}
        <FormRow>
          <Button type="submit" disabled={isSubmitting}>
            Save config
          </Button>
        </FormRow>
      </form>
    </Container>
  );
};
