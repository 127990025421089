import styled, { css } from 'styled-components';

export type RetailUnitWrapperVariant = 'primary' | 'secondary';
interface WrapperProps {
  $hasError?: Boolean;
  $variant?: RetailUnitWrapperVariant;
}

export const Wrapper = styled.div<WrapperProps>`
  border: 1px solid;
  ${({ $hasError, theme, $variant }) => {
    if ($hasError) {
      return css`
        background-color: ${theme.palette.error.light};
        border-color: ${theme.palette.error.base};
      `;
    }

    if ($variant === 'secondary') {
      return css`
        background-color: ${theme.palette.grey[1]};
        border-color: ${theme.palette.grey[4]};
      `;
    }

    return css`
      background-color: ${theme.palette.info.light};
      border-color: ${theme.palette.info.main};
    `;
  }}
  border-radius: 8px;

  ${({ theme }) => {
    return theme.currentFontSizeSet === 'large'
      ? css`
          padding: ${({ theme }) => `${theme.spacing(3)} ${theme.spacing(2)}`};
        `
      : css`
          padding: ${({ theme }) => `${theme.spacing(1)}`};
        `;
  }};

  display: flex;
  align-items: center;
  width: 100%;
`;

export const ImgWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.palette.grey[5]};
  background-color: #fff;
  height: 120px;
  width: 120px;
`;

export const ItemInfoWrapper = styled.div`
  padding-left: ${({ theme }) => `${theme.spacing(2)}`};
`;

export const Barcode = styled.span`
  display: block;
  overflow-wrap: anywhere;
  font-weight: ${({ theme }) => theme.weight.bold};
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  touch-action: none;
`;

export const Name = styled.span`
  display: block;
  font-weight: ${({ theme }) => theme.typography.body1.fontWeight};
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
`;

export const Options = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
`;

export const ItemImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const QuantityWrapper = styled.div`
  width: 60px;
  margin: ${({ theme }) => theme.spacing(1)};
  margin-left: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  font-weight: ${({ theme }) => theme.weight.bold};
  font-size: ${({ theme }) => theme.typography.h1.fontSize};
`;
