import styled from 'styled-components';

export const ChipBase = styled.div`
  display: inline-flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(1.5)}px;
  border-radius: ${({ theme }) => theme.spacing(12.5)}px;
  font-weight: bold;
  gap: ${({ theme }) => theme.spacing(1)}px;
  font-size: 0.8rem;
  text-transform: uppercase;
  line-height: 1.5;
  background-color: ${({ theme }) => theme.palette.metallicBronze.main};
  color: ${({ theme }) => theme.palette.metallicBronze.contrastText};
`;

export const IconWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.palette.metallicBronze.contrastText};
  & > svg {
    stroke: currentColor;
  }
`;
