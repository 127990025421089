"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.blindCountTotalItemQuantityInvalidOutcomeSchema = exports.toteNotFoundOutcomeSchema = exports.invalidFulfilmentOrderStatusSchema = exports.unknownBarcodeSchema = exports.PackingStationNotFound = exports.ToteAlreadyScanned = exports.ToteContainsResolutionPicklist = exports.InvalidTote = exports.invalidPicklistStatusSchema = exports.TroubleshootLabelGenerationFailed = exports.OrderPackingFailed = exports.DuplicatedCompletedPicklist = exports.RequestLabelFailed = exports.NoFulfilmentOrder = exports.PicklistNotFound = exports.NoPicklistAssigned = exports.InvalidStationStatus = exports.UnknownToteId = exports.NoOrderId = void 0;
const z = __importStar(require("zod"));
exports.NoOrderId = z.object({
    outcome: z.literal('NO_ORDER_ID'),
    toteId: z.string(),
});
exports.UnknownToteId = z.object({
    outcome: z.literal('UNKNOWN_TOTE_ID'),
});
exports.InvalidStationStatus = z.object({
    outcome: z.literal('INVALID_STATION_STATUS'),
    currentStatus: z.union([z.string(), z.array(z.string())]).optional(),
    expectedStatus: z.union([z.string(), z.array(z.string())]).optional(),
});
exports.NoPicklistAssigned = z.object({
    outcome: z.literal('NO_PICKLIST_ASSIGNED'),
});
// TODO (WMS-1621) Update all these types to correct format:
// 'thingNotFoundOutcomeSchema` / 'ThingNotFoundOutcome'
// and export types as such
exports.PicklistNotFound = z.object({
    outcome: z.literal('PICKLIST_NOT_FOUND'),
    picklistId: z.string(),
});
exports.NoFulfilmentOrder = z.object({
    outcome: z.literal('NO_FULFILMENT_ORDER'),
});
exports.RequestLabelFailed = z.object({
    outcome: z.literal('REQUEST_LABEL_FAILED'),
});
exports.DuplicatedCompletedPicklist = z.object({
    outcome: z.literal('DUPLICATED_COMPLETED_PICKLIST'),
});
exports.OrderPackingFailed = z.object({
    outcome: z.literal('ORDER_PACKING_FAILED'),
    orderId: z.string().optional(),
    picklistId: z.string().optional(),
});
exports.TroubleshootLabelGenerationFailed = z.object({
    outcome: z.literal('TROUBLESHOOT_LABEL_GENERATION_FAILED'),
    fulfilmentOrderId: z.string().optional(),
    stationId: z.number(),
});
exports.invalidPicklistStatusSchema = z.object({
    outcome: z.literal('INVALID_PICKLIST_STATUS'),
    toteId: z.string().optional(),
    expectedStatus: z.string(),
    actualStatus: z.string(),
});
exports.InvalidTote = z.object({
    outcome: z.literal('INVALID_TOTE'),
    toteId: z.string(),
});
exports.ToteContainsResolutionPicklist = z.object({
    outcome: z.literal('TOTE_CONTAINS_RESOLUTION_PICKLIST'),
    toteId: z.string(),
});
exports.ToteAlreadyScanned = z.object({
    outcome: z.literal('TOTE_ALREADY_SCANNED'),
    toteId: z.string(),
    stationId: z.number(),
});
exports.PackingStationNotFound = z.object({
    outcome: z.literal('PACKING_STATION_NOT_FOUND'),
});
exports.unknownBarcodeSchema = z.object({
    outcome: z.literal('UNKNOWN_BARCODE'),
});
exports.invalidFulfilmentOrderStatusSchema = z.object({
    outcome: z.literal('INVALID_FULFILMENT_ORDER_STATUS'),
    expectedStatus: z.string(),
    actualStatus: z.string(),
});
exports.toteNotFoundOutcomeSchema = z.object({
    outcome: z.literal('TOTE_NOT_FOUND'),
    toteId: z.string().optional(),
});
exports.blindCountTotalItemQuantityInvalidOutcomeSchema = z.object({
    outcome: z.literal('BLIND_COUNT_TOTAL_ITEM_QUANTITY_INVALID'),
    blindCountTotalItemQuantity: z.number().optional(),
});
