"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.autostoreStateReceivedEvent = exports.autostorePortErrorStatusChangedEventSchema = exports.autostorePortDefinitionChangedEvent = exports.autostoreLogDownloadCompletedEvent = exports.autostoreLogDownloadStartedEvent = exports.autostoreBinLocationChangedEvent = exports.autostoreBinClosedEvent = exports.autostoreBinOpenedEvent = exports.autostorePortModeChangedEvent = exports.autostoreCompletePortState = exports.autostorePortState = exports.autostoreBinStateSchema = exports.autostorePortDefinition = exports.autostorePortType = exports.autostorePortMode = exports.autostoreBinMode = void 0;
const z = __importStar(require("zod"));
exports.autostoreBinMode = z.enum([
    'GRID',
    'PREPARED',
    'PORT',
    'TOGRID',
    'OPEN',
    'CLOSED',
    'OUTSIDE',
    'FORECAST',
    'UNKNOWN',
]);
exports.autostorePortMode = z.enum([
    'OPEN',
    'INSERT',
    'AUTO',
    'CLOSED',
    'UNKNOWN',
]);
exports.autostorePortType = z.enum([
    'CONVEYOR',
    'CAROUSEL',
    'SWING',
    'RELAY',
    'CUSTOM',
    'UNKNOWN',
]);
exports.autostorePortDefinition = z.object({
    id: z.number().int().positive(),
    type: exports.autostorePortType,
    position: z.array(z.object({
        x: z.number(),
        y: z.number(),
    })),
});
exports.autostoreBinStateSchema = z.object({
    id: z.number().int().positive(),
    gridId: z.number(),
    xPosition: z.number().optional(),
    yPosition: z.number().optional(),
    depth: z.number().optional(),
    mode: exports.autostoreBinMode.optional(),
    contentCode: z.number().optional(),
    height: z.number().optional(),
});
exports.autostorePortState = z.object({
    id: z.number().int().positive(),
    mode: exports.autostorePortMode,
});
exports.autostoreCompletePortState = exports.autostorePortDefinition
    .partial()
    .and(exports.autostorePortState.partial())
    .and(z.object({
    bins: z.array(exports.autostoreBinStateSchema).optional(),
}));
exports.autostorePortModeChangedEvent = z.object({
    type: z.literal('AUTOSTORE_PORT_MODE_CHANGED'),
    payload: exports.autostorePortState,
    warehouseId: z.string().min(1),
});
exports.autostoreBinOpenedEvent = z.object({
    type: z.literal('AUTOSTORE_BIN_OPENED'),
    payload: exports.autostoreBinStateSchema.pick({ id: true }),
    warehouseId: z.string().min(1),
});
exports.autostoreBinClosedEvent = z.object({
    type: z.literal('AUTOSTORE_BIN_CLOSED'),
    payload: exports.autostoreBinStateSchema.pick({ id: true }),
    warehouseId: z.string().min(1),
});
exports.autostoreBinLocationChangedEvent = z.object({
    type: z.literal('AUTOSTORE_BIN_LOCATION_CHANGED'),
    payload: exports.autostoreBinStateSchema,
    warehouseId: z.string().min(1),
});
exports.autostoreLogDownloadStartedEvent = z.object({
    type: z.literal('AUTOSTORE_LOG_DOWNLOAD_STARTED'),
    warehouseId: z.string().min(1),
});
exports.autostoreLogDownloadCompletedEvent = z.object({
    type: z.literal('AUTOSTORE_LOG_DOWNLOAD_COMPLETED'),
    warehouseId: z.string().min(1),
});
exports.autostorePortDefinitionChangedEvent = z.object({
    type: z.literal('AUTOSTORE_PORT_DEFINITION_CHANGED'),
    payload: exports.autostorePortDefinition,
    warehouseId: z.string().min(1),
});
exports.autostorePortErrorStatusChangedEventSchema = z.object({
    type: z.literal('AUTOSTORE_PORT_ERROR_STATUS_CHANGED'),
    payload: z.object({ portId: z.number().int(), hasError: z.boolean() }),
    warehouseId: z.string().min(1),
});
exports.autostoreStateReceivedEvent = z.object({
    type: z.literal('AUTOSTORE_STATE_RECEIVED'),
    payload: z.object({ ports: z.array(exports.autostoreCompletePortState) }),
    warehouseId: z.string().min(1),
});
