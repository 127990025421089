import styled, { css } from 'styled-components';

export const Container = styled.div<{
  $selected?: boolean;
}>`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${(p) => `1rem ${p.theme.spacing(1)} 1rem ${p.theme.spacing(2)}`};
  position: relative;

  font-size: 1.25rem;
  line-height: 1.5;
  background-color: ${(p) => p.theme.palette.background.default};
  border-radius: ${(p) => p.theme.shape.borderRadius}px;

  ${(p) =>
    p.$selected &&
    css`
      background-color: ${p.theme.palette.primary.light};
      border-left: 6px solid ${p.theme.palette.primary.main};
      padding-left: calc(${p.theme.spacing(2)} - 6px);
    `}
  cursor: pointer;

  &:hover {
    ${(p) =>
      !p.$selected &&
      css`
        background-color: ${p.theme.palette.grey[3]};
      `}
  }

  &:active {
    ${(p) =>
      p.$selected
        ? css`
            background: linear-gradient(
                0deg,
                rgba(0, 81, 168, 0.2) 0%,
                rgba(0, 81, 168, 0.2) 100%
              ),
              #b5d4f4;
          `
        : css`
            border-left: 6px solid ${p.theme.palette.primary.main};
            padding-left: calc(${p.theme.spacing(2)} - 6px);
            background-color: ${p.theme.palette.primary.light};
          `}
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: ${(p) => p.theme.spacing(1)};
  top: 0.75rem;
`;
