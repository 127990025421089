import { ReactElement, useEffect, useRef } from 'react';
import { locationColours } from '../../../screens/pickStation/components/ToteLinkingForm/ToteLinkingForm';
import { TotesListSplitContainer } from './PickPort.elements';
import { ReactComponent as PickPortTotesSVG } from './svgs/pick-port-totes.svg';

export type PickCount = {
  [key: number]: number | undefined;
};

type PickPortTotesProps = {
  width?: string;
  height?: string;
  pickCount: PickCount;
  toteIds: (string | null)[];
  isDebugging: boolean;
};

const TOTE_NUMBER_SELECTOR = '.counter .number > tspan';

export const PickPortTotes = ({
  width = '500',
  height = 'auto',
  pickCount,
  toteIds,
  isDebugging,
}: PickPortTotesProps): ReactElement => {
  const totesWrapper = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    Object.entries(pickCount).forEach(([tote, count]) => {
      if (!count) {
        return;
      }

      const countElement = totesWrapper.current?.querySelector(
        `.tote-${tote} ${TOTE_NUMBER_SELECTOR}`
      );
      if (countElement) {
        countElement.textContent = count.toString();
      }
    });
  }, [pickCount, totesWrapper]);

  return (
    <div ref={totesWrapper}>
      <PickPortTotesSVG width={width} height={height} data-testid="totes" />
      {isDebugging ? <TotesListSplit toteIds={toteIds} /> : undefined}
    </div>
  );
};

type TotesListSplitProps = {
  toteIds: (string | null)[];
};

const TotesListSplit = ({ toteIds }: TotesListSplitProps) => {
  return (
    <TotesListSplitContainer data-testid="totes-list">
      <TotesList toteIds={toteIds.slice(0, 3)} addend={1} />
      <TotesList toteIds={toteIds.slice(3, 6)} addend={4} />
    </TotesListSplitContainer>
  );
};

type TotesListProps = {
  toteIds: (string | null)[];
  addend: number;
};

const TotesList = ({ toteIds, addend }: TotesListProps) => {
  return (
    <ul>
      {toteIds.map((toteId, index) => {
        if (toteId === null) {
          return null;
        }
        const colour = locationColours[index + addend];
        return <ToteLineItem colour={colour} toteId={toteId} />;
      })}
    </ul>
  );
};

type ToteLineItemProps = {
  colour: string;
  toteId: string;
};

const ToteLineItem = ({ colour, toteId }: ToteLineItemProps) => (
  <li key={`tote-id-debug-${toteId}`}>
    {colour} tote: {toteId}
  </li>
);
