import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  border: 1px solid ${({ theme }) => theme.palette.grey[4]};
  border-radius: 10px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
`;

export const StepperWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  padding: 20px 0px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
`;

export const ProgressWrapper = styled.div`
  position: absolute;
  top: 8px;
  left: -50%;
  right: 50%;
  z-index: 1;
`;

export const Step = styled.div`
  flex: 1;
  position: relative;
`;

export const StepContent = styled.div`
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 2;
`;

export const IconWrapper = styled.div`
  width: 20px;
  height: auto;
  margin: 0 auto;

  svg {
    width: 20px;
    height: auto;
    z-index: 20;
  }
`;

export const Title = styled.span`
  font-size: ${({ theme }) => theme.typography.h3.fontSize};
  font-family: ${({ theme }) => theme.typography.h3.fontFamily};
  font-weight: ${({ theme }) => theme.typography.h3.fontWeight};
`;

interface FillerProps {
  $progress: number;
}

export const ProgressBar = styled.div`
  height: 2px;
  width: 100%;
  border-radius: 50px;
  overflow: hidden;
  background: ${({ theme }) => theme.palette.grey[4]};
`;

export const Filler = styled.div<FillerProps>`
  height: 100%;
  width: ${({ $progress }) => `${$progress}%`};
  border-radius: inherit;
  background: ${({ theme }) => theme.palette.primary.main};
`;

export interface StepperProgressBarProps {
  progress: number;
}

export function StepperProgressBar({
  progress,
}: StepperProgressBarProps): React.ReactElement {
  return (
    <ProgressBar>
      <Filler $progress={progress} />
    </ProgressBar>
  );
}
