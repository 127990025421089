import styled, { css, CSSProperties } from 'styled-components';
import { ButtonBase } from '../ButtonBase';

export const Wrapper = styled.div<{ $margin: CSSProperties['margin'] }>`
  margin: ${({ theme, $margin }) =>
    $margin ? $margin : `${theme.spacing(2)} 0 0 0`};
  position: relative;
  display: inline-flex;
  overflow: hidden;
  border: none;
`;

export const Field = styled.input`
  width: 120px;
  text-align: center;
  border: none;

  ${({ theme }) => css`
    background: ${theme.palette.common.white};
    padding: ${theme.spacing(1.7)} ${theme.spacing(1)};
    font-family: ${theme.typography.fontFamily};
    font-size: ${theme.typography.body1.fontSize};
    font-weight: ${theme.typography.body1.fontWeight};
    line-height: ${theme.typography.body1.lineHeight};

    border: 1px solid ${theme.palette.grey[6]};

    &:focus {
      outline: none;
      border: 1px solid ${theme.palette.primary.main};
      box-shadow: 0 0 0 4px rgba(0, 95, 198, 0.4) inset;
    }
  `};
`;

export const NumericInputButton = styled(ButtonBase)<{
  $size?: 'regular' | 'large';
}>`
  ${({ $size, theme }) => css`
    position: relative;
    width: ${$size === 'large' ? '7.5rem' : '5rem'};
    height: ${$size === 'large' ? '7.5rem' : '5rem'};

    background: ${theme.palette.grey[3]};
    font-family: ${theme.typography.fontFamily};
    font-size: ${theme.typography.body1.fontSize};
    font-weight: ${theme.typography.body1.fontWeight};
    line-height: ${theme.typography.body1.lineHeight};
    border: 1px solid ${theme.palette.grey[6]};

    &:first-child {
      border-right: none;
      border-radius: ${theme.shape.borderRadius}px 0 0
        ${theme.shape.borderRadius}px;
    }

    &:last-child {
      border-left: 0;
      border-radius: 0 ${theme.shape.borderRadius}px
        ${theme.shape.borderRadius}px 0;
    }

    &:hover {
      &::before {
        position: absolute;
        content: '';
        width: ${$size === 'large' ? '7.5rem' : '5rem'};
        height: 100%;
        left: 0;
        top: 0;
        overflow: hidden;
        z-index: 0;
        background: rgba(0, 0, 0, 0.1);
      }
    }

    &:active {
      &::before {
        position: absolute;
        content: '';
        width: ${$size === 'large' ? '7.5rem' : '5rem'};
        height: 100%;
        left: 0;
        top: 0;
        overflow: hidden;
        z-index: 0;
        background: rgba(0, 0, 0, 0.2);
      }
    }

    &:disabled {
      ${({ theme }) => css`
        background: ${theme.palette.disabled.main};
        color: ${theme.palette.disabled.contrastText};
        border: 1px solid ${theme.palette.disabled.secondary};

        &:first-child {
          border-right: none;
          border-radius: ${theme.shape.borderRadius}px 0 0
            ${theme.shape.borderRadius}px;
        }

        &:last-child {
          border-left: 0;
          border-radius: 0 ${theme.shape.borderRadius}px
            ${theme.shape.borderRadius}px 0;
        }

        &:hover {
          cursor: default;
          &::before {
            content: none;
          }
        }
      `};
    }
  `}
`;
