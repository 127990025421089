import {
  AppBar as AppBarBase,
  Drawer as MuiDrawer,
  ListItem,
} from '@material-ui/core';
import { darken } from '@material-ui/core/styles';
import styled from 'styled-components';
import { APP_BAR_HEIGHT } from '../../../main/styles';

const DRAWER_WIDTH_OPEN = 240;

export const Wrapper = styled.div`
  width: ${DRAWER_WIDTH_OPEN}px;
`;

export const Drawer = styled(MuiDrawer)`
  .MuiDrawer-paper {
    margin-top: ${APP_BAR_HEIGHT}px;
    width: ${DRAWER_WIDTH_OPEN}px;
    overflow-x: hidden;
`;

export const Trigger = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
`;

interface SectionProps {
  spacing?: number;
  separator?: boolean;
}

export const Section = styled.section<SectionProps>`
  ${({ separator = true, theme }) =>
    separator ? `border-bottom: 1px solid ${theme.palette.grey[300]};` : null}
  padding: ${({ theme, spacing = 1 }) => theme.spacing(spacing)}px;
`;

export const Name = styled.div`
  height: 30px;
  white-space: nowrap;
  transition: ${({ theme }) =>
    theme.transitions.create(['opacity', 'height'], { duration: 100 })};
`;

export const Item = styled(({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <ListItem classes={{ selected: 'selected' }} {...props} />
))`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: ${({ theme }) => theme.spacing(1)}px;
  transition: all 0.3s ease;

  & .MuiListItemText-primary {
    font-size: ${({ theme }) => theme.typography.body2.fontSize};
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
    white-space: nowrap;
  }
  & .MuiListItemIcon-root {
    transition: ${({ theme }) => theme.transitions.create('color')};
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
    color: ${({ theme }) => theme.palette.primary.main};

    & .MuiListItemIcon-root {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  &.selected {
    background-color: ${({ theme }) => theme.palette.primary.light};
    color: ${({ theme }) => theme.palette.primary.main};

    &:hover {
      background-color: ${({ theme }) =>
        darken(theme.palette.primary.light, 0.1)};
    }

    & .MuiListItemIcon-root {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

export const Warehouse = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const WarehouseName = styled.span`
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  margin-left: ${({ theme }) => theme.spacing(3)}px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const AppBar = styled(AppBarBase)`
  background-color: white;
`;
