import { Text } from '../../../../components/warehouse/Text';
import { ListItemType } from '../ListItems/ListItems';
import {
  ContentWrapper,
  IconWrapper,
  Wrapper,
} from './ListItemsTitle.elements';

export type ListItemsTitleProps = {
  listItemType: ListItemType;
  title: string;
  sideText?: string;
  icon?: JSX.Element;
};

export function ListItemsTitle({
  listItemType,
  title,
  icon,
  sideText,
}: ListItemsTitleProps) {
  return (
    <Wrapper $type={listItemType}>
      <IconWrapper $type={listItemType}>{icon}</IconWrapper>
      <ContentWrapper>
        <Text variant="body1" tag="span" weight="medium">
          {title}
        </Text>
        {sideText && (
          <Text variant="body1" tag="span">
            {sideText}
          </Text>
        )}
      </ContentWrapper>
    </Wrapper>
  );
}
