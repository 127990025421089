import styled from 'styled-components';

export const TitleText = styled.p`
  display: contents;
  font-size: ${({ theme }) => theme.typography.h1.fontSize};
  font-weight: ${({ theme }) => theme.typography.h1.fontWeight};
  line-height: ${({ theme }) => theme.typography.h1.lineHeight};
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.blue.transparent};
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  padding: 0 8px;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const QuantityInputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  padding-top: ${({ theme }) => theme.spacing(2)};
`;
