import { useCallback, useEffect, useState } from 'react';

const LOCAL_STORAGE_CHANGED_EVENT_NAME = `local-storage-changed`;

type Key = 'AUTH_TOKEN' | 'DEVICE_CONFIG';

export const useLocalStorage = <T>(key: Key, initialValue?: T) => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  });

  const setValue = (value: T) => {
    setStoredValue(value);
    window.localStorage.setItem(key, JSON.stringify(value));
    // notify other components that are also using this hook
    window.dispatchEvent(new Event(LOCAL_STORAGE_CHANGED_EVENT_NAME));
  };

  const onLocalStorageChanged = useCallback(() => {
    const item = window.localStorage.getItem(key);
    const parsed = item ? JSON.parse(item) : initialValue;

    // another component may update the value, so we want to keep it in sync
    if (storedValue !== parsed) {
      setStoredValue(parsed);
    }
  }, [storedValue, initialValue, key]);
  useEffect(() => {
    window.addEventListener(
      LOCAL_STORAGE_CHANGED_EVENT_NAME,
      onLocalStorageChanged
    );
    return () =>
      window.removeEventListener(
        LOCAL_STORAGE_CHANGED_EVENT_NAME,
        onLocalStorageChanged
      );
  }, [onLocalStorageChanged]);

  return [storedValue, setValue] as const;
};
