import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const SummaryItemSubtext = styled(Box)`
  color: ${({ theme }) => theme.palette.grey['600']};
`;

export const SummaryMissingQuantityText = styled(Box)`
  font-size: ${({ theme }) => `${theme.typography.fontSize * 2}px`};
  color: ${({ theme }) => theme.palette.error.main};
  font-weight: bold;
`;
