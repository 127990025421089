import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { ApiClient, getApiClient } from '../apiClient';
import { SnackbarDefaults } from '../constants/snackbarDefaults';
import { useLocalStorage } from './useLocalStorage';

// TODO(WMS-735): address orphaned todo
// eslint-disable-next-line todo-plz/ticket-ref
// TODO: remove all ts-ignores
// Apiclient type derived mismatches the HttpClientSchema type
export const useApiClient = (): ApiClient => {
  const { enqueueSnackbar } = useSnackbar();
  const [authToken] = useLocalStorage<string>('AUTH_TOKEN');

  const api = useMemo(() => {
    const apiClient = getApiClient(authToken);
    return {
      // @ts-ignore
      get: async (path, info) => {
        try {
          // @ts-ignore
          return await apiClient.get(path, info);
        } catch (e) {
          enqueueSnackbar('An unexpected server error occurred', {
            ...SnackbarDefaults,
            variant: 'error',
          });
          throw e;
        }
      },
      // @ts-ignore
      post: async (path, info) => {
        try {
          // @ts-ignore
          return await apiClient.post(path, info);
        } catch (e) {
          enqueueSnackbar('An unexpected server error occurred', {
            ...SnackbarDefaults,
            variant: 'error',
          });
          throw e;
        }
      },
      // @ts-ignore
      put: async (path, info) => {
        try {
          // @ts-ignore
          return await apiClient.put(path, info);
        } catch (e) {
          enqueueSnackbar('An unexpected server error occurred', {
            ...SnackbarDefaults,
            variant: 'error',
          });
          throw e;
        }
      },
    };
  }, [enqueueSnackbar, authToken]);

  //@ts-ignore
  return api;
};
