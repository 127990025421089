import { PickPortStateType } from 'api-schema';
import { OpenPickPortOutcomeType } from 'api-schema/lib/commands/outcomes';
import { OpenPickPortOutcome } from 'api-schema/lib/commands/outcomes/pick';
import {
  PickPortWithHydratedEntities,
  ToteLocation,
} from 'api-schema/lib/model';
import { useSnackbar } from 'notistack';
import { ReactElement, useEffect, useState } from 'react';
import { usePickPortSocket } from '../../../components/common/SocketProvider';
import { AlertMessageTypes } from '../../../components/warehouse/AlertMessage';
import { AutoStoreResumed } from '../../../components/warehouse/AutoStoreResumed/AutoStoreResumed';
import { AutoStoreStoppedWorking } from '../../../components/warehouse/AutoStoreStoppedWorking/AutoStoreStoppedWorking';
import { Button } from '../../../components/warehouse/Button';
import { Layout } from '../../../components/warehouse/Layout';
import { StationLocked } from '../../../components/warehouse/StationLocked';
import { SnackbarDefaults } from '../../../constants/snackbarDefaults';
import { useWarehouseParam } from '../../../hooks/useWarehouseParam';
import { useAppState } from '../../../store';
import { pluralise } from '../../../utils/pluralise';
import { useThrottle } from '../../../utils/throttle';
import { AllItemsMissingModal } from '../components/AllItemsMissingModal';
import { ClosePortModal } from '../components/ClosePortModal';
import { FreeInputTroubleshootModal } from '../components/FreeInputTroubleshootModal';
import { Loading } from '../components/Loading';
import { MissingItemModal } from '../components/MissingItemModal';
import { NextItemModal } from '../components/NextItemModal';
import { NoPaperAvailableModal } from '../components/NoPaperAvailableModal';
import { PaperTroubleshootModal } from '../components/PaperTroubleshootModal';
import { PickingInformation } from '../components/PickingInformation';
import { PickPortError } from '../components/PickPortError';
import { PlacePaper } from '../components/PlacePaper';
import { PlaceTotes } from '../components/PlaceTotes';
import { PushBackTotes } from '../components/PushBackTotes';
import { ReplaceTotes } from '../components/ReplaceTotes';
import { RetryCloseBin } from '../components/RetryCloseBin';
import { ScanBarcodeInstructionModal } from '../components/ScanBarcodeInstructionModal';
import { ShortPickModal } from '../components/ShortPickModal';
import { StartPicking } from '../components/StartPicking';
import { ToteLinkingForm } from '../components/ToteLinkingForm';
import { PickStationView } from './PickStation.view';

const NO_PICKS_IN_PROGRESS_STATES: PickPortStateType['status'][] = [
  'READY',
  'LINKING',
  'NO_TASK_GROUP',
  'REPLACING_TOTES',
];

const LOCATION_LINKED_STATES: ToteLocation['state'][] = [
  'LINKED',
  'ASSIGNED',
  'READY',
  'COMPLETE',
];

const LOCATION_LINKING_STATES: ToteLocation['state'][] = [
  'UNLINKED',
  'SCANNED',
  'LINKED',
];

const OPEN_PORT_ERROR_MESSAGE =
  'The picking port failed to open. Please try again. If the problem persists, please alert your Manager.';

const NO_TASK_GROUPS_MESSAGE =
  'There are currently no pick lists available to complete.';

export const CHECK_BIN_HAS_ARRIVED_MESSAGE =
  'Please make sure the bin has arrived before pressing the button.';

const UNEXPECTED_BIN_MESSAGE =
  'There was a problem retrieving the next bin. Please alert your Manager.';

const RETRY_START_PICKING_MESSAGE =
  'The picking port is ready to continue picking. Please try again. If the problem persists, please alert your Manager.';

const CLOSE_PORT_ERROR_MESSAGE =
  'The picking port failed to close. Please try again. If the problem persists, please alert your Manager.';

const CHECK_BIN_HAS_ARRIVED_ERROR_MESSAGE =
  'There was a problem confirming bin has arrived. Please alert your Manager.';

const PORT_ALREADY_OPENED_WITH_TASK_ASSIGNED =
  'The picking port is already open and contains a task. If the problem persists, please alert your Manager.';

const openPortResultMessageMap: Record<
  OpenPickPortOutcomeType['outcome'],
  string | null
> = {
  INVALID_PORT_STATUS: PORT_ALREADY_OPENED_WITH_TASK_ASSIGNED,
  ERROR: OPEN_PORT_ERROR_MESSAGE,
  INVALID_PORT_TYPE: OPEN_PORT_ERROR_MESSAGE,
  NO_AUTOSTORE: OPEN_PORT_ERROR_MESSAGE,
  NO_MATCHING_BINS_EXIST: OPEN_PORT_ERROR_MESSAGE,
  NO_MATCHING_BINS_READY: OPEN_PORT_ERROR_MESSAGE,
  PORT_NOT_FOUND: OPEN_PORT_ERROR_MESSAGE,
  UNABLE_TO_GET_AUTOSTORE_PORT_STATUS:
    'There was an error connecting with the Autostore',
  PORT_ALREADY_OPENED_WITH_TASK_ASSIGNED,
  SUCCESS: null,
};

export const PickStation = () => {
  useWarehouseParam();
  const {
    appState: { portState, currentWarehouse },
  } = useAppState();

  const { dispatchCommand } = usePickPortSocket();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [modal, setModal] = useState<ReactElement | undefined>(undefined);
  const [hasAutoStoreError, setHasAutoStoreError] = useState(false);
  const [currentBinPortLocation, setCurrentBinPortLocation] = useState<
    number | undefined
  >(undefined);
  const [isScanBarcodeInstructionVisible, setIsScanBarcodeInstructionVisible] =
    useState<boolean>(false);

  const currentBin = portState?.currentBin;

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    const status = currentWarehouse?.autostore?.status;
    const isError = Boolean(status && status !== 'STARTED');
    if (!hasAutoStoreError && isError) {
      setHasAutoStoreError(true);
    }
  }, [hasAutoStoreError, currentWarehouse?.autostore?.status]);

  useEffect(() => {
    if (currentBin?.currentPortId !== portState?.portId) {
      setCurrentBinPortLocation(currentBin?.currentPortId);
    }
  }, [currentBin?.currentPortId, portState?.portId]);

  const pushCompletedTotes = async () => {
    await dispatchCommand({ type: 'PUSH_COMPLETED_TOTES' });
  };
  const throttledPushCompletedTotes = useThrottle(pushCompletedTotes, 1000);

  if (portState?.type !== 'PICK') {
    //TODO this should be some sort of error
    return <>Uh oh, this isn't a pick port</>;
  }

  const openPort = async () => {
    const commandResult = await dispatchCommand({ type: 'OPEN_PICK_PORT' });
    // commandResult should be of type OpenPickPortOutcome, parse to filter out other results from dispatchCommand
    const parsedCommandResult = OpenPickPortOutcome.safeParse(
      commandResult.result
    );
    if (parsedCommandResult.success) {
      const { outcome } = parsedCommandResult.data;
      setErrorMessage(openPortResultMessageMap[outcome]);
    } else {
      setErrorMessage(OPEN_PORT_ERROR_MESSAGE);
    }
  };

  const showCloseModal = () =>
    setModal(
      <ClosePortModal
        cancel={() => setModal(undefined)}
        close={closePort}
        picksAreInProgress={
          !NO_PICKS_IN_PROGRESS_STATES.includes(portState.status) ||
          !!portState.toteLocations.find((location) => location.picklist)
        }
      />
    );

  const showShortPickModal = () =>
    setModal(
      <ShortPickModal
        maximumNumberAvailable={portState.currentPick?.quantity ?? 0}
        closeModal={() => setModal(undefined)}
        updateQuantity={updateAvailableQuantity}
        allItemsMissing={showAllItemsMissingModal}
      />
    );

  const showAllItemsMissingModal = () =>
    setModal(
      <AllItemsMissingModal
        closeModal={() => setModal(undefined)}
        updateQuantity={updateAvailableQuantity}
      />
    );

  const showFreeInputTroubleshootModal = () =>
    setModal(
      <FreeInputTroubleshootModal
        closeModal={() => setModal(undefined)}
        submitIssue={raisePickProblem}
      />
    );

  const showPaperProblemModal = () =>
    setModal(
      <PaperTroubleshootModal
        paperVariant={
          portState.status === 'PLACING_RED_PAPER' ? 'red' : 'green'
        }
        closeModal={() => setModal(undefined)}
        noPaperAvailable={() => {
          raiseNoPaperProblem();
          showNoPaperModal();
        }}
        otherIssue={showFreeInputTroubleshootModal}
      />
    );

  const showNoPaperModal = () => {
    setModal(
      <NoPaperAvailableModal
        paperVariant={
          portState.status === 'PLACING_RED_PAPER' ? 'red' : 'green'
        }
        closeModal={() => setModal(undefined)}
      />
    );
  };

  const closePort = async () => {
    setModal(undefined);
    const commandResult = await dispatchCommand({ type: 'CLOSE_PORT' });
    if (commandResult.result.outcome !== 'SUCCESS') {
      setErrorMessage(CLOSE_PORT_ERROR_MESSAGE);
    }
  };

  const onCheckBinHasArrived = async () => {
    enqueueSnackbar(`Checking if bin has arrived at port`, SnackbarDefaults);
    const checkBinHasArrivedResult = await dispatchCommand({
      type: 'CHECK_PICK_BIN_HAS_ARRIVED',
    });
    if (checkBinHasArrivedResult.result.outcome !== 'SUCCESS') {
      setErrorMessage(CHECK_BIN_HAS_ARRIVED_ERROR_MESSAGE);
    }
  };

  const completePick = async () => {
    await dispatchCommand({ type: 'COMPLETE_PICK' });
  };

  const detectMissingItem = async () => {
    await dispatchCommand({
      type: 'DETECT_MISSING_ITEM',
    });
  };

  const cancelMissingItemResolution = async () => {
    await dispatchCommand({ type: 'CANCEL_MISSING_ITEM_RESOLUTION' });
  };

  const confirmMissingItem = async () => {
    const item = portState.currentPick?.item.id
      ? portState.currentPick?.item.id
      : '';
    await dispatchCommand({
      type: 'CONFIRM_MISSING_ITEM_DURING_PICK',
      currentPickItem: item,
    });
  };

  const undoPreviousPick = async () => {
    await dispatchCommand({ type: 'UNDO_PREVIOUS_PICK' });
  };

  const pushTotes = async () => {
    await dispatchCommand({ type: 'PUSH_PICK_TOTES' });
  };

  const undoPushTotes = async () => {
    await dispatchCommand({ type: 'UNDO_PUSH_PICK_TOTES' });
  };

  const replaceTotes = async () => {
    await dispatchCommand({ type: 'REPLACE_PICK_TOTES' });
  };

  const updateAvailableQuantity = async (availableQuantity: number) => {
    await dispatchCommand({
      type: 'UPDATE_AVAILABLE_PICK_QUANTITY',
      availableQuantity,
    });
    setModal(undefined);
  };

  const placePaper = async () => {
    await dispatchCommand({
      type: 'PLACE_PAPER_IN_TOTES',
    });
  };

  const raisePickProblem = async (problemDescription: string) => {
    await dispatchCommand({ type: 'RAISE_PICK_PROBLEM', problemDescription });
    setModal(undefined);
  };

  const raiseNoPaperProblem = async () => {
    await dispatchCommand({ type: 'RAISE_NO_PAPER_PROBLEM' });
  };

  const showScanBarcodeInstruction = () => {
    setIsScanBarcodeInstructionVisible(true);
  };

  const hideScanBarcodeInstruction = () => {
    setIsScanBarcodeInstructionVisible(false);
  };

  const validateBarcode = (barcode: string) => {
    dispatchCommand({ type: 'VALIDATE_PICK_ITEM_BARCODE', barcode });
  };

  if (portState.isLocked) {
    return (
      <StationLocked
        portId={portState.portId}
        portType={portState.type}
        reason={portState.lockReason}
      />
    );
  }

  if (portState.status === 'CLOSED') {
    return (
      <StartPicking
        portId={portState.portId}
        openPort={openPort}
        error={errorMessage}
      />
    );
  }

  if (portState.status === 'OPENING') {
    return (
      <Layout type="PORT">
        <Loading message="Opening picking port" />
      </Layout>
    );
  }

  if (portState.status === 'CLOSING') {
    return (
      <Layout type="PORT">
        <Loading message="Closing picking port" />
      </Layout>
    );
  }

  if (hasAutoStoreError && currentWarehouse?.autostore?.status === 'STARTED') {
    return (
      <AutoStoreResumed
        Button={
          <Button onClick={() => setHasAutoStoreError(false)}>
            Continue picking
          </Button>
        }
      />
    );
  }

  if (hasAutoStoreError) {
    const autoStore = currentWarehouse?.autostore;

    if (autoStore?.status === 'UNREACHABLE') {
      return (
        <AutoStoreStoppedWorking
          genericReason="Unreachable"
          errorText="Picking will resume once the AutoStore is active again."
        />
      );
    }

    return (
      <AutoStoreStoppedWorking
        errorText="Picking will resume once the AutoStore is active again."
        error={{
          stopCode: String(autoStore?.state?.stopCode || ''),
          mode: String(autoStore?.state?.mode || ''),
          chargePercent: String(autoStore?.state?.chargePercent ?? '0'),
          reason: String(autoStore?.state?.stopReason || ''),
          status: String(autoStore?.status || ''),
        }}
      />
    );
  }

  if (
    portState.status === 'OPEN' ||
    portState.status === 'PICK_COMPLETE' ||
    portState.status === 'CONFIRMING_MISSING_ITEM_DETECTION'
  ) {
    const barcode = portState.currentPick?.item.barcode
      ? portState.currentPick?.item.barcode
      : '';

    const determineModal = (status: string) => {
      if (
        status === 'PICK_COMPLETE' &&
        portState.shouldUseConveyorSystem === false
      ) {
        return (
          <NextItemModal
            goBack={undoPreviousPick}
            nextItem={throttledPushCompletedTotes}
          />
        );
      }
      if (status === 'CONFIRMING_MISSING_ITEM_DETECTION') {
        return (
          <MissingItemModal
            closeModal={() => {
              cancelMissingItemResolution();
            }}
            confirmMissingItem={confirmMissingItem}
            barcode={barcode}
          />
        );
      }
      if (isScanBarcodeInstructionVisible) {
        return (
          <ScanBarcodeInstructionModal
            closeModal={() => {
              hideScanBarcodeInstruction();
            }}
          />
        );
      }
      return modal;
    };

    const nonReadyTotes = portState.toteLocations.filter(
      (location) => !['READY', 'ASSIGNED'].includes(location.state)
    );

    if (nonReadyTotes.length > 0) {
      return (
        <PickStationView
          portState={portState}
          closePort={showCloseModal}
          rightPanel={
            <Loading
              message={`Waiting for ${pluralise(
                'tote',
                nonReadyTotes.length,
                false
              )} to arrive`}
            />
          }
        />
      );
    }

    const rightPanel = (
      <PickingInformation
        item={portState.currentPick?.item}
        completePick={completePick}
        triggerShortPick={showShortPickModal}
        troubleshoot={
          portState.currentPick?.isShortPick
            ? showFreeInputTroubleshootModal
            : undefined
        }
        isShortPick={portState.currentPick?.isShortPick ?? false}
        detectMissingItem={detectMissingItem}
        showScanBarcodeInstruction={showScanBarcodeInstruction}
        itemBarcodeValidation={portState.itemBarcodeValidation}
        validateBarcode={validateBarcode}
        isUsingConveyor={portState.shouldUseConveyorSystem}
      />
    );

    return (
      <PickStationView
        portState={portState}
        closePort={showCloseModal}
        rightPanel={rightPanel}
        modal={determineModal(portState.status)}
      />
    );
  }

  if (
    portState.status === 'PUSHING' ||
    portState.status === 'LOCATIONS_CLOSED'
  ) {
    const completedToteCount = portState.toteLocations.filter(
      (location) => location.state === 'COMPLETE'
    ).length;

    const totesBeingReplacedCount = portState.toteLocations.filter(
      (location) =>
        location.state === 'NO_TOTE' ||
        LOCATION_LINKING_STATES.includes(location.state)
    ).length;

    return (
      <PickStationView
        portState={portState}
        closePort={showCloseModal}
        rightPanel={
          <PushBackTotes
            completedToteCount={completedToteCount}
            totesBeingReplacedCount={totesBeingReplacedCount}
            goBack={undoPreviousPick}
            pushTotes={pushTotes}
            isUsingConveyor={portState.shouldUseConveyorSystem}
          />
        }
        modal={
          portState.status === 'LOCATIONS_CLOSED' ? (
            <NextItemModal
              goBack={undoPushTotes}
              nextItem={throttledPushCompletedTotes}
            />
          ) : (
            modal
          )
        }
      />
    );
  }

  if (portState.status === 'REPLACING_TOTES') {
    const missingToteCount = portState.toteLocations.filter(
      (location) => location.state === 'NO_TOTE'
    ).length;

    return (
      <PickStationView
        portState={portState}
        closePort={showCloseModal}
        rightPanel={
          <ReplaceTotes
            missingToteCount={missingToteCount}
            replaceTotes={replaceTotes}
            goBack={undoPushTotes}
          />
        }
        modal={modal}
      />
    );
  }

  if (portState.status === 'PLACING_MISSING_ITEM_PAPER') {
    const totesRequireNoItemPaper = portState.toteLocations.filter(
      (location) => location.state === 'REQUIRES_MISSING_ITEM_PAPER'
    );
    return (
      <PickStationView
        portState={portState}
        closePort={showCloseModal}
        rightPanel={
          <PlacePaper
            variant="red"
            paperPlaced={throttledPushCompletedTotes}
            toteCount={totesRequireNoItemPaper.length}
            goBack={portState.previousPick ? undoPreviousPick : undefined}
          />
        }
        modal={modal}
      />
    );
  }

  if (portState.status === 'PLACING_RED_PAPER') {
    return (
      <PickStationView
        portState={portState}
        closePort={showCloseModal}
        rightPanel={
          <PlacePaper
            variant="red"
            paperPlaced={placePaper}
            toteCount={
              portState.toteLocations.filter(
                (location) => location.state === 'REQUIRES_RED_PAPER'
              ).length
            }
            troubleshoot={showPaperProblemModal}
            goBack={portState.previousPick ? undoPreviousPick : undefined}
          />
        }
        modal={modal}
      />
    );
  }

  if (portState.status === 'PLACING_GREEN_PAPER') {
    return (
      <PickStationView
        portState={portState}
        closePort={showCloseModal}
        rightPanel={
          <PlacePaper
            variant="green"
            paperPlaced={placePaper}
            toteCount={
              portState.toteLocations.filter(
                (location) => location.state === 'REQUIRES_GREEN_PAPER'
              ).length
            }
            troubleshoot={showPaperProblemModal}
            goBack={portState.previousPick ? undoPreviousPick : undefined}
          />
        }
        modal={modal}
      />
    );
  }

  return (
    <PickStationView
      portState={portState}
      closePort={showCloseModal}
      rightPanel={
        <RightPanel
          portState={portState}
          onCheckBinHasArrived={onCheckBinHasArrived}
          currentBinPortLocation={currentBinPortLocation}
        />
      }
      modal={modal}
    />
  );
};

const RightPanel = ({
  portState,
  onCheckBinHasArrived,
  currentBinPortLocation,
}: {
  portState: PickPortWithHydratedEntities;
  onCheckBinHasArrived: () => void;
  currentBinPortLocation?: number;
}) => {
  const { dispatchCommand } = usePickPortSocket();
  const { appState } = useAppState();

  if (portState.status === 'LINKING') {
    let currentLocation;
    const nextLocationIndex = portState.toteLocations.findIndex(
      (location) =>
        location.state === 'UNLINKED' || location.state === 'SCANNED'
    );

    if (nextLocationIndex === -1) {
      currentLocation = -1;
    } else {
      currentLocation = nextLocationIndex + 1;
    }

    const isLastToteLinked = portState.toteLocations.every(
      (location) =>
        LOCATION_LINKED_STATES.includes(location.state) ||
        (location.state === 'SCANNED' && location.toteId)
    );

    const locationsToLinkCount = portState.toteLocations.filter((location) =>
      LOCATION_LINKING_STATES.includes(location.state)
    ).length;

    const isRelinking =
      !!portState.currentBin ||
      locationsToLinkCount !== portState.toteLocations.length;

    return (
      <ToteLinkingForm
        currentLocation={currentLocation}
        isLastToteLinked={isLastToteLinked}
        locationToLinkCount={locationsToLinkCount}
        isRelinking={isRelinking}
      />
    );
  }

  if (portState.status === 'NO_TASK_GROUP') {
    return (
      <PickPortError
        alertType={AlertMessageTypes.Warning}
        message={NO_TASK_GROUPS_MESSAGE}
        showRetryButton
        retryCommand={async () => {
          await dispatchCommand({
            type: 'RETRY_START_PICKING',
          });
        }}
      />
    );
  }

  if (portState.status === 'UNEXPECTED_BIN') {
    return (
      <PickPortError
        alertType={AlertMessageTypes.Error}
        message={UNEXPECTED_BIN_MESSAGE}
        showRetryButton={false}
      />
    );
  }

  if (
    portState.status === 'AWAITING_BIN' ||
    portState.status === 'AWAITING_TASK_GROUP_ASSIGNMENT'
  ) {
    const isFeatureEnabled = appState.featureFlags?.confirmBinHasArrived;

    return (
      <>
        <Loading
          message="Getting bin"
          onCheckBinHasArrived={onCheckBinHasArrived}
          alertMessage={
            currentBinPortLocation && (
              <>
                Bin <b>#{appState.portState?.currentAutostoreBinId}</b> is
                currently in use at Port {currentBinPortLocation}
              </>
            )
          }
          alertMessageType={AlertMessageTypes.Warning}
          isManualBinArrivedEnabled={isFeatureEnabled}
        />
      </>
    );
  }

  if (portState.status === 'PLACING_TOTES') {
    return <PlaceTotes />;
  }

  if (portState.status === 'ERROR_CLOSING_BIN') {
    return <RetryCloseBin />;
  }

  if (portState.status === 'READY_TO_RETRY_PICKING') {
    return (
      <PickPortError
        alertType={AlertMessageTypes.Warning}
        message={RETRY_START_PICKING_MESSAGE}
        showRetryButton
        retryCommand={async () => {
          await dispatchCommand({
            type: 'RETRY_START_PICKING',
          });
        }}
      />
    );
  }

  return <Loading message="Waiting" />;
};
