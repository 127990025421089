import InputBase from '@material-ui/core/InputBase';
import { alpha } from '@material-ui/core/styles';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background-color: ${({ theme }) => alpha(theme.palette.grey[400], 0.2)};
  display: flex;
  width: fit-content;
  height: 40px;
  padding: 2px 0;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => alpha(theme.palette.grey[400], 0.3)};
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  padding: ${({ theme }) => theme.spacing(0, 2)};
  pointer-events: none;
  color: ${({ theme }) => theme.palette.grey[600]};
`;

export const Input = styled(InputBase)`
  .MuiInputBase-root {
    color: inherit;
  }

  .MuiInputBase-input {
    padding: ${({ theme }) => theme.spacing(1, 1, 1, 0)};
    padding-left: ${({ theme }) => `calc(1em + ${theme.spacing(4)}px)`};
    transition: ${({ theme }) => theme.transitions.create('width')};
    width: 20ch;

    &:focus {
      width: 26ch;
    }
  }
`;
