import { ReactElement } from 'react';

import * as Elements from './Toggle.elements';

export interface ToggleProps {
  name: string;
  value: string;
  onChange: () => void;
  checked?: boolean;
}

export const Toggle = ({
  name,
  value,
  onChange,
  checked = false,
}: ToggleProps): ReactElement => {
  return (
    <Elements.OuterContainer role="switch" aria-checked={checked}>
      <Elements.HiddenInput {...{ name, value, checked, onChange }} />
      <Elements.DisplayContainer></Elements.DisplayContainer>
    </Elements.OuterContainer>
  );
};
