"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateTransferSuccess = exports.TransferAlreadyExists = exports.SaveLabelFailed = exports.LabelGenerationFailed = exports.NoWarehouseFound = exports.ItemsHaveInvalidWeight = exports.TransferTotalWeightMustBePositive = exports.NoTransferNumberRefFoundForTransfer = exports.NoItemsFoundForTransfer = void 0;
const z = __importStar(require("zod"));
const model_1 = require("../../model");
exports.NoItemsFoundForTransfer = z.object({
    outcome: z.literal('NO_ITEMS_FOUND_FOR_TRANSFER'),
});
exports.NoTransferNumberRefFoundForTransfer = z.object({
    outcome: z.literal('NO_TRANSFER_NUMBER_REF_FOUND_FOR_TRANSFER'),
});
exports.TransferTotalWeightMustBePositive = z.object({
    outcome: z.literal('TRANSFER_TOTAL_WEIGHT_MUST_BE_POSITIVE'),
});
exports.ItemsHaveInvalidWeight = z.object({
    outcome: z.literal('ITEMS_HAVE_INVALID_WEIGHT'),
    items: z.array(z.object({
        barcode: z.string(),
        weightGrams: z.number(),
    })),
});
exports.NoWarehouseFound = z.object({
    outcome: z.literal('NO_WAREHOUSE_FOUND'),
    warehouseId: z.string(),
});
exports.LabelGenerationFailed = z.object({
    outcome: z.literal('LABEL_GENERATION_FAILED'),
    error: z.string().optional(),
});
exports.SaveLabelFailed = z.object({
    outcome: z.literal('SAVE_LABEL_FAILED'),
    error: z.string().optional(),
});
exports.TransferAlreadyExists = z.object({
    outcome: z.literal('TRANSFER_ALREADY_EXISTS_FOR_EXTERNAL_ID'),
    transfer: model_1.transferSchema,
});
exports.CreateTransferSuccess = z.object({
    outcome: z.literal('SUCCESS'),
    transfer: model_1.transferSchema,
});
