import { ReactElement, ReactNode } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';

const useStyles = makeStyles((theme) => ({
  variantError: {
    background: `${theme.palette.error.main} !important`,
  },
  variantSuccess: {
    background: `${theme.palette.success.dark} !important`,
  },
  variantInfo: {
    background: `${theme.palette.info.main} !important`,
  },
  variantWarning: {
    background: `${theme.palette.warning.dark} !important`,
  },
}));

export function Notifications({
  children,
}: {
  children: ReactNode | ReactNode[];
}): ReactElement {
  const classes = useStyles();
  return (
    <SnackbarProvider
      classes={classes}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      {children}
    </SnackbarProvider>
  );
}
