import { Box } from '@material-ui/core';
import { SvgIconComponent } from '@material-ui/icons';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Wrapper } from './Button.elements';

export type ButtonVariant = 'primary' | 'secondary' | 'text' | 'heavy';

export type ButtonProps = {
  children: React.ReactNode;
  disabled?: boolean;
  variant?: ButtonVariant;
  href?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  external?: boolean;
  type?: 'button' | 'submit' | 'reset';
  icon?: SvgIconComponent;
  fullWidth?: boolean;
  alignIcon?: AlignIcon;
};

type MuiVariant = 'text' | 'outlined' | 'contained';
type AlignIcon = 'left' | 'center' | 'right';

const MUI_VARIANT_MAP: Record<ButtonVariant, MuiVariant> = {
  primary: 'contained',
  secondary: 'outlined',
  heavy: 'contained',
  text: 'text',
};

export function Button({
  children,
  variant = 'primary',
  disabled,
  href,
  onClick,
  external,
  type,
  icon: Icon,
  fullWidth,
  alignIcon = 'center',
  ...props
}: ButtonProps): ReactElement {
  const history = useHistory();
  const handleOnClick = !external && href ? () => history.push(href) : onClick;
  const childMargin = alignIcon === 'center' ? '' : '0 auto';
  const childOrder = alignIcon === 'right' ? '-1' : ''; // change order so icon is on right hand side

  return (
    <Wrapper
      variant={MUI_VARIANT_MAP[variant]}
      $buttonType={variant}
      disabled={disabled}
      href={external ? href : undefined}
      onClick={handleOnClick}
      type={type}
      fullWidth={fullWidth}
      {...props}
    >
      {Icon && (
        <Icon>
          {' '}
          <Icon fontSize="small" />
        </Icon>
      )}
      <Box margin={childMargin} order={childOrder}>
        {children}
      </Box>
    </Wrapper>
  );
}
