import { StorageType } from 'api-schema';
import bwipjs from 'bwip-js';
import { useEffect } from 'react';
import {
  BarcodeCanvas,
  DetailRow,
  DetailRowData,
  Wrapper,
} from './BarcodeLabel.elements';

type Props = {
  id: string;
  storageType: StorageType;
};

export const BarcodeLabel = ({ id, storageType }: Props) => {
  useEffect(() => {
    bwipjs.toCanvas(`canvas-${id}`, {
      bcid: 'datamatrix',
      text: id,
      scale: 3,
      height: 15,
      textxalign: 'center',
    });
    // Decided not to wrap this in try/catch so that if one barcode in a print
    // job errors, the error should propagate up and cause the whole page to fail.
  });

  return (
    <Wrapper>
      <DetailRow>
        Type:
        <DetailRowData>{storageType}</DetailRowData>
      </DetailRow>
      <DetailRow>
        ID:
        <DetailRowData>{id}</DetailRowData>
      </DetailRow>
      <BarcodeCanvas id={`canvas-${id}`} />
    </Wrapper>
  );
};
