"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WAREHOUSE_STATE_CHANGED_SOCKET_EVENT_NAMES = exports.WarehouseStateChangedEvents = exports.WarehouseEvent = exports.cmcGenesysEligibleServiceCodeRemovedSchema = exports.cmcGenesysEligibleServiceCodeAddedSchema = exports.cmcGenesysPackagingMachineInstalledSchema = exports.cmcGenesysRoutingPercentageConfiguredSchema = exports.releaseChannelAssignedToWarehouseSchema = exports.AutostoreUnreachable = exports.AutostoreStopped = exports.AutostoreStarted = exports.AutostoreIntegrated = exports.WarehouseEdited = exports.warehouseAddedSchema = void 0;
const zod_1 = require("zod");
const model_1 = require("../model");
const featureFlags_1 = require("../model/featureFlags");
const base_1 = require("./base");
exports.warehouseAddedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('WAREHOUSE_ADDED'),
    payload: zod_1.z.object({
        name: zod_1.z.string().min(1),
        address: zod_1.z.string().min(1),
        isTest: zod_1.z.boolean(),
    }),
});
exports.WarehouseEdited = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('WAREHOUSE_EDITED'),
    payload: zod_1.z.object({
        name: zod_1.z.string(),
        address: zod_1.z.string(),
        isTest: zod_1.z.boolean(),
        autostore: zod_1.z.object({
            networkAddress: zod_1.z.string(),
        }),
    }),
});
exports.AutostoreIntegrated = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('AUTOSTORE_INTEGRATED'),
    payload: zod_1.z.object({
        warehouseId: zod_1.z.string().min(1),
        autostore: zod_1.z.object({
            networkAddress: zod_1.z.string().url(),
            id: zod_1.z.string().min(1),
        }),
    }),
});
exports.AutostoreStarted = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('AUTOSTORE_STARTED'),
    payload: zod_1.z.object({
        warehouseId: zod_1.z.string().min(1),
        autostore: zod_1.z.object({
            state: model_1.autostoreState,
        }),
    }),
});
exports.AutostoreStopped = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('AUTOSTORE_STOPPED'),
    payload: zod_1.z.object({
        warehouseId: zod_1.z.string().min(1),
        autostore: zod_1.z.object({
            state: model_1.autostoreState,
        }),
    }),
});
exports.AutostoreUnreachable = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('AUTOSTORE_UNREACHABLE'),
    payload: zod_1.z.object({
        warehouseId: zod_1.z.string().min(1),
    }),
});
exports.releaseChannelAssignedToWarehouseSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('RELEASE_CHANNEL_ASSIGNED_TO_WAREHOUSE'),
    payload: zod_1.z.object({
        warehouseId: zod_1.z.string().min(1),
        releaseChannel: featureFlags_1.releaseChannel,
    }),
});
exports.cmcGenesysRoutingPercentageConfiguredSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CMC_GENESYS_ROUTING_PERCENTAGE_CONFIGURED'),
    payload: zod_1.z.object({
        percentage: zod_1.z.number().gte(0).lte(100),
    }),
});
exports.cmcGenesysPackagingMachineInstalledSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CMC_GENESYS_PACKAGING_MACHINE_INSTALLED'),
    payload: zod_1.z.object({
        machineId: zod_1.z.string(),
    }),
});
exports.cmcGenesysEligibleServiceCodeAddedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CMC_GENESYS_ELIGIBLE_SERVICE_CODE_ADDED'),
    payload: zod_1.z.object({
        machineId: zod_1.z.string(),
        serviceCode: zod_1.z.string(),
    }),
});
exports.cmcGenesysEligibleServiceCodeRemovedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CMC_GENESYS_ELIGIBLE_SERVICE_CODE_REMOVED'),
    payload: zod_1.z.object({
        machineId: zod_1.z.string(),
        serviceCode: zod_1.z.string(),
    }),
});
exports.WarehouseEvent = zod_1.z.union([
    exports.warehouseAddedSchema,
    exports.WarehouseEdited,
    exports.AutostoreIntegrated,
    exports.AutostoreStarted,
    exports.AutostoreStopped,
    exports.AutostoreUnreachable,
    exports.releaseChannelAssignedToWarehouseSchema,
    exports.cmcGenesysRoutingPercentageConfiguredSchema,
    exports.cmcGenesysPackagingMachineInstalledSchema,
    exports.cmcGenesysEligibleServiceCodeAddedSchema,
    exports.cmcGenesysEligibleServiceCodeRemovedSchema,
]);
exports.WarehouseStateChangedEvents = zod_1.z.union([
    exports.AutostoreStarted,
    exports.AutostoreStopped,
    exports.AutostoreUnreachable,
]);
exports.WAREHOUSE_STATE_CHANGED_SOCKET_EVENT_NAMES = exports.WarehouseStateChangedEvents.options.map((opt) => opt.shape.type.value);
