import { Box } from '../../../../components/warehouse/Box';
import { Button } from '../../../../components/warehouse/Button';
import { Modal } from '../../../../components/warehouse/Modal';
import { Text } from '../../../../components/warehouse/Text';
import { ButtonWrapper } from './ULDModal.elements';

export type ULDModalProps = {
  isVisible: boolean;
  onCancel: () => void;
  onSorted: () => void;
};

export const ULDModal = ({
  isVisible,
  onCancel,
  onSorted,
}: ULDModalProps): JSX.Element => {
  return (
    <Modal
      isVisible={isVisible}
      actions={[
        <ButtonWrapper key="cancel-btn-wrapper">
          <Button
            variant="secondary"
            fullWidth
            onClick={onCancel}
            testId="cancel-parcel-sorted-button"
          >
            Cancel
          </Button>
        </ButtonWrapper>,
        <ButtonWrapper key="sort-btn-wrapper">
          <Button fullWidth onClick={onSorted} testId="parcel-sorted-button">
            Parcel sorted
          </Button>
        </ButtonWrapper>,
      ]}
    >
      <Box padding="24px">
        <Text variant="h1" weight="medium">
          Please sort the parcel into the correct ULD
        </Text>
      </Box>
    </Modal>
  );
};
