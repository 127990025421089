import { ChangeEvent, ReactElement } from 'react';
import { Field } from './TextArea.elements';

export type TextAreaProps = {
  value?: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  rows?: number;
  placeholder?: string;
  hasError?: boolean;
};

const DEFAULT_ROWS = 4;

export const TextArea = ({
  value,
  onChange,
  rows = DEFAULT_ROWS,
  placeholder,
  hasError,
}: TextAreaProps): ReactElement => (
  <Field
    value={value}
    onChange={onChange}
    rows={rows}
    placeholder={placeholder}
    hasError={hasError}
  />
);
