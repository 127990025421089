import { BarcodeLabel } from '../../../components/admin/BarcodeLabel';
import { PrintBarcodesLocationState } from './PrintBarcodes';

export const PrintBarcodesView = ({
  ids,
  storageType,
}: PrintBarcodesLocationState) => (
  <div>
    {ids.map((id) => (
      <BarcodeLabel key={id} id={id} storageType={storageType} />
    ))}
  </div>
);
