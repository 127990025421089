import { useContext } from 'react';
import {
  IntermediaryStationSocketContext,
  LabelTroubleshootSocketContext,
  PackAndDispatchSocketContext,
  PickSocketContext,
  PutawaySocketContext,
  ShortPickTroubleshootSocketContext,
} from './context';

export const usePutawayPortSocket = () => useContext(PutawaySocketContext);

export const usePickPortSocket = () => useContext(PickSocketContext);

export const useIntermediaryStationSocket = () =>
  useContext(IntermediaryStationSocketContext);

export const usePackAndDispatchSocket = () =>
  useContext(PackAndDispatchSocketContext);

export const useShortPickTroubleshootSocket = () =>
  useContext(ShortPickTroubleshootSocketContext);

export const useLabelTroubleshootSocket = () =>
  useContext(LabelTroubleshootSocketContext);
