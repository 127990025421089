"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.messageQueueApiSchema = exports.messageQueueNamesSchema = exports.messageQueueNames = exports.getMessagesQueryParamsSchema = void 0;
const zod_http_schemas_1 = require("zod-http-schemas");
const datelike = zod_http_schemas_1.z.union([zod_http_schemas_1.z.number(), zod_http_schemas_1.z.string(), zod_http_schemas_1.z.date()]);
const dateSchema = datelike.pipe(zod_http_schemas_1.z.coerce.date());
const inboxMessage = zod_http_schemas_1.z.object({
    id: zod_http_schemas_1.z.number(),
    messageId: zod_http_schemas_1.z.string(),
    stream: zod_http_schemas_1.z.string(),
    data: zod_http_schemas_1.z.any(),
    createdAt: dateSchema,
});
const jobRunnerMessage = zod_http_schemas_1.z.object({
    id: zod_http_schemas_1.z.string(),
    name: zod_http_schemas_1.z.string(),
    data: zod_http_schemas_1.z.any(),
    status: zod_http_schemas_1.z.string(),
    retryLimit: zod_http_schemas_1.z.number(),
    retryCount: zod_http_schemas_1.z.number(),
    createdAt: dateSchema,
    completedAt: dateSchema.nullish(),
    output: zod_http_schemas_1.z.any().nullish(),
});
const messageQueueMessage = zod_http_schemas_1.z.union([inboxMessage, jobRunnerMessage]);
/** Query params when fetching messages  */
exports.getMessagesQueryParamsSchema = zod_http_schemas_1.z.object({
    limit: zod_http_schemas_1.z.coerce.number().optional(),
    orderBy: zod_http_schemas_1.z.union([zod_http_schemas_1.z.literal('asc'), zod_http_schemas_1.z.literal('desc')]).optional(),
});
/** The different queues that can be queried */
exports.messageQueueNames = [
    'wcs-message-inbox',
    'conveyor-message-inbox',
    'shipping-platform-inbox',
    'shipping-platform-label-inbox',
    'job-runner',
];
exports.messageQueueNamesSchema = zod_http_schemas_1.z.enum(exports.messageQueueNames);
exports.messageQueueApiSchema = (0, zod_http_schemas_1.createHttpSchema)({
    'GET /backoffice/message-queue/:queue/messages?limit=:limit&orderBy=:orderBy': {
        responseBody: zod_http_schemas_1.z.union([
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('SUCCESS'),
                messages: messageQueueMessage.array(),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.union([
                    zod_http_schemas_1.z.literal('QUEUE_NAME_NOT_SUPPORTED'),
                    zod_http_schemas_1.z.literal('INVALID_QUEUE_NAME'),
                    zod_http_schemas_1.z.literal('INVALID_SOURCE'),
                    zod_http_schemas_1.z.literal('INVALID_STATUS'),
                    zod_http_schemas_1.z.literal('FAILED_PARSING_INPUT'),
                ]),
                reason: zod_http_schemas_1.z.string().optional(),
            }),
        ]),
    },
});
