import { Box, Container, Divider, Grid, Typography } from '@material-ui/core';
import { BIN_TYPE_HEIGHT_MAP, StorageUnitCreationResult } from 'api-schema';
import { Button } from '../../../components/admin/Button';
import { SectionHeader } from '../../../components/admin/SectionHeader';

type Props = StorageUnitCreationResult & { onPrintClick: () => void };

export const StorageUnitCreatedView = ({
  storageType,
  binType,
  partitionsPerBin,
  onPrintClick,
  ids,
}: Props) => (
  <Container>
    <Box mx={-3}>
      <SectionHeader>Storage Units Created</SectionHeader>
      <Box my={2}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography>
              <strong>Storage Unit Type</strong>
            </Typography>
          </Grid>
          <Grid item>
            <Typography>{storageType}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box my={2}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography>
              <strong>Number of units</strong>
            </Typography>
          </Grid>
          <Grid item>
            <Typography>{ids.length}</Typography>
          </Grid>
        </Grid>
      </Box>
      {partitionsPerBin && (
        <Box my={2}>
          <Grid container justify="space-between">
            <Grid item>
              <Typography>
                <strong>Partitions per bin</strong>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>{partitionsPerBin}</Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      {binType && (
        <Box my={2}>
          <Grid container justify="space-between">
            <Grid item>
              <Typography>
                <strong>Bin size</strong>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>{BIN_TYPE_HEIGHT_MAP[binType]}</Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
    <Box mx={-6} mt={4}>
      <Divider />
    </Box>
    <Box my={2} mb={0} mx={-3}>
      <Button onClick={onPrintClick} variant="secondary">
        Print Barcodes
      </Button>
    </Box>
  </Container>
);
