import { FulfilmentOrder, Picklist } from 'api-schema/lib/model';
import { useEffect, useState } from 'react';
import { Button } from '../../../../components/warehouse/Button';
import { Modal } from '../../../../components/warehouse/Modal';
import { Spinner } from '../../../../components/warehouse/Spinner';
import { Text } from '../../../../components/warehouse/Text';

export type ForceFinishPackingTroubleshootModalProps = {
  isOpen: boolean;
  fulfilmentOrderId: FulfilmentOrder['id'];
  picklistId: Picklist['id'];
  onClose: () => void;
};

export const ForceFinishPackingTroubleshootModal = ({
  isOpen,
  onClose,
  fulfilmentOrderId,
  picklistId,
}: ForceFinishPackingTroubleshootModalProps) => {
  const [
    isForceFinishingPackingTroubleshoot,
    setIsForceFinishingPackingTroubleShoot,
  ] = useState(false);

  useEffect(() => {
    setIsForceFinishingPackingTroubleShoot(false);
  }, [isOpen]);

  const handleOnClose = () => {
    onClose();
    setIsForceFinishingPackingTroubleShoot(true);
  };

  return (
    <Modal
      key="troubleshoot-warning-modal"
      isVisible={isOpen}
      variant="warning"
      actions={[
        <Button
          testId="close-blocker-modal-btn"
          variant="primary"
          fullWidth
          onClick={handleOnClose}
        >
          {isForceFinishingPackingTroubleshoot ? (
            <Spinner />
          ) : (
            'Continue without label'
          )}
        </Button>,
      ]}
    >
      <div style={{ width: '100%' }}>
        <Text variant="h2">
          We encountered an issue while trying to print the troubleshoot label.
          Please fill in the Order ID and Picklist ID for troubleshooting later.
        </Text>
        <Text weight="bold">
          Order ID: {fulfilmentOrderId}
          <br />
          Picklist ID: {picklistId}
        </Text>
      </div>
    </Modal>
  );
};
