import { StorageType } from 'api-schema';
import { useLocation } from 'react-router-dom';
import { PrintBarcodesView } from './PrintBarcodes.view';

export type PrintBarcodesLocationState = {
  ids: string[];
  storageType: StorageType;
};

export const PrintBarcodes = () => {
  const {
    state: { ids, storageType },
  } = useLocation<PrintBarcodesLocationState>();

  return <PrintBarcodesView ids={ids} storageType={storageType} />;
};
