import { ReactElement } from 'react';
import { Button } from '../Button';
import { Container } from '../Container';
import { StartStationLabel, Wrapper } from './StartStation.elements';

export type StartStationProps = {
  stationHeaderText: string;
  startStationButtonText: string;
  handleOpenStationClick: () => Promise<void>;
};

export function StartStation({
  stationHeaderText,
  startStationButtonText,
  handleOpenStationClick,
}: StartStationProps): ReactElement {
  return (
    <Wrapper>
      <Container alignCenter>
        <StartStationLabel testId="station-label" variant="h1" weight="medium">
          {stationHeaderText}
        </StartStationLabel>
        <Button
          variant="primary"
          size="large"
          onClick={handleOpenStationClick}
          testId="start-station-button"
        >
          {startStationButtonText}
        </Button>
      </Container>
    </Wrapper>
  );
}
