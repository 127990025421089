import styled from 'styled-components';
import { Text } from '../../../../components/warehouse/Text';

export const TitleInnerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const RecipientText = styled(Text)`
  padding-right: 5px;
`;

export const StickyFooterInnerWrapper = styled.span`
  display: inline-flex;
  gap: ${({ theme }) => theme.spacing(1.6)};
`;
