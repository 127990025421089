import Grid from '@material-ui/core/Grid';
import { ReactElement } from 'react';
import autostoreLogo from '../../../assets/img/autostore-logo-bkng.png';
import cmcToteLogo from '../../../assets/img/cmc-tote-logo-bkng.png';
import orderToteLogo from '../../../assets/img/order-tote-logo-bkng.png';
import { Container } from '../../../components/admin/Container';
import { SectionHeader } from '../../../components/admin/SectionHeader';
import { Tile } from '../../../components/admin/Tile';
import { useWarehouseParam } from '../../../hooks/useWarehouseParam';
import { useAppState } from '../../../store';
import { generatePathWithWarehouseId } from '../../../utils/generatePathWithWarehouseId';

export interface StorageUnitSetupProps {
  label?: string;
}

export const StorageUnitSetup = ({
  label = 'Select storage system',
}: StorageUnitSetupProps): ReactElement => {
  useWarehouseParam();
  const {
    appState: { currentWarehouse: warehouse },
  } = useAppState();
  if (!warehouse) {
    return <h3>Warehouse not found</h3>;
  }
  return (
    <Container>
      <SectionHeader>{label}</SectionHeader>
      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item xs={12} md={2}>
          <Tile
            label="AutoStore"
            linkTo={generatePathWithWarehouseId(
              `/admin/warehouse/:warehouseId?/storage-units/setup/autostore`,
              { warehouseId: warehouse.id }
            )}
            backgroundImgSrc={autostoreLogo}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Tile
            label="Order Tote"
            linkTo={generatePathWithWarehouseId(
              '/admin/warehouse/:warehouseId?/storage-units/setup/order-tote',
              { warehouseId: warehouse.id }
            )}
            backgroundImgSrc={orderToteLogo}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Tile
            label="CMC Tote"
            linkTo={generatePathWithWarehouseId(
              '/admin/warehouse/:warehouseId?/storage-units/setup/cmc-tote',
              { warehouseId: warehouse.id }
            )}
            backgroundImgSrc={cmcToteLogo}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
