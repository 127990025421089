import styled, { css } from 'styled-components';
import { TextVariant, TextWeight } from './Text';

export interface TextProps {
  $weight: TextWeight;
  $variant: TextVariant;
  $margin?: 0 | 10 | 20;
  $align?: 'center' | 'left' | 'right';
}

export const BaseTextComponent = styled.p<TextProps>`
  ${({ $variant, theme }) => css`
    font-family: ${theme.typography[$variant].fontFamily};
    font-size: ${theme.typography[$variant].fontSize};
    line-height: ${theme.typography[$variant].lineHeight};
  `};

  ${({ $weight, theme }) => css`
    font-weight: ${theme.weight[$weight]};
  `};

  ${({ $margin }) => {
    if (typeof $margin !== 'undefined') {
      return css`
        margin: ${$margin}px 0px;
      `;
    }
  }};

  ${({ $align }) => css`
    text-align: ${$align};
  `};
`;
