import * as z from 'zod';

export const PartitionsPerBinEnum = z.enum(['1', '2', '4']);
export const BinHeightsEnum = z.enum(['220', '330', '425']);

/* Please refer to https://skutopia.atlassian.net/wiki/spaces/SKUT/pages/41025554/AutoStore
 * section 3.1.1 bin_type as binHeight is mapped to bin_type
 */
export const binHeightPicklist = [
  { key: '220 mm', value: 2 },
  { key: '330 mm', value: 1 },
  { key: '425 mm', value: 5 },
];

export const SetupAutoStoreSchema = z.object({
  numBins: z
    .number()
    .int()
    .positive({ message: 'Number of bins is a required field' }),
  partsPerBin: PartitionsPerBinEnum,
  binHeight: z.number(),
});

export type SetupAutoStoreFormValues = z.infer<typeof SetupAutoStoreSchema>;
