import { useSnackbar } from 'notistack';
import { useAppState } from '../../../store';
import { AwaitingToteView } from './AwaitingTote.view';

export function AwaitingTote() {
  const {
    appState: { intermediaryStationState },
  } = useAppState();
  const { enqueueSnackbar } = useSnackbar();
  if (!intermediaryStationState) {
    enqueueSnackbar('Intermediary station state is not available', {
      variant: 'error',
    });
    return <div>Intermediary station state is not available</div>;
  }
  return <AwaitingToteView />;
}
