import { useTransfersApi } from '../../../hooks/useTransfersApi';
import { useWarehouseParam } from '../../../hooks/useWarehouseParam';
import { useAppState } from '../../../store';
import { TransferOverviewView } from './TransferOverview.view';

export const TransferOverview = () => {
  useWarehouseParam();
  const {
    appState: { currentWarehouse: warehouse },
  } = useAppState();
  const [transfersData] = useTransfersApi(warehouse?.id);

  if (!warehouse) {
    return <h3>Warehouse not found</h3>;
  }
  return <TransferOverviewView transfers={transfersData} />;
};
