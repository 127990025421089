import { useRouteMatch } from 'react-router-dom';
import { SocketProvider } from '../components/common/SocketProvider';
import { baseUrl } from '../constants/config';
import { useWarehouse } from '../hooks/useWarehouse';
import { ShortPickTroubleshootStation } from '../screens/shortPickTroubleshoot';
import { WarehouseThemeProvider } from '../themes/WarehouseTheme';

type RouteParams = {
  warehouseId: string;
  stationId: string;
};

export const ShortPickTroubleshootStationApp = () => {
  const {
    params: { warehouseId, stationId },
  } = useRouteMatch<RouteParams>();

  useWarehouse(warehouseId);

  return (
    <WarehouseThemeProvider useLargeFontSet={false}>
      <SocketProvider
        baseUrl={baseUrl}
        warehouseId={warehouseId}
        stationId={stationId}
        providerType="shortPickTroubleshoot"
      >
        <main>
          <ShortPickTroubleshootStation />
        </main>
      </SocketProvider>
    </WarehouseThemeProvider>
  );
};
