"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fulfilmentOrderApiSchema = void 0;
const zod_http_schemas_1 = require("zod-http-schemas");
const fulfilmentOrder_1 = require("../commands/outcomes/fulfilmentOrder");
const printing_1 = require("../commands/outcomes/printing");
const base_1 = require("../events/base");
const http_1 = require("../http");
const job_1 = require("../http/job");
const model_1 = require("../model");
exports.fulfilmentOrderApiSchema = (0, zod_http_schemas_1.createHttpSchema)({
    'GET /fulfilment-order/:fulfilmentOrderId/events': {
        responseBody: zod_http_schemas_1.z.union([http_1.ErrorBody, base_1.eventBaseSchema.array()]),
    },
    'POST /fulfilment-order': {
        requestBody: http_1.createFulfilmentOrderRequestSchema,
        responseBody: zod_http_schemas_1.z.union([http_1.ErrorBody, http_1.CreateFulfilmentOrderResponse]),
    },
    'POST /fulfilmentOrder/:fulfilmentOrderId/label': {
        responseBody: http_1.RequestLabelResponse,
    },
    'POST /fulfilmentOrder/:fulfilmentOrderId/print': {
        responseBody: printing_1.PrintFulfilmentOrderOutcomes,
        requestBody: zod_http_schemas_1.z.object({ warehouseId: zod_http_schemas_1.z.string(), stationId: zod_http_schemas_1.z.number() }),
    },
    'POST /fulfilmentOrder/:fulfilmentOrderId/cancel': {
        responseBody: job_1.jobOutcomesSchema,
        requestBody: zod_http_schemas_1.z.object({ warehouseId: zod_http_schemas_1.z.string() }),
    },
    'POST /fulfilmentOrder/:fulfilmentOrderId/dispatch': {
        responseBody: fulfilmentOrder_1.DispatchFulfilmentOrderResponse,
    },
    'POST /fulfilmentOrder/bulk-cancel': {
        requestBody: zod_http_schemas_1.z.object({
            fulfilmentOrderIds: zod_http_schemas_1.z.string().array(),
            warehouseId: zod_http_schemas_1.z.string(),
        }),
        responseBody: job_1.jobOutcomesSchema,
    },
    'POST /fulfilment-order/bulk-delete': {
        requestBody: zod_http_schemas_1.z.object({
            fulfilmentOrderIds: zod_http_schemas_1.z.string().array(),
        }),
        responseBody: zod_http_schemas_1.z.union([
            http_1.ErrorBody,
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('SUCCESS'),
            }),
        ]),
    },
    'POST /:warehouseId/fulfilment-order/autostore-hand-picking/select-for-picking': {
        requestBody: zod_http_schemas_1.z.object({
            fulfilmentOrderIds: zod_http_schemas_1.z.string().array(),
        }),
        responseBody: zod_http_schemas_1.z.union([
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('SUCCESS'),
                fulfilmentOrderIds: zod_http_schemas_1.z.string().array(),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('PARTIAL_SUCCESS'),
                fulfilmentOrderIds: zod_http_schemas_1.z.string().array(),
                notFound: zod_http_schemas_1.z.string().array(),
                invalidStatus: zod_http_schemas_1.z.array(zod_http_schemas_1.z.object({
                    id: zod_http_schemas_1.z.string(),
                    currentOrderStatus: zod_http_schemas_1.z.string(),
                    currentPicklistStatus: zod_http_schemas_1.z.string().optional(),
                    isResolutionPicklist: zod_http_schemas_1.z.boolean().optional(),
                })),
            }),
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('NO_VALID_FULFILMENT_ORDERS') }),
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('WAREHOUSE_NOT_FOUND') }),
        ]),
    },
    'POST /:warehouseId/fulfilment-order/autostore-hand-picking/revert-to-auto-picking': {
        requestBody: zod_http_schemas_1.z.object({
            fulfilmentOrderIds: zod_http_schemas_1.z.string().array(),
        }),
        responseBody: zod_http_schemas_1.z.union([
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('SUCCESS'),
                fulfilmentOrderIds: zod_http_schemas_1.z.string().array(),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('PARTIAL_SUCCESS'),
                fulfilmentOrderIds: zod_http_schemas_1.z.string().array(),
                notFound: zod_http_schemas_1.z.string().array(),
                invalidStatus: zod_http_schemas_1.z.array(zod_http_schemas_1.z.object({
                    id: zod_http_schemas_1.z.string(),
                    currentStatus: zod_http_schemas_1.z.string(),
                })),
            }),
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('NO_VALID_FULFILMENT_ORDERS') }),
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('WAREHOUSE_NOT_FOUND') }),
        ]),
    },
    'POST /:warehouseId/fulfilment-order/autostore-hand-picking/start-picking': {
        requestBody: zod_http_schemas_1.z.object({
            fulfilmentOrderIds: zod_http_schemas_1.z.string().array(),
        }),
        responseBody: zod_http_schemas_1.z.union([
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('SUCCESS'),
                fulfilmentOrderIds: zod_http_schemas_1.z.string().array(),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('PARTIAL_SUCCESS'),
                fulfilmentOrderIds: zod_http_schemas_1.z.string().array(),
                notFound: zod_http_schemas_1.z.string().array(),
                invalidStatus: zod_http_schemas_1.z.array(zod_http_schemas_1.z.object({
                    id: zod_http_schemas_1.z.string(),
                    currentStatus: zod_http_schemas_1.z.string(),
                })),
            }),
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('NO_VALID_FULFILMENT_ORDERS') }),
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('WAREHOUSE_NOT_FOUND') }),
        ]),
    },
    'POST /warehouses/:warehouseId/fulfilment-orders/mark-order-as-packed': {
        requestBody: zod_http_schemas_1.z.object({
            fulfilmentOrderId: zod_http_schemas_1.z.string(),
            toteId: zod_http_schemas_1.z.string().optional(),
        }),
        responseBody: http_1.markOrderAsPackedResponseSchema,
    },
    'POST /warehouses/:warehouseId/totes/:toteId/force-send-to-cmc-genesys': {
        responseBody: zod_http_schemas_1.z.object({
            outcome: zod_http_schemas_1.z.union([
                zod_http_schemas_1.z.literal('SUCCESS'),
                zod_http_schemas_1.z.literal('WAREHOUSE_NOT_FOUND'),
                zod_http_schemas_1.z.literal('TOTE_NOT_FOUND'),
                zod_http_schemas_1.z.literal('TOTE_HAS_NO_ATTACHED_FULFILMENT_ORDER'),
                zod_http_schemas_1.z.literal('TOTE_HAS_NO_ATTACHED_PICKLIST'),
                zod_http_schemas_1.z.literal('INVALID_FULFILMENT_ORDER_STATUS'),
                zod_http_schemas_1.z.literal('FAILED_TO_SEND_TO_CMC_GENESYS'),
                zod_http_schemas_1.z.literal('INVALID_CONVEYOR_NETWORK_ADDRESS'),
                zod_http_schemas_1.z.literal('UNABLE_TO_FETCH_TOTE_STATE'),
                zod_http_schemas_1.z.literal('EXISTING_ZONE_REQUESTS_ON_TOTE'),
            ]),
        }),
    },
    'GET /warehouses/:warehouseId/fulfilment-orders': {
        responseBody: zod_http_schemas_1.z.object({
            fulfilmentOrders: zod_http_schemas_1.z.array(model_1.fulfilmentOrderSchema),
        }),
    },
});
