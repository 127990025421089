import { PickPortWithHydratedEntities } from 'api-schema/lib/model';
import { times } from 'ramda';
import { MutableRefObject, useEffect, useState } from 'react';
import { PickPortProps } from './PickPort';

const TOTES_PER_PORT = 6;

export function getWorkstationClasses(
  portState: PickPortWithHydratedEntities
): {
  binSelector: string;
  partitionSelector: string | null;
} {
  if (!portState.currentBin) {
    return {
      binSelector: '.port-hole',
      partitionSelector: null,
    };
  }

  const bin = portState.currentBin.partitions.length;
  const partition = portState.currentPick?.partitionNumber;

  const binSelector = `.bin-${bin}`;
  const partitionSelector = partition
    ? `${binSelector} .highlight-${partition}`
    : null;

  return {
    binSelector,
    partitionSelector,
  };
}

export const getToteClasses = (portState: PickPortProps['portState']) => {
  const classes = times((i) => `.tote-${i + 1} .default`, TOTES_PER_PORT);

  for (let i = 0; i < TOTES_PER_PORT; i += 1) {
    const toteLocation = portState.toteLocations[i];

    if (portState.status === 'LINKING') {
      if (toteLocation.state === 'READY' || toteLocation.state === 'ASSIGNED') {
        continue;
      }
      const locationLinked = toteLocation.state === 'LINKED';
      const locationScanned = toteLocation.state === 'SCANNED';
      const toteLinked = toteLocation.toteId !== null;

      if (locationLinked) {
        classes[i] = `.tote-${i + 1} .linked`;
      } else if (locationScanned && toteLinked) {
        classes[i] = `.tote-${i + 1} .linked`;
        if (!portState.shouldUseConveyorSystem) {
          return classes;
        }
      } else {
        classes[i] = `.tote-${i + 1} .unlinked`;
        if (!portState.shouldUseConveyorSystem) {
          return classes;
        }
      }
    }

    if (
      portState.status === 'OPEN' &&
      portState.currentPick &&
      toteLocation.pickCount
    ) {
      const currentLocationPick = portState.picksForCurrentPickCycle?.find(
        (pick) => pick.locationId === toteLocation.locationId
      );

      if (currentLocationPick?.state === 'PLANNED') {
        classes[i] = `.tote-${i + 1} .counter`;
      }

      if (currentLocationPick?.state === 'PICKED') {
        classes[i] = `.tote-${i + 1} .picked`;
      }
    }

    if (toteLocation.state === 'NO_TOTE') {
      classes[i] = `.tote-${i + 1} .replace`;
    }

    if (portState.status === 'PUSHING' && toteLocation.state === 'COMPLETE') {
      classes[i] = `.tote-${i + 1} .push`;
    }

    if (
      portState.status === 'PLACING_GREEN_PAPER' &&
      toteLocation.state === 'REQUIRES_GREEN_PAPER'
    ) {
      classes[i] = `.tote-${i + 1} .place-paper`;
    }

    if (
      portState.status === 'PLACING_RED_PAPER' &&
      toteLocation.state === 'REQUIRES_RED_PAPER'
    ) {
      classes[i] = `.tote-${i + 1} .place-paper`;
    }
    if (
      portState.status === 'PLACING_MISSING_ITEM_PAPER' &&
      toteLocation.state === 'REQUIRES_MISSING_ITEM_PAPER'
    ) {
      classes[i] = `.tote-${i + 1} .place-paper`;
    }
  }

  return classes;
};

export function handlePortState(
  portState: PickPortProps['portState'],
  workstationElement: HTMLDivElement | null,
  totesElement: HTMLDivElement | null
) {
  if (!workstationElement || !totesElement) {
    return new Error('No pick port element found');
  }

  const workstationClasses = getWorkstationClasses(portState);
  const toteSelectors = getToteClasses(portState);

  workstationElement
    .querySelectorAll('.active')
    .forEach((el) => el.classList.remove('active'));

  workstationElement
    .querySelectorAll(workstationClasses.binSelector)
    .forEach((el) => el.classList.add('active'));

  totesElement
    .querySelectorAll('.active')
    .forEach((el) => el.classList.remove('active'));

  toteSelectors?.forEach(
    (s) => totesElement.querySelector(s)?.classList.add('active')
  );

  if (workstationClasses.partitionSelector) {
    workstationElement
      .querySelector(workstationClasses.partitionSelector)
      ?.classList.add('active');
  }
}

export function usePickIndicatorState(
  portState: PickPortProps['portState'],
  pickIndicatorElement: MutableRefObject<HTMLSpanElement | null>,
  pickPortWrapperElement: MutableRefObject<HTMLDivElement | null>
) {
  const [indicatorState, setIndicatorState] = useState({
    x: 0,
    y: 0,
  });
  useEffect(() => {
    const handleResize = () => {
      const coords = getIndicatorPosition(
        portState,
        pickIndicatorElement.current,
        pickPortWrapperElement.current
      );
      if (coords) {
        setIndicatorState({
          ...coords,
        });
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
    // pickIndicatorElement and pickPortWrapperElement are not needed as dependencies as
    // changing them doesn't result in a re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portState, setIndicatorState]);
  return indicatorState;
}

type Coordinates = {
  x: number;
  y: number;
};

function getIndicatorPosition(
  portState: PickPortProps['portState'],
  pickIndicatorElement: HTMLSpanElement | null,
  pickPortWrapperElement: HTMLDivElement | null
): Coordinates | null {
  const partitionCenter = getPartitionCenter(portState, pickPortWrapperElement);

  if (!pickIndicatorElement || !partitionCenter) {
    return null;
  }
  const indicatorRect = pickIndicatorElement.getBoundingClientRect();
  const arrowHeight = parseInt(
    window.getComputedStyle(pickIndicatorElement, ':before').height,
    10
  );

  // Anchor indicator arrow to the center of the partition
  const x = partitionCenter.x - indicatorRect.width / 2;
  const y = partitionCenter.y - (indicatorRect.height + arrowHeight);
  return { x, y };
}

function getPartitionCenter(
  portState: PickPortProps['portState'],
  pickPortWrapperElement: HTMLDivElement | null
): Coordinates | null {
  const { partitionSelector } = getWorkstationClasses(portState);

  if (!partitionSelector) {
    return null;
  }

  const partition = document.querySelector(partitionSelector);

  if (!pickPortWrapperElement || !partition) {
    return null;
  }
  const wrapperCoords = pickPortWrapperElement.getBoundingClientRect();
  const partitionRect = partition.getBoundingClientRect();

  // Anchor to the center of the partition
  partitionRect.x = partitionRect.x + partitionRect.width / 2;
  partitionRect.y = partitionRect.y + partitionRect.height / 2;

  return {
    x: partitionRect.x - wrapperCoords.x,
    y: partitionRect.y - wrapperCoords.y,
  };
}
