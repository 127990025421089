import { ReactElement } from 'react';

import { Wrapper } from './Container.elements';

export interface ContainerProps {
  children: ReactElement | ReactElement[];
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export const Container = ({
  children,
  maxWidth = false,
}: ContainerProps): ReactElement => (
  <Wrapper maxWidth={maxWidth}>{children}</Wrapper>
);
