import { Box } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { isWeakNever } from '../../../../../utils/isNever';

type PartitionBoxProps = {
  $target?: boolean;
  $numPartitions: 1 | 2 | 4 | 8;
};

type PartitionContainerProps = Pick<PartitionBoxProps, '$numPartitions'>;

export const PartitionContainer = styled(Box)<PartitionContainerProps>`
  display: grid;
  aspect-ratio: 16/9;
  column-gap: ${({ theme }) => theme.spacing(1)}px;
  row-gap: ${({ theme }) => theme.spacing(1)}px;
  ${({ $numPartitions }) => {
    switch ($numPartitions) {
      case 1:
        return css`
          grid-template: 1fr / 1fr;
        `;
      case 2:
        return css`
          grid-template: 1fr / 1fr 1fr;
        `;
      case 4:
        return css`
          grid-template: 1fr 1fr / 1fr 1fr;
        `;
      case 8:
        return css`
          grid-template: 1fr 1fr / 1fr 1fr 1fr 1fr;
        `;
      default:
        isWeakNever($numPartitions);
    }
  }}
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const PartitionBox = styled(Box)<PartitionBoxProps>`
  background-color: ${({ theme }) => theme.palette.primary.light};
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: larger;
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;

  &.empty {
    background-color: ${({ theme }) => theme.palette.background.default};
    color: ${({ theme }) => theme.palette.common.dark};
    border: 1px solid ${({ theme }) => theme.palette.common.dark};
  }

  &.active {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.background.default};
  }
`;

export const PartitionSubtext = styled(Box)`
  position: absolute;
  right: 5px;
  bottom: 5px;
`;
