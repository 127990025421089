import { ReactElement } from 'react';
import { ReactComponent as GreenPaper } from '../../../assets//img/green-paper.svg';
import { ReactComponent as RedPaper } from '../../../assets//img/red-paper.svg';

export type PaperProps = {
  variant: 'red' | 'green';
};

export const Paper = ({ variant }: PaperProps): ReactElement => {
  return (
    <>
      {variant === 'red' && <RedPaper />}
      {variant === 'green' && <GreenPaper />}
    </>
  );
};
