import { ChangeEvent, ReactElement, useState } from 'react';
import { Button } from '../../../../components/warehouse/Button';
import { Modal } from '../../../../components/warehouse/Modal';
import { NumericInput } from '../../../../components/warehouse/NumericInput';
import { Text } from './ShortPickModal.elements';

export type ShortPickModalProps = {
  maximumNumberAvailable: number;
  closeModal: () => void;
  updateQuantity: (quantity: number) => void;
  allItemsMissing: () => void;
};

export const ShortPickModal = ({
  maximumNumberAvailable,
  closeModal,
  updateQuantity,
  allItemsMissing,
}: ShortPickModalProps): ReactElement => {
  const [numberAvailable, setNumberAvailable] = useState(0);

  const updateValue = (newValue: number) => setNumberAvailable(newValue);
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value);
    if (newValue >= 0 && newValue <= maximumNumberAvailable) {
      updateValue(newValue);
    }
  };

  return (
    <Modal
      isVisible
      header={{ text: 'How many items are available in the bin?' }}
      variant="danger"
      actions={[
        <Button variant="secondary" fullWidth onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          variant="danger"
          fullWidth
          onClick={() =>
            numberAvailable > 0
              ? updateQuantity(numberAvailable)
              : allItemsMissing()
          }
        >
          Update quantity
        </Button>,
      ]}
    >
      <NumericInput
        value={numberAvailable}
        onChange={onChange}
        setValue={updateValue}
        minValue={0}
        maxValue={maximumNumberAvailable}
      />
      <Text>
        Please ensure the quantity is correct. This update cannot be undone.
      </Text>
    </Modal>
  );
};
