import { ReactComponent as ArrowLeftIcon } from '../../../../assets/img/icons/arrow-left.svg';
import { Box } from '../../../../components/warehouse/Box';
import { Button } from '../../../../components/warehouse/Button';
import { warehouseColours } from '../../../../constants/colours';
import { SPACING_BASE } from '../../../../themes/WarehouseTheme';
import { LabelPrintingWrapper, PdfView } from './LabelPrinting.elements';

export type LabelPrintingProps = {
  labelUrl: string;
  onBackButtonClick: () => void;
};

export const LabelPrinting = ({
  labelUrl,
  onBackButtonClick,
}: LabelPrintingProps): JSX.Element => {
  return (
    <LabelPrintingWrapper>
      <Box padding={SPACING_BASE * 2}>
        <Button
          icon={<ArrowLeftIcon fill={warehouseColours.tertiary.contrastText} />}
          variant="tertiary"
          onClick={onBackButtonClick}
          testId="return-to-pack-and-print-button"
        >
          Pack and print
        </Button>
      </Box>
      <PdfView src={labelUrl} />
    </LabelPrintingWrapper>
  );
};
