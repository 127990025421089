"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InventoryEvent = exports.inventoryItemAllocatedResetSchema = exports.inventoryAllocatedResetSchema = void 0;
const zod_1 = require("zod");
const base_1 = require("./base");
const fulfilmentOrder_1 = require("./fulfilmentOrder");
const pick_1 = require("./pick");
const picklist_1 = require("./picklist");
const putaway_1 = require("./putaway");
const retailUnit_1 = require("./retailUnit");
const storage_1 = require("./storage");
const transfer_1 = require("./transfer");
exports.inventoryAllocatedResetSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('INVENTORY_ALLOCATED_RESET'),
    payload: zod_1.z.object({}),
});
exports.inventoryItemAllocatedResetSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('INVENTORY_ITEM_ALLOCATED_RESET'),
    payload: zod_1.z.object({
        merchantId: zod_1.z.string(),
        barcode: zod_1.z.string(),
    }),
});
exports.InventoryEvent = zod_1.z.union([
    transfer_1.transferCreatedSchema,
    transfer_1.transferCancelledSchema,
    putaway_1.putawayBinReturnedSchema,
    putaway_1.PutawayCompleted,
    fulfilmentOrder_1.fulfilmentOrderAcceptedSchema,
    pick_1.pickItemAddedToToteSchema,
    pick_1.missingItemConfirmedSchema,
    pick_1.pickItemRemovedFromToteSchema,
    fulfilmentOrder_1.fulfilmentOrderCancelledSchema,
    pick_1.shortPickRaisedSchema,
    picklist_1.resolutionPicklistCreatedSchema,
    storage_1.StockInBinAdjusted,
    fulfilmentOrder_1.fulfilmentOrderDeletedSchema,
    fulfilmentOrder_1.fulfilmentOrderLineItemsChangedSchema,
    fulfilmentOrder_1.orderItemsUnallocatedSchema,
    exports.inventoryAllocatedResetSchema,
    retailUnit_1.retailUnitRecordRemoved,
    exports.inventoryItemAllocatedResetSchema,
    fulfilmentOrder_1.fulfilmentOrderRejectedSchema,
]);
