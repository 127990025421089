import { FormHelperText } from '@material-ui/core';
import styled from 'styled-components';

interface ErrorTextProps {
  component?: string;
}

export const ErrorText = styled(FormHelperText).attrs(
  ({ component = 'span' }: ErrorTextProps) => ({
    component,
    error: true,
    role: 'alert',
  })
)``;
