"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.storageUnitApiSchema = void 0;
const zod_http_schemas_1 = require("zod-http-schemas");
const model_1 = require("../model");
exports.storageUnitApiSchema = (0, zod_http_schemas_1.createHttpSchema)({
    'GET /storage-units': {
        responseBody: zod_http_schemas_1.z.object({
            outcome: zod_http_schemas_1.z.literal('SUCCESS'),
            storageUnits: zod_http_schemas_1.z
                .union([model_1.autostoreBinWithRetailUnitsSchema, model_1.toteSchema])
                .array(),
        }),
    },
});
