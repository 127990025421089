import { NavigationLink } from '../components/admin/Navigation/Navigation';

export const matchBySimilarity = (
  target: string,
  matches: string[]
): string => {
  const targetAsArr = target.split('');
  const scores = matches.map(
    (m) => m.split('').filter((c, i) => c === targetAsArr[i]).length
  );
  const highestScore = Math.max(...scores);
  const highScoreIndex = scores.findIndex((s) => s === highestScore);
  return matches[highScoreIndex];
};

export const findBestLinkLabelForRoute = (
  links: NavigationLink[],
  route: string
) => {
  const closestRoute = matchBySimilarity(
    route,
    links.map((link) => link.path || '')
  );
  return links.find((link) => link.path === closestRoute)?.label || 'Home';
};
