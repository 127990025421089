"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createTransferResponse = exports.createTransferRequest = void 0;
const zod_http_schemas_1 = require("zod-http-schemas");
const transfer_1 = require("../commands/outcomes/transfer");
const model_1 = require("../model");
const fulfilmentOrder_1 = require("./fulfilmentOrder");
const createTransferRequestItem = model_1.retailUnitSchema
    .pick({
    barcode: true,
    externalId: true,
    depthMm: true,
    heightMm: true,
    imageSrc: true,
    name: true,
    options: true,
    sku: true,
    weightGrams: true,
    widthMm: true,
})
    .extend({ quantity: zod_http_schemas_1.z.number() });
exports.createTransferRequest = model_1.transferSchema
    .required({
    shipment: true,
})
    .pick({
    notes: true,
    isTest: true,
    shipment: true,
})
    .extend({
    senderName: zod_http_schemas_1.z.string(),
    externalId: zod_http_schemas_1.z.union([zod_http_schemas_1.z.string(), zod_http_schemas_1.z.number()]),
    transferNumberRef: zod_http_schemas_1.z.string(),
    parcelType: zod_http_schemas_1.z.string(),
    address: fulfilmentOrder_1.PostalAddressSchema,
    merchantId: zod_http_schemas_1.z.string(),
    items: createTransferRequestItem.array(),
});
const createTransferResponseErrorOutcomes = zod_http_schemas_1.z.union([
    transfer_1.CreateTransferSuccess,
    transfer_1.NoItemsFoundForTransfer,
    transfer_1.NoTransferNumberRefFoundForTransfer,
    transfer_1.TransferTotalWeightMustBePositive,
    transfer_1.NoWarehouseFound,
    transfer_1.LabelGenerationFailed,
    transfer_1.SaveLabelFailed,
    transfer_1.ItemsHaveInvalidWeight,
]);
const createTransferResponseSuccessOutcomes = transfer_1.CreateTransferSuccess;
exports.createTransferResponse = zod_http_schemas_1.z.union([
    createTransferResponseErrorOutcomes,
    createTransferResponseSuccessOutcomes,
]);
