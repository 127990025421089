import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 0 ${({ theme }) => theme.spacing(4)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(2)};
  width: 100%;
  max-width: fit-content;
`;
